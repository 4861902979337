import { Component, Input } from '@angular/core';

import {Abschnitt} from '../../../model/abschnitt';

@Component({
  selector: 'abschnitt-ops',
  templateUrl: './abschnitt-ops.component.html',
  styleUrls: ['./abschnitt-ops.component.scss']
})
export class AbschnittOpsComponent {

  @Input() abschnitt: Abschnitt

  constructor() {}
  
}
