/**
 * Excel-Datei hochladen
 * 
 * Attila Németh, UBG
 * 12.05.2020
 */
 
import {Component} from '@angular/core';

@Component({
  templateUrl: './upload.component.html',
})
export class GliederungenUploadDialogComponent {
  
  disabled: boolean = true
  fileToUpload: File = null
   
  constructor() {}
              
  fileSelected(event: Array<File>) {
    this.fileToUpload = event[0];
    this.disabled = false;
  }
  
}