<mat-dialog-content class="mat-body">
  <mat-progress-bar mode="buffer" *ngIf="isLoading" color="accent"></mat-progress-bar>
  <h1>{{ title }}</h1>
  <div class="beschluss" [innerHtml]="beschluss"></div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
  <button mat-raised-button color="primary" (click)="printBeschluss()">
    <mat-icon>print</mat-icon> In MS Word exportieren
  </button>
  <span fxFlex="1 1 auto"></span>
  <button mat-button color="accent" mat-dialog-close>
    <mat-icon>close</mat-icon> Schließen
  </button>
</mat-dialog-actions>