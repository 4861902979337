/**
 * Statistik
 * 
 * Attila Németh, UBG
 * 03.06.2020
 */
 
import {Component, Input} from '@angular/core';
import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';

import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'projekt-stat', 
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class ProjektStatComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
    }
  }
  projektObject: Projekt = null
  
  constructor(private snackbar: MatSnackBar,
                private projektService: ProjektService) {}
  
  printAll(): void {
    this.projektService.printAllStatistik(this.projektObject).subscribe((response: Blob) => {
      let fileName = 'stat.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
}