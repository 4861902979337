/**
 * Meine Anträge Startseite
 * 
 * Attila Németh, UBG
 * 27.08.2020
 */
 
import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {Projekt} from '../../../../model/projekt';
import {Gliederung} from '../../../../model/gliederung';
import {Antrag, AntragData} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';
import {AbschnittService} from '../../../../services/abschnitt.service';
import {AntragService} from '../../../../services/antrag.service';
import {AntragEvent} from '../../../../model/events';

@Component({
  selector: 'mein-antrag-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class ProjektMeinAntragHomeComponent implements OnInit, OnDestroy {
  
  @Input() set gliederung(gliederung: Gliederung) {
    this.gliederungObject = gliederung;
    this.loadAntraege();
  }
  gliederungObject: Gliederung = null
  @Input() set projekt(projekt: Projekt) {
    this.projektObject = projekt;
    this.loadAntraege();
  }
  projektObject: Projekt = null
  @Output() close = new EventEmitter()
  antraege: Array<AntragData> = []
  popupOpen: boolean = false
  antragEventSubscription: Subscription = null
  
  constructor(private abschnittService: AbschnittService,
                private antragService: AntragService) {}
                
  ngOnInit(): void {
    this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
      if (event !== null && (event.name == 'loaded')) {
        this.loadAntraege();
      }
    });
  }
  
  ngOnDestroy(): void {
    this.antragEventSubscription.unsubscribe();
  }
  
  loadAntraege(): void {
    if (this.gliederungObject !== null && this.projektObject !== null) {
      let newAntraege: Array<AntragData> = [];
      for (let i in this.projektObject.relationships.abschnitte.data) {
        this.abschnittService.get(this.projektObject.relationships.abschnitte.data[i].type, this.projektObject.relationships.abschnitte.data[i].id).subscribe((response: {
          data: Abschnitt
        }) => {
          this.abschnittService.getGliederungAntraege(response.data, this.gliederungObject).subscribe(antraege => {
            for (let j in antraege) {
              newAntraege.push({
                antrag: antraege[j],
                abschnitt: response.data,
              });
            }
            this.antraege = newAntraege.sort((antrag1: AntragData, antrag2: AntragData): number => {
              if (antrag1.abschnitt.attributes.buchstabe < antrag2.abschnitt.attributes.buchstabe) {
                return -1;
              }
              else if (antrag1.abschnitt.attributes.buchstabe > antrag2.abschnitt.attributes.buchstabe) {
                return +1;
              }
              else if (antrag1.abschnitt.attributes.buchstabe === antrag2.abschnitt.attributes.buchstabe) {
                const date1: Date = new Date(antrag1.antrag.attributes.changed);
                const date2: Date = new Date(antrag1.antrag.attributes.changed);
                if (date1 < date2) {
                  return -1;
                }
                else {
                  return +1;
                }
              }
            });
          });
        });
      }
    }
  }
  
  addAntrag(): void {
    this.popupOpen = true;
  }
  
  closePopup(): void {
    this.popupOpen = false;
  }
  
  toAbschnitte(): void {
    this.close.emit();
  }

}