/**
 * Leitantrag eintragen
 * 
 * Attila Németh, UBG
 * 22.05.2020
 */
 
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Abschnitt} from '../../../model/abschnitt';
import {Projekt} from '../../../model/projekt';
import {UserService} from '../../../services/user.service';
import {ProjektService} from '../../../services/projekt.service';
import {PageService} from '../../../services/page.service';
import {AbschnittService} from '../../../services/abschnitt.service';
import {Gliederung} from '../../../model/gliederung';
import {GliederungService} from '../../../services/gliederung.service';

export interface EmptyZiffer {
  nummer: number
  inhalt: string
}

@Component({
  selector: 'abschnitt-leitantrag',
  templateUrl: './leitantrag.component.html',
  styleUrls: ['./leitantrag.component.scss'],
})
export class AbschnittLeitantragComponent implements OnInit {
  
  abschnitt: Abschnitt = null
  projekt: Projekt = null
  abschnittId: string = null
  projektId: string = null
  isLoggedIn: number = -1
  zifferInhalte: Array<EmptyZiffer> = []
  gliederung: Array<Gliederung> = null
  gliederungForm: FormGroup
  disabled: boolean = true
  sortView: boolean = false
  
  constructor(route: ActivatedRoute,
                formBuilder: FormBuilder,
                private router: Router,
                private snackbar: MatSnackBar,
                userService: UserService,
                private projektService: ProjektService,
                private pageService: PageService,
                private abschnittService: AbschnittService,
                private gliederungService: GliederungService) {
    route.paramMap.subscribe((params) => {
      this.abschnittId = params.get('abschnittId');
      this.loadAbschnitt();
    });
    route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
      this.loadAbschnitt();
    });
    userService.userLoggedIn.subscribe((status: number) => {
      this.isLoggedIn = status;
      this.loadAbschnitt();
    });
    this.gliederungForm = formBuilder.group({
      gliederung: [null]
    });
  }
  
  ngOnInit(): void {
    this.gliederungForm.get('gliederung').valueChanges.subscribe((value: Array<Gliederung>) => {
      this.gliederung = value;
      this.updateDisabled();
    });
  }
  
  loadAbschnitt(): void {
    if (this.isLoggedIn == 1 && this.abschnittId !== null && this.projektId !== null) {
      this.projektService.get(this.projektId).subscribe((response: {
        data: Projekt
      }) => {
        this.projekt = response.data;
        this.pageService.setTitle(this.projekt.attributes.title);
        this.projektService.setActive(this.projekt);
      });
      this.abschnittService.getLeitantrag(this.abschnittId).subscribe(abschnitt => {
        this.abschnitt = abschnitt;
        if (abschnitt.relationships.antraege.data === undefined
          || abschnitt.relationships.antraege.data.length === 0) {
          this.gliederungService.getGliederungByName(this.projektId).subscribe(gliederung => {
            this.gliederungForm.get('gliederung').setValue([gliederung]);
          });
        }
        else {
          let zifferInhalte: Array<EmptyZiffer> = [];
          for (let i in abschnitt.relationships.antraege.data) {
            if (abschnitt.relationships.antraege.data[i].relationships.gliederung !== undefined) {
              this.gliederungForm.get('gliederung').setValue(abschnitt.relationships.antraege.data[i].relationships.gliederung.data);
            }
            zifferInhalte.push({
              nummer: abschnitt.relationships.antraege.data[i].relationships.ziffer.data.attributes.nummer,
              inhalt: abschnitt.relationships.antraege.data[i].relationships.ziffer.data.attributes.inhalt.value,
            });
          }
          this.zifferInhalte = zifferInhalte;
        }
        this.addEmptyZiffer();
      });
    }
  }
  
  addEmptyZiffer(): void {
    if (this.abschnitt !== null) {
      let zifferInhalte: Array<EmptyZiffer> = [];
      for (let i in this.zifferInhalte) {
        zifferInhalte.push(this.zifferInhalte[i]);
      }
      let newZiffer = {
        nummer: null,
        inhalt: null
      };   
      zifferInhalte.push(newZiffer);
      this.zifferInhalte = zifferInhalte;
    }
  }  
  
  updateDisabled(): void {
    let newDisabled = false;
    if (this.gliederung === null) {
      newDisabled = true;
    }
    if (this.zifferInhalte.length === 0) {
      newDisabled = true;
    }
    else {
      let emptyCount: number = 0;
      for (let i in this.zifferInhalte) {
        if (this.zifferInhalte[i].inhalt === null || this.zifferInhalte[i].inhalt === '') {
          emptyCount++;
        }
      }
      if (emptyCount > 1) {
        newDisabled = true;
      }
    }
    this.disabled = newDisabled;
  }
  
  zifferUpdated(): void {
    let n: number = 1;
    let hasEmpty: boolean = false;
    for (let i in this.zifferInhalte) {
      this.zifferInhalte[i].nummer = n;
      if (this.zifferInhalte[i].inhalt === null || this.zifferInhalte[i].inhalt === '') {
        hasEmpty = true;
      }
      n++;
    }
    if (!hasEmpty) {
      this.addEmptyZiffer();
    }
    this.updateDisabled();
  }
  
  zifferDeleted(event: EmptyZiffer): void {
    let newZiffern: Array<EmptyZiffer> = [];
    let n: number = 1;
    for (let i in this.zifferInhalte) {
      if (this.zifferInhalte[i].nummer !== event.nummer) {
        this.zifferInhalte[i].nummer = n;
        n++;
        newZiffern.push(this.zifferInhalte[i]);
      }
    }
    this.zifferInhalte = newZiffern;
  }
  
  activateSortView(): void {
    this.sortView = true;
  }
  
  drop(event: CdkDragDrop<string[]>) {
    let newZiffern: Array<EmptyZiffer> = [];
    for (let i in this.zifferInhalte) {
      newZiffern.push(this.zifferInhalte[i]);
    }
    moveItemInArray(newZiffern, event.previousIndex, event.currentIndex);
    let n: number = 1;
    for (let i in newZiffern) {
      newZiffern[i].nummer = n;
      n++;
    }
    this.zifferInhalte = newZiffern;
    this.sortView = false;
  }
  
  generateLeitantrag(): void {
    let ziffern: Array<string> = [];
    for (let i in this.zifferInhalte) {
      if (this.zifferInhalte[i].inhalt !== null && this.zifferInhalte[i].inhalt !== '') {
        ziffern.push(this.zifferInhalte[i].inhalt);
      }
    }
    this.abschnittService.generateLeitantrag(this.abschnitt, this.gliederung, ziffern).subscribe(() => {
      this.abschnittService.setActiveAbschnitt(this.abschnitt);
      this.router.navigate(['projekt/' + this.projekt.id + '/antraege']);
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
 
}