<form [formGroup]="ueberweisungForm">
  <mat-form-field>
    <input type="text" maxlength="240" matInput formControlName="title" required
           placeholder="Name">
    <mat-error>
      <span *ngIf="!ueberweisungForm.get('title').valid && ueberweisungForm.get('title').touched">
        Der Titel muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
</form>