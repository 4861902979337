<user-content>
  
  <div class="layout-margin" *ngIf="projekt !== null" fxLayout="row" fxLayoutGap="0.5rem">
    <div fxFlex="2 2 auto" class="dashboard">
      <mat-tab-group mat-stretch-tabs *ngIf="modal === null">
        <mat-tab>
          <ng-template mat-tab-label>
            Meine Anträge
          </ng-template>
          <ng-template matTabContent>
            <div class="dashboard-antrag-list">
              <antrag-meine [projekt]="projekt"></antrag-meine>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="editAccess">
          <ng-template mat-tab-label>
            Alle Anträge
          </ng-template>
          <ng-template matTabContent>
            <div class="dashboard-antrag-list">
              <antrag-offene [projekt]="projekt"></antrag-offene>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Statistiken
          </ng-template>
          <ng-template matTabContent>
            <projekt-stat [projekt]="projekt"></projekt-stat>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <projekt-abschnitte *ngIf="modal=='abschnitte'" [ngClass]="modalClass" [projekt]="projekt"></projekt-abschnitte>
      <projekt-themen *ngIf="modal=='themen'" [ngClass]="modalClass" [projekt]="projekt"></projekt-themen>
      <projekt-ueberweisungen *ngIf="modal=='ueberweisungen'" [ngClass]="modalClass" [projekt]="projekt"></projekt-ueberweisungen>
      <projekt-redner *ngIf="modal=='redner'" [ngClass]="modalClass" [projekt]="projekt"></projekt-redner>
      <projekt-gliederungen [projekt]="projekt" *ngIf="modal=='gliederungen'" [ngClass]="modalClass"></projekt-gliederungen>
    </div>
    <mat-action-list fxFlex="1 1 14rem" fxFlex.gt-md="1 1 16rem" *ngIf="modal!='gliederungen'">
      <button mat-list-item (click)="openAntraege()">
        Anträge
      </button>
      <button mat-list-item (click)="openMeinAntrag()" *ngIf="editAccess">
        Gliederungsanträge
      </button>
      <mat-divider></mat-divider>
      <button mat-list-item [matMenuTriggerFor]="editMenu" *ngIf="editAccess">
         Bearbeiten
      </button>
      <mat-menu #editMenu="matMenu">
        <button mat-menu-item (click)="editProjekt()">Projektdaten</button>
        <button mat-menu-item (click)="editMail()">E-Mail-Einstellungen</button>
        <button mat-menu-item (click)="banner()">Banner</button>
      </mat-menu>
      <button mat-list-item (click)="openModal('abschnitte')" [disabled]="modal=='abschnitte'"
              *ngIf="editAccess">
        Buchstaben
      </button>
      <button mat-list-item (click)="openModal('themen')" [disabled]="modal=='themen'"
              *ngIf="editAccess">
        Themen
      </button>
      <button mat-list-item (click)="openModal('ueberweisungen')" [disabled]="modal=='ueberweisungen'"
              *ngIf="editAccess">
        Überweisungen
      </button>
      <button mat-list-item (click)="openModal('redner')" [disabled]="modal=='redner'"
              *ngIf="editAccess">
        Redner der AK
      </button>
      <button mat-list-item (click)="openModal('gliederungen')" *ngIf="editAccess">
        Antragsberechtigte Gliederungen
      </button>
    </mat-action-list>
    <gliederung-operations fxFlex="1 1 auto" [projekt]="projekt" *ngIf="modal=='gliederungen'"></gliederung-operations>
  </div>
  
</user-content>