/**
 * Projekt auswählen Dialog
 * 
 * Attila Németh, UBG
 * 26.05.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';

import {Projekt} from '../../../../model/projekt';
import {ProjektService} from '../../../../services/projekt.service';

@Component({
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class ProjektSelectDialogComponent implements OnInit {
  
  disabled: boolean = true
  selectedProjekt: Projekt = null
  projektOptions: Array<Projekt> = []
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                exclude: Array<Projekt>
              },
              private projektService: ProjektService) {
  }
              
  ngOnInit(): void {
    this.projektService.getAll().subscribe((response: {
      data: Array<Projekt>
    }) => {
      let newProjekte: Array<Projekt> = [];
      let isExcluded: boolean
      for (let i in response.data) {
        isExcluded = false;
        for (let j in this.data.exclude) {
          if (response.data[i].id == this.data.exclude[j].id) {
            isExcluded = true;
          }
        }
        if (!isExcluded) {
          newProjekte.push(response.data[i]);
        }
      }
      this.projektOptions = newProjekte;
    });
  }
  
  projektSelected(event: MatSelectChange): void {
    this.selectedProjekt = event.value;
    this.disabled = false;
  }
  
}