/**
 * Gemeinsames Votum löschen
 * 
 * Attila Németh, UBG
 * 26.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Votum} from '../../../../model/votum';
import {Redner} from '../../../../model/redner';
import {Antrag} from '../../../../model/antrag';

@Component({
  templateUrl: './gemeinsam_delete.component.html',
  styleUrls: ['./gemeinsam_delete.component.scss'],
})
export class VotumGemeinsamDeleteDialogComponent {
  
  rednerOptions: Array<Redner> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                  votum: Votum,
                  antrag: Antrag,
                }) {
  }
  
}