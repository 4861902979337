/**
 * Abschnitt-Operationen und Suche
 * 
 * Attila Németh, UBG
 * 20.05.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Abschnitt} from '../../../model/abschnitt';
import {Antrag} from '../../../model/antrag';
import {AbschnittService} from '../../../services/abschnitt.service';
import {AntragNummerSchema, AntragService} from '../../../services/antrag.service';
import {AbschnittPrintDialogComponent} from '../dialog/print/print.component';
import {AbschnittPrintLeitantragDialogComponent} from '../dialog/print_leitantrag/print_leitantrag.component';
import {AbschnittGemeinsamDialogComponent} from '../dialog/gemeinsam/gemeinsam.component';
import {AbschnittBeschlussDialogComponent} from '../dialog/beschluss/beschluss.component';
import {PageService} from '../../../services/page.service';
import { PrintBegruendungOptions, ProjektBegruendungPrintDialogComponent } from '../../projekt/dialog/print-begruendung/print-begruendung';

@Component({
  selector: 'abschnitt-operations',
  templateUrl: './operations.component.html',
//  styleUrls: ['./operations.component.scss'],
})
export class AbschnittOperationsComponent implements OnInit, OnDestroy {
    
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
      this.abschnittService.editPermission(this.abschnittObject).subscribe(response => {
        this.sortPermission = response;
      });
      if (this.abschnittObject !== undefined && this.abschnittObject.relationships !== undefined 
        && this.abschnittObject.relationships.antraege.data !== null
        && this.abschnittObject.relationships.antraege.data.length > 0) {
        for (let i in this.abschnittObject.relationships.antraege.data) {
          this.antragService.editPermission(this.abschnittObject.relationships.antraege.data[i]).subscribe(result => {
            if (!result) {
              this.leitantragPermission = false;
            }
          });
        }
      }
    }
  }
  abschnittObject: Abschnitt = null
  projektId: string = null
  sortPermission: boolean = false
  dragSubscription: Subscription
  isDragged: boolean = false
  antragSubscription: Subscription
  selectedAntraege: Array<Antrag> = []
  leitantragPermission: boolean = true
  isSorting: boolean = false
  sortPercent: number = 0
  sortBack: number = 0
  
  constructor(route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private abschnittService: AbschnittService,
                private antragService: AntragService,
                private pageService: PageService) {
    route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
    });
  }
  
  ngOnInit(): void {
    this.dragSubscription = this.abschnittService.dragAbschnitt.subscribe(abschnitt => {
      if (abschnitt !== null && this.abschnittObject !== null 
        && abschnitt.id === this.abschnittObject.id) {
        this.isDragged = true;
      }
      else {
        this.isDragged = false;
      }
    });
    this.antragSubscription = this.antragService.antragSelected.subscribe(selected => {
      this.selectedAntraege = selected;
    });
  }
  
  ngOnDestroy(): void {
    this.dragSubscription.unsubscribe();
    this.antragSubscription.unsubscribe();
  }
  
  editLeitantrag(): void {
    this.pageService.setBackRoute('projekt/' + this.projektId + '/antraege');
    const path = 'projekt/' + this.projektId + '/' + this.abschnittObject.id + '/leitantrag';
    this.router.navigate([path]);
  }
  
  sortAntraege(): void {
    this.isSorting = true;
    // this.abschnittService.setQueryQAbschnitt(this.abschnittObject);
    this.abschnittService.sortAntraege(this.abschnittObject).subscribe((nummer: Array<AntragNummerSchema>) => {
      this.isSorting = false;
      this.setAntragNummer(nummer);
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
      this.isSorting = false;
    });
  }

  async setAntragNummer(nummern: Array<AntragNummerSchema>) {
    let count: number = 0;
    const start: Date = new Date();
    for (let nummer of nummern) {
      let antrag: Antrag = await this.antragService.getAntrag(nummer.type, nummer.id).toPromise();
      antrag.attributes.nummer = nummer.nummer;
      try {
        await this.antragService.updateAntrag(antrag).toPromise();
      }
      catch(error) {
        console.warn(error);
      }
      count++;
      this.sortPercent = count / nummern.length * 100;
      let now: Date = new Date();
      let diff: number = now.getTime() - start.getTime();
      let est: number = diff / count * nummern.length;
      this.sortBack = (est-diff)/1000;
      if (count === nummern.length) {
        this.antragService.setUpdateLastAntrag();
        this.snackbar.open('Die Anträge wurden neu sortiert', null, {
          duration: 6000,
        });
        this.abschnittService.setUpdateAbschnitt(this.abschnittObject);
        this.sortPercent = 0;
      }
    }
  }

  formatBack(back: number): string {
    const s: number = Math.floor(back);
    if (s < 60) {
      return s.toString();
    }
    else {
      const min: number = Math.floor(s/60);
      const sec: string = ('00' + (s - min*60).toString()).slice(-2);
      return min.toString() + ':' + sec;
    }
  }
  
  dragAntraege(): void {
    this.abschnittService.setDragAbschnitt(this.abschnittObject);
  }

  dragNoAntraege(): void {
    this.abschnittService.setDragAbschnitt(null);
  }
  
  abschnittPrint(): void {
    this.dialog.open(AbschnittPrintDialogComponent, {
      data: this.abschnittObject
    }).afterClosed().subscribe(result => {
      if (result !== undefined && result !== '0') {
        this.abschnittService.printAbschnitt(this.abschnittObject, result).subscribe((response: Blob) => {
          let fileName = this.abschnittObject.attributes.buchstabe + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  abschnittLeitantragPrint(): void {
    this.dialog.open(AbschnittPrintLeitantragDialogComponent, {
      data: this.abschnittObject
    }).afterClosed().subscribe(result => {
      if (result !== undefined && result !== '0') {
        this.abschnittService.printLeitantrag(this.abschnittObject, result).subscribe((response: Blob) => {
          let fileName = 'leitantrag-' + this.abschnittObject.attributes.buchstabe + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  leitantragEditable(): boolean {
    if (this.abschnittObject.type !== 'ubg_abschnitt--mit_leitantrag') {
      return false;
    }
    if (this.abschnittObject.relationships.antraege.data.length === 0) {
      return false;
    }
    for (let i in this.abschnittObject.relationships.antraege.data) {
      if (this.abschnittObject.relationships.antraege.data[i].type !== 'ubg_antrag--leitantrag') {
        return false;
      }
    }
    if (!this.leitantragPermission) {
      return false;
    }
    return true;
  }
  
  setGemeinsam(): void {
    this.dialog.open(AbschnittGemeinsamDialogComponent, {
      data: this.selectedAntraege,
    }).afterClosed().subscribe(result => {
      if (result !== 0 && result !== undefined) {
        this.antragService.setGemeinsam(result['antrag'][0], result['antrag'], result['votum'], result['begruendung']).subscribe(() => {
          this.abschnittService.setUpdateAbschnitt(this.abschnittObject);
          for (let i in result['antrag']) {
            this.antragService.deselectAntrag(result['antrag'][i]);
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  beschluss(): void {
    this.dialog.open(AbschnittBeschlussDialogComponent, {
      data: {
        projektId: this.projektId,
        abschnitt: this.abschnittObject,
      }
    });
  }

  printBegruendungen(): void {
//    this.dialog.open(ProjektBegruendungPrintDialogComponent).afterClosed().subscribe((result: PrintBegruendungOptions|null) => {
//      if (result !== null && result !== undefined) {
        const result: PrintBegruendungOptions = {
          votum: false,
        };
        this.abschnittService.printBegruendungen(this.abschnittObject, result).subscribe((response: Blob) => {
          let fileName = 'begruendungen-' + this.abschnittObject.attributes.buchstabe.toLocaleLowerCase() + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
//      }
//    });
  }

}