import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms'; 
import { FlexLayoutModule } from '@angular/flex-layout';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {AttusBannerModule} from '@attus/banner';

import {DrupalModule} from '../../../projects/ubg/drupal/src/lib/drupal.module';
import {ConfirmModule} from '@ubg/confirm';
import {UbgUploadModule} from '@ubg/upload';
import {UbgCkeTextModule} from '@ubg/cketext';

import {ProjektListComponent} from './components/projekt/list/projekt-list.component';
import {UserContentComponent} from './components/user/content/content.component';
import {ProjektEditDialogComponent} from './components/projekt/dialog/edit.component';
import {ProjektEditComponent} from './components/projekt/edit/edit.component';
import {ProjektDashboardComponent} from './components/projekt/dashboard/dashboard.component';
import {ProjektHeaderComponent} from './components/projekt/header/header.component';
import {ProjektHomeComponent} from './components/projekt/home/home.component';
import {ProjektAbschnitteComponent} from './components/projekt/abschnitte/abschnitte.component';
import {AbschnittEditDialogComponent} from './components/abschnitt/dialog/edit.component';
import {AbschnittEditComponent} from './components/abschnitt/edit/edit.component';
import {ProjektThemenComponent} from './components/projekt/themen/themen.component';
import {ThemaElementComponent} from './components/thema/element/element.component';
import {ThemaEditDialogComponent} from './components/thema/dialog/edit.component';
import {ProjektUeberweisungenComponent} from './components/projekt/ueberweisungen/ueberweisungen.component';
import {UeberweisungEditDialogComponent} from './components/ueberweisung/dialog/edit/edit.component';
import {UeberweisungEditComponent} from './components/ueberweisung/edit/edit.component';
import {UeberweisungGruppeEditDialogComponent} from './components/ueberweisung/dialog/edit_gruppe/edit.component';
import {UeberweisungGruppeEditComponent} from './components/ueberweisung/edit_gruppe/edit.component';
import {UeberwieunsgGruppeComponent} from './components/ueberweisung/gruppe/gruppe.component';
import {UeberweisungComponent} from './components/ueberweisung/element/element.component';
import {ProjektRednerComponent} from './components/projekt/redner/redner.component';
import {RednerComponent} from './components/redner/view/view.component';
import {RednerEditDialogComponent} from './components/redner/dialog/edit/edit.component';
import {ProjektGliederungenComponent} from './components/projekt/gliederungen/gliederungen.component';
import {GliederungListComponent} from './components/gliederung/list/list.component';
import {GliederungOperationsComponent} from './components/gliederung/operations/operations.component';
import {GliederungenUploadDialogComponent} from './components/gliederung/dialog/upload/upload.component';
import {GliederungKategorieComponent} from './components/gliederung/kategorie/kategorie.component';
import {GliederungKategorieDialogComponent} from './components/gliederung/dialog/kategorie/kategorie.component';
import {GliederungComponent} from './components/gliederung/element/element.component';
import {GliederungDialogComponent} from './components/gliederung/dialog/gliederung/gliederung.component';
import {GliederungKategorieClosedComponent} from './components/gliederung/kategorie/closed/closed.component';
import {GliederungKategorieOpenComponent} from './components/gliederung/kategorie/open/open.component';
import {ProjektAntraegeComponent} from './components/projekt/antraege/antraege.component';
import {AbschnittSelektorComponent} from './components/abschnitt/selektor/selektor.component';
import {AbschnittComponent} from './components/abschnitt/abschnitt/abschnitt.component';
import {AbschnittOhneLeitantragComponent} from './components/abschnitt/abschnitt/ohne_leitantrag/abschnitt.component';
import {AbschnittInititivantraegeComponent} from './components/abschnitt/abschnitt/initiativantraege/initiativantraege.component';
import {AntragOhneLeitantragEditComponent} from './components/antrag/ohne_leitantrag/edit/edit.component';
import {AntragInitiativantraegeEditComponent} from './components/antrag/initiativantraege/edit/edit.component';
import {AntragZumLeitantragEditComponent} from './components/antrag/zum_leitantrag/edit/edit.component';
import {AntragEditDialogComponent} from './components/antrag/dialog/antrag/antrag.component';
import {GliederungSelectComponent} from './components/projekt/gliederung/select/select.component';
import {AntragOhneLeitantragComponent} from './components/antrag/ohne_leitantrag/view/view.component';
import {AntragInitiativantraegeComponent} from './components/antrag/initiativantraege/view/view.component';
import {AntragHeaderComponent} from './components/antrag/components/header/header.component';
import {AbschnittMitLeitantragComponent} from './components/abschnitt/abschnitt/mit_leitantrag/abschnitt.component';
import {AbschnittOperationsComponent} from './components/abschnitt/operations/operations.component';
import {AbschnittLeitantragComponent} from './components/abschnitt/leitantrag/leitantrag.component';
import {ZifferCreateComponent} from './components/ziffer/create/create.component';
import {AntragLeitantragComponent} from './components/antrag/leitantrag/view/view.component';
import {AntragContainerComponent} from './components/antrag/components/container/container.component';
import {AntragContentComponent} from './components/antrag/components/content/content.component';
import {ZifferViewComponent} from './components/ziffer/view/view.component';
import {ProjektOperationsComponent} from './components/projekt/operations/operations.component';
import {ProjektAntragPopupComponent} from './components/projekt/operations/popup/antrag/antrag.component';
import {AntragMixedEditComponent} from './components/antrag/mixed_edit/mixed_edit.component';
import {ProjektSelectDialogComponent} from './components/projekt/dialog/select/select.component';
import {AntragZumLeitantragComponent} from './components/antrag/zum_leitantrag/view/view.component';
import {AntragInhaltComponent} from './components/antrag/components/inhalt/inhalt.component';
import {AntragDragDropComponent} from './components/antrag/drag_drop/drag_drop.component';
import {ProjektMeinAntragComponent} from './components/projekt/mein/mein.component';
import {ProjektMeinAntragLoginComponent} from './components/projekt/mein/login/login.component';
import {ProjektMeinAntragListComponent} from './components/projekt/mein/list/list.component';
import {ProjektMeinAntragListOhneLeitantragComponent} from './components/projekt/mein/list/ohne_leitantrag/list.component';
import {ProjektMeinAntragHomeComponent} from './components/projekt/mein/home/home.component';
import {AbschnittFilterComponent} from './components/abschnitt/filter/filter.component';
import {ProjektMeinAntragListMitLeitantragComponent} from './components/projekt/mein/list/mit_leitantrag/list.component';
import {AntragMeinLeitantragComponent} from './components/antrag/leitantrag/mein/mein.component';
import {AntragMenuComponent} from './components/antrag/components/menu/menu.component';
import {ProjektEmailDialogComponent} from './components/projekt/dialog/mail/mail.component';
import {ProjektStatComponent} from './components/projekt/stat/stat.component';
import {ProjektStatElementComponent} from './components/projekt/stat/element/element.component';
import {AntragOffeneComponent} from './components/antrag/offene/offene.component';
import {AntragViewDialogComponent} from './components/antrag/dialog/view/view.component';
import {AntragZuweisungDialogComponent} from './components/antrag/dialog/zuweisung/zuweisung.component';
import {AntragMeineComponent} from './components/antrag/meine/meine.component';
import {VotumEditComponent} from './components/votum/edit/edit.component';
import {VotumEditDialogComponent} from './components/votum/dialog/edit/edit.component';
import {AntragDeleteDialogComponent} from './components/antrag/dialog/delete/delete.component';
import {VotumBegruendungEditDialogComponent} from './components/votum/dialog/begruendung/begruendung.component';
import {AntragPrintDialogComponent} from './components/antrag/dialog/print/print.component';
import {AntragGemeinsamDialogComponent} from './components/antrag/dialog/gemeinsam/gemeinsam.component';
import {VotumDeleteDialogComponent} from './components/antrag/dialog/votum_delete/votum_delete.component';
import {VotumRednerDialogComponent} from './components/votum/dialog/redner/redner.component';
import {AbschnittPrintDialogComponent} from './components/abschnitt/dialog/print/print.component';
import {AntragThemaDialogComponent} from './components/antrag/dialog/thema/thema.component';
import {AntragMarkupDialogComponent} from './components/antrag/dialog/markup/markup.component';
import {VotumGemeinsamDeleteDialogComponent} from './components/votum/dialog/gemeinsam_delete/gemeinsam_delete.component';
import {AbschnittPrintLeitantragDialogComponent} from './components/abschnitt/dialog/print_leitantrag/print_leitantrag.component';
import {ProjektBroschuerePrintDialogComponent} from './components/projekt/dialog/print/print.component';
import {ProjektBannerDialogComponent} from './components/projekt/dialog/banner/banner.component';
import {ProjektBeschlussComponent} from './components/projekt/beschluss/beschluss.component';
import {AntragCheckboxComponent} from './components/antrag/components/checkbox/checkbox.component';
import {AbschnittGemeinsamDialogComponent} from './components/abschnitt/dialog/gemeinsam/gemeinsam.component';
import {AbschnittBeschlussDialogComponent} from './components/abschnitt/dialog/beschluss/beschluss.component';
import {AbschnittOpsComponent} from './components/abschnitt/abschnitt-ops/abschnitt-ops.component';
import {UeberweisungSelectComponent} from './components/ueberweisung/select/select.component';
import {ProjektCloneDialogComponent} from './components/projekt/dialog/clone/clone.component';

import {AntragService} from './services/antrag.service';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ProjektBegruendungPrintDialogComponent } from './components/projekt/dialog/print-begruendung/print-begruendung';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const routes: Routes = [
  {
    path: '',
    component: ProjektHomeComponent,
  },
  {
    path: 'projekte',
    component: ProjektListComponent,
  },
  {
    path: 'projekt/:id',
    component: ProjektDashboardComponent,
  },
  {
    path: 'projekt/:id/antraege',
    component: ProjektAntraegeComponent,
  },
  {
    path: 'projekt/:id/mein-antrag',
    component: ProjektMeinAntragComponent,
  },
  {
    path: 'projekt/:id/beschluss',
    component: ProjektBeschlussComponent,
  },
  {
    path: 'projekt/:id/:abschnittId/leitantrag',
    component: AbschnittLeitantragComponent,
  },
];

@NgModule({
  declarations: [
    ProjektListComponent,
    UserContentComponent,
    ProjektEditDialogComponent,
    ProjektEditComponent,
    ProjektDashboardComponent,
    ProjektHeaderComponent,
    ProjektHomeComponent,
    ProjektAbschnitteComponent,
    AbschnittEditDialogComponent,
    AbschnittEditComponent,
    ProjektThemenComponent,
    ThemaElementComponent,
    ThemaEditDialogComponent,
    ProjektUeberweisungenComponent,
    UeberweisungEditDialogComponent,
    UeberweisungEditComponent,
    UeberweisungGruppeEditDialogComponent,
    UeberweisungGruppeEditComponent,
    UeberwieunsgGruppeComponent,
    UeberweisungComponent,
    ProjektRednerComponent,
    RednerComponent,
    RednerEditDialogComponent,
    ProjektGliederungenComponent,
    GliederungListComponent,
    GliederungOperationsComponent,
    GliederungenUploadDialogComponent,
    GliederungKategorieComponent,
    GliederungKategorieDialogComponent,
    GliederungComponent,
    GliederungDialogComponent,
    GliederungKategorieClosedComponent,
    GliederungKategorieOpenComponent,
    ProjektAntraegeComponent,
    AbschnittSelektorComponent,
    AbschnittComponent,
    AbschnittOhneLeitantragComponent,
    AbschnittInititivantraegeComponent,
    AntragOhneLeitantragEditComponent,
    AntragInitiativantraegeEditComponent,
    AntragZumLeitantragEditComponent,
    AntragEditDialogComponent,
    GliederungSelectComponent,
    AntragOhneLeitantragComponent,
    AntragInitiativantraegeComponent,
    AntragHeaderComponent,
    AbschnittMitLeitantragComponent,
    AbschnittOperationsComponent,
    AbschnittLeitantragComponent,
    ZifferCreateComponent,
    AntragLeitantragComponent,
    AntragContainerComponent,
    AntragContentComponent,
    ZifferViewComponent,
    ProjektOperationsComponent,
    ProjektAntragPopupComponent,
    AntragMixedEditComponent,
    ProjektSelectDialogComponent,
    AntragZumLeitantragComponent,
    AntragInhaltComponent,
    AntragDragDropComponent,
    ProjektMeinAntragComponent,
    ProjektMeinAntragLoginComponent,
    ProjektMeinAntragListComponent,
    ProjektMeinAntragListOhneLeitantragComponent,
    ProjektMeinAntragHomeComponent,
    AbschnittFilterComponent,
    ProjektMeinAntragListMitLeitantragComponent,
    AntragMeinLeitantragComponent,
    AntragMenuComponent,
    ProjektEmailDialogComponent,
    ProjektStatComponent,
    ProjektStatElementComponent,
    AntragOffeneComponent,
    AntragViewDialogComponent,
    AntragZuweisungDialogComponent,
    AntragMeineComponent,
    VotumEditComponent,
    VotumEditDialogComponent,
    AntragDeleteDialogComponent,
    VotumBegruendungEditDialogComponent,
    AntragPrintDialogComponent,
    AntragGemeinsamDialogComponent,
    VotumDeleteDialogComponent,
    VotumRednerDialogComponent,
    AbschnittPrintDialogComponent,
    AntragThemaDialogComponent,
    AntragMarkupDialogComponent,
    VotumGemeinsamDeleteDialogComponent,
    AbschnittPrintLeitantragDialogComponent,
    ProjektBroschuerePrintDialogComponent,
    ProjektBegruendungPrintDialogComponent,
    ProjektBannerDialogComponent,
    ProjektBeschlussComponent,
    AntragCheckboxComponent,
    AbschnittGemeinsamDialogComponent,
    AbschnittBeschlussDialogComponent,
    AbschnittOpsComponent,
    UeberweisungSelectComponent,
    ProjektCloneDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule, 
    ReactiveFormsModule,
    FlexLayoutModule,
    DragDropModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSortModule,
    MatProgressSpinnerModule,
    AttusBannerModule,
    DrupalModule,
    ConfirmModule,
    UbgUploadModule,
    UbgCkeTextModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE, 
      useValue: 'de-DE'
    },
    AntragService,
    {
      provide: APP_INITIALIZER,
      useFactory: (antragService: AntragService) => {
        return () => antragService.initialize()
      },
      deps: [AntragService],
      multi: true
    }
  ],
  entryComponents: [
    ProjektEditDialogComponent,
    AbschnittEditDialogComponent,
    ThemaEditDialogComponent,
    UeberweisungEditDialogComponent,
    UeberweisungGruppeEditDialogComponent,
    RednerEditDialogComponent,
    GliederungenUploadDialogComponent,
    GliederungKategorieDialogComponent,
    GliederungDialogComponent,
    AntragEditDialogComponent,
    ProjektSelectDialogComponent,
    ProjektEmailDialogComponent,
    AntragViewDialogComponent,
    AntragZuweisungDialogComponent,
    VotumEditDialogComponent,
    AntragDeleteDialogComponent,
    VotumBegruendungEditDialogComponent,
    AntragPrintDialogComponent,
    AntragGemeinsamDialogComponent,
    VotumDeleteDialogComponent,
    VotumRednerDialogComponent,
    AbschnittPrintDialogComponent,
    AntragThemaDialogComponent,
    AntragMarkupDialogComponent,
    VotumGemeinsamDeleteDialogComponent,
    AbschnittPrintLeitantragDialogComponent,
    ProjektBroschuerePrintDialogComponent,
    ProjektBegruendungPrintDialogComponent,
    ProjektBannerDialogComponent,
    AbschnittGemeinsamDialogComponent,
    AbschnittBeschlussDialogComponent,
    ProjektCloneDialogComponent,
  ],
  exports: [
    ProjektHeaderComponent,
  ],
})
export class AntragswesenModule { }
