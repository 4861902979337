/**
 * Gliederung-Kategorie - auf
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {GliederungKategorie, Gliederung} from '../../../../model/gliederung';
import {GliederungKategorieDialogComponent} from '../../dialog/kategorie/kategorie.component';
import {GliederungService} from '../../../../services/gliederung.service';
import {GliederungDialogComponent} from '../../dialog/gliederung/gliederung.component';

@Component({
  selector: 'gliederung-kategorie-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss']
})
export class GliederungKategorieOpenComponent implements OnInit {
  
  @Input() set kategorie(kategorie: GliederungKategorie) {
    this.kategorieObject = kategorie;
  }
  @Output() close = new EventEmitter
  kategorieObject: GliederungKategorie = null
  className: string = 'wrapper'
  showFilter: boolean = false
  filterForm: FormGroup
  filters: {
    name: string
  }
  isLoading: boolean = false
    
  constructor(private formBuilder : FormBuilder, 
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private gliederungService: GliederungService) {
    this.filters = {
      name: null,
    };
    this.filterForm = this.formBuilder.group({
      name: [null],
    });
    this.filterForm.get('name').valueChanges.subscribe((value: string) => {
      this.filters.name = value.toLowerCase();
    });
  }
  
  ngOnInit(): void {
    setTimeout(() => {
      this.className = 'wrapper open mat-elevation-z2';
    }, 50);
  }
  
  closeWindow(): void {
    this.className = 'wrapper mat-elevation-z2';
    setTimeout(() => {
      this.close.emit();
    }, 200);
  }
  
  gliederungDeleted(): void {
    this.isLoading = true;
    this.gliederungService.getKategorie(this.kategorieObject.id).subscribe((kategorie) => {
      let newGliederungen: Array<Gliederung> = [];
      for (let i in kategorie.relationships.gliederungen.data) {
        if (kategorie.relationships.gliederungen.data[i].id !== 'missing'
          || kategorie.relationships.gliederungen.data[i].id !== 'missing') {
          newGliederungen.push(kategorie.relationships.gliederungen.data[i]);
        }
      }
      kategorie.relationships.gliederungen.data = newGliederungen;
      this.gliederungService.updateKategorie(kategorie).subscribe((kategorie) => {
        this.gliederungService.getKategorieGliederungen(kategorie).subscribe(kategorie => {
          this.kategorieObject = kategorie;
          this.isLoading = false;
        });
      }, (error: string) => {
        this.isLoading = false;
        this.snackbar.open(error, null, {
          duration: 15000,
        })
      });
    });
  }
  
  editKategorie() {
    this.dialog.open(GliederungKategorieDialogComponent, {
      data: this.kategorieObject,
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.gliederungService.getKategorie(this.kategorieObject.id).subscribe((kategorie) => {
          kategorie.attributes.title = result['attributes']['title'];
          if (result['attributes']['kurzb'] !== undefined) {
            kategorie.attributes.kurzb = result['attributes']['kurzb'];
          } 
          this.gliederungService.updateKategorie(kategorie).subscribe((kategorie) => {
            this.kategorieObject = kategorie;
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            })
          });
        });
      }
    });
  }
  
  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }
  
  matchFilters(gliederung: Gliederung): boolean {
    let matches: boolean = true;
    if (this.filters.name !== null) {
      if (gliederung.attributes.title.toLowerCase().indexOf(this.filters.name) < 0) {
        matches = false;
      }
    }
    return matches;
  }
  
  resetFilter(property: string): void {
    this.filters[property] = null;
    this.filterForm.get(property).setValue('');
  }
  
  addGliederung(): void {
    let gliederung: Gliederung = new Gliederung;
    gliederung.create();
    this.dialog.open(GliederungDialogComponent, {
      data: gliederung,
    }).afterClosed().subscribe(result => {
      if (result !== '0' && result !== undefined) {
        this.isLoading = true;
        this.gliederungService.createGliederung(result).subscribe((gliederung) => {
          this.gliederungService.getKategorie(this.kategorieObject.id).subscribe((kategorie) => {
            kategorie.relationships.gliederungen.data.push(gliederung);
            this.gliederungService.updateKategorie(kategorie).subscribe((kategorie) => {
              this.gliederungService.getKategorieGliederungen(kategorie).subscribe(kategorie => {
                this.kategorieObject = kategorie;
                this.isLoading = false;
              });
            }, (error: string) => {
              this.isLoading = false;
              this.snackbar.open(error, null, {
                duration: 15000,
              })
            });
          });
        });
      }
    });
  }
  
}