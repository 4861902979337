/**
 * Antrag-Container
 * 
 * Attila Németh, UBG
 * 25.05.2020
 */
 
import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';
import {AntragEvent} from '../../../../model/events';

@Component({
  selector: 'antrag-container',
  templateUrl: './container.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragContainerComponent implements OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.updateAntragClass();
      this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
        if (event !== null && event.antrag.id === this.antragObject.id) {
          switch (event.name) {
            case 'load':
              this.isLoading = true;
              break;
            case 'loaded':
              this.isLoading = false;
              break;
            default:
              console.error(event.name);
          }
        }
      });
    }
  }
  antragObject: Antrag = null
  antragClass: string = null
  antragEventSubscription: Subscription = null
  isLoading: boolean = false;
  
  constructor(private antragService: AntragService) {}
  
  ngOnDestroy(): void {
    if (this.antragEventSubscription !== null) {
      this.antragEventSubscription.unsubscribe();
    }
  }
 
  private updateAntragClass(): void {
    let classes: Array<string> = [
      'mat-elevation-z2',
      'antrag-container',
    ];
    if (this.antragObject === null) {
      classes.push('null');
    }
    else {
      classes.push('type-' + this.antragObject.type);
      if (this.antragObject.relationships !== undefined) {
        if (this.antragObject.relationships.folgeantrag !== undefined
              && this.antragObject.relationships.folgeantrag.data !== undefined
              && this.antragObject.relationships.folgeantrag.data !== null) {
          classes.push('antrag-anchor');
        }
      }
    }
    if (this.antragObject.attributes.status == false) {
      classes.push('antrag-entwurf');
    }
    if (this.antragObject.attributes.votum !== undefined) {
      classes.push('antrag-votum-' + this.antragObject.attributes.votum.kategorie);
    }
    this.antragClass = classes.join(' ');
  }
 
}