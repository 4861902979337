/**
 * Redner der Antraskommission
 * 
 * Attila Németh, UBG
 * 17.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {Votum} from '../../../../model/votum';
import {VotumService} from '../../../../services/votum.service';
import {Redner} from '../../../../model/redner';

@Component({
  templateUrl: './redner.component.html',
})
export class VotumRednerDialogComponent implements OnInit {
  
  rednerOptions: Array<Redner> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Votum,
                private votumService: VotumService) {
  }
  
  ngOnInit(): void {
    this.loadRednerOptions();
  }
  
  loadRednerOptions(): void {
    this.votumService.getRednern(this.data).subscribe(rednern => {
      this.rednerOptions = rednern;
    });
  }
  
  rednerChanged(event: MatCheckboxChange): void {
    let newRednern: Array<Redner> = [];
    if (this.data.relationships.redner_der_ak !== undefined) {
      for (let i in this.data.relationships.redner_der_ak.data) {
        if (this.data.relationships.redner_der_ak.data[i].id !== event.source.value) {
          newRednern.push(this.data.relationships.redner_der_ak.data[i]);
        }
      }
    }
    if (event.checked) {
      for (let i in this.rednerOptions) {
        if (this.rednerOptions[i].id == event.source.value) {
          newRednern.push(this.rednerOptions[i]);
        }
      }
    }
    this.data.relationships.redner_der_ak = {
      data: newRednern,
    };
  }

  votumHasRedner(redner: Redner): boolean {
    const existingRedner = this.data.relationships.redner_der_ak.data.find(votumRedner => votumRedner.id === redner.id);
    return existingRedner !== undefined;
  }
  
}