<mat-dialog-content class="mat-body">
  <form [formGroup]="kategorieForm">
    <mat-form-field>
      <input matInput type="text" formControlName="title" required placeholder="Name"
             maxlength="255">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="kurzb" maxlength="16" placeholder="Kurzbezeichnung">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" 
          [disabled]="!kategorieForm.valid">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>