/**
 * Ziffer erstellen
 * 
 * Attila Németh, UBG
 * 22.05.2020
 */
 
import {Component, forwardRef, OnInit, Output, EventEmitter} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {EmptyZiffer} from '../../abschnitt/leitantrag/leitantrag.component';
import {ConfirmComponent} from '@ubg/confirm';

const noop = () => {};

@Component({
  selector: 'ziffer-create',
  templateUrl: './create.component.html',
//  styleUrls: ['./create.component.scss'],
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ZifferCreateComponent),
    multi: true
  }]
})
export class ZifferCreateComponent implements OnInit {

  @Output() update = new EventEmitter 
  @Output() delete = new EventEmitter
  @Output() move   = new EventEmitter
  zifferInhalt: EmptyZiffer = null
  zifferForm: FormGroup = null
  mode: string = 'view'
  disabled: boolean = true
  
  constructor(formBuilder: FormBuilder,
                private dialog: MatDialog) {
    this.zifferForm = formBuilder.group({
      inhalt: [null, [Validators.required, WordLengthValidator]],
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  ngOnInit(): void {
    this.zifferForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.zifferInhalt.inhalt = value;
      this.onChangeCallback(this.zifferInhalt);
    });
    this.zifferForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
  }
  
  get value(): EmptyZiffer {
    return this.zifferInhalt;
  };

  set value(v: EmptyZiffer) {
    this.zifferInhalt = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: EmptyZiffer) {
    if (value !== null) {
      this.zifferInhalt = value;
      this.updateFormFromValue();
      if (value.inhalt === null || value.inhalt === '') {
        this.mode = 'edit';
      }
      else {
        this.mode = 'view';
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.zifferInhalt);
  }
  
  updateFormFromValue(): void {
    this.zifferForm.get('inhalt').setValue(this.zifferInhalt.inhalt);
  }
  
  updateZiffer(): void {
    this.mode = 'view';
    this.update.emit();
  }
  
  edit(): void {
    this.mode = 'edit';
  }
  
  deleteZiffer(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diese Ziffer entfernen?',
      }
    }).afterClosed().subscribe(result => {
      if (result === '1') {
        this.delete.emit(this.zifferInhalt);
      }
    });
  }
  
  moveZiffer(dir: number): void {
    this.move.emit({
      ziffer: this.zifferInhalt,
      dir: dir,
    });
  }
  
}

interface ValidatorFn {    
  (c: AbstractControl): ValidationErrors | null 
}
const WordLengthValidator: ValidatorFn = (formControl: FormControl) => {
  let errors = {};
  if (formControl.value !== null) {
    const words: Array<string> = formControl.value.split(' ');
    for (let i in words) {
      if (words[i].length >= 240) {
        errors['toolong'] = true;
      }
    }
  }
  return errors;
};