<mat-dialog-content class="mat-body">
  <h2 class="mat-title">Antrag {{ data.antrag.attributes.label }}</h2>
  <p *ngIf="data.votum.relationships.antrag.data.length == 2">
    Dieser Antrag hat ein gemeinsames Votum mit einem anderen Antrag.
  </p>
  <p *ngIf="data.votum.relationships.antrag.data.length > 2">
    Dieser Antrag hat ein gemeinsames Votum 
    mit {{ data.votum.relationships.antrag.data.length - 1 }} anderen Anträgen.
  </p>
  <ul>
    <li class="warning">
      Wenn Sie an "Gemeinsames Votum löschen" klicken, wird das Votum aller betroffenen, 
      gemeinsam behandelten Anträge entfernt.
    </li>
    <li>
      Möchten Sie nur diesen Antrag von gemeinsamer Behandlung rausnehmen, wählen Sie 
      "Verwerfen", und im Antrag-Menü "Nicht mehr gemeinsam behandeln".
    </li>
    <li>
      Möchten Sie die gemeinsame Behandlung behalten, diesem Antrag aber ein abweichendes 
      Votum geben, wählen Sie "Verwerfen", und im Antrag-Menü "Abweichendes Votum".
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button mat-dialog-close="1" color="warn">
    <mat-icon>delete</mat-icon> Gemeinsames Votum löschen
  </button>
</mat-dialog-actions>