<h1 mat-dialog-title>Gemeinsame Behandlung</h1>
<mat-dialog-content class="mat-body">
  <form [formGroup]="gemeinsamForm" class="full-width-form">
    <mat-list>
      <mat-list-item *ngFor="let antrag of data">
        <span [innerHtml]="antrag.attributes.label"></span>
        <span>&nbsp;</span>
        <span [innerHtml]="antrag.attributes.title"></span>
      </mat-list-item>
    </mat-list>
    <mat-form-field *ngIf="votumOptions.length > 1">
      <mat-select formControlName="votum" placeholder="Votum" required>
        <mat-option *ngFor="let votum of votumOptions" [value]="votum">
          {{ getVotumLabel(votum) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="begruendungOptions.length > 1">
      <mat-select formControlName="begruendung" placeholder="Begründung">
        <mat-option *ngFor="let begruendung of begruendungOptions" [value]="begruendung">
          {{ getBegruendungLabel(begruendung) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">Verwerfen</button>
  <button mat-button [mat-dialog-close]="response" [disabled]="disabled" color="primary">
    Speichern
  </button>
</mat-dialog-actions>