/**
 * Mandant-Service
 * 
 * Attila Németh, UBG
 * 29.04.2020
 */
 
import {Injectable} from '@angular/core';

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Mandant} from '../model/mandant';

@Injectable({
  providedIn: 'root'
})
export class MandantService {

  constructor(private drupal: DrupalService) {}

  getAll() {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get('api/ubg_mandant/ubg_mandant', httpOptions);
  }
   
}