<h1 mat-dialog-title>Begründungen in MS Word exportieren</h1>
<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <form [formGroup]="optionsForm">
    <mat-checkbox formControlName="votum">
      Mit Votum der Antragskommission
    </mat-checkbox>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button [mat-dialog-close]="optionsForm.value" color="primary">
    <mat-icon>print</mat-icon> Drucken
  </button>
</mat-dialog-actions>