<h1 mat-dialog-title>Broschüre in MS Word exportieren</h1>
<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <mat-checkbox (change)="setOption('mitVotum', $event)" color="primary">
    Mit Votum
  </mat-checkbox>
  <mat-checkbox (change)="setOption('mitBegruendungAntragsteller', $event)" color="primary">
    Mit Begründung des Antragstellers
  </mat-checkbox>
  <mat-checkbox (change)="setOption('mitBegruendungKommission', $event)" color="primary">
    Mit Begründung der Antragskommission
  </mat-checkbox>
  <mat-checkbox (change)="setOption('mitMarkup', $event)" color="primary">
    Mit Markup
  </mat-checkbox>
  <mat-checkbox (change)="setOption('mitRedner', $event)" color="primary">
    Mit Redner der AK
  </mat-checkbox>
  <mat-checkbox (change)="setOption('mitEntwurf', $event)" color="primary">
    Mit Entwurf-Wasserzeichen
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button [mat-dialog-close]="printOptions" color="primary">
    <mat-icon>print</mat-icon> Drucken
  </button>
</mat-dialog-actions>