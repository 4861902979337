export const environment = {
  production: false,
  drupal: {
    baseUrl: 'https://antragsdb.cdu.de',
    tokenUrl: 'https://antragsdb.cdu.de/oauth/token',
    clientId: '9a116c2e-ed7d-438f-b768-bf4adaba4162',
    clientSecret: 'test$123',
    scope: 'administrator cdu_experte cdu_redaktion antragswesen_klient angular_gliederung',
  }
};
