<button mat-raised-button color="primary" (click)="printAll()">
  <mat-icon>print</mat-icon> Alle in MS Word exportieren
</button>

<projekt-stat-element [projekt]="projektObject" stat="input">
  <p class="mat-h2">Eingetragene Anträge</p>
</projekt-stat-element>

<projekt-stat-element [projekt]="projektObject" stat="votum" details="votum">
  <p class="mat-h2">Votum</p>
</projekt-stat-element>

<projekt-stat-element [projekt]="projektObject" stat="zuweisung" details="zuweisung">
  <p class="mat-h2">Anträge verteilt an die Redaktion</p>
</projekt-stat-element>

<projekt-stat-element [projekt]="projektObject" stat="gliederung" details="gliederung">
  <p class="mat-h2">Gliederungen</p>
</projekt-stat-element>

<projekt-stat-element [projekt]="projektObject" stat="count" details="count">
  <p class="mat-h2">Anträge</p>
</projekt-stat-element>

<projekt-stat-element [projekt]="projektObject" stat="theme" details="theme">
  <p class="mat-h2">Themen</p>
</projekt-stat-element>