/**
 * Antrag-Service
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';

import {Abschnitt} from '../model/abschnitt';
import {Antrag} from '../model/antrag';
import {Gliederung} from '../model/gliederung';
import {CookieService} from '@ubg/cookie-service';
import {AntragEvent} from '../model/events';
import {User} from '../model/user';
import {Votum, VotumBegruendung, VotumGemeinsam} from '../model/votum';
import {Ueberweisung} from '../model/ueberweisung';
import {Thema} from '../model/thema';

@Injectable({
  providedIn: 'root'
})
export class AntragService {

  private lastAntragUpdateData: BehaviorSubject<number> = new BehaviorSubject(null);
  public lastAntragUpdate: Observable<number> = this.lastAntragUpdateData.asObservable();
  private antragEventData: BehaviorSubject<AntragEvent> = new BehaviorSubject(null);
  public antragEvent: Observable<AntragEvent> = this.antragEventData.asObservable();
  private antragSelectedData: BehaviorSubject<Array<Antrag>> = new BehaviorSubject([]);
  public antragSelected: Observable<Array<Antrag>> = this.antragSelectedData.asObservable();
  
  constructor(private drupal: DrupalService,
                private cookieService: CookieService) {}
  
  initialize() {
    console.info('Antrag-Service wurde initialisiert');
    setInterval(() => {
      this.updateLastAntrag();
    }, 90000);
  }
  
  private updateLastAntrag(): void {
    this.getRecentAntrag().subscribe(antrag => {
      let d = new Date(antrag.attributes.changed);
      this.lastAntragUpdateData.next(d.getTime());
    });
  }
  
  /**
   * Einen Antrag laden
   * 
   * 28.05.2020
   */
  getAntrag(type: string, id: string): Observable<Antrag> {
    const path: string = 'api/' + type.replace('--', '/') + '/' + id;
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Antrag
      }) => {
        return response.data;
      })
    );
  }
  
  setUpdateLastAntrag(): void {
    const d:Date = new Date;
    this.lastAntragUpdateData.next(d.getTime());
  }

  getPossibleGliederungen(antrag: Antrag): Observable<Array<Gliederung>> {
    const path = 'ubg/antrag/' + antrag.id + '/gliederungen';
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Gliederung>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Einen Antrag erstellen
   */
  createAntrag(antrag: Antrag): Observable<Antrag> {
    const path: string = 'api/' + antrag.type.replace('--', '/');
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(path, {
      data: antrag
    }, httpOptions).pipe(
      map((response: {
        data: Antrag
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Einen Antrag bearbeiten
   * 
   * 22.05.2020
   */
  updateAntrag(antrag: Antrag): Observable<Antrag> {
    const path: string = 'api/' + antrag.type.replace('--', '/') + '/' + antrag.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.patch(path, {
      data: antrag
    }, httpOptions).pipe(
      map((response: {
        data: Antrag
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Den Antrag einem Abschnitt hinzufügen.
   */
  addAntragToAbschnitt(antrag: Antrag, abschnitt: Abschnitt) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/antrag/' + antrag.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.post(path, null, httpOptions);
  }
  
  setBearbeitung(antrag: Antrag) {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/bearbeitung';
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  /**
   * Der zuletzt geänderte Antrag
   */
  getRecentAntrag(): Observable<Antrag> {
    const path: string = 'ubg/antrag/api/last';
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Antrag
      }) => {
        return response.data;
      }),
    );
  }
  
  getAntraegeZumLeitantrag(leitantrag: Antrag): Observable<Array<Antrag>> {
    const path: string = 'ubg/antrag/' + leitantrag.id + '/api/antraege';
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      }),
    );
  }
  
  /**
   * Ob der Benutzer die Berechtigung hat, den Antrag zu bearbeiten
   * 
   * 020.6.2020
   */
  editPermission(antrag: Antrag): Observable<boolean> {
    return this.drupal.entityPermission('ubg_antrag', antrag.id, 'edit').pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
  
  /**
   * Ob der Benutzer die Berechtigung hat, den Antrag zu löschen
   * 
   * 22.06.2020
   */
  deletePermission(antrag: Antrag): Observable<boolean> {
    return this.drupal.entityPermission('ubg_antrag', antrag.id, 'delete').pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
  
  setAntragEvent(event: AntragEvent): void {
    this.antragEventData.next(event);
  }
  
  /**
   * Benutzer, die denen dieser Antrag zugewiesen werden darf
   */
  getZuweisungUsers(antrag: Antrag): Observable<Array<User>> {
    const path: string = 'ubg/antrag/' + antrag.id + '/zuweisung';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<User>
      }) => {
        return response.data;
      })
    );
  }
  
  deleteAntrag(antrag: Antrag) {
    const path: string = 'api/' + antrag.type.replace('--', '/') + '/' + antrag.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(path, httpOptions);
  }
  
  /**
   * Die Voten des Antrags
   */
  getVoten(antrag: Antrag): Observable<Array<Votum>> {
    const path: string = 'ubg/antrag/' + antrag.id + '/votum';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Votum>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Die Begründung der AK
   */
  getVotumBegruendung(antrag: Antrag): Observable<VotumBegruendung> {
    const path: string = 'ubg/antrag/' + antrag.id + '/votum/begruendung'
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: VotumBegruendung
      }) => {
        return response.data;
      })
    );
  }
  
  printAntrag(antrag: Antrag, options: AntragPrintOptions) {
    const path: string = 'ubg/antrag/' + antrag.id + '/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, options, httpOptions);
  }
  
  getProjektAntraege(antrag: Antrag): Observable<Array<Antrag>> {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/projekt_antraege';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  
  setGemeinsam(antrag: Antrag, antraege: Array<Antrag>, votum?: Votum, begruendung?: VotumBegruendung) {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/gemeinsam';
    let data = {
      antraege: [],
      votum: null,
      begruendung: null,
    };
    for (let i in antraege) {
      data.antraege.push(antraege[i].id);
    }
    if (votum !== undefined && votum !== null) {
      data.votum = votum.id;
    }
    if (begruendung !== undefined && begruendung !== null) {
      data.begruendung = begruendung.id;
    }
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, data, httpOptions);
  }
  
  removeGemeinsam(antrag: Antrag) {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/gemeinsam';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.delete(path, httpOptions);
  }
  
  /**
   * Optionen für gemeinsame Behandlung
   * 
   * 25.06.2020
   */
  getGemeinsamen(antrag: Antrag): Observable<Array<VotumGemeinsam>> {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/gemeinsamen';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<VotumGemeinsam>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Überweisung-Optionen des Votums
   * 
   * 16.06.2020
   */
  getUeberweisungen(antrag: Antrag): Observable<Array<Ueberweisung>> {
    const path: string = 'ubg/antrag/' + antrag.id + '/ueberweisungen';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Ueberweisung>
      }) => {
        return response.data;
      })
    );
  }
  
  getZiffern(antrag: Antrag): Observable<Array<Antrag>> {
    const path: string = 'ubg/antrag/' + antrag.id + '/ziffern';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  
  getThemen(antrag: Antrag): Observable<Array<Thema>> {
    const path: string = 'ubg/antrag/' + antrag.id + '/themen';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Thema>
      }) => {
        response.data.sort((thema1: Thema, thema2: Thema): number => {
          if (thema1.attributes.title < thema2.attributes.title) {
            return -1;
          }
          else {
            return 1;
          }
        });
        return response.data;
      })
    );
  }
  
  /**
   * Markup
   * 
   * 25.06.2020
   */
  getMarkup(antrag: Antrag): Observable<string> {
    const path: string = 'ubg/antrag/' + antrag.id + '/markup';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        markup: string
      }) => {
        return response.markup;
      })
    );
  }
  
  selectAntrag(antrag: Antrag): void {
    let antraege: Array<Antrag> = this.antragSelectedData.value;
    antraege.push(antrag);
    this.antragSelectedData.next(antraege);
  }
  
  deselectAntrag(antrag: Antrag): void {
    let antraege: Array<Antrag> = 
      this.antragSelectedData.value.filter(selected => selected.id !== antrag.id)
    this.antragSelectedData.next(antraege);
  }

  setAntragThema(antrag: Antrag, thema: Thema) {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/thema';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const args = {
      thema: thema.id,
    };
    return this.drupal.post(path, args, httpOptions);
  }
  
}

export interface AntragPrintOptions {
  mitVotum: boolean
  mitBegruendungAntragsteller: boolean
  mitBegruendungKommission: boolean
  nurBegruendungKommission: boolean
  mitMarkup: boolean
  mitRedner: boolean
}

export interface AntragNummerSchema {
  id: string
  type: string
  nummer: number
}