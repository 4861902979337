/**
 * Thema-Service
 * 
 * Attila Németh, UBG
 * 04.05.2020
 */
 
import {Injectable} from '@angular/core';

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Thema} from '../model/thema';

@Injectable({
  providedIn: 'root'
})
export class ThemaService {

  basePath: string = 'api/ubg_thema/ubg_thema'
  
  constructor(private drupal: DrupalService) {}
  
  get(id: string) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath + '/' + id, httpOptions);
  }
  
  create(thema: Thema) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(this.basePath, {
      data: thema
    }, httpOptions);
  }
  
  update(thema: Thema) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(this.basePath + '/' + thema.id, {
      data: thema
    }, httpOptions);
  }
  
  delete(thema: Thema) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(this.basePath + '/' + thema.id, httpOptions);
  }
    
}
