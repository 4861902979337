/**
 * Banner-Komponent
 * 
 * Attila Németh, UBG
 * 30.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Projekt} from '../../../../model/projekt';

@Component({
  templateUrl: './banner.component.html',
})
export class ProjektBannerDialogComponent implements OnInit {
  
  disabled: boolean = true
  fileToUpload: File = null
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Projekt) {}
  
  ngOnInit(): void {}
  
  fileSelected(event: Array<File>) {
    this.fileToUpload = event[0];
    this.disabled = false;
  }
  
}