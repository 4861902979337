/**
 * Überweisung bearbeiten
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Output, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Ueberweisung, UeberweisungGruppe} from '../../../model/ueberweisung';
import {UeberweisungService} from '../../../services/ueberweisung.service';
import {Projekt} from '../../../model/projekt';

const noop = () => {};

@Component({
  selector: 'ueberweisung-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UeberweisungEditComponent),
    multi: true
  }]
})
export class UeberweisungEditComponent {
  
  @Output() validityChange = new EventEmitter
  ueberweisungForm: FormGroup
  ueberweisung: Ueberweisung = null
  ueberweisungGuppen: Array<UeberweisungGruppe> = []
  focused: boolean = false
  
  
  constructor(formBuilder: FormBuilder,
                private ueberweisungService: UeberweisungService) {
    this.ueberweisungForm = formBuilder.group({
      title: [null, Validators.required],
      gruppe: [{
        value: null,
        disabled: true,
      }],
    });
    this.ueberweisungForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.ueberweisungForm.get('title').valueChanges.subscribe((value: string) => {
      this.ueberweisung.attributes.title = value;
    });
    this.ueberweisungForm.get('gruppe').valueChanges.subscribe((value: string) => {
      for (let i in this.ueberweisungGuppen) {
        if (this.ueberweisungGuppen[i].id == value) {
          this.ueberweisung.relationships.gruppe.data = this.ueberweisungGuppen[i];
          break;
        }
      }
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  get value(): Ueberweisung {
    return this.ueberweisung;
  };

  set value(v: Ueberweisung) {
    if (v !== this.ueberweisung) {
      this.ueberweisung = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  setFocus() {
    this.focused = true;
  }

  writeValue(value: Ueberweisung) {
    if (value !== null && value !== this.ueberweisung) {
      this.ueberweisung = value;
      if (this.ueberweisung.attributes === undefined) {
        this.ueberweisung.attributes = {
          title: null,
        };
      }
      this.ueberweisungForm.get('title').setValue(this.ueberweisung.attributes.title);
      if (this.ueberweisung.relationships !== undefined
        && this.ueberweisung.relationships.gruppe.data !== null) {
        this.ueberweisungForm.get('gruppe').setValue(this.ueberweisung.relationships.gruppe.data.id);
      }
      this.loadGruppen();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.ueberweisung);
  }
  
  loadGruppen(): void {
    if (this.ueberweisung !== null && this.ueberweisung.id !== undefined) {
      this.ueberweisungService.getGruppenByUeberweisung(this.ueberweisung).subscribe((response: {
        data: Projekt
      }) => {
        this.ueberweisungGuppen = response.data.relationships.ueberweisung_gruppen.data;
        this.ueberweisungForm.get('gruppe').enable();
        this.ueberweisungForm.get('gruppe').setValidators(Validators.required);
      });
    }
  }

}

 
