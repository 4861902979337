<div fxLayout="row" fxLayoutGap="1rem" fxLayoutGap.gt-md="1.5rem" fxLayout.xs="column">
  <antrag-container [antrag]="antragObject" fxFlex="1 1 50%">
    <div class="mein-header" fxLayout="row" fxLayoutGap="1rem" fxLayoutGap.gt-md="1.5rem">
      <h3 fxFlex="1 1 auto">
        Ziffer {{ antragObject.relationships.ziffer.data.attributes.nummer }}
      </h3>
      <button mat-button (click)="addAntrag()" color="primary" [disabled]="expired">
        <mat-icon>add_circle</mat-icon> Antrag stellen
      </button>
    </div>
    <div *ngIf="antragObject !== null" class="ziffer-container">
      <ziffer [ziffer]="antragObject.relationships.ziffer.data"></ziffer>
    </div>
  </antrag-container>
  <div class="antrag-list" fxFlex="1 1 50%" *ngIf="editAntrag === null">
    <antrag-zum-leitantrag [antrag]="antrag" [abschnitt]="abschnittObject" 
      *ngFor="let antrag of gliederungAntraege">
    </antrag-zum-leitantrag>
  </div>
  
  <div class="antrag-edit" *ngIf="editAntrag !== null">
    <antrag-zum-leitantrag-edit [(ngModel)]="editAntrag" (validityChange)="antragValidityChanged($event)">
    </antrag-zum-leitantrag-edit>
    <button mat-raised-button color="primary" (click)="antragSave()" *ngIf="editAntragSaveable">
      <mat-icon>save</mat-icon> Speichern
    </button>
    <button mat-button color="primary" (click)="antragCancel()">
      <mat-icon>close</mat-icon> Verwerfen
    </button>
  </div>
  
</div>