/**
 * Votum bearbeiten Dialog
 * 
 * Attila Németh, UBG
 * 10.06.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Votum} from '../../../../model/votum';

@Component({
  templateUrl: './edit.component.html',
})
export class VotumEditDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: Votum) {
  }
  
  validityChanged(event: boolean) {
    this.disabled = !event;
  }

}