/**
 * Antrag-Filter
 * 
 * Attila Németh, UBG
 * 02.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {Abschnitt} from '../../../model/abschnitt';
import {FilterParams} from '../../../model/filter';
import {FilterService} from '../../../services/filter.service';
import {VotumType} from '../../../model/votum';
import {VotumService} from '../../../services/votum.service';
import {User} from '../../../model/user';
import {AntragService} from '../../../services/antrag.service';
import {Redner} from '../../../model/redner';
import {RednerService} from '../../../services/redner.service';

@Component({
  selector: 'abschnitt-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class AbschnittFilterComponent implements OnInit, OnDestroy {
    
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
      if (this.abschnittObject.relationships.antraege !== undefined 
        && this.abschnittObject.relationships.antraege.data.length > 0) {
        this.filterForm.get('zuweisung').disable();
        this.antragService.getZuweisungUsers(this.abschnittObject.relationships.antraege.data[0]).subscribe(users => {
          this.zuweisungOptions = users;
          this.filterForm.get('zuweisung').enable();
        });
      }
      else {
        this.zuweisungOptions = [];
        this.filterForm.get('zuweisung').disable();
      }
      this.loadRednern();
    }
  }
  abschnittObject: Abschnitt = null
  filterForm: FormGroup
  filterParams: FilterParams
  breakpoint: string = '(max-height: 840px)'
  buttonBreakpoint: string = '(max-width: 960px)'
  buttonSubscription: Subscription
  buttonLayout: boolean = true
  isSmall: boolean = true
  isOpen: boolean = false
  votumTypes: Array<VotumType> = []
  zuweisungOptions: Array<User> = []
  rednerOptions: Array<Redner> = []
 
  constructor(formBuilder: FormBuilder,
                private router: Router,
                private breakpointObserver: BreakpointObserver,
                private filterService: FilterService,
                private votumService: VotumService,
                private antragService: AntragService,
                private rednerService: RednerService) {
    this.filterForm = formBuilder.group({
      suche: [null],
      gliederungen: [null],
      nummer: [null],
      votum: [{
        value: null,
        disabled: true,
      }],
      zuweisung: [{
        value: null,
        disabled: true,
      }],
      redner: [{
        value: null,
        disabled: true,
      }],
    });
    this.filterForm.valueChanges.subscribe((values: FilterParams) => {
      this.filterParams = values;
    });
    this.resetParams();
    breakpointObserver.observe(this.breakpoint).subscribe(result => {
      if (result.matches) {
        this.isSmall = true;
        this.isOpen = false;
      }
      else {
        this.isSmall = false;
        this.isOpen = true;
      }
    });
  }
  
  ngOnInit(): void {
    this.votumService.getVotumTypes().subscribe(types => {
      this.votumTypes = types;
      this.filterForm.get('votum').enable();
    });
    this.buttonSubscription = this.breakpointObserver.observe(this.buttonBreakpoint).subscribe(result => {
      this.buttonLayout = !result.matches;
    });
//    this.rednerService.getAll().subscribe(rednern => {
//      this.rednerOptions = rednern;
//      if (this.rednerOptions.length > 0) {
//        this.filterForm.get('redner').enable();
//      }
//    });
  }
  
  ngOnDestroy(): void {
    this.buttonSubscription.unsubscribe();
  }
  
  loadRednern(): void {
    this.rednerService.getByAbschnitt(this.abschnittObject).subscribe(rednern => {
      this.rednerOptions = rednern;
      if (this.rednerOptions.length > 0) {
        this.filterForm.get('redner').enable();
      }
    });    
  }
  
  filterAntraege(): void {
    const url: string = this.router.url.replace(/\#(.*?)$/, '');
    this.router.navigate([url]);
    setTimeout(() => {
      this.filterService.getAntraegeVonAbschnitt(this.abschnittObject, this.filterParams);
    }, 150);
  }
  
  resetField(fieldName: string): void {
    this.filterParams[fieldName] = null;
    this.filterForm.get(fieldName).reset(null);
    this.filterAntraege();
  }
  
  reset(): void {
    this.resetParams();
    this.resetForm();
    this.filterAntraege();
  }
  
  resetParams(): void {
    this.filterParams = {
      suche: null,
      gliederungen: null,
      nummer: null,
      votum: null,
      zuweisung: null,
    };
  }
  
  resetForm(): void {
    this.filterForm.get('suche').reset(null);
    this.filterForm.get('gliederungen').reset(null);
    this.filterForm.get('nummer').reset(null);
    this.filterForm.get('votum').reset(null);
    this.filterForm.get('zuweisung').reset(null);
    this.filterForm.get('redner').reset(null);
  }
  
  toggle(): void {
    this.isOpen = !this.isOpen;
  }
   
}