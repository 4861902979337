<form [formGroup]="ueberweisungForm" fxLayout="row" fxLayoutGap="1rem">
  <mat-form-field fxFlex="1 1 50%">
    <input matInput type="text" placeholder="Überweisung"
            formControlName="ueberweisung" [matAutocomplete]="autoUeberweisung"
            (blur)="onBlur()">
    <mat-autocomplete #autoUeberweisung="matAutocomplete" 
      (optionSelected)="ueberweisungSelected($event)">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
        {{ option.attributes.title }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-list fxFlex="1 1 50%">
    <mat-list-item *ngFor="let ueberweisung of this.ueberweisungen" fxLayout="row" fxLayoutGap="0.5rem">
      <span fxFlex="2 2 auto">
        {{ ueberweisung.attributes.title }}
      </span>
      <button mat-icon-button color="primary" (click)="removeUeberweisung(ueberweisung)" matTooltip="Überweisung entfernen" fxFlex="1 1 64px">
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</form>