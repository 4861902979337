/**
 * Überweisung-Service
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Injectable} from '@angular/core';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Ueberweisung, UeberweisungGruppe} from '../model/ueberweisung';

@Injectable({
  providedIn: 'root'
})
export class UeberweisungService {
  
  basePath: string = 'api/ubg_ueberweisung/ubg_ueberweisung';
  baseGruppePath: string = 'api/ubg_ueberweisung_gruppe/ubg_ueberweisung_gruppe';
  
  constructor(private drupal: DrupalService) {}
  
  getGruppe(id: string) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.baseGruppePath + '/' + id, httpOptions).pipe(
      map((response: {
        data: UeberweisungGruppe
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Die Überweisungsgruppen
   */
  getGruppenByUeberweisung(ueberweisung: Ueberweisung) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    let path: string = 'ubg/projekt/ueberweisung/' + ueberweisung.id;
    return this.drupal.get(path, httpOptions).pipe();
  }
  
  createGruppe(ueberweisungGruppe: UeberweisungGruppe) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(this.baseGruppePath, {
      data: ueberweisungGruppe,
    }, httpOptions);
  }
  
  updateGruppe(ueberweisungGruppe: UeberweisungGruppe) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(this.baseGruppePath + '/' + ueberweisungGruppe.id, {
      data: ueberweisungGruppe,
    }, httpOptions).pipe(
      map((response: {
        data: UeberweisungGruppe
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Eine Gruppe löschen
   */
  deleteGruppe(ueberweisungGruppe: UeberweisungGruppe) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(this.baseGruppePath + '/' + ueberweisungGruppe.id, httpOptions);
  }
  
  /**
   * Eine Überweisung erstellen
   */
  createUeberWeisung(ueberweisung: Ueberweisung) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(this.basePath, {
      data: ueberweisung,
    }, httpOptions).pipe(
      map((response: {
        data: Ueberweisung
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Eine Überweisung laden
   */
  getUeberweisung(id: string) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath + '/' + id, httpOptions).pipe(
      map((response: {
        data: Ueberweisung
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Eine Überweisung ändern
   */
  updateUeberweisung(ueberweisung: Ueberweisung) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(this.basePath + '/' + ueberweisung.id, {
      data: ueberweisung,
    }, httpOptions).pipe(
      map((response: {
        data: Ueberweisung
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Eine Überweisung löschen
   * 
   * 30.06.2020
   */
  deleteUeberweisung(ueberweisung: Ueberweisung) {
    const path: string = this.basePath + '/' + ueberweisung.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(path, httpOptions);
  }
  
  deleteGruppPermission(ueberweisungGruppe: UeberweisungGruppe) {
    return this.drupal.entityPermission('ubg_ueberweisung_gruppe', ueberweisungGruppe.id, 'delete');
  }
    
}