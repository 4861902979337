/**
 * Gliederungen auswählen Input Komponent
 * 
 * Attila Németh, UBG
 * 19.05.2020
 */
 
import {Component, Input, forwardRef, OnInit, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Gliederung} from '../../../../model/gliederung';
import {Projekt} from '../../../../model/projekt';
import {ProjektService} from '../../../../services/projekt.service';

const noop = () => {};

@Component({
  selector: 'gliederung-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'], 
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GliederungSelectComponent),
      multi: true
    }
  ]
})
export class GliederungSelectComponent implements OnInit, OnDestroy {
  
  gliederungenSelected: Array<Gliederung> = []
  gliederungenOptions: Array<Gliederung> = []
  gliederungenAvailableOptions: Array<Gliederung> = []
  projekt: Projekt = null
  gliederungForm: FormGroup
  @Input() placeholder: string = 'Gliederung'
  @Input() required: boolean = false
  @Input() restriction: number = -1
  isLoading: boolean = false
  projektSubscription: Subscription
  
  constructor(formBuilder: FormBuilder,
                private projektService: ProjektService) {
    this.gliederungForm = formBuilder.group({
      name: [null],
    });
    this.gliederungForm.get('name').valueChanges.subscribe((value: string) => {
      if (value !== null) {
        let isSelected: boolean = false
        let newOptions: Array<Gliederung> = [];
        for (let i in this.gliederungenOptions) {
          if (newOptions.length < 20 
            && this.gliederungenOptions[i].attributes.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            isSelected = false;
            for (let j in this.gliederungenSelected) {
              if (this.gliederungenSelected[j].id == this.gliederungenOptions[i].id) {
                isSelected = true;
              }
            }
            if (!isSelected) {
              newOptions.push(this.gliederungenOptions[i]);
            }
          }
        }
        this.gliederungenAvailableOptions = newOptions;
        this.onTouchedCallback();
      }
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Array<Gliederung> {
    return this.gliederungenSelected
  };

  set value(v: Array<Gliederung>) {
    this.gliederungenSelected = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Array<Gliederung>) {
    if (value !== null) {
      this.gliederungenSelected = value;
      this.updateFormFromValue();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  ngOnInit(): void {
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      if (projekt !== null) {
        this.projekt = projekt;
        this.loadGliederungen();
      }
    });
  }
  
  ngOnDestroy(): void {
    this.projektSubscription.unsubscribe();
  }
  
  updateChanges() {
    this.onChangeCallback(this.gliederungenSelected);
  }
  
  loadGliederungen(): void {
    this.isLoading = true;
    this.projektService.getProjektAntragGliederungen(this.projekt).subscribe(gliederungen => {
      this.gliederungenOptions = gliederungen;
      if (gliederungen.length == 1) {
        this.gliederungenSelected = gliederungen;
        this.updateFormFromValue();
        this.onChangeCallback(gliederungen);
      }
      let newSelected: Array<Gliederung> = [];
      let found: boolean;
      for (let i in this.gliederungenSelected) {
        found = true;
        for (let j in this.gliederungenOptions) {
          if (this.gliederungenSelected[i].id === this.gliederungenOptions[j].id) {
            newSelected.push(this.gliederungenOptions[j]);
            found = true;
          }
        }
        if (!found) {
          newSelected.push(this.gliederungenSelected[i]);
        }
      }
      this.gliederungenSelected = newSelected;
      this.isLoading = false;
    });
  }
  
  updateFormFromValue(): void {
//    console.warn('UPDATE FORM');
  }
  
  optionSelected(event: MatAutocompleteSelectedEvent) {
    let newSelected: Array<Gliederung> = [];
    for (let i in this.gliederungenSelected) {
      newSelected.push(this.gliederungenSelected[i]);
    }
    for (let i in this.gliederungenOptions) {
      if (this.gliederungenOptions[i].id == event.option.value) {
        newSelected.push(this.gliederungenOptions[i]);
      }
    }
    this.gliederungenSelected = newSelected;
    this.gliederungForm.get('name').setValue(null);
    this.onChangeCallback(newSelected);
  }
  
  removeSelected(gliederung: Gliederung): void {
    let newSelected: Array<Gliederung> = [];
    for (let i in this.gliederungenSelected) {
      if (this.gliederungenSelected[i].id !== gliederung.id) {
        newSelected.push(this.gliederungenSelected[i]);
      }
    }
    this.gliederungenSelected = newSelected;
    this.onChangeCallback(newSelected);
  }
   
}