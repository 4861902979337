<mein-antrag-list-ohne-leitantrag 
  *ngIf="abschnittObject !== null && gliederungObject !== null 
      && abschnittObject.type == 'ubg_abschnitt--ohne_leitantrag'"
      [abschnitt]="abschnittObject" [gliederung]="gliederungObject">
</mein-antrag-list-ohne-leitantrag>

<mein-antrag-list-mit-leitantrag 
  *ngIf="abschnittObject !== null && gliederungObject !== null 
      && abschnittObject.type == 'ubg_abschnitt--mit_leitantrag'"
      [abschnitt]="abschnittObject" [gliederung]="gliederungObject">
</mein-antrag-list-mit-leitantrag>