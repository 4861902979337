<ul class="abschnitt-list mat-elevation-z2">
  <li *ngFor="let abschnitt of abschnitte" (click)="setActiveAbschnitt(abschnitt)"
    [matTooltip]="abschnitt.attributes.title">
    <button mat-icon-button color="primary" *ngIf="!isLarge">
      {{ abschnitt.attributes.buchstabe }}
    </button>
    <button mat-button color="primary" *ngIf="isLarge && activeAbschnitt.id !== abschnitt.id">
      {{ abschnitt.attributes.buchstabe }}
    </button>
    <button mat-flat-button color="primary" *ngIf="isLarge && activeAbschnitt.id === abschnitt.id">
      {{ abschnitt.attributes.buchstabe }}
    </button>
  </li>
</ul>