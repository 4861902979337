<mat-card>
  <mat-card-header>
    <mat-card-title>Operationen</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-action-list>
      <button mat-list-item (click)="loadExcel()" 
        [disabled]="uploadPhase >= 0 || projektObject.relationships.gliederungen.data.length > 0">
        <mat-icon>note_add</mat-icon> Excel-Liste hochladen
      </button>
      <mat-progress-bar mode="determinate" *ngIf="uploadPhase >= 0" [value]="uploadPhase"
        color="accent">
      </mat-progress-bar>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="accent">
      </mat-progress-bar>
      <button mat-list-item (click)="downloadExcel()" [disabled]="uploadPhase >= 0" 
              *ngIf="projektObject.relationships.gliederungen.data.length > 0">
        <mat-icon>save_alt</mat-icon> Excel-Liste herunterladen
      </button>
      <button mat-list-item (click)="providePassword()" [disabled]="uploadPhase >= 0 || isProviding" 
              *ngIf="projektObject.relationships.gliederungen.data.length > 0">
        <mat-icon *ngIf="!isProviding">lock_open</mat-icon>
        <mat-icon *ngIf="isProviding">hourglass_empty</mat-icon>
        Passwörter verteilen
      </button>
      <mat-progress-bar mode="indeterminate" *ngIf="isProviding" color="accent">
      </mat-progress-bar>
    </mat-action-list>
  </mat-card-content>
</mat-card>