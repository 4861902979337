<mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1rem" fxLayoutGap.xs="0.5rem"
     *ngIf="gliederungenOptions.length > 1">
  <form [formGroup]="gliederungForm" fxFlex="1 1 auto" 
    *ngIf="restriction < 0 || restriction > gliederungenSelected.length">
    <mat-form-field>
      <input type="text" matInput formControlName="name" [matAutocomplete]="auto"
             [placeholder]="placeholder">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let gliederung of gliederungenAvailableOptions" [value]="gliederung.id">
        {{ gliederung.attributes.longTitle }}
      </mat-option>
    </mat-autocomplete>
  </form>
  <mat-list fxFlex="1 1 auto">
    <mat-list-item *ngFor="let gliederung of gliederungenSelected">
      <p matLine fxLayout="row" class="removable">
        <span fxFlex="2 2 auto" *ngIf="gliederung.attributes !== undefined">
          {{ gliederung.attributes.longTitle }}
        </span>
        <span fxFlex="2 2 auto" *ngIf="gliederung.attributes === undefined">
         ...
        </span>
        <button mat-icon-button fxFlex="1 1 3rem" (click)="removeSelected(gliederung)"
                matTooltip="Diese Gliederung entfernen" color="primary">
          <mat-icon>close</mat-icon>
        </button>
      </p>
    </mat-list-item>
  </mat-list>
</div>

<div *ngIf="gliederungenOptions.length <= 1">
  <p class="no-choice-label">{{ placeholder }}</p>
  <p *ngFor="let gliederung of gliederungenSelected" class="no-choice-option">
    <span fxFlex="2 2 auto" *ngIf="gliederung.attributes !== undefined">
      {{ gliederung.attributes.longTitle }}
    </span>
    <span fxFlex="2 2 auto" *ngIf="gliederung.attributes === undefined">
     ...
    </span>
  </p>
</div>