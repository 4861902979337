/**
 * Redner
 * 
 * Attila Németh, UBG
 * 07.05.2020
 */
 
export class Redner {
  
  type: string = 'ubg_redner--ubg_redner'
  id: string
  attributes: {
    title: string
  }
  
}