/**
 * Filter-Service
 * 
 * Attila Németh, UBG
 * 05.06.2020
 */
 
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {FilterParams, FilterResult} from '../model/filter';
import {Abschnitt} from '../model/abschnitt';
import {Antrag} from '../model/antrag';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  
  private filterResultData: BehaviorSubject<FilterResult> = new BehaviorSubject(null);
  public filterResult: Observable<FilterResult> = this.filterResultData.asObservable();
  
  constructor(private drupal: DrupalService) {}
  
  getAntraegeVonAbschnitt(abschnitt: Abschnitt, params: FilterParams): void {
    let valid: boolean = false;
    for (let key in params) {
      if (params[key] !== null) {
        valid = true;
      }
    }
    if (valid) {
      const path: string = 'ubg/abschnitt/' + abschnitt.id + '/filter';
      let httpOptions = this.drupal.getHttpOptions(['Authentication']);
      this.drupal.post(path, params, httpOptions).subscribe((response: {
        data: Array<Antrag>
      }) => {
        this.filterResultData.next({
          abschnitt: abschnitt,
          result: response.data,
        });
      }, () => {
        this.filterResultData.next({
          abschnitt: abschnitt,
          result: [],
        });
      });
    }
    else {
      this.filterResultData.next({
        abschnitt: abschnitt,
        result: null,
      });
    }
  }
  
}