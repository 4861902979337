<mat-dialog-content class="mat-body">
  <form [formGroup]="gliederungForm">
    <mat-form-field>
      <input matInput type="text" formControlName="title" required placeholder="Name"
             maxlength="255">
      <mat-error *ngIf="!gliederungForm.get('title').valid">
        <span *ngIf="gliederungForm.get('title').errors.required && gliederungForm.get('title').touched">
          Der Name muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="email" formControlName="email" required placeholder="E-Mail"
             maxlength="255">
      <mat-error *ngIf="!gliederungForm.get('email').valid">
        <span *ngIf="gliederungForm.get('email').errors.required && gliederungForm.get('email').touched">
          Die E-Mail-Adresse muss angegeben werden
        </span>
        <span *ngIf="gliederungForm.get('email').errors.email && gliederungForm.get('email').touched">
          Die E-Mail-Adresse ist ungültig
        </span>
      </mat-error>
    </mat-form-field>
    <div fxLayout="column" fxLayoutGap="1rem">
      <mat-checkbox formControlName="freier_name" fxFlex="1 1">
        Name kann angegeben werden (für Mitglieder)
      </mat-checkbox> 
      <mat-checkbox formControlName="ubg_gliederung_deleg" *ngIf="data.attributes.freier_name"  fxFlex="1 1">
        Name kann angegeben werden (für Delegierte)
      </mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" 
          [disabled]="!gliederungForm.valid">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>