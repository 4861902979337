/**
 * Antrag-Menü
 * 
 * Attila Németh, UBG
 * 02.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ConfirmComponent} from '@ubg/confirm';

import {Antrag} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';
import {AbschnittService} from '../../../../services/abschnitt.service';
import {AntragService} from '../../../../services/antrag.service';
import {AntragEvent} from '../../../../model/events';
import {AntragEditDialogComponent} from '../../dialog/antrag/antrag.component';
import {AntragZuweisungDialogComponent} from '../../dialog/zuweisung/zuweisung.component';
import {Votum, VotumBegruendung, VotumGemeinsam} from '../../../../model/votum';
import {VotumEditDialogComponent} from '../../../votum/dialog/edit/edit.component';
import {VotumService} from '../../../../services/votum.service';
import {AntragDeleteDialogComponent} from '../../dialog/delete/delete.component';
import {VotumBegruendungEditDialogComponent} from '../../../votum/dialog/begruendung/begruendung.component';
import {AntragPrintDialogComponent} from '../../dialog/print/print.component';
import {AntragGemeinsamDialogComponent} from '../../dialog/gemeinsam/gemeinsam.component';
import {VotumDeleteDialogComponent} from '../../dialog/votum_delete/votum_delete.component';
import {VotumRednerDialogComponent} from '../../../votum/dialog/redner/redner.component';
import {AntragThemaDialogComponent} from '../../dialog/thema/thema.component';
import {ThemaService} from '../../../../services/thema.service';
import {Thema} from '../../../../model/thema';
import {AntragMarkupDialogComponent} from '../../dialog/markup/markup.component';
import {VotumGemeinsamDeleteDialogComponent} from '../../../votum/dialog/gemeinsam_delete/gemeinsam_delete.component';

@Component({
  selector: 'antrag-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragMenuComponent implements OnInit, OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.editPermissionSubscription = this.antragService.editPermission(this.antragObject).subscribe(editPermission => {
        this.editPermission = editPermission;
        // console.debug(this.editPermission);
      });
      this.deletePermissionSubscription = this.antragService.deletePermission(this.antragObject).subscribe(deletePermission => {
        this.deletePermission = deletePermission;
      });
      this.antragService.getVoten(this.antragObject).subscribe(voten => {
        this.votumAk = null;
        this.votumPt = null;
        for (let i in voten) {
          if (voten[i].attributes.ebene === 'ak') {
            this.votumAk = voten[i];
          }
          else if (voten[i].attributes.ebene === 'pt') {
            this.votumPt = voten[i];
          }
        }
        this.printPermission = true;
      }, (error) => {
        this.printPermission = false;
      });
      this.votumService.getGemeinsamByAntrag(this.antragObject).subscribe(gemeinsam => {
        if (gemeinsam.id === undefined || gemeinsam.id === null) {
          this.gemeinsam = null;
        }
        else {
          this.gemeinsam = gemeinsam;
        }
      }, () => {
        this.gemeinsam = null;
      });
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
    }
  }
  abschnittObject: Abschnitt = null
  editPermission: boolean = false
  editPermissionSubscription: Subscription = null
  deletePermission: boolean = false
  deletePermissionSubscription: Subscription = null
  printPermission: boolean = false;
  begruendung: VotumBegruendung = null
  votumAk: Votum = null
  votumPt: Votum = null
  gemeinsam: VotumGemeinsam = null
  breakpointSubscription: Subscription
  nestedMenu: boolean = false
  nestedMenuBreakpoint: string = '(max-height: 720px)'
  
  constructor(private breakpointObserver: BreakpointObserver,
                private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar, 
                private antragService: AntragService,
                private abschnittService: AbschnittService,
                private votumService: VotumService,
                private themaService: ThemaService) {}
  
  ngOnInit(): void {
    this.antragService.getVotumBegruendung(this.antragObject).subscribe(begruendung => {
      this.begruendung = begruendung;
    }, () => {
      this.begruendung = null;
    });
    this.breakpointSubscription = this.breakpointObserver.observe(this.nestedMenuBreakpoint).subscribe(state => {
      this.nestedMenu = state.matches;
    });
  }
                
  ngOnDestroy(): void {
    if (this.editPermissionSubscription !== null) {
      this.editPermissionSubscription.unsubscribe();
    }
    if (this.deletePermissionSubscription !== null) {
      this.deletePermissionSubscription.unsubscribe();
    }
    this.breakpointSubscription.unsubscribe();
  }
  
  emitEvent(name: string): void {
    const event: AntragEvent = {
      antrag: this.antragObject,
      name: name,
    };
    this.antragService.setAntragEvent(event);
  }
  
  antragLive(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diesen Antrag stellen?',
        hint: 'Sie können den Antrag nicht mehr bearbeiten',
      }
    }).afterClosed().subscribe(result => {
      if (result === '1') {
        this.antragService.setAntragEvent({
          antrag: this.antragObject,
          name: 'load',
        });
        this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(antrag => {
          antrag.attributes.status = true;
          this.antragService.updateAntrag(antrag).subscribe(antrag => {
            this.antragObject = antrag;
            this.antragService.setAntragEvent({
              antrag: this.antragObject,
              name: 'loaded',
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
            this.antragService.setAntragEvent({
              antrag: this.antragObject,
              name: 'loaded',
            });
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
          this.antragService.setAntragEvent({
            antrag: this.antragObject,
            name: 'loaded',
          });
        });
      }
    });
  }
  
  antragEdit(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(antrag => {
      this.dialog.open(AntragEditDialogComponent, {
        width: '640px',
        data: antrag,
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          this.antragService.updateAntrag(result).subscribe(antrag => {
            this.antragService.setBearbeitung(antrag).subscribe(() => {
              this.antragObject = antrag;
              this.antragService.setAntragEvent({
                antrag: this.antragObject,
                name: 'loaded',
              });
            }, (error) => {
              this.antragObject = antrag;
              this.antragService.setAntragEvent({
                antrag: this.antragObject,
                name: 'loaded',
              });
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  antragNoAnchor(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(antrag => {
      this.dialog.open(ConfirmComponent, {
        data: {
          question: 'Soll dieser Antrag nicht mehr verankert werden?',
          hint: 'Sie können es immer wieder positionieren',
        }
      }).afterClosed().subscribe(result => {
        if (result == '1') {
          antrag.relationships.folgeantrag.data = null;
          this.antragService.updateAntrag(antrag).subscribe(antrag => {
            this.antragService.setBearbeitung(antrag).subscribe(() => {
              this.antragObject = antrag;
              this.antragService.setAntragEvent({
                antrag: this.antragObject,
                name: 'loaded',
              });
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  antragZuweisung(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(antrag => {
      this.dialog.open(AntragZuweisungDialogComponent, {
        width: '720px',
        data: antrag
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          this.antragService.updateAntrag(antrag).subscribe(antrag => {
            this.antragService.setBearbeitung(antrag).subscribe(() => {
              this.antragService.setAntragEvent({
                antrag: this.antragObject,
                name: 'loaded',
              });
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  antragDelete(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(antrag => {
      this.dialog.open(ConfirmComponent, {
        data: {
          question: 'Soll dieser Antrag gelöscht werden?',
          hint: 'Diese Aktion kann nicht rückgängig gemacht werden',
        }
      }).afterClosed().subscribe(result => {
        if (result == '1') {
          this.dialog.open(AntragDeleteDialogComponent, null).afterClosed().subscribe(result => {
            if (result === '1') {
              this.antragService.deleteAntrag(this.antragObject).subscribe(() => {
                this.abschnittService.get(this.abschnittObject.type, this.abschnittObject.id).subscribe((response: {
                  data: Abschnitt
                }) => {
                  let newAntraege: Array<Antrag> = [];
                  for (let i in response.data.relationships.antraege.data) {
                    if (response.data.relationships.antraege.data[i].id !== this.antragObject.id
                          && response.data.relationships.antraege.data[i].id !== 'missing') {
                      newAntraege.push(response.data.relationships.antraege.data[i]);
                    }
                  }
                  response.data.relationships.antraege.data = newAntraege;
                  this.abschnittService.update(response.data).subscribe(() => {
                    this.abschnittService.setUpdateAbschnitt(this.abschnittObject);
                    this.snackbar.open('Der Antrag wurde gelöscht', null, {
                      duration: 6000,
                    });
                  });
                });
              });
            }
          });
        }
      });
    });
  }
  
  setVotum(): void {
    let votum: Votum = null;
    this.antragService.getVoten(this.antragObject).subscribe(voten => {
      const akVoten: Array<Votum> = voten.filter(votum => votum.attributes.ebene === 'ak');
      for (let i in akVoten) {
        votum = akVoten[i];
      }
      let originalType: string = null;
      this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(rawAntrag => {
        if (votum === null) {
          votum = new Votum();
          votum.relationships = {
            antrag: {
              data: [rawAntrag],
            }
          };
          votum.attributes = {
            ebene: 'ak',
          };
        }
        else {
          originalType = votum.type;
        }
        this.dialog.open(VotumEditDialogComponent, {
          width: '720px',
          data: votum
        }).afterClosed().subscribe(result => {
          if (result !== '0') {
            if (result['id'] === undefined) {
              // Neues Votum
              if (result['attributes']['begruendung'] !== ''
                    && result['attributes']['begruendung'] !== undefined) {
                let begruendung = new VotumBegruendung;
                begruendung.attributes = {
                  inhalt: {
                    value: result['attributes']['begruendung'],
                  }
                };
                begruendung.relationships = {
                  antraege: result['relationships']['antrag'],
                };
                this.votumService.createBegruendung(begruendung).subscribe(() => {});
              }
              delete(result['attributes']['begruendung']);
              this.votumService.createVotum(result).subscribe(votum => {
                this.publishChange();
              }, (error: string) => {
                this.snackbar.open(error, null, {
                  duration: 15000,
                });
              });
            }
            else {
              // Vorhandenes Votum
              if (originalType !== null && originalType !== result['type']) {
                // Das Votum wurde geändert. Das alte Votum muss gelöscht, und das neue als
                // neues Votum gespeichert werden.
                this.votumService.getVotum(originalType, result['id']).subscribe(oldVotum => {
                  this.votumService.deleteVotum(oldVotum).subscribe(() => {});
                });
                this.votumService.createVotum(result).subscribe(votum => {
                  this.publishChange();
                }, (error: string) => {
                  this.snackbar.open(error, null, {
                    duration: 15000,
                  });
                });
              }
              else {
                this.votumService.updateVotum(result).subscribe(votum => {
                  this.publishChange();
                }, (error: string) => {
                  this.snackbar.open(error, null, {
                    duration: 15000,
                  });
                });
              }
            }
          }
        });
      });
    });
  }
  
  setParteitag(): void {
    let votum: Votum = null;
    this.antragService.getVoten(this.antragObject).subscribe(voten => {
      const partetagVoten: Array<Votum> = voten.filter(votum => votum.attributes.ebene === 'pt');
      for (let i in partetagVoten) {
        votum = partetagVoten[i];
      }
      let originalType: string = null;
      this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(rawAntrag => {
        if (votum === null) {
          votum = new Votum();
          votum.relationships = {
            antrag: {
              data: [rawAntrag],
            }
          };
          votum.attributes = {
            ebene: 'pt',
          };
        }
        else {
          originalType = votum.type;
        }
        this.dialog.open(VotumEditDialogComponent, {
          width: '720px',
          data: votum
        }).afterClosed().subscribe(result => {
          if (result !== '0') {
            if (result['id'] === undefined) {
              // Neues Votum
              if (result['attributes']['begruendung'] !== ''
                    && result['attributes']['begruendung'] !== undefined) {
                let begruendung = new VotumBegruendung;
                begruendung.attributes = {
                  inhalt: {
                    value: result['attributes']['begruendung'],
                  }
                };
                begruendung.relationships = {
                  antraege: result['relationships']['antrag'],
                };
                this.votumService.createBegruendung(begruendung).subscribe(() => {});
              }
              delete(result['attributes']['begruendung']);
              this.votumService.createVotum(result).subscribe(votum => {
                this.publishChange();
              }, (error: string) => {
                this.snackbar.open(error, null, {
                  duration: 15000,
                });
              });
            }
            else {
              // Vorhandenes Votum
              if (originalType !== null && originalType !== result['type']) {
                // Das Votum wurde geändert. Das alte Votum muss gelöscht, und das neue als
                // neues Votum gespeichert werden.
                this.votumService.getVotum(originalType, result['id']).subscribe(oldVotum => {
                  this.votumService.deleteVotum(oldVotum).subscribe(() => {});
                });
                this.votumService.createVotum(result).subscribe(votum => {
                  this.publishChange();
                }, (error: string) => {
                  this.snackbar.open(error, null, {
                    duration: 15000,
                  });
                });
              }
              else {
                this.votumService.updateVotum(result).subscribe(votum => {
                  this.publishChange();
                }, (error: string) => {
                  this.snackbar.open(error, null, {
                    duration: 15000,
                  });
                });
              }
            }
          }
        });
      });
    });
  }
  
  deleteVotum(): void {
    if (this.votumAk.relationships.antrag.data.length === 1) {
      this.dialog.open(VotumDeleteDialogComponent, {
        data: this.antragObject,
      }).afterClosed().subscribe(result => {
        if (result === 1 || result === -1) {
          this.votumService.deleteVotum(this.votumAk).subscribe(() => {
            this.publishChange();
          });
        }
        if (result === 1 && this.begruendung !== null && this.begruendung !== undefined) {
          this.votumService.deleteBegruendung(this.begruendung).subscribe(() => {
            this.publishChange();
          });
        }
      });
    }
    else {
      this.dialog.open(VotumGemeinsamDeleteDialogComponent, {
        data: {
          antrag: this.antragObject,
          votum: this.votumAk,
        }
      }).afterClosed().subscribe((result: string) => {
        if (result === '1') {
          this.dialog.open(VotumDeleteDialogComponent, {
            data: this.antragObject,
          }).afterClosed().subscribe(result => {
            if (result === 1 || result === -1) {
              this.votumService.deleteVotum(this.votumAk).subscribe(() => {
                this.publishChange();
              });
            }
            if (result === 1) {
              this.votumService.deleteBegruendung(this.begruendung).subscribe(() => {
                this.publishChange();
              });
            }
          });
        }
      });
    }
  }
  
  deleteParteitag(): void {
    if (this.votumPt.relationships.antrag.data.length === 1) {
      this.dialog.open(VotumDeleteDialogComponent, {
        data: this.antragObject,
      }).afterClosed().subscribe(result => {
        if (result === 1 || result === -1) {
          this.votumService.deleteVotum(this.votumPt).subscribe(() => {
            this.publishChange();
          });
        }
        if (result === 1) {
          this.votumService.deleteBegruendung(this.begruendung).subscribe(() => {
            this.publishChange();
          });
        }
      });
    }
    else {
      this.dialog.open(VotumGemeinsamDeleteDialogComponent, {
        data: {
          antrag: this.antragObject,
          votum: this.votumAk,
        }
      }).afterClosed().subscribe((result: string) => {
        if (result === '1') {
          this.dialog.open(VotumDeleteDialogComponent, {
            data: this.antragObject,
          }).afterClosed().subscribe(result => {
            if (result === 1 || result === -1) {
              this.votumService.deleteVotum(this.votumPt).subscribe(() => {
                this.publishChange();
              });
            }
            if (result === 1) {
              this.votumService.deleteBegruendung(this.begruendung).subscribe(() => {
                this.publishChange();
              });
            }
          });
        }
      });
    }
  }
  
  publishChange(): void {
    this.antragService.setBearbeitung(this.antragObject).subscribe(() => {
      this.antragService.setAntragEvent({
        antrag: this.antragObject,
        name: 'loaded',
      });
    });
  }
  
  editBegruendung(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(rawAntrag => {
      if (this.begruendung === null) {
        this.begruendung = new VotumBegruendung;
        this.begruendung.relationships = {
          antraege: {
            data: [rawAntrag],
          }
        };
        this.begruendung.attributes = {
          inhalt: {
            value: null,
          }
        };
      }
      this.dialog.open(VotumBegruendungEditDialogComponent, {
        width: '720px',
        data: this.begruendung,
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          if (this.begruendung.id === undefined) {
            // Neue Begründung
            this.votumService.createBegruendung(result).subscribe(() => {
              this.publishChange();
              this.snackbar.open('De Begründung wurde gespeichert', null, {
                duration: 6000,
              });
            }, (error: string) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          }
          else {
            // Vorhandene Begründung
            let newAntraege: Array<Antrag> = []; 
            for (let i in this.begruendung.relationships.antraege.data) {
              delete(this.begruendung.relationships.antraege.data[i].meta);
              newAntraege.push(this.begruendung.relationships.antraege.data[i]);
            }
            this.begruendung.relationships.antraege.data = newAntraege;
            this.votumService.updateBegruendung(this.begruendung).subscribe(() => {
              this.publishChange();
              this.snackbar.open('De Begründung wurde gespeichert', null, {
                duration: 6000,
              });
            }, (error: string) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          }
        }
      });
    });
  }
  
  antragPrint(): void {
    this.dialog.open(AntragPrintDialogComponent, {
      data: this.antragObject,
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.antragService.printAntrag(this.antragObject, result).subscribe((response: Blob) => {
          let fileName = 'antrag-' + this.antragObject.attributes.label.replace(' ', '-').replace('&nbsp;', '-') + '.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  setGemeinsam(): void {
    this.dialog.open(AntragGemeinsamDialogComponent, {
      width: '360px',
      data: this.antragObject
    }).afterClosed().subscribe(result => {
      if (result !== '0' && result !== undefined) {
        // console.debug(result);
        this.antragService.setGemeinsam(this.antragObject, result['antrag'], result['votum'], result['begruendung']).subscribe(() => {
          this.publishChange();
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  removeGemeinsam(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Soll dieser Antrag nicht mehr mit anderen gemeinsam behandelt werden?',
      }
    }).afterClosed().subscribe(result => {
      if (result == 1) {
        this.antragService.removeGemeinsam(this.antragObject).subscribe(() => {
          this.publishChange();
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  setRedner(): void {
    this.votumService.getVotum(this.votumAk.type, this.votumAk.id).subscribe(votum => {
      this.dialog.open(VotumRednerDialogComponent, {
        data: votum,
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          this.votumService.updateVotum(result).subscribe(() => {
            this.publishChange();
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  setThema(): void {
    this.dialog.open(AntragThemaDialogComponent, {
      width: '480px',
      data: this.antragObject,
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.themaService.get(result).subscribe((response: {
          data:Thema
        }) => {
          this.antragService.setAntragThema(this.antragObject, response.data).subscribe(() => {
            this.antragService.setBearbeitung(this.antragObject).subscribe(() => {
              this.antragService.setAntragEvent({
                antrag: this.antragObject,
                name: 'loaded',
              });
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        });
      }
    });
  }
  
  antragMarkup(): void {
    this.antragService.getMarkup(this.antragObject).subscribe(markup => {
      this.dialog.open(AntragMarkupDialogComponent, {
        width: '720px',
        data: markup,
      });
    });
  }
  
  abweichendesVotum(): void {
    let votum: Votum = null;
    let gemeinsamVotum: Votum = null
    this.antragService.getVoten(this.antragObject).subscribe(voten => {
      for (let i in voten) {
        gemeinsamVotum = voten[i];
      }
    });
    let originalType: string = null;
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(rawAntrag => {
      votum = new Votum();
      votum.relationships = {
        antrag: {
          data: [rawAntrag],
        }
      };
      votum.attributes = {
        ebene: 'ak',
      };
      this.dialog.open(VotumEditDialogComponent, {
        width: '720px',
        data: votum
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          if (gemeinsamVotum !== null) {
            const restAntraege: Array<Antrag> = 
              gemeinsamVotum.relationships.antrag.data.filter(antrag => antrag.id !== this.antragObject.id);
            gemeinsamVotum.relationships.antrag.data = restAntraege;
            this.votumService.updateVotum(gemeinsamVotum).subscribe(() => {});
          }
          if (result['attributes']['begruendung'] !== ''
                && result['attributes']['begruendung'] !== undefined) {
            let begruendung = new VotumBegruendung;
            begruendung.attributes = {
              inhalt: {
                value: result['attributes']['begruendung'],
              }
            };
            begruendung.relationships = {
              antraege: result['relationships']['antrag'],
            };
            this.votumService.createBegruendung(begruendung).subscribe(() => {});
          }
          delete(result['attributes']['begruendung']);
          this.votumService.createVotum(result).subscribe(() => {
            this.publishChange();
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  abweichendeBegruendung(): void {
    this.antragService.getAntrag(this.antragObject.type, this.antragObject.id).subscribe(rawAntrag => {
      let newBegruendung: VotumBegruendung = new VotumBegruendung;
      newBegruendung.relationships = {
        antraege: {
          data: [rawAntrag],
        }
      };
      newBegruendung.attributes = {
        inhalt: {
          value: null,
        }
      };
      this.dialog.open(VotumBegruendungEditDialogComponent, {
        width: '720px',
        data: newBegruendung,
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          let newAntraege: Array<Antrag> = []; 
          for (let i in this.begruendung.relationships.antraege.data) {
            if (this.begruendung.relationships.antraege.data[i].id !== this.antragObject.id) {
              delete(this.begruendung.relationships.antraege.data[i].meta);
              newAntraege.push(this.begruendung.relationships.antraege.data[i]);
            }
          }
          this.begruendung.relationships.antraege.data = newAntraege;
          this.votumService.updateBegruendung(this.begruendung).subscribe(() => {
            this.votumService.createBegruendung(result).subscribe(() => {
              this.publishChange();
              this.snackbar.open('Die Begründung wurde gespeichert', null, {
                duration: 6000,
              });
            }, (error: string) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  menuOpened(): void {
    const url: string = this.router.url.replace(/\#(.*?)$/, '');
    window.history.replaceState({}, document.title, url);
  }
  
}