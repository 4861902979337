<form [formGroup]="antragForm">
  <gliederung-select formControlName="gliederung" required>
  </gliederung-select>
  <mat-error *ngIf="!antragForm.get('gliederung').valid">
    <span *ngIf="antragForm.get('gliederung').errors.required && antragForm.get('gliederung').touched">
      Eine oder mehr Gliederungen müssen ausgewählt werden
    </span>
  </mat-error>
  <mat-form-field *ngIf="hasAntragstellerName">
    <mat-label>Antragsteller</mat-label>
    <textarea matInput formControlName="antragsteller" rows="2"></textarea>
    <mat-error *ngIf="!antragForm.get('antragsteller').valid">
      <span *ngIf="antragForm.get('antragsteller').errors.required && antragForm.get('antragsteller').touched">
        Der Antragsteller (z.B. die Namen der Mitglieder) muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <h3>
    <mat-form-field>
      <input matInput type="text" maxlength="255" formControlName="title" 
             placeholder="Titel" required>
      <mat-error *ngIf="!antragForm.get('title').valid">
        <span *ngIf="antragForm.get('title').errors.required && antragForm.get('title').touched">
          Der Titel muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
  </h3>
  <cketext formControlName="inhalt" placeholder="Antrag">
  </cketext>
  <p>
    <mat-checkbox (change)="ohneBegruendungChanged($event)" formControlName="ohne_begruendung">
      Keine Begründung angeben
    </mat-checkbox>
  </p>
  <cketext formControlName="begruendung" placeholder="Begründung" 
           *ngIf="antrag !== null && !antrag.attributes.ohne_begruendung">
  </cketext>
  <mat-error *ngIf="!antragForm.get('begruendung').valid">
    <span *ngIf="antragForm.get('begruendung').errors.required && antragForm.get('begruendung').touched">
      Die Begründung muss angegeben werden.
    </span>
  </mat-error>
</form>