/**
 * Redner der AK
 * 
 * Attila Németh, UBG
 * 07.05.2020
 */
 
import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {Redner} from '../../../model/redner';
import {RednerService} from '../../../services/redner.service';
import {ProjektSelectDialogComponent} from '../dialog/select/select.component';

@Component({
  selector: 'projekt-redner',
  templateUrl: './redner.component.html',
  styleUrls: ['./redner.component.scss'],
})
export class ProjektRednerComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
    }
  }
  projektObject: Projekt = null
  rednerForm: FormGroup
  
  constructor(formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private projektService: ProjektService,
                private rednerService: RednerService) {
    this.rednerForm = formBuilder.group({
      title: [null, Validators.required],
    });
  }
  
  addRedner(): void {
    let redner = new Redner;
    redner.attributes = {
      title: this.rednerForm.get('title').value,
    };
    this.rednerService.createRedner(redner).subscribe((createdRedner: Redner) => {
      this.projektService.get(this.projektObject.id).subscribe((response: {
        data: Projekt
      }) => {
        let projekt = response.data;
        let newRednern: Array<Redner> = [];
        if (projekt.relationships.rednern === undefined) {
          if (projekt.relationships === undefined) {
            projekt.relationships = {};
          }
          projekt.relationships.rednern = {
            data: [],
          };
        }
        else {
          for (let i in projekt.relationships.rednern.data) {
            newRednern.push(projekt.relationships.rednern.data[i]);
          }
        }
        newRednern.push(createdRedner);
        projekt.relationships.rednern.data = newRednern;
        this.projektService.update(projekt).subscribe((response: {
          data: Projekt
        }) => {
          this.projektObject = response.data;
          this.rednerForm.get('title').reset();
          this.rednerForm.get('title').markAsUntouched();
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          })
        })
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      })
    });
  }
  
  deleteRedner(): void {
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let newRedner: Array<Redner> = [];
      let projekt = response.data;
      for (let i in projekt.relationships.rednern.data) {
        if (projekt.relationships.rednern.data[i].id !== 'missing') {
          newRedner.push(projekt.relationships.rednern.data[i]);
        }
      }
      projekt.relationships.rednern.data = newRedner;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
      }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        })
      });
    });
  }
  
  uebernehmen(): void {
    this.dialog.open(ProjektSelectDialogComponent, {
      width: '480px',
      data: {
        exclude: [this.projektObject],
      }
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.projektService.uebernehmen(result, this.projektObject, 'rednern').subscribe(() => {
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            this.projektObject = response.data;
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
}