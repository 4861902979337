/**
 * Überweisungsgruppe bearbeiten
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Output, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Ueberweisung, UeberweisungGruppe} from '../../../model/ueberweisung';

const noop = () => {};

@Component({
  selector: 'ueberweisung-gruppe-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UeberweisungGruppeEditComponent),
    multi: true
  }]
})
export class UeberweisungGruppeEditComponent {
  
  @Output() validityChange = new EventEmitter
  ueberweisungForm: FormGroup
  ueberweisungGruppe: UeberweisungGruppe
  focused: boolean = false
  
  constructor(formBuilder: FormBuilder) {
    this.ueberweisungForm = formBuilder.group({
      title: [null, Validators.required],
    });
    this.ueberweisungForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.ueberweisungForm.get('title').valueChanges.subscribe((value: string) => {
      this.ueberweisungGruppe.attributes.title = value;
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  ngOnInit() {
    
  }

  get value(): UeberweisungGruppe {
    return this.ueberweisungGruppe;
  };

  set value(v: UeberweisungGruppe) {
    if (v !== this.ueberweisungGruppe) {
      this.ueberweisungGruppe = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  setFocus() {
    this.focused = true;
  }

  writeValue(value: UeberweisungGruppe) {
    if (value !== this.ueberweisungGruppe && value !== null) {
      this.ueberweisungGruppe = value;
      if (this.ueberweisungGruppe.attributes === undefined) {
        this.ueberweisungGruppe.attributes = {
          title: null,
        };
      }
      this.ueberweisungForm.get('title').setValue(this.ueberweisungGruppe.attributes.title);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.ueberweisungGruppe);
  }

}

 
