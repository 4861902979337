/**
 * Abschnitt mit Leitantrag
 * 
 * Attila Németh, UBG
 * 20.05.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

import {AbschnittService} from '../../../../services/abschnitt.service';
import {Abschnitt} from '../../../../model/abschnitt';
import {AntragService} from '../../../../services/antrag.service';
import {AntragEvent} from '../../../../model/events';
import {FilterService} from '../../../../services/filter.service';
import {Antrag} from '../../../../model/antrag';

@Component({
  selector: 'abschnitt-mit-leitantrag',
  templateUrl: './abschnitt.component.html', 
  styleUrls: ['../abschnitt.component.scss'],
})
export class AbschnittMitLeitantragComponent implements OnInit, OnDestroy {
    
  @Input() set abschnitt(abschnitt: Abschnitt) {
    this.abschnittObject = abschnitt;
    this.alignAntraege();
    this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
      if (event !== null && (event.name == 'loaded')) {
        this.reloadAbschnitt();
      }
      this.filterSubscription = this.filterService.filterResult.subscribe(result => {
        if (result !== null && result.abschnitt.id === this.abschnittObject.id) {
          if (result.result === null) {
            this.reloadAbschnitt();
          }
          else {
            this.fragment = null;
            let newAntraege: Array<Antrag> = [];
            for (let i in this.abschnittObject.relationships.antraege.data) {
              for (let j in result.result) {
                if (result.result[j].type == 'ubg_antrag--zum_leitantrag'
                && result.result[j].relationships.leitantrag.data.id 
                  == this.abschnittObject.relationships.antraege.data[i].id) {
                  newAntraege.push(this.abschnittObject.relationships.antraege.data[i]);
                  break;
                }
              }
            }
            this.abschnittObject.relationships.antraege.data = newAntraege;
          }
        }
      });
    });
  }
  @Input() set scroll(scroll: number) {
    if (scroll >= 0) {
      let top: number = 76 - scroll;
      if (top < 8) {
        top = 8;
      }
      this.opsTop = top.toString() + 'px';
    }
  }
  abschnittObject: Abschnitt = null
  antragSubscription: Subscription
  lastAntragUpdate: number = new Date().getTime()
  antragEventSubscription: Subscription = null
  filterSubscription: Subscription
  fragmentSubscription: Subscription
  fragment: string = null
  opsTop: string = '76px'
  
  constructor(private route: ActivatedRoute,
                private abschnittService: AbschnittService,
                private antragService: AntragService,
                private filterService: FilterService) {}
                
  ngOnInit(): void {
    this.antragSubscription = this.abschnittService.updateAbschnitt.subscribe(abschnitt => {
      if (abschnitt !== null && this.abschnittObject !== null && abschnitt.id === this.abschnittObject.id) {
        this.reloadAbschnitt();
      }
    });
    this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
      if (fragment === undefined) {
        this.fragment = null;
      }
      else {
        this.fragment = fragment;
      }
      this.alignAntraege();
    });
  }
    
  ngOnDestroy(): void {
    this.antragSubscription.unsubscribe();
    if (this.antragEventSubscription !== null) {
      this.antragEventSubscription.unsubscribe();
    }
    this.filterSubscription.unsubscribe();
  }
                
  reloadAbschnitt(): void {
    this.abschnittService.getExtended(this.abschnittObject.id).subscribe(abschnitt => {
      this.abschnittObject = abschnitt;
      console.info('Der Abschnitt wurde neu geladen. Es gibt neue Anträge');
      this.alignAntraege();
    });
  }
  
  alignAntraege(): void {
    if (this.fragment !== null) {
      for (let i in this.abschnittObject.relationships.antraege.data) {
        if (this.abschnittObject.relationships.antraege.data[i].id === this.fragment.replace('antrag-', '')) {
          for (let j in this.abschnittObject.relationships.antraege.data) {
            if (this.abschnittObject.relationships.antraege.data[i].relationships.leitantrag !== undefined
              && this.abschnittObject.relationships.antraege.data[i].relationships.leitantrag.data.id
              === this.abschnittObject.relationships.antraege.data[j].id) {
              this.abschnittObject.relationships.antraege.data[j].meta.isOpen = true;
            }
          }
        }
      }
    }
  }
   
}