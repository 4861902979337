<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <form [formGroup]="editForm">
    <mat-form-field>
      <input matInput type="text" formControlName="title" required>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data.thema" color="primary" [disabled]="disabled">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>