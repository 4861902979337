/**
 * Themen
 * 
 * Attila Németh, UBG
 * 04.05.2020
 */
 
import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop} from '@angular/cdk/drag-drop';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

import {Projekt} from '../../../model/projekt';
import {Thema} from '../../../model/thema';
import {ThemaService} from '../../../services/thema.service';
import {ProjektService} from '../../../services/projekt.service';
import {ProjektSelectDialogComponent} from '../dialog/select/select.component';

@Component({
  selector: 'projekt-themen',
  templateUrl: './themen.component.html',
  styleUrls: ['./themen.component.scss'],
})
export class ProjektThemenComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
    }
  }
  projektObject: Projekt = null
  themaForm: FormGroup = null
  messages: Array<string> = ['Aaa', 'Bbb', 'Ccc']
  isLoading: boolean = false
  
  constructor(formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private projektService: ProjektService,
                private themaService: ThemaService) {
    this.themaForm = formBuilder.group({
      thema: [null, Validators.required],
    }); 
  }
  
  addThema(): void {
    let thema = new Thema;
    thema.attributes = {
      title: this.themaForm.get('thema').value,
    };
    this.themaService.create(thema).subscribe((response: {
      data: Thema
    }) => {
      const newThema = response.data;
      this.projektService.get(this.projektObject.id).subscribe((response: {
        data: Projekt
      }) => {
        let projekt = response.data;
        let projektThemen: Array<Thema> = [];
        if (projekt.relationships.themen !== undefined) {
          for (let i in projekt.relationships.themen.data) {
            projektThemen.push(projekt.relationships.themen.data[i]);
          }
        }
        projektThemen.push(newThema);
        projekt.relationships.themen.data = projektThemen;
        this.projektService.update(projekt).subscribe((response: {
          data: Projekt
        }) => {
          this.projektObject = response.data;
          this.themaForm.get('thema').reset(null);
        });
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
  drop(event: CdkDragDrop<string[]>) {
    this.isLoading = true;
    const movedThema: Thema = this.projektObject.relationships.themen.data[event.previousIndex];
    let newThemen: Array<Thema> = [];
    for (let i in this.projektObject.relationships.themen.data) {
      if (Number(i) == event.currentIndex) {
        newThemen.push(movedThema);
      }
      if (this.projektObject.relationships.themen.data[i].id !== movedThema.id) {
        newThemen.push(this.projektObject.relationships.themen.data[i]);
      }
    }
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let projekt = response.data;
      projekt.relationships.themen.data = newThemen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
        this.isLoading = false;
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
  deleteThema(themaId: string): void {
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let projekt: Projekt = response.data;
      let newThemen: Array<Thema> = [];
      for (let i in projekt.relationships.themen.data) {
        if (projekt.relationships.themen.data[i].id !== themaId
              && projekt.relationships.themen.data[i].id !== 'missing') {
          newThemen.push(projekt.relationships.themen.data[i]);
        }
      }
      projekt.relationships.themen.data = newThemen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
      });
    });
  }
  
  uebernehmen(): void {
    this.dialog.open(ProjektSelectDialogComponent, {
      width: '480px',
      data: {
        exclude: [this.projektObject],
      }
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.projektService.uebernehmen(result, this.projektObject, 'themen').subscribe(() => {
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            this.projektObject = response.data;
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
}