/**
 * Projekt
 * 
 * Attila Németh, UBG
 * 22.04.2020
 */
 
import {Abschnitt} from './abschnitt';
import {Mandant} from './mandant';
import {Thema} from './thema';
import {Ueberweisung, UeberweisungGruppe} from './ueberweisung';
import {Redner} from './redner';
import {GliederungKategorie} from './gliederung';
 
export class Projekt {
  
  type: string = 'ubg_projekt--ubg_projekt'
  id: string
  attributes: {
    title: string
    aktiv: boolean
    antragsfrist?: {
      value: string
      end_value: string
    }
    absender?: string       // E-Mail
    absenden?: {
      value: string
    }
    zuweisung?: {
      value: string
    }
    entwurf_benachrichtigung?: {
      value: string
    }
  }
  relationships?: {
    abschnitte?: {
      data: Array<Abschnitt>
    }
    mandant?: {
      data: Mandant
    }
    themen?: {
      data: Array<Thema>
    }
    ueberweisungen?: {
      data: Array<Ueberweisung>
    }
    ueberweisung_gruppen?: {
      data: Array<UeberweisungGruppe>
    }
    rednern?: {
      data: Array<Redner>
    }
    gliederungen?: {
      data: Array<GliederungKategorie>
    }
  }
  
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
  
  create() {
    this.attributes = {
      title: null,
      aktiv: false,
    };
  }
  
  isAbgelaufen(): boolean {
    const antragsFrist = new Date(this.attributes.antragsfrist.end_value);
    const now = new Date();
    return now > antragsFrist;
  }
  
}

export interface BroschuerePrintOptions {
  
  mitVotum: boolean
  mitBegruendungKommission: boolean
  mitBegruendungAntragsteller: boolean
  mitMarkup: boolean
  mitEntwurf: boolean
  mitRedner: boolean
  
}