/**
 * Überweisungen
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Input} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {Ueberweisung, UeberweisungGruppe} from '../../../model/ueberweisung';
import {UeberweisungEditDialogComponent} from '../../ueberweisung/dialog/edit/edit.component';
import {UeberweisungGruppeEditDialogComponent} from '../../ueberweisung/dialog/edit_gruppe/edit.component';
import {UeberweisungService} from '../../../services/ueberweisung.service';
import {ProjektSelectDialogComponent} from '../dialog/select/select.component';

@Component({
  selector: 'projekt-ueberweisungen',
  templateUrl: './ueberweisungen.component.html',
  styleUrls: ['./ueberweisungen.component.scss'],
})
export class ProjektUeberweisungenComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
    }
  }
  projektObject: Projekt = null
  isLoading: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                private ueberweisungService: UeberweisungService) {}
  
  addUeberweisung(): void {
    const ueberweisung = new Ueberweisung;
    ueberweisung.relationships = {
      gruppe: {
        data: this.projektObject.relationships.ueberweisung_gruppen.data[0],
      }
    };
    this.dialog.open(UeberweisungEditDialogComponent, {
      width: '480px',
      data: {
        ueberweisung: ueberweisung,
      }
    }).afterClosed().subscribe((response: any) => {
      if (response !== '0') {
        this.ueberweisungService.createUeberWeisung(response).subscribe((createdUeberweisung: Ueberweisung) => {
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            const projekt: Projekt = response.data;
            let newUeberweisungen: Array<Ueberweisung> = [];
            for (let i in projekt.relationships.ueberweisungen.data) {
              newUeberweisungen.push(projekt.relationships.ueberweisungen.data[i]);
            }
            newUeberweisungen.push(createdUeberweisung);
            projekt.relationships.ueberweisungen.data = newUeberweisungen;
            this.projektService.update(projekt).subscribe((response: {
              data: Projekt
            }) => {
              this.projektObject = response.data;
            }, (error) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          });
        });
      }
    });
  }
  
  ueberweisungDeleted(event: Ueberweisung): void {
    this.isLoading = true;
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      const projekt: Projekt = response.data;
      let newUeberweisungen: Array<Ueberweisung> = [];
      for (let i in projekt.relationships.ueberweisungen.data) {
        if (projekt.relationships.ueberweisungen.data[i].id !== event.id) {
          newUeberweisungen.push(projekt.relationships.ueberweisungen.data[i]);
        }
      }
      projekt.relationships.ueberweisungen.data = newUeberweisungen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
        this.isLoading = false;
      }, (error) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
    });
  }
  
  addUeberweisungGruppe(): void {
    const ueberweisungGruppe = new UeberweisungGruppe;
    this.dialog.open(UeberweisungGruppeEditDialogComponent, {
      width: '480px',
      data: {
        ueberweisungGruppe: ueberweisungGruppe,
      }
    }).afterClosed().subscribe((response: any) => {
    if (response !== '0') {
        this.ueberweisungService.createGruppe(response).subscribe((response: {
          data: UeberweisungGruppe,
        }) => {
          const newGruppe: UeberweisungGruppe = response.data;
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            const projekt: Projekt = response.data;
            let newGruppen: Array<UeberweisungGruppe> = [];
            for (let i in projekt.relationships.ueberweisung_gruppen.data) {
              newGruppen.push(projekt.relationships.ueberweisung_gruppen.data[i]);
            }
            newGruppen.push(newGruppe);
            projekt.relationships.ueberweisung_gruppen.data = newGruppen;
            this.projektService.update(projekt).subscribe((response: {
              data: Projekt
            }) => {
              this.projektObject = response.data;
            }, (error) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          });
        }, (error) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
    }
    });
  }

  dropUeberweisung(event: CdkDragDrop<string[]>): void {
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let projekt = response.data;
      let newUeberweisungen: Array<Ueberweisung> = [];
      for (let i in projekt.relationships.ueberweisungen.data) {
        newUeberweisungen.push(projekt.relationships.ueberweisungen.data[i]);
      }
      moveItemInArray(newUeberweisungen, event.previousIndex, event.currentIndex);
      projekt.relationships.ueberweisungen.data = newUeberweisungen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
      });
    });
  }
  
  dropGruppe(event: CdkDragDrop<string[]>): void {
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let projekt = response.data;
      let newGruppen: Array<UeberweisungGruppe> = [];
      for (let i in projekt.relationships.ueberweisung_gruppen.data) {
        newGruppen.push(projekt.relationships.ueberweisung_gruppen.data[i]);
      }
      moveItemInArray(newGruppen, event.previousIndex, event.currentIndex);
      projekt.relationships.ueberweisung_gruppen.data = newGruppen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
      });
    });
  }
  
  deleteGruppe(deletedId: string) {
    this.projektService.get(this.projektObject.id).subscribe((response: {
      data: Projekt
    }) => {
      let projekt = response.data;
      let newGruppen: Array<UeberweisungGruppe> = [];
      for (let i in projekt.relationships.ueberweisung_gruppen.data) {
        if (projekt.relationships.ueberweisung_gruppen.data[i].id !== deletedId
        && projekt.relationships.ueberweisung_gruppen.data[i].id !== 'missing') {
          newGruppen.push(projekt.relationships.ueberweisung_gruppen.data[i]);
        }
      }
      projekt.relationships.ueberweisung_gruppen.data = newGruppen;
      this.projektService.update(projekt).subscribe((response: {
        data: Projekt
      }) => {
        this.projektObject = response.data;
      }, (error) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
    });
  }
  
  uebernehmen(): void {
    this.dialog.open(ProjektSelectDialogComponent, {
      width: '480px',
      data: {
        exclude: [this.projektObject],
      }
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.projektService.uebernehmen(result, this.projektObject, 'ueberweisungen').subscribe(() => {
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            this.projektObject = response.data;
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
}