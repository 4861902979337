import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {ProjektEditDialogComponent} from '../dialog/edit.component';
import {UserService} from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjektCloneDialogComponent } from '../dialog/clone/clone.component';

@Component({
  templateUrl: './projekt-list.component.html',
  styleUrls: ['./projekt-list.component.scss']
})
export class ProjektListComponent implements OnInit {
  
  projekte: Array<Projekt> = []
  createAccess: boolean = false
  editableProjekte: Array<Projekt> = []
  isCloning: boolean = false
  
  displayedColumns: Array<string> = ['title', 'aktiv', 'operations']
  
  constructor(private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                userService: UserService) {
    userService.userLoggedIn.subscribe((status: number) => {
      if (status === 0) {
        this.projekte = [];
      }
      else {
        this.projektService.createPermission().subscribe((response: boolean) => {
          this.createAccess = response;
        });
        this.loadProjekte();
      }
    });
  }
  
  ngOnInit(): void {
    this.projektService.resetActive();
  }
  
  loadProjekte(): void {
    this.projektService.getAll().subscribe((response: {
      data: Array<Projekt>
    }) => {
      this.projekte = response.data;
      this.editableProjekte = [];
      for (let i in this.projekte) {
        this.projektService.editPermission(this.projekte[i]).subscribe((response: any) => {
          if (response) {
            this.editableProjekte.push(this.projekte[i]);
          }
        });
      }
      this.isCloning = false;
    }, (error) => {
      console.warn(error);
    });
  }
  
  addProjekt(): void {
    let newProjekt = new Projekt;
    newProjekt.create();
    this.dialog.open(ProjektEditDialogComponent, {
      width: '480px',
      data: {
        projekt: newProjekt,
      }
    }).afterClosed().subscribe((newProjekt: any) => {
      if (newProjekt != 0) {
        this.projektService.create(newProjekt).subscribe(() => {
          this.loadProjekte();
          this.projektService.reloadAllProjekte();
        });
      }
    });
  }
  
  editProjekt(projekt: Projekt): void {
    this.dialog.open(ProjektEditDialogComponent, {
      width: '480px',
      data: {
        projekt: projekt,
      }
    }).afterClosed().subscribe((newProjekt: any) => {
      if (newProjekt == 0) {
        this.loadProjekte();
        this.projektService.reloadAllProjekte();
      }
      else {
        this.projektService.update(newProjekt).subscribe(() => {
          this.loadProjekte();
          this.projektService.reloadAllProjekte();
        });
      }
    });
  }
  
  openProjekt(projekt: Projekt): void {
    this.router.navigate(['projekt/' + projekt.id]);
  }
  
  projektEditable(projekt: Projekt): boolean {
    for (let i in this.editableProjekte) {
      if (projekt.id == this.editableProjekte[i].id) {
        return true;
      }
    }
    return false;
  }

  cloneProjekt(projekt: Projekt): void {
    this.dialog.open(ProjektCloneDialogComponent, {
      width: '480px',
      data: {
        projekt: projekt,
        votum: null
      }
    }).afterClosed().subscribe(result => {
      if (result != 0 && result != undefined) {
        this.isCloning = true;
        this.projektService.cloneProjekt(projekt, result.votum).subscribe(() => {
          this.snackbar.open('Das Projekt wurde geklont', null, {
            duration: 6000,
          });
          this.loadProjekte();
        });
      }
    });
  }

}
