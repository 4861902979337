/**
 * Mein-Antrag-Komponent für die antragsberechtigten Gliederungen
 * 
 * Attila Németh, UBG
 * 28.05.2020
 */
 
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription, Observable, of, combineLatest} from 'rxjs';
import {startWith, map} from 'rxjs/operators';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {UserService} from '../../../services/user.service';
import {ProjektService} from '../../../services/projekt.service';
import {PageService} from '../../../services/page.service';
import {CookieService} from '@ubg/cookie-service';
import {Gliederung} from '../../../model/gliederung';
import {AbschnittService} from '../../../services/abschnitt.service';
import {Abschnitt} from '../../../model/abschnitt';

@Component({
  templateUrl: './mein.component.html',
  styleUrls: ['./mein.component.scss'],
})
export class ProjektMeinAntragComponent implements OnInit, OnDestroy {
  
  isLoggedIn: boolean = false
  hasLoginFailed: boolean = false
  loginSubscription: Subscription
  projektId: string = null
  projekt: Projekt
  password: string = null
  gliederungForm: FormGroup
  gliederung: Gliederung = null
  gliederungSubscription: Subscription
  abschnittSubscription: Subscription
  abschnitt: Abschnitt = null
  antragEventSubscription: Subscription
  page: string = 'home'
  homeSubscription: Subscription
  pageSubscription: Subscription
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private userService: UserService,
                private projektService: ProjektService,
                private pageService: PageService,
                private cookieService: CookieService,
                private abschnittService: AbschnittService) {
    this.gliederungForm = this.formBuilder.group({
      gliederung: [null],
    });
  }

  ngOnInit(): void {
    this.loginSubscription = this.userService.userLoggedIn.subscribe(status => {
      if (status === 1) {
        this.isLoggedIn = true;
      }
      else {
        if (this.hasLoginFailed) {
          this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', null, {
            duration: 6000,
          });
        }
        this.isLoggedIn = false;
        this.hasLoginFailed = true;
      }
      this.loadProjekt();
    });
    this.route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
      this.loadProjekt();
    });
    this.gliederungSubscription = this.gliederungForm.get('gliederung').valueChanges.subscribe(gliederungen => {
      this.gliederung = gliederungen[0];
    });
    this.abschnittSubscription = this.abschnittService.activeAbschnitt.subscribe(abschnitt => {
      this.abschnitt = abschnitt;
    });
    this.homeSubscription = this.pageService.homeClicked().subscribe((status) => {
      if (status) {
        this.router.navigate(['projekt/' + this.projektId]);
      }
    });
    this.pageSubscription = this.projektService.activePage.subscribe(page => {
      if (page === 'home') {
        this.page = 'home';
      }
    });
  }
  
  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
    this.gliederungSubscription.unsubscribe();
    this.abschnittSubscription.unsubscribe();
    this.pageSubscription.unsubscribe();
  }
  
  login(password: string) {
    this.password = password;
    this.userService.gliederungLogin(password, this.projektId);
    setTimeout(() => {
      this.cookieService.delete('refresh_token');
    }, 5000);
    this.cookieService.set('gliederung', password, null);
  }
  
  loadProjekt(): void {
    if (this.isLoggedIn && this.projektId !== null) {
      this.projektService.getForGliederung(this.projektId, this.password).subscribe(projekt => {
        this.pageService.setTitle(projekt.attributes.title);
        this.projektService.setActive(projekt);
        this.projekt = projekt;
      });
    }
  }
  
  toAbschnitte(): void {
    this.page = 'abschnitte';
    this.projektService.openPage('meine-abschnitte');
  }
              
}