<h1 mat-dialog-title>Korrigierte Version (13.8.2022)</h1>
<mat-dialog-content class="mat-body">
  <mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>
  <form [formGroup]="gemeinsamForm" class="full-width-form">
    <mat-form-field>
      <input matInput type="text" placeholder="Gemeinsam mit"
             formControlName="antrag" [matAutocomplete]="autoAntraege" required>
      <mat-autocomplete #autoAntraege="matAutocomplete" (optionSelected)="antragSelected($event)">
        <mat-option *ngFor="let option of antragFilteredOptions" [value]="option"
          [innerHtml]="getAntragLabel(option)">
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="votumOptions.length > 1">
      <mat-select formControlName="votum" placeholder="Votum">
        <mat-option *ngFor="let votum of votumOptions" [value]="votum"
          [innerHtml]="getVotumLabel(votum)">
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="begruendungOptions.length > 1">
      <mat-select formControlName="begruendung" placeholder="Begründung">
        <mat-option *ngFor="let begruendung of begruendungOptions" [value]="begruendung">
          {{ getBegruendungLabel(begruendung) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <h2 *ngIf="(response.begruendung === null || response.begruendung === undefined) && !disabled">
      Keine Begründung!
    </h2>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">Verwerfen</button>
  <button mat-button [mat-dialog-close]="response" [disabled]="disabled" color="primary">
    Speichern
  </button>
</mat-dialog-actions>