<user-content>
  
  <div *ngIf="projekt !== null" fxLayout="row" fxLayoutGap="0">
    <abschnitt-selektor fxFlex="1 1 48px" fxFlex.gt-sm="1 1 64px"></abschnitt-selektor>
    <div class="layout-margin" fxLayout="row" fxLayoutGap="0.5rem" fxFlex="2 2 auto">
      <div fxFlex="2 2 auto">
        <abschnitt></abschnitt>
      </div>
    </div>
  </div>
  
</user-content>