/**
 * Broschüre Drucken
 * 
 * Attila Németh, UBG
 * 29.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {Projekt, BroschuerePrintOptions} from '../../../../model/projekt';

@Component({
  templateUrl: './print.component.html',
})
export class ProjektBroschuerePrintDialogComponent implements OnInit {
  
  printOptions: BroschuerePrintOptions = {
    mitVotum: false,
    mitBegruendungKommission: false,
    mitBegruendungAntragsteller: false,
    mitMarkup: false,
    mitEntwurf: false,
    mitRedner: false,
  }
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Projekt) {}
  
  ngOnInit(): void {}
  
  setOption(name: string, event: MatCheckboxChange): void {
    this.printOptions[name] = event.checked;
  }
  
}