/**
 * Überweisungsgruppe
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {UeberweisungGruppe} from '../../../model/ueberweisung';
import {UeberweisungService} from '../../../services/ueberweisung.service';
import {UeberweisungGruppeEditDialogComponent} from '../dialog/edit_gruppe/edit.component';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'ueberweisung-gruppe',
  templateUrl: './gruppe.component.html',
  styleUrls: ['./gruppe.component.scss'],
})
export class UeberwieunsgGruppeComponent {
  
  @Input() set ueberweisungGruppe(ueberweisungGruppe: UeberweisungGruppe) {
    this.ueberweisungGruppeObject = ueberweisungGruppe
    if (this.ueberweisungGruppeObject.attributes === undefined) {
      this.ueberweisungService.getGruppe(this.ueberweisungGruppeObject.id).subscribe((response: UeberweisungGruppe) => {
        this.ueberweisungGruppeObject = response;
        this.ueberweisungService.deleteGruppPermission(this.ueberweisungGruppeObject).subscribe((response: boolean) => {
          this.deletePermission = response;
        });
      });
    }
  }
  @Output() delete = new EventEmitter
  ueberweisungGruppeObject: UeberweisungGruppe = null
  deletePermission: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private ueberweisungService: UeberweisungService) {}
  
  editGruppe(): void {
    this.dialog.open(UeberweisungGruppeEditDialogComponent, {
      data: {
        ueberweisungGruppe: this.ueberweisungGruppeObject,
      }
    }).afterClosed().subscribe((response: any) => {
      if (response !== '0') {
        this.ueberweisungService.getGruppe(this.ueberweisungGruppeObject.id).subscribe((gruppe: UeberweisungGruppe) => {
          gruppe.attributes.title = response['attributes']['title'];
          this.ueberweisungService.updateGruppe(gruppe).subscribe((response: UeberweisungGruppe) => {
            this.ueberweisungGruppeObject = response;
          });
        });
      }
    });
  }
  
  deleteGruppe(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diese Gruppe löschen?',
        hint: 'Es kann nicht rückgängig gemacht werden',
      }
    }).afterClosed().subscribe((response: string) => {
      if (response == '1') {
        this.ueberweisungService.deleteGruppe(this.ueberweisungGruppeObject).subscribe(() => {
          this.delete.emit(this.ueberweisungGruppeObject.id);
        }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
      }
    });
  }
     
}