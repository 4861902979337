/**
 * Gliederungen-Operationen
 * 
 * Attila Németh, UBG
 * 12.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {GliederungenUploadDialogComponent} from '../dialog/upload/upload.component';
import {GliederungService} from '../../../services/gliederung.service';
import {ConfirmComponent} from '@ubg/confirm';

interface UploadEvent {
  type: string
  loaded?: number
  total?: number
  body?: any
  status?: number
}

@Component({
  selector: 'gliederung-operations',
  templateUrl: './operations.component.html',
//  styleUrls: ['./operations.component.scss']
})
export class GliederungOperationsComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== undefined && projekt !== null) {
      this.projektObject = projekt;
    }
  }
  projektObject: Projekt = null
  uploadPhase: number = -1
  isLoading: boolean = false
  isProviding: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private projektService: ProjektService,
                private gliederungService: GliederungService) {}
  
  loadExcel(): void {
    this.dialog.open(GliederungenUploadDialogComponent).afterClosed().subscribe((result) => {
      if (result !== '0') {
        this.uploadPhase = 0;
        this.gliederungService.upload(this.projektObject, result).subscribe((event: UploadEvent) => {
          switch (event.type) {
            case 'upload':
              this.uploadPhase = event.loaded / event.total * 100;
              if (this.uploadPhase > 90) {
                this.isLoading = true;
              }
              break;
            case 'header':
              if (event.status !== 200) {
                this.snackBar.open('Diese Datei kann nicht hochgeladen werden', null, {
                  duration: 15000,
                });
              }
              break;
            case 'response':
              this.uploadPhase = -1;
              this.isLoading = false;
              this.projektService.get(this.projektObject.id).subscribe((response: {
                data: Projekt
              }) => {
                this.projektService.setActiveProjektGliederungUpdated();
              });
              break;
            case 'sent':
            case 'other':
              break;
            default:
              console.error('Unbekanntes Antwort', event.type);
          }
        });
      }
    });
  }
  
  downloadExcel() {
    this.gliederungService.download(this.projektObject).subscribe((response: Blob) => {
      let fileName = 'gliederungen.xlsx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }
  
  providePassword(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Sollen den Gliederungen neue Passwörter verteilt werden?',
        hint: 'Möglicherweise bestehende Passwörter werden gelöscht',
      }
    }).afterClosed().subscribe(result => {
      if (result === '1') {
        this.isProviding = true;
        this.projektService.providePassword(this.projektObject).subscribe(() => {
          this.snackBar.open('Neue Passwörter wurden verteilt', null, {
            duration: 6000,
          });
          this.isProviding = false;
        }, (error: string) => {
          this.snackBar.open(error, null, {
            duration: 15000,
          });
          this.isProviding = false;
        });
      }
    });
  }
      
}