/**
 * Überweisung
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */

export class UeberweisungGruppe {
  
  type: string = 'ubg_ueberweisung_gruppe--ubg_ueberweisung_gruppe'
  id: string
  attributes?: {
    title: string
  }
  
}
  
export class Ueberweisung {
  
  type: string = 'ubg_ueberweisung--ubg_ueberweisung'
  id: string
  attributes?: {
    title: string
  }
  relationships: {
    gruppe: {
      data: UeberweisungGruppe
    }
  }
  
}