/**
 * Anträge
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {Projekt} from '../../../model/projekt';
import {Abschnitt} from '../../../model/abschnitt';
import {ProjektService} from '../../../services/projekt.service';
import {UserService} from '../../../services/user.service';
import {PageService} from '../../../services/page.service';
import {AbschnittService} from '../../../services/abschnitt.service';

@Component({
  templateUrl: './antraege.component.html',
  styleUrls: ['./antraege.component.scss'],
})
export class ProjektAntraegeComponent {
  
  projektId: string = null
  projekt: Projekt = null
  isLoggedIn: number = 0
  pageSubscription: Subscription

  constructor(route: ActivatedRoute,
                userService: UserService,
                private router: Router,
                private projektService: ProjektService,
                private pageService: PageService,
                private abschnittService: AbschnittService) {
    route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
      this.loadProjekt();
    });
    userService.userLoggedIn.subscribe((status: number) => {
      this.isLoggedIn = status;
      this.loadProjekt();
    });
  }
  
  ngOnInit(): void {
    this.pageSubscription = this.pageService.homeClicked().subscribe((event) => {
      if (event) {
        this.router.navigate(['projekt/' + this.projektId]);
      }
    });
  }
  
  ngOnDestroy(): void {
    this.pageSubscription.unsubscribe();
  }
  
  loadProjekt(): void {
    if (this.isLoggedIn != 0 && this.projektId !== null) {
      this.projektService.get(this.projektId).subscribe((response: {
        data: Projekt
      }) => {
        this.projekt = response.data;
        this.pageService.setTitle(this.projekt.attributes.title);
        this.projektService.setActive(this.projekt);
        let ownActiveAbschnitt: Abschnitt = null
        if (this.abschnittService.getActiveAbscnhitt() !== null) {
          ownActiveAbschnitt = this.projekt.relationships.abschnitte.data.find(abschnitt => abschnitt.id === this.abschnittService.getActiveAbscnhitt().id);
        }
        if (ownActiveAbschnitt === null || ownActiveAbschnitt === undefined) {
          this.abschnittService.setActiveAbschnitt(null);
        }
        else {
          this.abschnittService.setActiveAbschnitt(ownActiveAbschnitt);
        }
      });
    }
  }

}

 
