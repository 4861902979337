/**
 * Antrag ohne Leitantrag bearbeiten
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, Output, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {Gliederung} from '../../../../model/gliederung';

const noop = () => {};

@Component({
  selector: 'antrag-initiativantraege-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['../../edit.component.scss'], 
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AntragInitiativantraegeEditComponent),
    multi: true
  }]
})
export class AntragInitiativantraegeEditComponent {
 
  @Output() validityChange = new EventEmitter
  antrag: Antrag = null
  antragForm: FormGroup
  gliederungen: Array<Gliederung> = []
   
  constructor(formBuilder: FormBuilder) {
    this.antragForm = formBuilder.group({
      title: [null, Validators.required],
      antragsteller: [null, Validators.required],
      inhalt: [null, Validators.required],
      ohne_begruendung: [null],
      begruendung: [null, Validators.required],
    });
    this.antragForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.antragForm.get('title').valueChanges.subscribe(value => {
      this.antrag.attributes.title = value;
    });
    this.antragForm.get('antragsteller').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.antragsteller = value;
    });
    this.antragForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.inhalt = {
        value: value,
      };
    });
    this.antragForm.get('begruendung').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.begruendung = {
        value: value,
      };
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Antrag {
    return this.antrag;
  };

  set value(v: Antrag) {
    this.antrag = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Antrag) {
    if (value !== null) {
      this.antrag = value;
      this.updateFormFromValue();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.antrag);
  }
  
  updateFormFromValue(): void {
    this.antragForm.get('title').setValue(this.antrag.attributes.title);
    this.antragForm.get('antragsteller').setValue(this.antrag.attributes.antragsteller);
    this.antragForm.get('inhalt').setValue(this.antrag.attributes.inhalt.value);
    this.antragForm.get('ohne_begruendung').setValue(this.antrag.attributes.ohne_begruendung);
    if (this.antrag.attributes.ohne_begruendung == 1) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
    }
    if (this.antrag.attributes.begruendung !== undefined 
      && this.antrag.attributes.begruendung !== null) {
      this.antragForm.get('begruendung').setValue(this.antrag.attributes.begruendung.value);
    }
  }
  
  ohneBegruendungChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.antrag.attributes.ohne_begruendung = 1;
    }
    else {
      this.antrag.attributes.ohne_begruendung = 0;
    }
    this.onChangeCallback(this.antrag);
    if (this.antrag.attributes.ohne_begruendung) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
      if (this.antrag.attributes.begruendung === null 
        || this.antrag.attributes.begruendung.value === '') {
        this.antragForm.get('begruendung').setErrors({
          required: true,
        });
      }
    }
  }
   
}