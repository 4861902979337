/**
 * Themadialog
 * 
 * Attila Németh, UBG
 * 24.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';
import {Votum, VotumBegruendung} from '../../../../model/votum';
import {Thema} from '../../../../model/thema';

@Component({
  templateUrl: './thema.component.html',
  styleUrls: ['./thema.component.scss'],
})
export class AntragThemaDialogComponent {
  
  themaForm: FormGroup
  themaOptions: Array<Thema> = []
  disabled: boolean = true
  thema: string = null
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag,
                formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private antragService: AntragService) {
    this.themaForm = formBuilder.group({
      thema: [{
        disabled: true
      }, Validators.required],
    });
  }
  
  ngOnInit(): void {
    this.loadThemen();
    this.themaForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.themaForm.get('thema').valueChanges.subscribe((thema: string) => {
      this.thema = thema;
    });
    this.antragService.getAntrag(this.data.type, this.data.id).subscribe(antrag => {
      if (antrag.relationships.thema !== undefined && antrag.relationships.thema.data !== null) {
        this.themaForm.get('thema').setValue(antrag.relationships.thema.data.id);
      }
    });
  }
 
  private loadThemen(): void {
    this.antragService.getThemen(this.data).subscribe(themen => {
      this.themaOptions = themen;
      if (themen.length > 0) {
        this.themaForm.get('thema').enable();
      }
      else {
        this.snackbar.open('Es gibt keine Themen in diesem Projekt', null, {
          duration: 15000,
        })
      }
    });
  }
  
}