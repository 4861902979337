import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {startWith, map} from 'rxjs/operators';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {PageService} from './antragswesen/services/page.service';
import {UserService} from './antragswesen/services/user.service';
import {User} from './antragswesen/model/user';
import {UserPasswordDialogComponent} from './components/dialog/password/password.component';
import {UserSettingsDialogComponent} from './components/dialog/settings/settings.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {  
  
  isLoggedIn: boolean = false
  user: User = null
  headerTitle: Observable<string> = of('Antragswesen')
  
  constructor(private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private pageService: PageService,
                private userService: UserService) {
    userService.userLoggedIn.subscribe((status: number) => {
      this.user = null;
      if (status === 1) {
        this.isLoggedIn = true;
        userService.getCurrentUser().subscribe((response: {
          data: User
        }) => {
          this.user = response.data;
        });
      }
      else {
        this.isLoggedIn = false;
      }
    });
  }
  
  ngOnInit(): void {
    this.headerTitle = this.pageService.headerTitle.pipe(
      map((value: string) => {
        return value;
      })
    );
  }
  
  gotoPage(path: string): void {
    this.router.navigate([path]);
  }
  
  logout(): void {
    this.userService.logout();
  }
  
  password(): void {
    this.dialog.open(UserPasswordDialogComponent, {
      width: '320px',
    }).afterClosed().subscribe((response: any) => {
      if (response !== '0') {
        this.userService.changePassword(response['current'], response['new']).subscribe((response: {
          status: string
        }) => {
          if (response.status == 'OK') {
            this.snackbar.open('Ihr Passwort wurde geändert, Sie müssen sich mit dem neuen Passwort anmelden', null, {
              duration: 15000,
            });
            this.logout();
          }
        }, (error) => {
          this.snackbar.open('Ihr Passwort kann nicht geändert werden', null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  settings(): void {
    this.userService.getSettings().subscribe(settings => {
      this.dialog.open(UserSettingsDialogComponent, {
        width: '480px',
        data: settings
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          this.userService.setSettings(result).subscribe(() => {
            this.snackbar.open('Ihre Einstellungen wurden geändert, Sie müssen sich wieder anmelden', null, {
              duration: 6000,
            });
            this.logout();
          }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
        }
      });
    });
  }
  
}
