/**
 * Thema
 * 
 * Attila Németh, UBG
 * 04.05.2020
 */
 
export class Thema {
  
  type: string = 'ubg_thema--ubg_thema'
  id: string
  attributes: {
    title: string
  }
  
}