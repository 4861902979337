/**
 * Thema-Element (nur Titel)
 * 
 * Attila Németh, UBG
 * 04.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Thema} from '../../../model/thema';
import {ThemaService} from '../../../services/thema.service';
import {ThemaEditDialogComponent} from '../dialog/edit.component';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'thema-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
})
export class ThemaElementComponent {
  
  @Input() set thema(thema: Thema) {
    if (thema !== null) {
      this.themaObject = thema;
      if (this.themaObject.attributes === undefined) {
        this.loadThema();
      }
    }
  }
  @Output() delete = new EventEmitter
  themaObject: Thema = null
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private themaService: ThemaService) {
  }
  
  loadThema(): void {
    this.themaService.get(this.themaObject.id).subscribe((response: {
      data: Thema
    }) => {
      this.themaObject = response.data;
    })
  }
  
  edit(): void {
    this.dialog.open(ThemaEditDialogComponent, {
      data: {
        thema: this.themaObject,
      }
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.themaService.get(this.themaObject.id).subscribe((response: {
          data: Thema
        }) => {
          let thema: Thema = response.data;
          thema.attributes.title = result['attributes']['title'];
          this.themaService.update(thema).subscribe((response: {
            data: Thema
          }) => {
            this.loadThema();
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  deleteThema(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie dieses Thema wirklich löschen?',
        hint: 'Diese Aktion ist nicht widerrufbar',
      }
    }).afterClosed().subscribe((result: string) => {
      if (result === '1') {
        this.themaService.delete(this.themaObject).subscribe(() => {
          this.delete.emit(this.themaObject.id);
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
}