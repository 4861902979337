/**
 * Überweisung auswählen
 * 
 * Attila Németh
 * 30.07.2020
 */

import {Component, Input, forwardRef, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup} from '@angular/forms';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Ueberweisung} from '../../../model/ueberweisung';

const noop = () => {};

@Component({
  selector: 'ueberweisung-select',
  templateUrl: './select.component.html',
  //styleUrls: ['./select.component.scss'], 
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UeberweisungSelectComponent),
      multi: true
    }
  ]
})
export class UeberweisungSelectComponent implements OnInit {
  
  ueberweisungen: Array<Ueberweisung> = []
  @Input() options: Array<Ueberweisung> = []
  filteredOptions: Array<Ueberweisung> = []
  @Input() limit = -1
  ueberweisungForm: FormGroup

  constructor(formBuilder: FormBuilder) {
    this.ueberweisungForm = formBuilder.group({
      ueberweisung: [{
        value: null,
        disabled: false,
      }]
    });
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Array<Ueberweisung> {
    return this.ueberweisungen
  };

  set value(v: Array<Ueberweisung>) {
    this.ueberweisungen = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Array<Ueberweisung>) {
    if (value !== null) {
      this.ueberweisungen = value;
      this.updateFormFromValue();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.ueberweisungen);
  }

  ngOnInit(): void {
    this.ueberweisungForm.get('ueberweisung').valueChanges.subscribe((value: string) => {
      if (value !== null) {
        this.filteredOptions = this.options.filter(ueberweisung => ueberweisung.attributes.title.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      }
    });
    
  }
  
  updateFormFromValue(): void {}

  ueberweisungSelected(event: MatAutocompleteSelectedEvent) {
    const selectedUeberweisung = this.options.find(ueberweisung => ueberweisung.id === event.option.value);
    let newUeberweisungen: Array<Ueberweisung> = [];
    for (let i in this.ueberweisungen) {
      newUeberweisungen.push(this.ueberweisungen[i]);
    }
    newUeberweisungen.push(selectedUeberweisung);
    this.ueberweisungen = newUeberweisungen;
    this.ueberweisungForm.get('ueberweisung').reset(null);
    if (this.limit > 0 && this.ueberweisungen.length >= this.limit) {
      this.ueberweisungForm.disable();
    }
    this.updateChanges();
  }

  removeUeberweisung(removedUeberweisung: Ueberweisung): void {
    this.ueberweisungen = this.ueberweisungen.filter(ueberweisung => ueberweisung.id !== removedUeberweisung.id);
    this.updateChanges();
  }

}