/**
 * Abschnitt bearbeiten
 * 
 * Attila Németh, UBG
 * 28.04.2020
 */
 
import {Component, Output, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Abschnitt} from '../../../model/abschnitt';

const noop = () => {};

@Component({
  selector: 'abschnitt-edit',
  templateUrl: './edit.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AbschnittEditComponent),
    multi: true
  }]
})
export class AbschnittEditComponent {
  
  @Output() validityChange = new EventEmitter
  abschnitt: Abschnitt = null
  focused: boolean = false
  abschnittForm: FormGroup
  typeDisabled: boolean = false

  constructor(private formBuilder: FormBuilder) {
    this.abschnitt = new Abschnitt;
    this.abschnitt.create();
    this.abschnittForm = this.formBuilder.group({
      type: [this.abschnitt.type, Validators.required],
      title: [this.abschnitt.attributes.title, Validators.required],
      buchstabe: [this.abschnitt.attributes.buchstabe, Validators.required],
      sortierung: [this.abschnitt.attributes.sortierung, Validators.required],
    });
    this.abschnittForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else if (status === 'INVALID') {
        this.validityChange.emit(false);
      }
      else {
        console.error('Invalid Form Status', status);
      }
    });
    this.abschnittForm.get('type').valueChanges.subscribe((type: string) => {
      if (type === 'ubg_abschnitt--mit_leitantrag') {
        this.abschnittForm.get('sortierung').setValidators(null);
        delete(this.abschnitt.attributes.sortierung);
      }
      else {
        this.abschnittForm.get('sortierung').setValidators(Validators.required);
        if (this.abschnitt.attributes.sortierung === undefined) {
          this.abschnitt.attributes.sortierung = null;
        }
      }
      this.abschnittForm.get('sortierung').updateValueAndValidity();
      this.abschnitt.type = type;
    });
    this.abschnittForm.get('title').valueChanges.subscribe((title: string) => {
      this.abschnitt.attributes.title = title;
    });
    this.abschnittForm.get('buchstabe').valueChanges.subscribe((buchstabe: string) => {
      this.abschnitt.attributes.buchstabe = buchstabe;
    });
    this.abschnittForm.get('sortierung').valueChanges.subscribe((sortierung: string) => {
      if (sortierung === null) {
        delete(this.abschnitt.attributes.sortierung);
      }
      else {
        this.abschnitt.attributes.sortierung = sortierung;
      }
    });
  }
  
  isValid(): boolean {
    return this.abschnittForm.valid;
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  ngOnInit() {}

  get value(): Abschnitt {
    return this.abschnitt
  };

  set value(v: Abschnitt) {
    if (v !== this.abschnitt) {
      this.abschnitt = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  setFocus() {
    this.focused = true;
  }

  writeValue(value: Abschnitt) {
    if (value !== this.abschnitt && value !== null) {
      this.abschnitt = value;
      let formValues = {
        type: this.abschnitt.type,
        title: this.abschnitt.attributes.title,
        buchstabe: this.abschnitt.attributes.buchstabe,
      };
      if (this.abschnitt.attributes.sortierung !== undefined) {
        formValues['sortierung'] = this.abschnitt.attributes.sortierung;
      }
      this.abschnittForm.patchValue(formValues);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.abschnitt);
  }

}

 
