<p class="op-title mat-h4">Suche</p>
<form [formGroup]="filterForm" (ngSubmit)="filterAntraege()">
  <mat-form-field class="no-hint">
    <input type="text" matInput placeholder="Suche" formControlName="suche">
    <button *ngIf="filterParams.suche !== null" matSuffix mat-icon-button 
      (click)="resetField('suche')" color="accent" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field class="no-hint">
    <input type="text" matInput placeholder="Gliederung" formControlName="gliederungen">
    <button *ngIf="filterParams.gliederungen !== null" matSuffix mat-icon-button 
      (click)="resetField('gliederungen')" color="accent" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Nummer" formControlName="nummer">
    <button *ngIf="filterParams.nummer !== null" matSuffix mat-icon-button 
      (click)="resetField('nummer')" color="accent" type="button">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>Mehrfachauswahl, z.B. c2, c5</mat-hint>
  </mat-form-field>
  <mat-form-field class="no-hint">
    <mat-select placeholder="Votum" formControlName="votum">
      <mat-option value="none">Kein Votum</mat-option>
      <mat-option *ngFor="let type of votumTypes" [value]="type.id">
        {{ type.attributes.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="no-hint">
    <mat-select placeholder="Zuweisung" formControlName="zuweisung">
      <mat-option value="null">Keine Zuweisung</mat-option>
      <mat-option *ngFor="let user of zuweisungOptions" [value]="user.id">
        {{ user.attributes.nachname }},
        {{ user.attributes.vorname }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="no-hint">
    <mat-select placeholder="Redner der AK" formControlName="redner">
      <mat-option *ngFor="let redner of rednerOptions" [value]="redner.id">
        {{ redner.attributes.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0.5rem" fxLayoutGap.gt-md="1rem"
       class="actions">
    <div class="filter-actions" fxFlex="1 1 auto" fxLayoutGap="8px">
      <ng-container *ngIf="buttonLayout">
        <button type="button" mat-button color="primary" (click)="reset()" fxFlex="1 1 auto">
          Zurücksetzen
        </button>
        <button mat-raised-button color="primary" fxFlex="1 1 auto">
          Suche
        </button>
      </ng-container>
      <ng-container *ngIf="!buttonLayout">
        <button type="button" mat-icon-button color="accent" (click)="reset()" fxFlex="1 1 auto">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button color="primary" fxFlex="1 1 auto">
          <mat-icon>search</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</form>