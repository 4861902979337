<user-content>
  <mat-progress-bar mode="buffer" color="accent" *ngIf="isLoading"></mat-progress-bar>
  <div *ngIf="projekt !== null">
    <div class="layout-margin">
      <button mat-raised-button color="primary" (click)="printBeschluss()">
        <mat-icon>print</mat-icon> In MS Word exportieren
      </button>
      &nbsp;
      <button mat-raised-button [matMenuTriggerFor]="menu" color="primary">
        <mat-icon>list</mat-icon> Beschlussprotokolle
      </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="beschlussProtokoll('ueberweisungen')">
            Überweisungen
          </button>
          <button mat-menu-item (click)="beschlussProtokoll('erledigungen')">
            Erledigungen
          </button>
          <button mat-menu-item (click)="beschlussProtokoll('ablehnungen')">
            Ablehnungen
          </button>
          <button mat-menu-item (click)="beschlussProtokollXlsx()">
            In Excel
          </button>
        </mat-menu>
      <div class="beschluss" [innerHtml]="beschluss"></div>
    </div>
  </div>
  
</user-content>