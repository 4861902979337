<form [formGroup]="projektForm" fxLayout="column">
  <mat-form-field *ngIf="projekt !== null && mandants.length > 1">
    <mat-label>Mandant</mat-label>
    <mat-select required [disabled]="projekt.id !== undefined" 
                formControlName="mandant" required>
      <mat-option *ngFor="let mandant of mandants" [value]="mandant.id">
        {{ mandant.attributes.title }}
      </mat-option>
    </mat-select>
    <mat-error>
      <span *ngIf="!projektForm.get('mandant').valid && projektForm.get('mandant').touched">
        Ein Mandant muss ausgewählt werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Titel" required formControlName="title">
    <mat-error>
      <span *ngIf="!projektForm.get('title').valid && projektForm.get('title').touched">
        Der Titel muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-checkbox formControlName="aktiv" color="primary">Aktives Projekt</mat-checkbox>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5rem" fxLayoutGap.xs="0">
    <mat-form-field flFlex="1">
      <mat-label>Antragsfrist Anfang</mat-label>
      <input matInput [matDatepicker]="antragsfrist_anfang_picker"
             formControlName="antragsfrist_anfang" required>
      <mat-datepicker-toggle matSuffix [for]="antragsfrist_anfang_picker"></mat-datepicker-toggle>
      <mat-datepicker #antragsfrist_anfang_picker></mat-datepicker>
      <mat-error>
        <span *ngIf="(!projektForm.get('antragsfrist_anfang').valid && projektForm.get('antragsfrist_anfang').touched)">
          Die Antragsfrist muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field flFlex="1">
      <mat-label>Antragsfrist Ende</mat-label>
      <input matInput [matDatepicker]="antragsfrist_ende_picker"
             formControlName="antragsfrist_ende" required>
      <mat-datepicker-toggle matSuffix [for]="antragsfrist_ende_picker"></mat-datepicker-toggle>
      <mat-datepicker #antragsfrist_ende_picker></mat-datepicker>
      <mat-error>
        <span *ngIf="(!projektForm.get('antragsfrist_ende').valid && projektForm.get('antragsfrist_ende').touched)">
          Die Antragsfrist muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field>
    <input type="email" matInput placeholder="E-Mail" required formControlName="email" required>
    <mat-error>
      <span *ngIf="projektForm.get('email').touched 
                && projektForm.get('email').errors !== null
                && projektForm.get('email').errors.email !== undefined
                && projektForm.get('email').errors.email">
        Es ist keine gültige E-Mail-Adresse
      </span>
      <span *ngIf="projektForm.get('email').touched 
                && projektForm.get('email').errors !== null
                && projektForm.get('email').errors.required !== undefined
                && projektForm.get('email').errors.required">
        Die E-Mail muss angegeben werden
      </span>
    </mat-error>
    <mat-hint>Die Absender-Adresse der vom System versendeten E-Mails</mat-hint>
  </mat-form-field>
</form>