<div class="abschnitt-wrapper">
  <div class="antrag-list-wrapper">
    <button mat-raised-button color="accent" (click)="addAntrag()">
      <mat-icon>add</mat-icon> Neuen Antrag hinzufügen
    </button>
    
    <div fxLayout="column" fxLayoutGap="1rem" *ngIf="!isDragged">
      <ng-container *ngFor="let antrag of abschnittObject.relationships.antraege.data">
        <h2 *ngIf="antragHasThema(antrag)">
          {{ antragHasThema(antrag) }}
        </h2>
        <antrag-initiativantraege 
          [antrag]="antrag" [abschnitt]="abschnittObject">
        </antrag-initiativantraege>
      </ng-container>
    </div>
    <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="isDragged">
      <antrag-drag-drop *ngFor="let antrag of abschnittObject.relationships.antraege.data"
        [antrag]="antrag" [abschnitt]="abschnittObject" cdkDrag>
      </antrag-drag-drop>
    </div>
    
  </div>
  <abschnitt-ops [abschnitt]="abschnittObject" [style.top]="opsTop"></abschnitt-ops>
</div>