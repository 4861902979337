/**
 * Projekt bearbeiten
 * 
 * Attila Németh, UBG
 * 23.04.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Projekt} from '../../../model/projekt';

@Component({
  templateUrl: './edit.component.html',
})
export class ProjektEditDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                projekt: Projekt,
              }) {}
              
  validityChanged(valid: boolean) {
    this.disabled = !valid;
  }
  
}
