/**
 * Gliederungen
 * 
 * Attila Németh, UBG
 * 12.05.2020
 */

export class GliederungKategorie {
  
  type: string = 'ubg_gliederung_kategorie--ubg_gliederung_kategorie'
  id: string
  attributes: {
    title: string
    kurzb: string
  }
  relationships?: {
    gliederungen: {
      data: Array<Gliederung>
    }
  }
  
}

export class Gliederung {
  
  type: string = 'ubg_gliederung--ubg_gliederung'
  id: string
  attributes: {
    title: string
    e_mail: string
    longTitle: string
    freier_name: boolean
    ubg_gliederung_deleg?: boolean
  }
  
  create(): void {
    this.attributes = {
      title: null,
      e_mail: null,
      longTitle: null,
      freier_name: false,
      ubg_gliederung_deleg: false,
    };
  }
  
}