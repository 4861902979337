/**
 * Komponent für Anmeldemaske oder Inhalt
 * 
 * Attila Németh, UBG
 * 22.04.2020
 */
 
import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';  

import {UserService} from '../../../services/user.service';

@Component({
  selector: 'user-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class UserContentComponent {
  
  loginStatus: number = -1
  
  loginForm: FormGroup

  constructor(private formBuilder: FormBuilder,
                private userService: UserService) {
    this.userService.userLoggedIn.subscribe((loginStatus: number) => {
      this.loginStatus = loginStatus;
    });
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }
  
  formSubmitted(values: {
    username: string,
    password: string
  }): void {
    this.userService.login(values.username, values.password);
  }

}
