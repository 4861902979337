/**
 * Antrag anzeigen
 * 
 * Attila Németh, UBG
 * 04.06.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Antrag} from '../../../../model/antrag';

@Component({
  templateUrl: './view.component.html',
})
export class AntragViewDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: Antrag) {
  }

}