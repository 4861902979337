import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators'; 
import {HttpParams} from '@angular/common/http';

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Projekt, BroschuerePrintOptions} from '../model/projekt';
import {Abschnitt} from '../model/abschnitt';
import {AbschnittService} from './abschnitt.service';
import {GliederungKategorie, Gliederung} from '../model/gliederung';
import {CookieService} from '@ubg/cookie-service';
import {Antrag} from '../model/antrag';
import { PrintBegruendungOptions } from '../components/projekt/dialog/print-begruendung/print-begruendung';

@Injectable({
  providedIn: 'root'
})
export class ProjektService {

  basePath: string = 'api/ubg_projekt/ubg_projekt'
  
  private activeProjektData: BehaviorSubject<Projekt> = new BehaviorSubject(null);
  public activeProjekt: Observable<Projekt> = this.activeProjektData.asObservable();
  private activePageData: BehaviorSubject<string> = new BehaviorSubject(null);
  public activePage: Observable<string> = this.activePageData.asObservable();
  private activeProjektGliederungUpdateData: BehaviorSubject<number> = new BehaviorSubject(0);
  public activeProjektGliederungUpdate: Observable<number> = this.activeProjektGliederungUpdateData.asObservable();
  private allProjekteData: BehaviorSubject<Array<Projekt>> = new BehaviorSubject(null);
  public allProjekt: Observable<Array<Projekt>> = this.allProjekteData.asObservable();
  
  constructor(private drupal: DrupalService,
                private abschnittService: AbschnittService,
                private cookieService: CookieService) {}
  
  getAll() {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath, httpOptions);
  }
  
  getAllObservable(): Observable<Array<Projekt>> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath, httpOptions).pipe(
      map((response: {
        data: Array<Projekt>
      }) => {
        return response.data;
      }),
    );
  }
  
  get(id: string) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath + '/' + id, httpOptions);
  }
  
  /**
   * Projekt duch Gliederung-Passwort laden
   */
  getForGliederung(id: string, password: string): Observable<Projekt> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    httpOptions.headers = httpOptions.headers.set('X-Antragswesen', password);
    return this.drupal.get(this.basePath + '/' + id, httpOptions).pipe(
      map(response => new Projekt(response['data']))
    );
  }
  
  create(projekt: Projekt) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(this.basePath, {
      data: projekt
    }, httpOptions);
  }
  
  update(projekt: Projekt) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(this.basePath + '/' + projekt.id, {
      data: projekt
    }, httpOptions);
  }
  
  setActive(projekt: Projekt) {
    if (projekt === null) {
      this.activeProjektData.next(null);
      console.info('Kein Aktives Projekt');
    }
    else {
      this.activeProjektData.next(projekt);
      console.info('Aktives Projekt', projekt.attributes.title);
    }
  }
  
  resetActive(): void {
    this.setActive(null);
  }
  
  getAbschnitte(projekt: Projekt): Observable<Array<Abschnitt>> {
    if (projekt === null) {
      console.error('Projekt ist Null');
      return;
    }
    return new Observable((observer) => {
      let abschnitte: Array<Abschnitt> = [];
      for (let i in projekt.relationships.abschnitte.data) {
        this.abschnittService.get(projekt.relationships.abschnitte.data[i].type, 
        projekt.relationships.abschnitte.data[i].id).subscribe((response: {
          data: Abschnitt
        }) => {
          abschnitte.push(response.data);
          if (abschnitte.length == projekt.relationships.abschnitte.data.length) {
            abschnitte.sort((a: Abschnitt, b: Abschnitt): number => {
              if (a.attributes.buchstabe.toLowerCase() < b.attributes.buchstabe.toLowerCase()) {
                return -1;
              }
              else {
                return 1;
              }
            });
            observer.next(abschnitte);
          }
        });
      }
    });
  }
  
  createPermission() {
    return this.drupal.entityCreatePermission('ubg_projekt');
  }
  
  editPermission(projekt: Projekt) {
    return this.drupal.entityPermission('ubg_projekt', projekt.id, 'edit');
  }
  
  viewPermission(projekt: Projekt): Observable<boolean> {
    return this.drupal.entityPermission('ubg_projekt', projekt.id, 'view').pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
  
  openPage(name: string): void {
    this.activePageData.next(name);
  }
  
  closePage(): void {
    this.activePageData.next('home');
  }
  
  getProjektGliederungen(projekt: Projekt): Observable<Array<GliederungKategorie>> {
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/gliederungen';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<GliederungKategorie>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Die verfügbaren Gliederungen für Anträge dieses Projekts
   */
  getProjektAntragGliederungen(projekt: Projekt): Observable<Array<Gliederung>> {
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    const path: string = 'ubg/projekt/' + projekt.id + '/antrag/gliederungen';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Gliederung>
      }) => {
        return response.data;
      })
    );
  }
  
  setActiveProjektGliederungUpdated(): void {
    const d = new Date;
    this.activeProjektGliederungUpdateData.next(d.getTime());
  }
  
  /**
   * Daten Übernehmen
   * 
   * 26.05.2020
   */
  uebernehmen(sourceProjekt: Projekt, destinationProjekt: Projekt, category: string) {
    const path = 'ubg/projekt/' + sourceProjekt.id + '/' + destinationProjekt.id + '/'
            + category + '/uebernehmen';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  /**
   * Passwörter den Gliederungen verteilen
   * 
   * 28.05.2020
   */
  providePassword(projekt: Projekt) {
    const path: string = 'ubg/projekt/' + projekt.id + '/passwoerter';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  getStatistik(projekt: Projekt, stat: string, details?: boolean): Observable<any> {
    let path: string;
    if (details === true) {
      path = 'ubg/projekt/' + projekt.id + '/' + stat + '/details';
    }
    else {
      path = 'ubg/projekt/' + projekt.id + '/' + stat;
    }
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  
  printStatistik(projekt: Projekt, stat: string) {
    const path: string = 'ubg/projekt/' + projekt.id + '/stat/' + stat + '/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  printAllStatistik(projekt: Projekt) {
    const path: string = 'ubg/projekt/' + projekt.id + '/stat/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  /**
   * Die offenen Anträge
   * 
   * 03.06.2020
   */
  getOffeneAntraege(projekt: Projekt, params: OffeneParams): Observable<Array<Antrag>> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    httpOptions.params = new HttpParams()
      .set('zuweisung', String(!params.zuweisung))
      .set('votum', String(!params.votum));
    const path: string = 'ubg/projekt/' + projekt.id + '/offene';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Anträge dir dem aktuellen Benutzer zugewiesen sind
   * 
   * 09.06.2020
   */
  getMeineAntraege(projekt: Projekt): Observable<Array<Antrag>> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/meine';
    httpOptions.params = new HttpParams();
    httpOptions.params = httpOptions.params.append('sort', 'zuweisungsdatum');
    httpOptions.params = httpOptions.params.append('dir', 'desc');
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Ein Antrag im Projekt
   * 
   * 09.06.2020
   */
  getByFragment(projekt: Projekt, antragId: string): Observable<Abschnitt> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/antrag/' + antragId;
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Abschnitt
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Dir Broschüre drucken
   * 
   * 29.06.2020
   */
  printBroschuere(projekt: Projekt, options: BroschuerePrintOptions) {
    const path: string = 'ubg/projekt/' + projekt.id + '/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, options, httpOptions);
  }
  
  uploadBanner(projekt: Projekt, banner: File) {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/banner/upload';
    return this.drupal.postFile(path, banner, httpOptions);
  }
  
  /**
   * Der Beschluss
   */
  getBeschluss(projekt: Projekt): Observable<string> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/beschluss';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: string) => {
        return response;
      })
    );
  }
  
  /**
   * Den Beschluss drucken
   * 
   * 30.06.2020
   */
  printBeschluss(projekt: Projekt) {
    const path: string = 'ubg/projekt/' + projekt.id + '/print/beschluss';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  /**
   * Beschlussprotokolle drucken
   * 
   * 01.07.2020
   */
  printBeschlussProtokoll(projekt: Projekt, name: string) {
    const path: string = 'ubg/projekt/' + projekt.id + '/print/beschlussprotokoll/' + name;
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }

  /**
   * Offene Anträge drucken
   * @param projekt 
   * 
   * 24.07.2020
   */
  printOffeneAntraege(projekt: Projekt, params: OffeneParams) {
    const path: string = 'ubg/projekt/' + projekt.id + '/offene/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    httpOptions.params = new HttpParams()
      .set('zuweisung', String(!params.zuweisung))
      .set('votum', String(!params.votum));
    return this.drupal.post(path, null, httpOptions);
  }
  
  reloadAllProjekte(): void {
    this.getAll().subscribe((response: {
      data: Array<Projekt>
    }) => {
      this.allProjekteData.next(response.data);
    });
  }

  cloneProjekt(projekt: Projekt, votumTypeId: string): Observable<any> {
    const path: string = 'ubg/projekt/' + projekt.id + '/clone';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const data = {
      votum: votumTypeId,
    };
    return this.drupal.post(path, data, httpOptions);
  }

  /***
   * Redner drucken
   * 
   * 31.08.2022
   */
  exportRedner(projekt: Projekt) {
    const path: string = 'ubg/projekt/' + projekt.id + '/redner/export';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }

  /**
   * Die Begründungen der Antragskommission drucken
   * @param projekt 
   * @param options 
   */
  printBegruendungen(projekt: Projekt, options: PrintBegruendungOptions) {
    const path: string = 'ubg/projekt/' + projekt.id + '/print/begruendungen';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, options, httpOptions);
  }

  /**
   * Beschlussprotokoll in Excel
   * @param projekt 
   * @returns Blob
   */
  exportXlsxBeschlussprotokoll(projekt: Projekt): Observable<any> {
    const path: string = 'ubg/projekt/' + projekt.id + '/export/xlsx/beschlussprotokoll';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }
  
}

export interface OffeneParams {
  
  zuweisung: boolean
  votum: boolean
  
}