import {Component} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  templateUrl: './print-begruendung.component.html',
})
export class ProjektBegruendungPrintDialogComponent {
 
  optionsForm: FormGroup

  constructor(formBuilder: FormBuilder) {
    this.optionsForm = formBuilder.group({
      votum: [true],
    });
  }

}

export interface PrintBegruendungOptions {

  votum: boolean

}