/**
 * Antrag-Drucken-Dialog
 * 
 * Attila Németh, UBG
 * 15.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {AbschnittPrintOptions} from '../../../../services/abschnitt.service';
import {Abschnitt} from '../../../../model/abschnitt';

@Component({
  templateUrl: './print.component.html',
})
export class AbschnittPrintDialogComponent implements OnInit {
  
  printOptions: AbschnittPrintOptions = {
    mitBegruendungAntragsteller: false,
    mitBegruendungKommission: false,
    mitMarkup: false,
    mitRedner: false,
  }
  printAccess: AbschnittPrintOptions = {
    mitBegruendungAntragsteller: true,
    mitBegruendungKommission: true,
    mitMarkup: true,
    mitRedner: true,
  }
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Abschnitt) {
  }
  
  ngOnInit(): void {
    
  }
  
  setOption(name: string, event: MatCheckboxChange): void {
    this.printOptions[name] = event.checked;
  }
  
}