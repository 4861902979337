<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <h2 class="mat-title">Möchten Sie dieses Votum löschen?</h2>
  <mat-checkbox (change)="begruendungChanged($event)" [disabled]="!hasBegruendung"
    color="primary">
    Die Begründung der Antragskommission auch löschen
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button [mat-dialog-close]="deleteBegruendung" color="primary">
    <mat-icon>delete</mat-icon> Ja, löschen
  </button>
</mat-dialog-actions>