/**
 * E-Mail-Einstellungen
 * 
 * Attila Németh, UBG
 * 03.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VotumType, VotumTypeInfo } from 'src/app/antragswesen/model/votum';
import { VotumService } from 'src/app/antragswesen/services/votum.service';

import {Projekt} from '../../../../model/projekt';

@Component({
  templateUrl: './clone.component.html',
})
export class ProjektCloneDialogComponent implements OnInit {

  voten: Array<VotumTypeInfo> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    projekt: Projekt
    votum: string
  }, private votumService: VotumService) {}

  ngOnInit(): void {
    this.loadVoten();
  }

  loadVoten(): void {
    this.voten = [];
    this.votumService.getVotumTypes().subscribe(voten => {
      for (let i in voten) {
        this.votumService.getVotumTypeInfo(voten[i]).subscribe(info => {
          let voten: Array<VotumTypeInfo> = [];
          for (let i in this.voten) {
            voten.push(this.voten[i]);
          }
          if (info.fields.length <= 3) {
            voten.push(info);
          }
          this.voten = voten;
        });
      }
    });
  }
  
}