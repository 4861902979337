<mat-dialog-content class="mat-body">
  <form [formGroup]="themaForm">
    <mat-form-field>
      <mat-select formControlName="thema" required placeholder="Thema">
        <mat-option *ngFor="let thema of themaOptions" [value]="thema.id">
          {{ thema.attributes.title }}
        </mat-option>
      </mat-select>
    </mat-form-field> 
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">Verwerfen</button>
  <button mat-button [mat-dialog-close]="thema" color="primary" [disabled]="disabled">
    Speichern
  </button>
</mat-dialog-actions>