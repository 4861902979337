/**
 * Liste der Gliederungsanträge in C
 * 
 * Attila Németh, UBG
 * 02.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Abschnitt} from '../../../../../model/abschnitt';
import {Gliederung} from '../../../../../model/gliederung';
import {AbschnittService} from '../../../../../services/abschnitt.service';
import {Antrag} from '../../../../../model/antrag';
import {AntragService} from '../../../../../services/antrag.service';
import {AntragEditDialogComponent} from '../../../../antrag/dialog/antrag/antrag.component';
import {AntragEvent} from '../../../../../model/events';
import {ProjektService} from '../../../../../services/projekt.service';

@Component({
  selector: 'mein-antrag-list-ohne-leitantrag',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ProjektMeinAntragListOhneLeitantragComponent implements OnInit, OnDestroy {
  
  @Input() set abschnitt(abschnitt: Abschnitt) {
    this.abschnittObject = abschnitt;
    this.loadAntraege();
    this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
      if (event !== null && (event.name == 'loaded')) {
        this.loadAntraege();
      }
    });
  }
  abschnittObject: Abschnitt = null
  @Input() set gliederung(gliederung: Gliederung) {
    this.gliederungObject = gliederung;
    this.loadAntraege(); 
  }
  gliederungObject: Gliederung = null
  gliederungAntraege: Array<Antrag> = []
  antragEventSubscription: Subscription = null
  expired: boolean = false
  projektSubscription: Subscription
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private abschnittService: AbschnittService,
                private antragService: AntragService,
                private projektService: ProjektService) {}
  
  ngOnInit(): void {
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      this.expired = projekt.isAbgelaufen();
    });
  }
  
  ngOnDestroy(): void {
    if (this.antragEventSubscription !== null) {
      this.antragEventSubscription.unsubscribe();
    }
    this.projektSubscription.unsubscribe();
  }
  
  private loadAntraege(): void {
    if (this.abschnittObject !== null && this.gliederungObject !== null) {
      this.abschnittService.getGliederungAntraege(this.abschnittObject, this.gliederungObject).subscribe(antraege => {
        this.gliederungAntraege = antraege;
      }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
    }
  }
  
  addAntragOhneLeitenatrag(): void {
    let antrag = new Antrag;
    antrag.type = 'ubg_antrag--ohne_leitantrag';
    antrag.create();
    antrag.attributes.status = false;
    this.dialog.open(AntragEditDialogComponent, {
      width: '640px',
      data: antrag,
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.antragService.createAntrag(result).subscribe(antrag => {
          this.antragService.addAntragToAbschnitt(antrag, this.abschnittObject).subscribe(() => {
            this.loadAntraege();
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }

}