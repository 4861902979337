<mat-card *ngIf="header !== null">
  <mat-card-header>
    <ng-content></ng-content>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="0.5rem">
      <div *ngFor="let item of header.data; let i = index" [ngClass]="getClass(item, i)"
        fxFlex class="stat-element">
        <p class="label">{{ item.label }}</p>
        <p class="value">{{ item.value }}</p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="details !== null" color="primary" (click)="toggleDetails()">
      <mat-icon>details</mat-icon> Details
    </button>
    <button mat-button *ngIf="details !== null" color="primary" (click)="printStat()">
      <mat-icon>print</mat-icon> In MS Word exportieren
    </button>
  </mat-card-actions>
  <mat-card-content *ngIf="detailsData !== null">
    <table mat-table [dataSource]="detailsData.data">
      <ng-container *ngFor="let column of detailsColumns" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.values[column] }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="detailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: detailsColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>