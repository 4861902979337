<mat-dialog-content class="mat-body">
  <mat-form-field>
    <mat-label>Projekt</mat-label>
    <mat-select [disabled]="projektOptions.length <= 0" (selectionChange)="projektSelected($event)" required>
      <mat-option *ngFor="let projekt of projektOptions" [value]="projekt">
        {{ projekt.attributes.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="selectedProjekt" color="primary" [disabled]="disabled">Auswählen</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>