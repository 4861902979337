/**
 * Überweisung bearbeiten
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {UeberweisungGruppe} from '../../../../model/ueberweisung';

@Component({
  templateUrl: './edit.component.html',
})
export class UeberweisungGruppeEditDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                ueberweisungGruppe: UeberweisungGruppe
              }) {}
              
  validityChanged(valid: boolean) {
    this.disabled = !valid;
  }
  
}
