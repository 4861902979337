<user-content>
  
  <div class="layout-margin" *ngIf="projekt !== null">
    <div fxLayout="column" fxLayoutGap="0.5rem" *ngIf="!sortView">
      <form [formGroup]="gliederungForm">
        <gliederung-select required restriction="1" formControlName="gliederung">
        </gliederung-select>
      </form>
      <ziffer-create *ngFor="let ziffer of zifferInhalte; let i = index" 
        [(ngModel)]="zifferInhalte[i]" (update)="zifferUpdated()" (delete)="zifferDeleted($event)">
      </ziffer-create>
      <p></p>
    </div>
       
    <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="sortView">
      <div *ngFor="let ziffer of zifferInhalte" fxLayout="row" cdkDrag class="drag">
        <div fxFlex="1 1 3rem">
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div fxFlex="2 2 auto" [innerHtml]="ziffer.inhalt"></div>
      </div>
    </div>
       
    <button mat-raised-button color="primary" *ngIf="abschnitt !== null" 
            [disabled]="disabled" (click)="generateLeitantrag()">
      Speichern
    </button>
    <button mat-flat-button color="primary" [disabled]="sortView || zifferInhalte.length < 2" 
            (click)="activateSortView()">
      Ziffern neu sortieren
    </button>
  </div>
  
</user-content>