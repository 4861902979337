<!--
<button mat-raised-button color="accent" (click)="addAntrag()">
  <mat-icon>add</mat-icon> Neuen Antrag schnell hinzufügen
</button>
-->
<button mat-raised-button color="accent" (click)="toAbschnitte()">
  <mat-icon>view_list</mat-icon> Zu den Buchstaben
</button>

<div *ngIf="gliederungObject !== null" fxLayout="column" fxLayoutGap="1rem">
  <ng-container *ngFor="let antragData of antraege">
    <antrag-mein-leitantrag [antrag]="antragData.antrag" [abschnitt]="antragData.abschnitt" [gliederung]="gliederungObject"
      *ngIf="antragData.antrag.type=='ubg_antrag--leitantrag' && antragData.antrag.relationships !== undefined">
    </antrag-mein-leitantrag>
    <antrag-ohne-leitantrag [antrag]="antragData.antrag" [abschnitt]="antragData.abschnitt">
    </antrag-ohne-leitantrag>
  </ng-container>
</div>

<projekt-antrag-popup class="modal mat-elevation-z4" *ngIf="popupOpen" (close)="closePopup()">