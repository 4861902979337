<!-- Leitantrag -->

<div class="abschnitt-wrapper">
  <div *ngIf="abschnittObject !== null" fxLayout="column" fxLayoutGap="1rem"
        class="antrag-list-wrapper">
    <ng-container *ngFor="let antrag of abschnittObject.relationships.antraege.data">
      <antrag-leitantrag [antrag]="antrag" [abschnitt]="abschnittObject" 
        *ngIf="antrag.type=='ubg_antrag--leitantrag'">
      </antrag-leitantrag>
    </ng-container>
  </div>
  <abschnitt-ops [abschnitt]="abschnittObject" [style.top]="opsTop"></abschnitt-ops>
</div>