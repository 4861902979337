/**
 * Redner-Service
 * 
 * Attila Németh, UBG
 * 07.05.2020
 */
 
import {Injectable} from '@angular/core';
import {pipe, Observable} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Redner} from '../model/redner';
import {Abschnitt} from '../model/abschnitt';

@Injectable({
  providedIn: 'root'
})
export class RednerService {

  basePath: string = 'api/ubg_redner/ubg_redner'

  constructor(private drupal: DrupalService) {}

  createRedner(redner: Redner) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(this.basePath, {
      data: redner
    }, httpOptions).pipe(
      map((response: {
        data: Redner
      }) => {
        return response.data;
      })
    );
  }
  
  getAll(): Observable<Array<Redner>> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(this.basePath, httpOptions).pipe(
      map((response: {
        data: Array<Redner>
      }) => {
        return response.data.sort((redner1: Redner, redner2: Redner): number => {
          if (redner1.attributes.title < redner2.attributes.title) {
            return 1;
          }
          else {
            return -1;
          }
        });
      })
    );
  }
  
  getByAbschnitt(abschnitt: Abschnitt): Observable<Array<Redner>> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/rednern';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Redner>
      }) => {
        return response.data;
      })
    );
  }
  
  getRedner(id: string) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(this.basePath + '/' + id, httpOptions).pipe(
      map((response: {
        data: Redner
      }) => {
        return response.data;
      })
    );
  }
  
  updateRedner(redner: Redner) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(this.basePath + '/' + redner.id, {
      data: redner
    }, httpOptions).pipe(
      map((response: {
        data: Redner
      }) => {
        return response.data;
      })
    );
  }
  
  deleteRedner(redner: Redner) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(this.basePath + '/' + redner.id, httpOptions);
  }
   
}