/**
 * Dialog für gemeinsame Behandlung
 * 
 * Attila Németh, UBG
 * 26.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';
import {Votum, VotumBegruendung, GemeinsamOptions} from '../../../../model/votum';

@Component({
  templateUrl: './gemeinsam.component.html',
})
export class AntragGemeinsamDialogComponent {
  
  response: GemeinsamOptions
  gemeinsamForm: FormGroup
  disabled: boolean = true
  isLoading: boolean = false
  antragOptions: Array<Array<Antrag>> = []
  antragFilteredOptions: Array<Array<Antrag>> = []
  votumOptions: Array<Votum> = []
  begruendungOptions: Array<VotumBegruendung> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag,
                formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private antragService: AntragService) {
    this.gemeinsamForm = formBuilder.group({
      antrag: [{
        value: null,
        disabled: true,
      }, Validators.required],
      votum: [{
        value: null,
        disabled: true,
      }, Validators.required],
      begruendung: [{
        value: null,
        disabled: true,
      }],
    });
    this.response = {
      antrag: [],
      votum: null,
      begruendung: null,
    };
  }
  
  ngOnInit(): void {
    this.loadAntragOptions();
    this.gemeinsamForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.gemeinsamForm.get('antrag').valueChanges.subscribe((value: string) => {
      if (typeof value === 'string') {
        this.filterAntragOptions(value);
      }
    });
    this.gemeinsamForm.get('votum').valueChanges.subscribe((value: Votum) => {
      this.response.votum = value;
    });
    this.gemeinsamForm.get('begruendung').valueChanges.subscribe((value: VotumBegruendung) => {
      this.response.begruendung = value;
    });
  }
  
  loadAntragOptions(): void {
    this.isLoading = true;
    this.antragService.getProjektAntraege(this.data).subscribe(antraege => {
      this.antragService.getGemeinsamen(this.data).subscribe(gemeinsamen => {
        let newOptions: Array<Array<Antrag>> = [];
        for (let i in gemeinsamen) {
          newOptions.push(gemeinsamen[i].relationships.antraege.data.sort((antrag1: Antrag, antrag2: Antrag): number => {
            if (antrag1.attributes.label < antrag2.attributes.label) {
              return -1;
            }
            else {
              return +1;
            }
          }));
        }
        let antragFound: boolean = false;
        for (let i in antraege) {
          antragFound = false;
          for (let j in newOptions) {
            for (let k in newOptions[j]) {
              if (antraege[i].id === newOptions[j][k].id) {
                antragFound = true;
              }
            }
          }
          if (!antragFound) {
            newOptions.push([antraege[i]]);
          }
        }
        this.antragOptions = newOptions.sort((option1: Array<Antrag>, option2: Array<Antrag>): number => {
          const key1: string = option1[0].attributes.label.replace(/([0-9]+)$/, '') + (option1[0].attributes.nummer + 20000).toString().replace('&nbsp;','');
          const key2: string = option2[0].attributes.label.replace(/([0-9]+)$/, '') + (option2[0].attributes.nummer + 20000).toString().replace('&nbsp;','');
          if (key1 < key2) {
            return -1;
          }
          else {
            return +1;
          }
        });
        this.filterAntragOptions('');
        this.isLoading = false;
        this.gemeinsamForm.get('antrag').enable();
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 7000,
      });
    });
  }
  
  antragSelected(event: MatAutocompleteSelectedEvent): void {
    this.response.antrag = event.option.value;
    this.gemeinsamForm.get('antrag').setValue(this.getAntragLabel(this.response.antrag));
    const allAntraege = [
      this.data
    ];
    for (let i in this.response.antrag) {
      allAntraege.push(this.response.antrag[i]);
    }
    this.loadVotumOptions(allAntraege);
    this.loadBegruendungOptions(allAntraege);
  }
  
  loadVotumOptions(antraege: Array<Antrag>): void {
    for (let i in antraege) {
      this.antragService.getVoten(antraege[i]).subscribe(voten => {
        for (let j in voten) {
          if (voten[j].attributes.ebene === 'ak') {
            let newOptions: Array<Votum> = [];
            for (let k in this.votumOptions) {
              if (this.votumOptions[k].id !== voten[j].id) {
                newOptions.push(this.votumOptions[k]);
              }
            }
            newOptions.push(voten[j]);
            this.votumOptions = newOptions;
            if (this.votumOptions.length === 1) {
              this.response.votum = this.votumOptions[0];
              this.gemeinsamForm.get('votum').setValidators(null);
            }
            else {
              this.response.votum = null;
              this.gemeinsamForm.get('votum').setValidators(Validators.required);
              this.gemeinsamForm.get('votum').enable();
            }
          }
        }
      });
    }
  }
  
  loadBegruendungOptions(antraege: Array<Antrag>): void {
    const newOptions: Array<VotumBegruendung> = [];
    console.debug('==========================');
    for (let antrag of antraege) {
      // setTimeout(() => {
        this.antragService.getVotumBegruendung(antrag).subscribe(begruendung => {
          console.debug(begruendung);
          if (begruendung !== null && begruendung !== undefined) {
            let existing = newOptions.find(o => o.id === begruendung.id);
            if (existing === undefined) {
              newOptions.push(begruendung);
            }
          }
          //console.debug(newOptions);
          this.begruendungOptions = newOptions;
          if (this.begruendungOptions.length === 1) {
            this.response.begruendung = this.begruendungOptions[0];
          }
          else {
            this.response.begruendung = null;
            this.gemeinsamForm.get('begruendung').enable();        }
          //console.debug(this.response.begruendung);
        });
      // }, Math.random() * 4000);
    }
    return;





    for (let i in antraege) {
      this.antragService.getVotumBegruendung(antraege[i]).subscribe(votumBegruendung => {
        let newOptions: Array<VotumBegruendung> = [];
        for (let k in this.begruendungOptions) {
          if (votumBegruendung !== null && this.begruendungOptions[k] !== null
              && this.begruendungOptions[k].id !== votumBegruendung.id) {
            newOptions.push(this.begruendungOptions[k]);
          }
        }
        newOptions.push(votumBegruendung);
        this.begruendungOptions = newOptions;
        if (this.begruendungOptions.length === 1) {
          this.response.begruendung = this.begruendungOptions[0];
        }
        else {
          this.response.begruendung = null;
          this.gemeinsamForm.get('begruendung').enable();        }
        console.debug(this.response.begruendung);
      });
    }
  }
  
  filterAntragOptions(search: string): void {
    let newOptions: Array<Array<Antrag>> = [];
    const cleanedSearch: string = search.replace(' ', '').toLowerCase();
    for (let i in this.antragOptions) {
      if (this.getAntragLabel(this.antragOptions[i]).replace(' ', '').toLowerCase().indexOf(cleanedSearch) >= 0) {
        newOptions.push(this.antragOptions[i]);
      }
    }
    this.antragFilteredOptions = newOptions;
  }
  
  getAntragLabel(antrag: Array<Antrag>): string {
    let labels: Array<string> = [];
    for (let i in antrag) {
      labels.push(antrag[i].attributes.label.replace('&nbsp;', ' '));
    }
    return labels.join(', ');
  }
  
  getVotumLabel(votum: Votum): string {
    let labels: Array<string> = [];
    for (let i in votum.relationships.antrag.data) {
      labels.push(votum.relationships.antrag.data[i].attributes.label);
    }
    return labels.sort((label1: string, label2: string): number => {
      if (label1 < label2) {
        return -1;
      }
      else {
        return +1;
      }
    }).join(', ');
  }
  
  getBegruendungLabel(begruendung: VotumBegruendung): string {
    let labels: Array<string> = [];
    for (let i in begruendung.relationships.antraege.data) {
      labels.push(begruendung.relationships.antraege.data[i].attributes.label);
    }
    return labels.sort((label1: string, label2: string): number => {
      if (label1 < label2) {
        return -1;
      }
      else {
        return +1;
      }
    }).join(', ');
  }
 
  
}