<div class="inhalt-wrapper" fxLayout="row" fxLayoutGap="1rem">
  <div class="inhalt-gliederung" fxFlex="1 1 66.6666%" fxLayout="column" fxLayoutGap="0.5rem">
    <div class="inhalt" [innerHtml]="antragObject.attributes.inhalt.value"></div>
      <div class="begruendung begruendung-ohne" *ngIf="antragObject.attributes.ohne_begruendung == 1">
        <p>Keine Begründung</p>
      </div>
      <div class="begruendung" *ngIf="antragObject.attributes.ohne_begruendung != 1">
        <p>Begründung:</p>
        <div [innerHtml]="antragObject.attributes.begruendung.value"></div>
      </div>
  </div>
  <div class="inhalt-antragskommission" fxFlex="1 1 33.3333%" fxLayout="column" fxLayoutGap="0.5rem"
       *ngIf="antragObject.type !== 'ubg_antrag--zum_leitantrag'">
    <div class="votum" fxFlex="1 1 auto">
      <ng-container *ngFor="let votum of voten">
        <div class="votum-text" [innerHtml]="votum.attributes.generierter_text.value"></div>
      </ng-container>
      <div class="votum-text" [innerHtml]="gemeinsam.attributes.text" *ngIf="gemeinsam !== null"></div>
    </div>
    <div class="votum-begruendung" fxFlex="1 1 auto" *ngIf="begruendung !== null">
      <p class="label">Begründung:</p>
      <div class="begruendung-text" [innerHtml]="begruendung.attributes.inhalt.value"></div>
    </div>
    <div class="votum-redner" fxFlex="1 1 auto" *ngIf="hasRedner()">
      <p class="label">Redner der Antragskommission:</p>
      <ul>
        <ng-container *ngFor="let votum of voten">
          <ng-container *ngIf="votum.relationships.redner_der_ak !== undefined">
            <li *ngFor="let redner of votum.relationships.redner_der_ak.data">
              {{ redner.attributes.title }}
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>