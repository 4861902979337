<div class="layout-margin">
  <mein-login *ngIf="!isLoggedIn" (login)="login($event)"></mein-login>
  <div fxLayout="column" fxLayoutGap="1rem" fxLayoutGap.gt-md="1.5rem"
       *ngIf="isLoggedIn && projekt !== null">
    <form [formGroup]="gliederungForm" fxFlex>
      <gliederung-select formControlName="gliederung" required></gliederung-select>
    </form>
    <div fxFlex fxLayout="row" fxLayoutGap="1rem" fxLayoutGap.gt-md="1.5rem">
      <ng-container *ngIf="page==='abschnitte'">
        <abschnitt-selektor fxFlex="1 1 48px" fxFlex.gt-sm="1 1 64px">
        </abschnitt-selektor>
        <div fxFlex="2 2 auto">
          <mat-card *ngIf="projekt !== undefined && projekt.isAbgelaufen()">
            <mat-card-content>
              Leider ist die Antragsfrist verstrichen und Sie können nun keine neuen Anträge mehr stellen.
            </mat-card-content>
          </mat-card>
          <mein-antrag-list [abschnitt]="abschnitt" [gliederung]="gliederung">
          </mein-antrag-list>
        </div>
      </ng-container>
      <div fxFlex="2 2 auto" *ngIf="page==='home'">
        <mat-card *ngIf="projekt !== undefined && projekt.isAbgelaufen()">
          <mat-card-content>
            Leider ist die Antragsfrist verstrichen und Sie können nun keine neuen Anträge mehr stellen.
          </mat-card-content>
        </mat-card>
        <mein-antrag-home [projekt]="projekt" [gliederung]="gliederung" (close)="toAbschnitte()">
        </mein-antrag-home>
      </div>
    </div>
  </div>
</div>