/**
 * Projekt-Startseite
 * 
 * Attila Németh, UBG
 * 24.04.2020
 */
 
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {CookieService} from '@ubg/cookie-service';

@Component({
  template: '',
})
export class ProjektHomeComponent implements OnInit {
 
  constructor(private router: Router,
                private cookieService: CookieService) {}
  
  ngOnInit(): void {
    if (this.cookieService.get('current_projekt') === null) {
      this.router.navigate(['projekte']);
    }
    else {
      this.router.navigate(['projekt/' + this.cookieService.get('current_projekt')]);
    }
  }
  
}