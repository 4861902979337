<button mat-raised-button color="accent" (click)="addAbschnitt()">
  <mat-icon>add</mat-icon> Neuen Buchstaben hinzufügen
</button>

<table mat-table [dataSource]="abschnitte" *ngIf="projektObject !== null">
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-container matColumnDef="buchstabe">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let abschnitt">{{ abschnitt.attributes.buchstabe }}</td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let abschnitt">{{ abschnitt.attributes.title }}</td>
  </ng-container>
  <ng-container matColumnDef="sortierung">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let abschnitt">
      <span *ngIf="abschnitt.attributes.sortierung=='c'">Klassisch</span>
      <span *ngIf="abschnitt.attributes.sortierung=='t'">Themen</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="operations">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let abschnitt">
      <button mat-icon-button matTooltip="Bearbeiten" color="primary" (click)="editAbschnitt(abschnitt)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Löschen" color="primary" (click)="deleteAbschnitt(abschnitt)"
          [disabled]="abschnitt.relationships.antraege.data.length > 0">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>
</table>