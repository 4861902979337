<antrag-container [antrag]="antragObject">
  <antrag-header [antrag]="antragObject" [abschnitt]="abschnittObject" (toggle)="toggle($event)">
  </antrag-header>
  <antrag-content [antrag]="antragObject" [abschnitt]="abschnittObject" *ngIf="isOpen"
    fxLayout="column" fxLayoutGap="1rem">
    <div fxFlex fxLayout="row" fxLayoutGap="1rem" *ngIf="antragObject !== null" class="ziffer-container">
      <ziffer [ziffer]="antragObject.relationships.ziffer.data" fxFlex="1 1 66.6666%"></ziffer>
      <div class="inhalt-antragskommission" fxFlex="1 1 33.3333%" fxLayout="column" fxLayoutGap="0.5rem">
        <div class="votum" fxFlex="1 1 auto">
          <ng-container *ngFor="let votum of voten">
            <div class="votum-text" [innerHtml]="votum.attributes.generierter_text.value"
               *ngIf="votum.attributes.generierter_text !== undefined"></div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let antrag of antraege">
      <antrag-zum-leitantrag [antrag]="antrag" [abschnitt]="abschnittObject" 
        *ngIf="antrag.attributes.status == 1">
     </antrag-zum-leitantrag>
    </ng-container>
  </antrag-content>
</antrag-container>