/**
 * Leitantrag
 * 
 * Attila Németh, UBG
 * 25.05.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

import {Antrag} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';
import {AntragService} from '../../../../services/antrag.service';
import {FilterService} from '../../../../services/filter.service';
import {Votum} from '../../../../model/votum';

@Component({
  selector: 'antrag-leitantrag',
  templateUrl: './view.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragLeitantragComponent implements OnInit, OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      if (this.antragObject.meta !== undefined && this.antragObject.meta.isOpen === true) {
        this.toggle('open');
      }
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
      this.filterSubscription = this.filterService.filterResult.subscribe(result => {
        this.fragment = null;
        if (result !== null && result.abschnitt.id === this.abschnittObject.id) {
          this.filteredAntraege = result.result;
          if (this.isOpen) {
            this.loadAntraege();
          }
        }
      });
    }
  }
  abschnittObject: Abschnitt = null
  isOpen: boolean = false
  antraege: Array<Antrag> = []
  filteredAntraege: Array<Antrag> = null
  antragEventSubscription: Subscription = null
  filterSubscription: Subscription = null
  fragmentSubscription: Subscription
  fragment: string = null
  voten: Array<Votum> = []
  
  constructor(private antragService: AntragService,
                private filterService: FilterService,
                private route: ActivatedRoute) {}
  
  ngOnInit(): void {
    this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }
                
  ngOnDestroy(): void {
    if (this.filterSubscription !== null) {
      this.filterSubscription.unsubscribe();
    }
    if (this.fragmentSubscription !== null) {
      this.fragmentSubscription.unsubscribe();
    }
  }
  
  toggle(event: string): void {
    if (event === 'open') {
      this.isOpen = true;
      this.loadAntraege();
    }
    else {
      this.isOpen = false;
    }
  }
  
  loadAntraege(): void {
    this.antragService.getAntraegeZumLeitantrag(this.antragObject).subscribe(antraege => {
      if (this.filteredAntraege === null) {
        this.antraege = antraege;
      }
      else {
        let newAntraege: Array<Antrag> = [];
        for (let i in antraege) {
          for (let j in this.filteredAntraege) {
            if (antraege[i].id === this.filteredAntraege[j].id) {
              newAntraege.push(antraege[i]);
            }
          }
        }
        this.antraege = newAntraege;
      }
    });
    this.antragService.getVoten(this.antragObject).subscribe(voten => {
      const v: Votum = voten.pop();
      this.voten = [v];
    });
  }
  
}