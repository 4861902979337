<button mat-flat-button color="primary" (click)="uebernehmen()">
  <mat-icon>library_books</mat-icon> Von anderem Projekt übernehmen
</button>

<mat-list *ngIf="projektObject !== null && projektObject.relationships.rednern !== undefined">
  <mat-list-item *ngFor="let redner of projektObject.relationships.rednern.data">
    <redner [redner]="redner" (delete)="deleteRedner()"></redner>
  </mat-list-item>
</mat-list>

<form [formGroup]="rednerForm" (ngSubmit)="addRedner()">
  <mat-form-field>
    <input type="text" matInput placeholder="Neuer Redner" required formControlName="title">
  </mat-form-field>
  <br />
  <button mat-flat-button color="accent" type="submit" [disabled]="!rednerForm.valid">
    <mat-icon>add</mat-icon> Redner hinzufügen
  </button>
</form>
