<button mat-flat-button color="primary" (click)="uebernehmen()">
  <mat-icon>library_books</mat-icon> Von anderem Projekt übernehmen
</button>

<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let thema of projektObject.relationships.themen.data" cdkDrag class="thema-drag"
    fxLayout="row">
    <mat-icon fxFlex="1 1 2rem">drag_indicator</mat-icon>
    <thema-element [thema]="thema" fxFlex="1 1 auto" (delete)="deleteThema($event)"></thema-element>
  </div>
</div>

<form [formGroup]="themaForm" (ngSubmit)="addThema()">
  <mat-form-field>
    <input type="text" matInput placeholder="Neues Thema" required formControlName="thema">
  </mat-form-field>
  <br />
  <button mat-flat-button color="accent" type="submit" [disabled]="!themaForm.valid">
    <mat-icon>add</mat-icon> Thema hinzufügen
  </button>
</form>