<h1 mat-dialog-title>Antrag in MS Word exportieren</h1>
<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <mat-checkbox [disabled]="!printAccess.mitVotum" (change)="setOption('mitVotum', $event)"
      color="primary">
    Mit Votum
  </mat-checkbox>
  <mat-checkbox [disabled]="!printAccess.mitBegruendungAntragsteller" 
    (change)="setOption('mitBegruendungAntragsteller', $event)"
      color="primary">
    Mit Begründung des Antragstellers
  </mat-checkbox>
  <mat-checkbox [disabled]="!printAccess.mitBegruendungKommission" 
    (change)="setOption('mitBegruendungKommission', $event)"
      color="primary">
    Mit Begründung der Antragskommission
  </mat-checkbox>
  <mat-checkbox [disabled]="!printAccess.nurBegruendungKommission" 
    (change)="setOption('nurBegruendungKommission', $event)"
      color="primary">
    NUR die Begründung der Antragskommission
  </mat-checkbox>
  <mat-checkbox [disabled]="!printAccess.mitMarkup" 
    (change)="setOption('mitMarkup', $event)"
      color="primary">
    Mit Markup
  </mat-checkbox>
  <mat-checkbox [disabled]="!printAccess.mitRedner" 
    (change)="setOption('mitRedner', $event)"
      color="primary">
    Mit Redner der AK
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button [mat-dialog-close]="printOptions" color="primary">
    <mat-icon>print</mat-icon> Drucken
  </button>
</mat-dialog-actions>