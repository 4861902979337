<p fxLayout="row">
  <span *ngIf="ueberweisungGruppeObject.attributes !== undefined 
    && ueberweisungGruppeObject.attributes.title !== null" fxFlex="2 2 auto">
    {{ ueberweisungGruppeObject.attributes.title }}
  </span>
  <button mat-icon-button (click)="editGruppe()" matTooltip="Bearbeiten" fxFlex="1 1 2.5rem"
          color="primary">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="deleteGruppe()" matTooltip="Löschen" fxFlex="1 1 2.5rem"
          color="primary" [disabled]="!deletePermission">
    <mat-icon>delete</mat-icon>
  </button>
</p>