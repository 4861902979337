/**
 * Antragsdialog
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Antrag} from '../../../../model/antrag';

@Component({
  templateUrl: './antrag.component.html',
})
export class AntragEditDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: Antrag) {
  }
              
  validityChanged(valid: boolean) {
    this.disabled = !valid;
  }
  
}