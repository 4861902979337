import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient, HttpParams, HttpErrorResponse, HttpRequest, HttpEventType} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, TimeoutError} from 'rxjs';
import {retry, timeout, catchError, map} from 'rxjs/operators';

import {CookieService} from './cookie.service';
import {accessTokenRequest} from './token_request';
import {accessTokenResponse} from './token_response';

import {environment} from '../../../../../src/environments/environment';

interface httpOptions {
  headers?: HttpHeaders,
  reportProgress?: boolean
  responseType?: any
  params?: HttpParams
}

@Injectable({
  providedIn: 'root'
})
export class DrupalService {
  
  private userLoggedInData: BehaviorSubject<number> = new BehaviorSubject(-1);
  public userLoggedIn: Observable<number> = this.userLoggedInData.asObservable();

  accessToken: string = null
  baseUrl: string = ''
    
  constructor(private http: HttpClient,
                private cookieService: CookieService) {
    this.baseUrl = environment.drupal.baseUrl;
  }
  
  load() {
    this.refreshToken();
  }
  
  login(username: string, password: string) {
    let requestData: accessTokenRequest = {
      client_id: environment.drupal.clientId,
      client_secret: environment.drupal.clientSecret,
      grant_type: 'password',
      username: username,
      password: password,
      scope: environment.drupal.scope,
    };
    this.updateAccessToken(requestData).subscribe((response: accessTokenResponse) => {
      console.info('Access Token wurde aktualisiert');
      this.accessToken = response.token_type + ' ' + response.access_token;
      this.cookieService.set('refresh_token', response.refresh_token);
      this.userLoggedInData.next(1);
      setTimeout(() => {
        this.refreshToken();
      }, (response.expires_in - 30) * 1000);
    }, () => {
      this.cookieService.delete('refresh_token');
      this.userLoggedInData.next(-1);
    });
  }
  
  /**
   * Anmeldung durch Custom-Parametern
   * 
   * 28.05.2020
   */
  customLogin(params: any): void {
    let requestData: accessTokenRequest = {
      client_id: environment.drupal.clientId,
      client_secret: environment.drupal.clientSecret,
      scope: environment.drupal.scope,
      grant_type: params['grant_type'],
    };
    for (let key in params) {
      requestData[key] = params[key];
    }
    this.updateAccessToken(requestData).subscribe((response: accessTokenResponse) => {
      console.info('Access Token wurde aktualisiert');
      this.accessToken = response.token_type + ' ' + response.access_token;
      this.cookieService.set('refresh_token', response.refresh_token);
      this.userLoggedInData.next(1);
      setTimeout(() => {
        this.refreshToken();
      }, (response.expires_in - 30) * 1000);
    }, () => {
      this.cookieService.delete('refresh_token');
      this.userLoggedInData.next(-1);
    });
  }
  
  logout(): void {
    this.accessToken = null;
    this.cookieService.delete('refresh_token');
    this.userLoggedInData.next(-1);
  }
  
  /**
   * Password ändern
   */
  changePassword(currentPass: string, newPass: string) {
    const options: {
      headers?: HttpHeaders,
    } = this.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = 'ubg/user/password';
    let data = {
      current: currentPass,
      new: newPass,
    };
    return this.post(path,data, options);
  }
  
  private refreshToken(): void {
    if (this.cookieService.get('refresh_token') !== null) {
      this.userLoggedInData.next(0);
      let requestData: accessTokenRequest = {
        client_id: environment.drupal.clientId,
        client_secret: environment.drupal.clientSecret,
        grant_type: 'refresh_token',
        refresh_token: this.cookieService.get('refresh_token'),
      };
      this.updateAccessToken(requestData).subscribe((response: accessTokenResponse) => {
        console.info('Access Token wurde aktualisiert');
        this.accessToken = response.token_type + ' ' + response.access_token;
        this.cookieService.set('refresh_token', response.refresh_token);
        this.userLoggedInData.next(1);
        setTimeout(() => {
          this.refreshToken();
        }, (response.expires_in - 30) * 1000);
      }, () => {
        this.cookieService.delete('refresh_token');
        this.userLoggedInData.next(-1);
      });
    }
    else {
      this.userLoggedInData.next(-1);
    }
  }
  
  private updateAccessToken(requestData: accessTokenRequest) {
    console.info('Access Token wird aktualisiert...');
    const formData = new FormData();
    for (let key in requestData) {
      formData.set(key, requestData[key]);
    }
    return this.http.post(environment.drupal.tokenUrl, formData).pipe(
      retry(3),
      timeout(15000),
      catchError(this.formatErrors),
    );
  }
  
  private formatErrors(error: HttpErrorResponse) {
    console.error('HTTP', error.status);
    if (error instanceof TimeoutError) {
      return throwError('Die Anfrage hat zu lange gedauert');
    }
    switch (error.status) {
      case 422:
      case 500:
        console.warn(error.statusText);
        console.warn(error.message);
        let errorDetails: string = null
        for (let i in error.error['errors']) {
          console.warn('--', error.error['errors'][i]['status'], 
                                error.error['errors'][i]['detail']);
          errorDetails = error.statusText + ': ' + error.error['errors'][i]['detail'];
        }
        if (errorDetails !== null) {
          return throwError(errorDetails);
        }
        return throwError(error.status + ' ' + error.statusText);
        break;
      case 401:
        return throwError('HTTP 401 Ihre Anmeldung ist nicht gültig');
      case 403:
        return throwError('HTTP 403 Zugriff verweigert');
      case 404:
        return throwError('HTTP 404 Nicht gefunden');
      case 412:
        return throwError('HTTP 412 Voraussetzungen nicht erfüllt');
      default:
        console.warn(error.statusText);
        console.warn('--', error.message);
        return throwError(error.message);
    }
    return throwError(error);
  }
  
  getCurrentUser() {
    const options: {
      headers?: HttpHeaders,
    } = this.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = 'ubg/user/me';
    return this.get(path, options);
  }
  
  getHttpOptions(headers: Array<string>): httpOptions {
    let httpHeaders = new HttpHeaders();
    let httpOptions = {
      headers: httpHeaders,
    };
    let methodName: string;
    for (let i in headers) {
      methodName = 'set' + headers[i] + 'Header';
      if (this[methodName] !== undefined) {
        httpOptions = this[methodName](httpOptions);
      }
      else {
        console.warn('Invalid Request Header Type', headers[i]);
      }
    }
    return httpOptions;
  }
  
  private setJsonApiHeader(options: httpOptions): httpOptions {
    options.headers = options.headers.set('Content-Type', 'application/vnd.api+json');
    return options;
  }
  
  private setOctetStreamHeader(options: httpOptions): httpOptions {
    options.headers = options.headers.set('Content-Type', 'application/octet-stream');
    return options;
  }
  
  private setAuthenticationHeader(options: httpOptions): httpOptions {
    options.headers = options.headers.set('Authorization', this.accessToken);
    return options;
  }
  
  private setBlobHeader(options: httpOptions): httpOptions {
    options.responseType = 'blob';
    return options;
  }
  
  get(path: string, options?: httpOptions) {
    return this.http.get(this.baseUrl + '/' + path, options).pipe(
      retry(3),
      timeout(8000),
      catchError(this.formatErrors),
    );
  }
  
  post(path: string, data: any, options?: httpOptions) {
    return this.http.post(this.baseUrl + '/' + path, data, options).pipe(
      retry(3),
      timeout(24000),
      catchError(this.formatErrors),
    );
  }
  
  postFile(path: string, file: File, options: httpOptions) {
    options.headers = options.headers.set('Content-Type', file.type);
    options.headers = options.headers.set('Content-Disposition', 'file; filename="' + file.name + '"');
    options.reportProgress = true;
    const request = new HttpRequest('POST', this.baseUrl + '/' + path, file, options);
    return this.http.request(request).pipe(
      retry(3),
      timeout(60000),
      catchError(this.formatErrors),
      map((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          return {
            type: 'upload',
            loaded: event.loaded,
            total: event.total,
          }
        }
        else if (event.type == HttpEventType.Sent) {
          return {
            type: 'sent',
          };
        }
        else if (event.type == HttpEventType.ResponseHeader) {
          return {
            type: 'header',
            status: event.status,
            message: event.statusText,
          };
        }
        else if (event.type == HttpEventType.Response) {
          return {
            type: 'response',
            body: event.body,
          };
        }
        else {
          return {
            type: 'other',
          }
        }
      })
    );
  }
  
  patch(path: string, data: any, options?: httpOptions) {
    return this.http.patch(this.baseUrl + '/' + path, data, options).pipe(
      retry(3),
      timeout(24000),
      catchError(this.formatErrors),
    );
  }
  
  delete(path: string, options?: httpOptions) {
    return this.http.delete(this.baseUrl + '/' + path, options).pipe(
      retry(3),
      timeout(12000),
      catchError(this.formatErrors),
    );
  }
  
  entityCreatePermission(entityType: string, bundle?: string) {
    const options: {
      headers?: HttpHeaders,
    } = this.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = 'ubg/user/entity/permission';
    let data = {
      operation: 'create',
      entityType: entityType,
      bundle: bundle,
    };
    if (bundle === undefined) {
      data.bundle = entityType;
    }
    return this.post(path,data, options);
  }
  
  entityPermission(entityType: string, entityId: string, operation: string) {
    const options: {
      headers?: HttpHeaders,
    } = this.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      options.headers = options.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    const path: string = 'ubg/user/entity/permission';
    let data = {
      operation: operation,
      entityType: entityType,
      entityId: entityId
    };
    return this.post(path,data, options);
  }
  
}
