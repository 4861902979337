<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <antrag-ohne-leitantrag-edit [(ngModel)]="data"
    *ngIf="data !== null && data.type=='ubg_antrag--ohne_leitantrag'"
    (validityChange)="validityChanged($event)">
  </antrag-ohne-leitantrag-edit>
  <antrag-zum-leitantrag-edit [(ngModel)]="data"
    *ngIf="data !== null && data.type=='ubg_antrag--zum_leitantrag'"
    (validityChange)="validityChanged($event)">
  </antrag-zum-leitantrag-edit>
  <antrag-initiativantraege-edit [(ngModel)]="data"
    *ngIf="data !== null && data.type=='ubg_antrag--initiativantrag'"
    (validityChange)="validityChanged($event)">
  </antrag-initiativantraege-edit>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="disabled">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>