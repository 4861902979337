<form [formGroup]="antragForm">
  <gliederung-select formControlName="gliederung" required>
  </gliederung-select>
  <mat-error *ngIf="!antragForm.get('gliederung').valid">
    <span *ngIf="antragForm.get('gliederung').errors.required && antragForm.get('gliederung').touched">
      Eine oder mehr Gliederungen müssen ausgewählt werden
    </span>
  </mat-error>

  <mat-form-field *ngIf="hasAntragstellerName">
    <mat-label>Antragsteller</mat-label>
    <textarea matInput formControlName="antragsteller" rows="2"></textarea>
    <mat-error *ngIf="!antragForm.get('antragsteller').valid">
      <span *ngIf="antragForm.get('antragsteller').errors.required && antragForm.get('antragsteller').touched">
        Der Antragsteller (z.B. die Namen der Mitglieder) muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  
  <mat-form-field *ngIf="antrag !== null">
    <mat-label>Ziffer</mat-label>
    <mat-select formControlName="ziffer" required [disabled]="antrag.relationships.leitantrag.data.attributes === undefined">
      <mat-option [value]="antrag.relationships.leitantrag.data">
        <span *ngIf="antrag.relationships.leitantrag.data.attributes !== undefined">
          {{ antrag.relationships.leitantrag.data.attributes.title }}
        </span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="!antragForm.get('ziffer').valid">
      <span *ngIf="antragForm.get('ziffer').errors.required && antragForm.get('ziffer').touched">
        Die Ziffer muss ausgewählt werden
      </span>
    </mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutGap="0.5rem">
    <mat-form-field fxFlex *ngIf="zeileFirst !== null && zeileLast !== null">
      <input matInput type="number" placeholder="Von Zeile" required formControlName="zeile_von">
      <mat-hint>{{ zeileFirst }} - {{ zeileLast }}</mat-hint>
      <mat-error *ngIf="!antragForm.get('zeile_von').valid && antragForm.get('zeile_von').touched">
        <span *ngIf="antragForm.get('zeile_von').errors.required">
          Die Zeilennummern müssen angegeben werden
        </span>
        <span *ngIf="antragForm.get('zeile_von').errors.min || antragForm.get('zeile_von').errors.max">
          Diese Ziffer hat die Zeilen {{ zeileFirst }} - {{ zeileLast }}
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="zeileFirst !== null && zeileLast !== null">
      <input matInput type="number" placeholder="Bis Zeile" required formControlName="zeile_bis">
      <mat-hint>{{ zeileFirst }} - {{ zeileLast }}</mat-hint>
      <mat-error *ngIf="!antragForm.get('zeile_bis').valid && antragForm.get('zeile_bis').touched">
        <span *ngIf="antragForm.get('zeile_bis').errors.required">
          Die Zeilennummern müssen angegeben werden
        </span>
        <span *ngIf="antragForm.get('zeile_bis').errors.min || antragForm.get('zeile_bis').errors.max">
          Diese Ziffer hat die Zeilen {{ zeileFirst }} - {{ zeileLast }}
        </span>
      </mat-error>
    </mat-form-field>
  </div>
  
  <cketext formControlName="inhalt" placeholder="Antrag">
  </cketext>
  <p>
    <mat-checkbox (change)="ohneBegruendungChanged($event)" formControlName="ohne_begruendung">
      Keine Begründung angeben
    </mat-checkbox>
  </p>
  <cketext formControlName="begruendung" placeholder="Begründung" 
           *ngIf="antrag !== null && !antrag.attributes.ohne_begruendung">
  </cketext>
  <mat-error *ngIf="!antragForm.get('begruendung').valid">
    <span *ngIf="antragForm.get('begruendung').errors.required && antragForm.get('begruendung').touched">
      Die Begründung muss angegeben werden.
    </span>
  </mat-error>
</form>