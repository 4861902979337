<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <h1 color="warn">
    <mat-icon>delete</mat-icon> Antrag löschen
  </h1>
  <p>Sind Sie sicher, dass Sie diesen Antrag endgültig aus dem System entfernen möchten?
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button mat-dialog-close="1" color="warn">
    <mat-icon>delete</mat-icon> Ja, ich möchte den Antrag löschen
  </button>
</mat-dialog-actions>