/**
 * Coookie-Service
 * 
 * Attila Németh, UBG
 * 22.04.2020
 */
 
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  
  constructor() { }
  
  get(name: string): any {
    let cookies: Array<string> = document.cookie.split('; ');
    let cookieParts: Array<string>;
    for (let i in cookies) {
      cookieParts = cookies[i].split('=');
      if (cookieParts[0] == name) {
        return cookieParts[1];
      }
    }
    return null;
  }
  
  set(name: string, value: any): void {
    let d = new Date('2099-12-31');
    document.cookie = name + '=' + value + '; expires=' + d.toUTCString() + '; path=/;';
  }
  
  delete(name: string): void {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  
}
