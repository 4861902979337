/**
 * Antrag in Drag&Drop-Liste
 * 
 * Attila Németh, UBG
 * 27.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {Antrag} from '../../../model/antrag';
import {Abschnitt} from '../../../model/abschnitt';

@Component({
  selector: 'antrag-drag-drop',
  templateUrl: './drag_drop.component.html',
  styleUrls: ['../view.component.scss'],
})
export class AntragDragDropComponent {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.updateLabel();
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
      this.updateLabel();
    }
  }
  abschnittObject: Abschnitt = null
  headerLabel: string = null
  gliederungLabel: string = null
  
  constructor() {}
  
  updateLabel(): void {
    let elements: Array<string> = [];
    if (this.abschnittObject !== null) {
      elements.push(this.abschnittObject.attributes.buchstabe);
    }
    if (this.antragObject !== null) {
      elements.push(this.antragObject.attributes.nummer.toString());
    }
    this.headerLabel = elements.join(' ');
    let gliederungLabels: Array<string> = [];
    for (let i in this.antragObject.relationships.gliederung.data) {
      gliederungLabels.push(this.antragObject.relationships.gliederung.data[i].attributes.longTitle);
    }
    this.gliederungLabel = gliederungLabels.join(', ');
  }
       
}