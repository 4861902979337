/**
 * Votum-Service
 * 
 * Attila Németh, UBG
 * 10.06.2020
 */
 
import {Injectable} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';

import {Votum, VotumType, VotumTypeInfo, VotumBegruendung, VotumGemeinsam} from '../model/votum';
import {Redner} from '../model/redner';
import {Antrag} from '../model/antrag';

@Injectable({
  providedIn: 'root'
})
export class VotumService {

  constructor(private drupal: DrupalService) {}
 
  getVotumTypes(): Observable<Array<VotumType>> {
    const path: string = 'api/ubg_votum_type/ubg_votum_type?sort=gewicht_opt';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<VotumType>
      }) => {
        return response.data;
      })
    );
  }
  
  getVotumTypeInfo(type: VotumType): Observable<VotumTypeInfo> {
    const path: string = 'ubg/votum/type/api/' + type.id;
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: VotumTypeInfo) => {
        return response;
      })
    );
  }
  
  getVotum(type: string, id: string): Observable<Votum> {
    const path: string = 'api/' + type.replace('--', '/') + '/' + id;
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Votum
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Ein Votum erstellen
   */
  createVotum(votum: Votum): Observable<Votum> {
    const path: string = 'api/' + votum.type.replace('--', '/');
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (votum.id !== undefined) {
      delete votum.id;
      delete votum.attributes.drupal_internal__id;
    }
    if (votum.attributes.changed !== undefined) {
      delete votum.attributes.changed;
    }
    return this.drupal.post(path, {
      data: this.cleanVotum(votum),
    }, httpOptions).pipe(
      map((response: {
        data: Votum
      }) => {
        return response.data;
      })
    );
  }
  
  updateVotum(votum: Votum): Observable<Votum> {
    const path: string = 'api/' + votum.type.replace('--', '/') + '/' + votum.id;
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(path, {
      data: this.cleanVotum(votum),
    }, httpOptions).pipe(
      map((response: {
        data: Votum
      }) => {
        return response.data;
      })
    );
  }
  
  private cleanVotum(votum: Votum): Votum {
    for (let i in votum.relationships.antrag.data) {
      if (votum.relationships.antrag.data[i].meta !== undefined) {
        delete votum.relationships.antrag.data[i].meta;
      }
    }
    return votum;
  }
  
  deleteVotum(votum: Votum) {
    const path: string = 'api/' + votum.type.replace('--', '/') + '/' + votum.id;
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(path, httpOptions);
  }
  
  createBegruendung(begruendung: VotumBegruendung) {
    const path: string = 'api/ubg_votum_begruendung/ubg_votum_begruendung';
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(path, {
      data: begruendung,
    }, httpOptions).pipe(
      map((response: {
        data: Votum
      }) => {
        return response.data;
      })
    );
  }
  
  deleteBegruendung(begruendung: VotumBegruendung) {
    if (begruendung !== null && begruendung !== undefined) {
      const path: string = 'api/ubg_votum_begruendung/ubg_votum_begruendung/' + begruendung.id;
      const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
      return this.drupal.delete(path, httpOptions);
    }
    else {
      console.warn('Keine Begründung zum Löschen');
    }
  }
  
  updateBegruendung(begruendung: VotumBegruendung): Observable<VotumBegruendung> {
    if (begruendung !== null && begruendung !== undefined) {
      const path: string = 'api/ubg_votum_begruendung/ubg_votum_begruendung/' + begruendung.id;
      const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
      return this.drupal.patch(path, {
        data: begruendung
      }, httpOptions).pipe(
        map((response: {
          data: VotumBegruendung
        }) => {
          return response.data;
        })
      );
    }
    else {
      console.warn('Keine Begründung zum Bearbeiten');
    }
  }
  
  /**
   * Redner-Optionen
   * 
   * 17.06.2020
   */
  getRednern(votum: Votum): Observable<Array<Redner>> {
    const path: string = 'ubg/votum/' + votum.id + '/rednern';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Redner>
      }) => {
        return response.data;
      })
    );
  }
  
  getGemeinsamByAntrag(antrag: Antrag): Observable<VotumGemeinsam> {
    const path: string = 'ubg/antrag/api/' + antrag.id + '/gemeinsam';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: VotumGemeinsam,
      }) => {
        return response.data;
      })
    );
  }
     
}