/**
 * Abschnitt-Service
 * 
 * Attila Németh, UBG
 * 24.04.2020
 */
 
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Abschnitt, AbschnittBeschluss} from '../model/abschnitt';
import {Antrag} from '../model/antrag';
import {Gliederung} from '../model/gliederung';
import {CookieService} from '@ubg/cookie-service';
import { PrintBegruendungOptions } from '../components/projekt/dialog/print-begruendung/print-begruendung';

@Injectable({
  providedIn: 'root'
})
export class AbschnittService {

  private activeAbschnittData: BehaviorSubject<Abschnitt> = new BehaviorSubject(null);
  public activeAbschnitt: Observable<Abschnitt> = this.activeAbschnittData.asObservable();
  private updateAbschnittData: BehaviorSubject<Abschnitt> = new BehaviorSubject(null);
  public updateAbschnitt: Observable<Abschnitt> = this.updateAbschnittData.asObservable();
  private dragAbschnittData: BehaviorSubject<Abschnitt> = new BehaviorSubject(null);
  public dragAbschnitt: Observable<Abschnitt> = this.dragAbschnittData.asObservable();
  private queryAbschnittData: BehaviorSubject<Abschnitt> = new BehaviorSubject(null);
  public queryAbschnitt: Observable<Abschnitt> = this.queryAbschnittData.asObservable();

  constructor(private drupal: DrupalService,
                private cookieService: CookieService) {}

  get(type: string, id: string) {
    let path: string = 'api/' + type.replace('--', '/') + '/' + id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions);
  }
  
  getExtended(id: string): Observable<Abschnitt> {
    const path: string = 'ubg/abschnitt/api/' + id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Abschnitt
      }) => {
        return response.data;
      })
    );
  }
  
  create(abschnitt: Abschnitt) {
    let path: string = 'api/' + abschnitt.type.replace('--', '/');
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(path, {
      data: abschnitt,
    }, httpOptions);
  }
  
  update(abschnitt: Abschnitt) {
    let path: string = 'api/' + abschnitt.type.replace('--', '/') + '/' + abschnitt.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.patch(path, {
      data: abschnitt,
    }, httpOptions);
  }

  deleteAbschnitt(abschnitt: Abschnitt) {
    const path: string = 'api/' + abschnitt.type.replace('--', '/') + '/' + abschnitt.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.delete(path, httpOptions);
  }
  
  setActiveAbschnitt(abschnitt: Abschnitt): void {
    this.activeAbschnittData.next(abschnitt);
  }
  
  getActiveAbscnhitt(): Abschnitt {
    return this.activeAbschnittData.value;
  }
  
  /**
   * Der Leitantrag
   */
  getLeitantrag(id: string): Observable<Abschnitt> {
    const path: string = 'ubg/abschnitt/api/' + id + '/leitantrag';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Abschnitt
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Leitantrag generieren
   * Der Prozess läuft komplett am Server
   * 
   * 22.05.2020
   */
  generateLeitantrag(abschnitt: Abschnitt, gliederung: Array<Gliederung>, ziffern: Array<string>) {
    const path: string = 'ubg/abschnitt/api/' + abschnitt.id + '/leitantrag/generate';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    let gliederungIds: Array<string> = [];
    for (let i in gliederung) {
      gliederungIds.push(gliederung[i].id);
    }
    return this.drupal.post(path, {
      gliederung: gliederungIds,
      ziffern: ziffern,
    }, httpOptions);
  }
  
  /**
   * Anträge sortieren
   * 
   * 26.05.2020
   */
  sortAntraege(abschnitt: Abschnitt) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/sort';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, null, httpOptions);
  }
  
  editPermission(abschnitt: Abschnitt): Observable<boolean> {
    return this.drupal.entityPermission('ubg_abschnitt', abschnitt.id, 'edit').pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
  
  /**
   * Ein Abschnitt als aktualisiert zu markieren
   * 
   * 27.05.2020
   */
  setUpdateAbschnitt(abschnitt: Abschnitt): void 
  {
    this.updateAbschnittData.next(abschnitt);
  }
  
  /**
   * Ein Abschnitt für Drag&Drop zu markieren
   * 
   * 27.05.2020
   */
  setDragAbschnitt(abschnitt: Abschnitt): void 
  {
    this.dragAbschnittData.next(abschnitt);
  }
  
  /**
   * Die Anträge einer Gliederung in einem Abschnitt
   * 
   * 28.05.2020
   */
  getGliederungAntraege(abschnitt: Abschnitt, gliederung: Gliederung): Observable<Array<Antrag>> {
    const path:string = 'ubg/abschnitt/' + abschnitt.id + '/gliederung/' + gliederung.id + '/antraege';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  
  printAbschnitt(abschnitt: Abschnitt, options: AbschnittPrintOptions) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/print';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, options, httpOptions);
  }
  
  printLeitantrag(abschnitt: Abschnitt, view: string) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/print/leitantrag';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, {
      view: view
    }, httpOptions);
  }
  
  setQueryQAbschnitt(abschnitt: Abschnitt): void {
    this.queryAbschnittData.next(abschnitt);
  }
  
  /**
   * Der Beschluss
   */
  getBeschluss(abschnitt: Abschnitt): Observable<AbschnittBeschluss> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/beschluss';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: AbschnittBeschluss) => {
        return response;
      })
    );
  }
  
  /**
   * Den Beschluss drucken
   * 
   * 21.07.2020
   */
  printBeschluss(abschnitt: Abschnitt) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/print/beschluss';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, null, httpOptions);
  }

  printBegruendungen(abschnitt: Abschnitt, options: PrintBegruendungOptions) {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/print/begruendungen';
    let httpOptions = this.drupal.getHttpOptions(['Authentication', 'Blob']);
    return this.drupal.post(path, options, httpOptions);
  }
  
  /**
   * Suchergebnis
   * 
   * 02.06.2020
   *
  getFilteredAntraege(abschnitt: Abschnitt, params: FilterParams): Observable<Array<Antrag>> {
    const path: string = 'ubg/abschnitt/' + abschnitt.id + '/filter';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, params, httpOptions).pipe(
      map((response: {
        data: Array<Antrag>
      }) => {
        return response.data;
      })
    );
  }
  */
  
}

export interface AbschnittPrintOptions {
  mitBegruendungAntragsteller: boolean
  mitBegruendungKommission: boolean
  mitMarkup: boolean
  mitRedner: boolean
}