<form [formGroup]="ueberweisungForm">
  <mat-form-field>
    <input type="text" maxlength="240" matInput formControlName="title" required
           placeholder="Name">
    <mat-hint>Artikel kleingeschrieben und in Akkusativ, z.B. "den Bundesvorstand"</mat-hint>
    <mat-error>
      <span *ngIf="!ueberweisungForm.get('title').valid && ueberweisungForm.get('title').touched">
        Der Titel muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="ueberweisung !== null && ueberweisung.id !== undefined">
    <mat-label>Gruppe</mat-label>
    <mat-select required formControlName="gruppe">
      <mat-option *ngFor="let ueberweisungGruppe of ueberweisungGuppen"
        [value]="ueberweisungGruppe.id">
        {{ ueberweisungGruppe.attributes.title }}
      </mat-option> 
    </mat-select>
    <mat-error>
      <span *ngIf="!ueberweisungForm.get('gruppe').valid && ueberweisungForm.get('gruppe').touched">
        Eine Gruppe muss ausgewählt werden
      </span>
    </mat-error>
  </mat-form-field>
</form>