<p fxLayout="row" *ngIf="ueberweisungObject !== null">
  <span *ngIf="ueberweisungObject.attributes !== undefined 
    && ueberweisungObject.attributes.title !== null" fxFlex="2 2 auto" [matTooltip]="gruppeName">
    {{ ueberweisungObject.attributes.title }}
  </span>
  <span *ngIf="displayGruppe" fxFlex="1 1 12rem">
    {{ gruppeName }}
  </span>
  <button mat-icon-button (click)="editUeberweisung()" matTooltip="Bearbeiten" fxFlex="1 1 2.5rem"
          color="primary">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="deleteUeberweisung()" matTooltip="Löschen" fxFlex="1 1 2.5rem"
          color="primary">
    <mat-icon>delete</mat-icon>
  </button>
</p>