<ng-container *ngIf="loginStatus == -1">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Anmelden</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="formSubmitted(loginForm.value)">
        <mat-form-field>
          <input type="text" matInput placeholder="Benutzername" required formControlName="username">
          <mat-error>
            <span *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
              Benutzername ist Pflichtfeld
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="password" matInput placeholder="Passwort" required formControlName="password">
          <mat-error>
            <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">
              Passwort ist Pflichtfeld
            </span>
          </mat-error>
        </mat-form-field>
        <button mat-button color="primary" [disabled]="!loginForm.valid">Anmelden</button>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-container *ngIf="loginStatus == 0">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-container>

<ng-container *ngIf="loginStatus == 1">
  <ng-content></ng-content>
</ng-container>