/**
 * Gliederung-Kategorie - zu
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {GliederungKategorie} from '../../../../model/gliederung';

@Component({
  selector: 'gliederung-kategorie-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class GliederungKategorieClosedComponent {
  
  @Input() set kategorie(kategorie: GliederungKategorie) {
    this.kategorieObject = kategorie;
  }
  @Output() open = new EventEmitter
  kategorieObject: GliederungKategorie = null
    
  constructor() {}
  
}