/**
 * Gliederung
 * 
 * Attila Németh, UBG
 * 14.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {GliederungKategorie, Gliederung} from '../../../model/gliederung';
import {GliederungKategorieDialogComponent} from '../dialog/kategorie/kategorie.component';
import {GliederungService} from '../../../services/gliederung.service';

@Component({
  selector: 'gliederung-kategorie',
  templateUrl: './kategorie.component.html',
  styleUrls: ['./kategorie.component.scss']
})
export class GliederungKategorieComponent {
  
  @Input() set kategorie(kategorie: GliederungKategorie) {
    this.kategorieObject = kategorie;
  }
  kategorieObject: GliederungKategorie
  isOpen: boolean = false
  className: string = 'closed'
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private gliederungService: GliederungService) {
                
    console.error('Dieses Komponent darf nicht mehr angewendet werden');
    
  }
  
  open(): void {
    this.isOpen = true;
    this.updateClassName();
  }
  
  close(): void {
    this.isOpen = false;
    this.updateClassName();
  }
  
  updateClassName(): void {
    let classes: Array<string> = [];
    if (this.isOpen) {
      classes.push('open');
    }
    else {
      classes.push('closed');
    }
    this.className = classes.join(' ');
  }
  
  editKategorie() {
    this.dialog.open(GliederungKategorieDialogComponent, {
      data: this.kategorieObject,
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.gliederungService.getKategorie(this.kategorieObject.id).subscribe((kategorie) => {
          kategorie.attributes.title = result['attributes']['title'];
          if (result['attributes']['kurzb'] !== undefined) {
            kategorie.attributes.kurzb = result['attributes']['kurzb'];
          }
          this.gliederungService.updateKategorie(kategorie).subscribe((kategorie) => {
            this.kategorieObject = kategorie;
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            })
          });
        });
      }
    });
  }
  
  gliederungDeleted(): void {
    this.gliederungService.getKategorie(this.kategorieObject.id).subscribe((kategorie) => {
      let newGliederungen: Array<Gliederung> = [];
      for (let i in kategorie.relationships.gliederungen.data) {
        if (kategorie.relationships.gliederungen.data[i].id !== 'missing') {
          newGliederungen.push(kategorie.relationships.gliederungen.data[i]);
        }
      }
      kategorie.relationships.gliederungen.data = newGliederungen;
      this.gliederungService.updateKategorie(kategorie).subscribe((kategorie) => {
        this.kategorieObject = kategorie;
      }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        })
      });
    });
  }
  
}