<form [formGroup]="abschnittForm" fxLayout="column">
  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select required [disabled]="typeDisabled" formControlName="type">
      <mat-option value="ubg_abschnitt--mit_leitantrag">Mit Leitantrag</mat-option>
      <mat-option value="ubg_abschnitt--ohne_leitantrag">Ohne Leitantrag</mat-option>
      <mat-option value="ubg_abschnitt--initiativantraege">Initiativantraege</mat-option>
    </mat-select>
    <mat-error>
      <span *ngIf="!abschnittForm.get('type').valid && abschnittForm.get('type').touched">
        Ein Typ muss ausgewählt werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Titel" required formControlName="title">
    <mat-error>
      <span *ngIf="!abschnittForm.get('title').valid && abschnittForm.get('title').touched">
        Der Titel muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Buchstabe" required formControlName="buchstabe" 
           maxlength="1" size="4">
    <mat-error>
      <span *ngIf="!abschnittForm.get('buchstabe').valid && abschnittForm.get('buchstabe').touched">
        Der Titel muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="abschnitt.type !== null && abschnitt.type !== 'ubg_abschnitt--mit_leitantrag'">
    <mat-label>Sortierung</mat-label>
    <mat-select required formControlName="sortierung" >
      <mat-option value="c">Klassisch</mat-option>
      <mat-option value="t">Themen</mat-option>
    </mat-select>
    <mat-error>
      <span *ngIf="!abschnittForm.get('sortierung').valid && abschnittForm.get('sortierung').touched">
        Sortierung muss ausgewählt werden
      </span>
    </mat-error>
  </mat-form-field>
</form>