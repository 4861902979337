import {NgModule, APP_INITIALIZER} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {DrupalService} from './drupal.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  exports: [],
  providers: [
    DrupalService,
    {
      provide: APP_INITIALIZER,
      useFactory: (drupal: DrupalService) => { 
        return () => drupal.load();
      },
      deps: [DrupalService],
      multi: true
    }
  ],
})
export class DrupalModule { }
