<div class="mat-elevation-z2 antrag-container">
  <div fxLayout="row" fxLayoutGap="0.5rem" class="header-closed">
    <button mat-icon-button fxFlex="1 1 3rem">
      <mat-icon>drag_indicator</mat-icon>
    </button>
    <h3 fxFlex="1 1 5rem">
      {{ headerLabel }}
    </h3>
    <div class="title" fxFlex="3 3 auto">
      <p class="antrag-gliederung">{{ gliederungLabel }}</p>
      <p class="antrag-title" [matTooltip]="antragObject.attributes.title">
        {{ antragObject.attributes.title }}
      </p>
    </div>
  </div>
</div>