/**
 * Antrag-Popup-Komponent für die Redaktion
 * 
 * Attila Németh, UBG
 * 26.05.2020
 */
 
import {Component, Output, EventEmitter} from '@angular/core';

import {Antrag} from '../../../../../model/antrag';

@Component({
  selector: 'projekt-antrag-popup',
  templateUrl: './antrag.component.html',
//  styleUrls: ['./operations.component.scss'],
})
export class ProjektAntragPopupComponent {
  
  @Output() close = new EventEmitter
  
  antrag: Antrag = null
    
  constructor() {}
  
  closeModal(): void {
    this.close.emit();
  }
  
} 