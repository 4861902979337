<!--
<mat-card *ngIf="projekt !== null">
  <mat-card-header>
    <mat-card-title>
      {{ projekt.attributes.title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <projekt-antrag-popup class="modal mat-elevation-z4" *ngIf="modal === 'antrag'"
                          (close)="closeModal()">
    </projekt-antrag-popup>
    <mat-action-list>
      <button mat-list-item (click)="addAntrag()">
        <mat-icon>add_box</mat-icon> Antrag eintragen
      </button>
      <button mat-list-item (click)="printBroschuere()">
        <mat-icon>print</mat-icon> Broschüre in MS Word
      </button>
      <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isPrinting">
      </mat-progress-bar>
      <button mat-list-item (click)="beschluss()">
        <mat-icon>description</mat-icon> Beschluss
      </button>
    </mat-action-list>
  </mat-card-content>
</mat-card>
-->

<p class="op-title mat-h4">
  {{ projekt.attributes.title }}
</p>

<projekt-antrag-popup class="modal mat-elevation-z4" *ngIf="modal === 'antrag'"
                      (close)="closeModal()">
</projekt-antrag-popup>
<mat-action-list>
  <button mat-list-item (click)="addAntrag()">
    <mat-icon>add_box</mat-icon> Antrag eintragen
  </button>
  <button mat-list-item (click)="printBroschuere()">
    <mat-icon>print</mat-icon> Broschüre in MS Word
  </button>
  <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isPrinting">
  </mat-progress-bar>
  <button mat-list-item (click)="beschluss()">
    <mat-icon>description</mat-icon> Beschluss
  </button>
  <button mat-list-item (click)="printRedner()">
    <mat-icon>record_voice_over</mat-icon> Redner in MS Word
  </button>
  <button mat-list-item (click)="printBegruendungen()">
    <mat-icon>print</mat-icon> Begründungen in MS Word
  </button>
</mat-action-list>