/**
 * E-Mail-Einstellungen
 * 
 * Attila Németh, UBG
 * 03.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Observable, of, combineLatest} from 'rxjs';
import {startWith, map} from 'rxjs/operators';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Projekt} from '../../../../model/projekt';
import {ProjektService} from '../../../../services/projekt.service';

@Component({
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class ProjektEmailDialogComponent implements OnInit {
  
  disabled: boolean = true
  projektForm: FormGroup  
  emptySettings$: Observable<boolean> = of(false)
  projekte: Array<Projekt> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Projekt,
                formBuilder: FormBuilder,
                private ProjektService: ProjektService) {
    this.projektForm = formBuilder.group({
      absender: [null, [Validators.required, Validators.email]],
      absenden: [null, Validators.required],
      zuweisung: [null, Validators.required],
      entwurf_benachrichtigung: [null, Validators.required],
    });
  }
  
  ngOnInit(): void {
    if (this.data.attributes.absender !== undefined) {
      this.projektForm.get('absender').setValue(this.data.attributes.absender);
    }
    if (this.data.attributes.absenden !== undefined && this.data.attributes.absenden !== null) {
      this.projektForm.get('absenden').setValue(this.data.attributes.absenden.value);
    }
    if (this.data.attributes.zuweisung !== undefined
        && this.data.attributes.zuweisung !== null) {
      this.projektForm.get('zuweisung').setValue(this.data.attributes.zuweisung.value);
    }
    if (this.data.attributes.entwurf_benachrichtigung !== undefined
        && this.data.attributes.entwurf_benachrichtigung !== null) {
      this.projektForm.get('entwurf_benachrichtigung').setValue(this.data.attributes.entwurf_benachrichtigung.value);
    }
    this.projektForm.get('absender').valueChanges.subscribe((value: string) => {
      this.data.attributes.absender = value;
    });
    this.projektForm.get('absenden').valueChanges.subscribe((value: string) => {
      this.data.attributes.absenden = {
        value: value,
      };
    });
    this.projektForm.get('zuweisung').valueChanges.subscribe((value: string) => {
      this.data.attributes.zuweisung = {
        value: value,
      };
    });
    this.projektForm.get('entwurf_benachrichtigung').valueChanges.subscribe((value: string) => {
      this.data.attributes.entwurf_benachrichtigung = {
        value: value,
      };
    });
    this.projektForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.emptySettings$ = combineLatest([
      this.projektForm.get('absenden').valueChanges.pipe(startWith(this.data.attributes.absenden ? this.data.attributes.absenden.value : '')),
      this.projektForm.get('zuweisung').valueChanges.pipe(startWith(this.data.attributes.zuweisung ? this.data.attributes.zuweisung.value : '')),
      this.projektForm.get('entwurf_benachrichtigung').valueChanges.pipe(startWith(this.data.attributes.entwurf_benachrichtigung ? this.data.attributes.entwurf_benachrichtigung.value : '')),
    ]).pipe(
      map(([absenden, zuweisung, entwurf]) => {
        if (absenden === '' && zuweisung === '' && entwurf === '') {
          if (this.projekte.length == 0) {
            this.ProjektService.getAllObservable().subscribe(projekte => {
              this.projekte = projekte;
            });
          }
          return true;
        }
        else {
          return false;
        }
      }),
    );
  }
  
  copyFromProjekt(projekt: Projekt): void {
    if (projekt.attributes.absenden !== undefined && projekt.attributes.absenden !== null) {
      this.projektForm.get('absenden').setValue(projekt.attributes.absenden.value);
    }
    if (projekt.attributes.zuweisung !== undefined
        && projekt.attributes.zuweisung !== null) {
      this.projektForm.get('zuweisung').setValue(projekt.attributes.zuweisung.value);
    }
    if (projekt.attributes.entwurf_benachrichtigung !== undefined
        && projekt.attributes.entwurf_benachrichtigung !== null) {
      this.projektForm.get('entwurf_benachrichtigung').setValue(projekt.attributes.entwurf_benachrichtigung.value);
    }
  }
  
}