/**
 * Aktive-Abschnitt-Komponent
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, OnDestroy, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

import {AbschnittService} from '../../../services/abschnitt.service';
import {Abschnitt} from '../../../model/abschnitt';
import {ProjektService} from '../../../services/projekt.service';
import {Projekt} from '../../../model/projekt';

@Component({
  selector: 'abschnitt',
  templateUrl: './abschnitt.component.html',
})
export class AbschnittComponent implements OnDestroy {
    
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    setTimeout(() => {
      this.scroll = window.pageYOffset 
        || document.documentElement.scrollTop 
        || document.body.scrollTop || 0;
    }, 250);
  }
  abschnitt: Abschnitt = null
  projektSubscription: Subscription
  abschnittSubscription: Subscription
  fragmentSubscription: Subscription;
  fragment: string = null
  projekt: Projekt = null
  isLoading: boolean = false
  loadError: string = null
  scroll: number = 0
  
  constructor(route: ActivatedRoute,
                private abschnittService: AbschnittService,
                private projektService: ProjektService) {
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      this.projekt = projekt;
      this.loadByFragment();
    });
    this.abschnittSubscription = abschnittService.activeAbschnitt.subscribe(abschnitt => {
      if (abschnitt !== null && this.fragment === null) {
        this.abschnitt = abschnitt;
        this.loadAbschnitt();
      }
    });
    this.fragmentSubscription = route.fragment.subscribe(fragment => {
      if (fragment === undefined) {
        this.fragment = null;
      }
      else {
        this.fragment = fragment;
      }
      this.loadByFragment();
    });
  }

  loadAbschnitt(): void {
    this.isLoading = true;
    this.loadError = null;
    this.abschnittService.getExtended(this.abschnitt.id).subscribe(abschnitt => {
      this.abschnitt = abschnitt;
      this.isLoading = false;
      this.loadError = null;
    }, (error: string) => {
      this.loadError = error;
      this.isLoading = false;
    });
  }
  
  ngOnDestroy(): void {
    this.projektSubscription.unsubscribe();
    this.abschnittSubscription.unsubscribe();
    this.fragmentSubscription.unsubscribe();
  }
  
  loadByFragment(): void {
    if (this.fragment !== null && this.projekt !== null) {
      this.projektService.getByFragment(this.projekt, this.fragment.replace('antrag-', '')).subscribe(abschnitt => {
        this.abschnittService.getExtended(abschnitt.id).subscribe(response => {
          this.abschnitt = response;
          this.abschnittService.setActiveAbschnitt(response);
          setTimeout(() => {
            this.abschnittService.setActiveAbschnitt(response);
          }, 1000);
        });
      });
    }
  } 
   
}