/**
 * Offene Anträge
 * 
 * Attila Németh, UBG
 * 03.06.2020
 */
 
import {Component, Input, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Sort} from '@angular/material/sort';

import {Projekt} from '../../../model/projekt';
import {ProjektService, OffeneParams} from '../../../services/projekt.service';
import {Antrag} from '../../../model/antrag';
import {AntragViewDialogComponent} from '../dialog/view/view.component';
import {AntragService} from '../../../services/antrag.service';
import {AntragZuweisungDialogComponent} from '../dialog/zuweisung/zuweisung.component';

@Component({
  selector: 'antrag-offene',
  templateUrl: './offene.component.html',
  styleUrls: ['./offene.component.scss'],
})
export class AntragOffeneComponent implements OnDestroy {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
      this.loadAntraege()
    }
  }
  projektObject: Projekt = null
  antraege: Array<Antrag> = []
  columns: Array<string> = ['label', 'open', 'gliederung', 'goto', 'title', 'eingangsdatum', 
          'zuweisung', 'zuweisung_button', 'votum']
  updateSubscription: Subscription = null
  hasNew: boolean = false
  lastUpdate: number = null
  offeneParams: OffeneParams = {
    zuweisung: false,
    votum: false
  }
  isLoading: boolean = false
  loadError: string = null
    
  constructor(private router: Router,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                private antragService: AntragService) {}
  
  loadAntraege(): void {
    this.isLoading = true;
    this.projektService.getOffeneAntraege(this.projektObject, this.offeneParams).subscribe(antraege => {
      this.antraege = antraege;
      this.hasNew = false;
      this.updateSubscription = this.antragService.lastAntragUpdate.subscribe((value) => {
        if (value !== null) {
          if (this.lastUpdate !== null && value > this.lastUpdate) {
            this.hasNew = true;
          }
          this.lastUpdate = value;
        }
      });
      this.isLoading = false;
      this.loadError = null;
    }, (error: string) => {
      this.isLoading = false;
      this.loadError = error;
    });
  }
  
  ngOnDestroy(): void {
    if (this.updateSubscription !== null) {
      this.updateSubscription.unsubscribe();
    }
  }
  
  openAntrag(antrag: Antrag): void {
    this.dialog.open(AntragViewDialogComponent, {
      data: antrag,
    });
  }
  
  zuweisungAntrag(antrag: Antrag): void {
    this.antragService.getAntrag(antrag.type, antrag.id).subscribe(antrag => {
      this.dialog.open(AntragZuweisungDialogComponent, {
        width: '720px',
        data: antrag
      }).afterClosed().subscribe(result => {
        if (result !== '0') {
          this.antragService.getAntrag(antrag.type, antrag.id).subscribe(antrag => {
            antrag.relationships.zuweisung = result.relationships.zuweisung;
            this.antragService.updateAntrag(antrag).subscribe(antrag => {
              this.antragService.setBearbeitung(antrag).subscribe(() => {
                this.loadAntraege();
              });
            }, (error: string) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          });
        }
      });
    });
  }
  
  changeZuweisung(event: MatCheckboxChange) {
    this.offeneParams.zuweisung = event.checked;
    this.loadAntraege();
  }
  
  changeVotum(event: MatCheckboxChange) {
    this.offeneParams.votum = event.checked;
    this.loadAntraege();
  }
  
  gotoAntrag(antrag: Antrag): void {
    this.router.navigate(['projekt/' + this.projektObject.id + '/antraege'], {
      fragment: 'antrag-' + antrag.id,
    })
  }
  
  printAntraege(): void {
    this.projektService.printOffeneAntraege(this.projektObject, this.offeneParams).subscribe((response: Blob) => {
      let fileName = 'alle_antraege.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }

  sort(event: Sort): void {
    let sortedAntraege: Array<Antrag> = this.antraege.sort((antrag1: Antrag, antrag2: Antrag): number => {
      let values: Array<string> = [];
      let response: number = 0;
      switch(event.active) {
        case 'label':
          values.push(antrag1.attributes.label.replace(/([0-9]+)$/, (antrag1.attributes.nummer + 1000000).toString()));
          values.push(antrag2.attributes.label.replace(/([0-9]+)$/, (antrag2.attributes.nummer + 1000000).toString()));
          break;
        case 'gliederung':
          values.push(antrag1.attributes.gliederungen);
          values.push(antrag2.attributes.gliederungen);
          break;
        case 'zuweisung':
          values.push(antrag1.attributes.zuweisungen);
          values.push(antrag2.attributes.zuweisungen);
          break;
        case 'eingangsdatum':
          values.push(antrag1.attributes.eingangsdatum);
          values.push(antrag2.attributes.eingangsdatum);
          break;
        default:
          console.error('Unbekannte Sortierung', event.active);
          return 0;
      }
      if (values[0] < values[1]) {
        response = 1;
      }
      else {
        response = -1;
      }
      if (event.direction === 'desc') {
        response = response * -1;
      }
      return response;
    });
    this.antraege = [];
    for (let i in sortedAntraege) {
      this.antraege.push(sortedAntraege[i]);
    }
  }

  getAntragClass(antrag: Antrag): string {
    const now: Date = new Date;
    if (now.getDay() === 1) {
      now.setDate(now.getDate() - 3);
    }
    else {
      now.setDate(now.getDate() - 1);
    }
    const changed: Date = new Date(antrag.attributes.changed);
    const eingang: Date = new Date(antrag.attributes.eingangsdatum);
    if (eingang > now) {
      return 'antrag-recent';
    }
    else if (changed > now) {
      return 'antrag-changed';
    }
    else {
      return null;
    }
  }
  
}