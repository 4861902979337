/**
 * Votum löschen Dialog
 * 
 * Attila Németh, UBG
 * 17.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';

@Component({
  templateUrl: './votum_delete.component.html',
})
export class VotumDeleteDialogComponent implements OnInit {
  
  hasBegruendung: boolean = false
  deleteBegruendung: number = 0
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag,
                private antragService: AntragService) {
  }
  
  ngOnInit(): void {
    this.antragService.getVotumBegruendung(this.data).subscribe(begruendung => {
      this.hasBegruendung = true;
      this.deleteBegruendung = -1;
    }, (error) => {
      this.hasBegruendung = false;
      this.deleteBegruendung = -1;
    });
  }
  
  begruendungChanged(event: MatCheckboxChange): void {
    if (event.checked) {
      this.deleteBegruendung = 1;
    }
    else {
      this.deleteBegruendung = -1;
    }
  }
  
}