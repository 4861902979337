/**
 * Abschnitte
 * 
 * Attila Németh, UBG
 * 24.04.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {Abschnitt} from '../../../model/abschnitt';
import {AbschnittEditDialogComponent} from '../../abschnitt/dialog/edit.component';
import {AbschnittService} from '../../../services/abschnitt.service';
import {ProjektService} from '../../../services/projekt.service';
import { ConfirmComponent } from '@ubg/confirm';

@Component({
  selector: 'projekt-abschnitte',
  templateUrl: './abschnitte.component.html',
  styleUrls: ['./abschnitte.component.scss'],
})
export class ProjektAbschnitteComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
      this.loadAbschnitte();
    }
  }
  projektObject: Projekt = null
  abschnitte: Array<Abschnitt> = []
  
  displayedColumns: Array<string> = ['buchstabe', 'title', 'sortierung', 'operations']
 
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private abschnittService: AbschnittService,
                private projektService: ProjektService) {}
  
  loadAbschnitte(): void {
    this.abschnitte = [];
    this.projektService.getAbschnitte(this.projektObject).subscribe((response: any) => {
      this.abschnitte = response;
    });
  }
                
  addAbschnitt(): void {
    const abschnitt = new Abschnitt;
    abschnitt.create();
    this.dialog.open(AbschnittEditDialogComponent, {
      width: '480px',
      data: {
        abschnitt: abschnitt
      },
    }).afterClosed().subscribe((editedAbschnitt: any) => {
      if (editedAbschnitt !== '0') {
        this.abschnittService.create(editedAbschnitt).subscribe((response: {
          data: Abschnitt
        }) => {
          let newAbschnitte: Array<Abschnitt> = [];
          for (let i in this.projektObject.relationships.abschnitte.data) {
            newAbschnitte.push(this.projektObject.relationships.abschnitte.data[i]);
          }
          newAbschnitte.push(response.data);
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            response.data.relationships.abschnitte.data = newAbschnitte;
            this.projektService.update(response.data).subscribe((response: {
              data: Projekt
            }) => {
              this.projektObject = response.data;
              this.loadAbschnitte();
              this.snackbar.open('Der Buchstabe wurde hinzugefügt', null, {
                duration: 8000,
              });
            }, (error) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  editAbschnitt(abschnitt: Abschnitt): void {
    this.dialog.open(AbschnittEditDialogComponent, {
      width: '480px',
      data: {
        abschnitt: abschnitt
      },
    }).afterClosed().subscribe((editedAbschnitt: any) => {
      if (editedAbschnitt !== '0') {
        this.abschnittService.update(editedAbschnitt).subscribe(() => {
          this.loadAbschnitte();
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }

  deleteAbschnitt(abschnitt: Abschnitt): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie den Buchstaben ' + abschnitt.attributes.buchstabe + ' endgültig löschen?',
      },
    }).afterClosed().subscribe(response => {
      if (response == 1) {
        let newAbschnitte: Array<Abschnitt> = this.projektObject.relationships.abschnitte.data.filter(projektAbschnitt => projektAbschnitt.id !== abschnitt.id);
        this.projektObject.relationships.abschnitte.data = newAbschnitte;
        this.projektService.update(this.projektObject).subscribe((response: {
          data: Projekt
        }) => {
          this.projektObject = response.data;
          this.loadAbschnitte();
          this.snackbar.open('Der Buchstabe wurde entfernt', null, {
            duration: 8000,
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    }); 
  }
     
}