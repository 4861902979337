/**
 * Antrag-Zuweisung-Dialog
 * 
 * Attila Németh, UBG
 * 04.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Antrag} from '../../../../model/antrag';
import {User} from '../../../../model/user';
import {AntragService} from '../../../../services/antrag.service';

@Component({
  templateUrl: './zuweisung.component.html',
  styleUrls: ['./zuweisung.component.scss'],
})
export class AntragZuweisungDialogComponent implements OnInit {
  
  zuweisungForm: FormGroup
  zuweisungOptions: Array<User> = []
  zuweisungFilteredOptions: Array<User> = []
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag,
                formBuilder: FormBuilder,
                private antragService: AntragService) {
    this.zuweisungForm = formBuilder.group({
      zuweisung: [null],
    });
  }
  
  ngOnInit(): void {
    this.loadOptions();
    this.zuweisungForm.get('zuweisung').valueChanges.subscribe((value: string) => {
      if (value !== null) {
        let newOptions: Array<User> = [];
        let found: boolean;
        const lowerValue: string = value.toLowerCase();
        for (let i in this.zuweisungOptions) {
          if (this.zuweisungOptions[i].attributes.vorname.toLowerCase().indexOf(lowerValue) >= 0
                || this.zuweisungOptions[i].attributes.nachname.toLowerCase().indexOf(lowerValue) >= 0) {
            found = false;
            if (this.data.relationships.zuweisung !== undefined) {
              for (let j in this.data.relationships.zuweisung.data) {
                if (this.data.relationships.zuweisung.data[j].id === this.zuweisungOptions[i].id) {
                  found = true;
                }
              }
            }
            if (!found) {
              newOptions.push(this.zuweisungOptions[i]);
            }
          }
        }
        this.zuweisungFilteredOptions = newOptions;
      }
    });
  }
  
  loadOptions(): void {
    this.antragService.getZuweisungUsers(this.data).subscribe(users => {
      this.zuweisungOptions = users;
      this.zuweisungFilteredOptions = users;
      let newZuweisungen: Array<User> = [];
      for (let i in this.data.relationships.zuweisung.data) {
        for (let j in this.zuweisungOptions) {
          if (this.data.relationships.zuweisung.data[i].id === this.zuweisungOptions[j].id) {
            newZuweisungen.push(this.zuweisungOptions[j]);
          }
        }
      }
      this.data.relationships.zuweisung.data = newZuweisungen;
    });
  }
  
  zuweisungSelected(event: MatAutocompleteSelectedEvent) {
    let newZuweisungen: Array<User> = [];
    if (this.data.relationships.zuweisung !== undefined) {
      for (let i in this.data.relationships.zuweisung.data) {
        newZuweisungen.push(this.data.relationships.zuweisung.data[i]);
      }
    }
    for (let i in this.zuweisungOptions) {
      if (this.zuweisungOptions[i].id == event.option.value) {
        newZuweisungen.push(this.zuweisungOptions[i]);
      }
    }
    this.data.relationships.zuweisung.data = newZuweisungen;
    this.zuweisungForm.get('zuweisung').reset(null);
  }
  
  removeZuweisung(user: User): void {
    let newZuweisungen: Array<User> = [];
    for (let i in this.data.relationships.zuweisung.data) {
      if (this.data.relationships.zuweisung.data[i].id !== user.id) {
        newZuweisungen.push(this.data.relationships.zuweisung.data[i]);
      }
    }
    this.data.relationships.zuweisung.data = newZuweisungen;
  }
              
}