/**
 * Projekt Kopfbereich-Menü
 * 
 * Attila Németh, UBG
 * 24.04.2020
 */
 
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {UserService} from '../../../services/user.service';
import {PageService} from '../../../services/page.service';
import {CookieService} from '@ubg/cookie-service';

@Component({
  selector: 'projekt-header',
  templateUrl: './header.component.html',
})
export class ProjektHeaderComponent implements OnInit, OnDestroy {
  
  projekt: Projekt = null
  projekte: Array<Projekt> = []
  projektActivePage: string = null
  viewAccess: boolean = false;
  viewAccessSubscription: Subscription = null
  pageRouteSubscrpition: Subscription = null
  backRoute: string = null
  projektSubscription: Subscription
  isGliederung: boolean = false
  routerSubscription: Subscription
  isMeinAntrag: boolean = false
 
  constructor(private router: Router,
                private projektService: ProjektService,
                private cookieService: CookieService,
                private userService: UserService,
                private pageService: PageService) {}
  
  ngOnInit(): void {
    this.userService.userLoggedIn.subscribe((status: number) => {
      if (status === 0) {
        this.projekte = [];
      }
      else {
        this.projektService.getAll().subscribe((projekte: {
          data: Array<Projekt>
        }) => {
          let newProjekte: Array<Projekt> = [];
          for (let i in projekte.data) {
            if (projekte.data[i].attributes.aktiv) {
              newProjekte.push(projekte.data[i]);
            }
          }
          this.projekte = newProjekte;
        });
      }
    });
    this.projektService.activeProjekt.subscribe((projekt: Projekt) => {
      this.projekt = projekt;
      if (this.viewAccessSubscription !== null) {
        this.viewAccessSubscription.unsubscribe();
      }
      if (this.projekt !== null) {
        this.viewAccessSubscription = this.projektService.viewPermission(this.projekt).subscribe(access => {
          this.viewAccess = access;
        });
      }
    });
    this.projektService.activePage.subscribe((name: string) => {
      this.projektActivePage = name;
    });
    this.pageRouteSubscrpition = this.pageService.backRoute.subscribe(route => {
      this.backRoute = route;
    });
    this.projektSubscription = this.projektService.allProjekt.subscribe((projekte) => {
      if (projekte !== null) {
        this.projekte = projekte;
      }
    });
    if (this.cookieService.get('gliederung') !== undefined && this.cookieService.get('gliederung') !== null) {
      this.isGliederung = true;
    }
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.match(/\/mein\-antrag$/g)) {
          this.isMeinAntrag = true;
        }
        else {
          this.isMeinAntrag = false;
        }
      }
    });
  }
  
  ngOnDestroy(): void {
    this.viewAccessSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
  
  gotoProjekt(projekt: Projekt): void {
    if (this.projekt.id !== projekt.id) {
      this.router.navigate(['projekt/' + projekt.id]);
    }
    else {
      this.pageService.setHome();
    }
  }
  
  gotoProjektHome(): void {
    this.projektService.closePage();
  }
  
  goBack(): void {
    this.router.navigate([this.backRoute]);
    this.pageService.setBackRoute(null);
  }
  
  gotoMeinHome(): void {
    this.projektService.closePage();
  }
  
}

 
