/**
 * Statistik-Element
 * 
 * Attila Németh, UBG
 * 03.06.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../../model/projekt';
import {ProjektService} from '../../../../services/projekt.service';
import {StatHeader, StatHeaderElement} from '../../../../model/stat';

interface DetailsData {
  data: Array<{
    values: {}
  }>  
}

@Component({
  selector: 'projekt-stat-element',
  templateUrl: './element.component.html',
  styleUrls: ['../stat.component.scss'],
})
export class ProjektStatElementComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
      this.reload();
    }
  }
  projektObject: Projekt = null
  @Input() set stat(stat: string) {
    this.statString = stat;
    this.reload();
  }
  @Input() details: string = null
  statString: string = null
  header: StatHeader = null
  classes: Array<string> = ['teal', 'purple', 'orange', 'blue', 'light-green', 'yellow']
  detailsColumns: Array<string> = []
  detailsData: DetailsData = null
  
  constructor(private snackbar: MatSnackBar,
                private projektService: ProjektService) {}
  
  reload(): void {
    if (this.projektObject !== null && this.statString !== null) {
      this.projektService.getStatistik(this.projektObject, this.statString).subscribe(result => {
        this.header = result;
      });
    }
  }
  
  loadDetails(): void {
    if (this.projektObject !== null && this.statString !== null) {
      this.projektService.getStatistik(this.projektObject, this.statString, true).subscribe((result: DetailsData) => {
        let columns = {};
        for (let i in result.data) {
          for (let key in result.data[i].values) {
            columns[key] = true;
          }
        }
        this.detailsColumns = [];
        for (let key in columns) {
          this.detailsColumns.push(key);
        }
        this.detailsData = result;
      });
    }
  }
  
  toggleDetails(): void {
    if (this.detailsData === null) {
      this.loadDetails();
    }
    else {
      this.detailsData = null;
    }
  }
  
  getClass(element: StatHeaderElement, index: number): string {
    if (element.color === undefined) {
      return 'color-' + this.classes[index];
    }
    else {
      return 'color-' + element.color;
    }
  }
  
  printStat() {
    this.projektService.printStatistik(this.projektObject, this.statString).subscribe((response: Blob) => {
      let fileName = 'stat-' + this.stat + '.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
}