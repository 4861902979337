/**
 * Redner bearbeiten Dialog
 * 
 * Attila Németh, UBG
 * 07.05.2020
 */
 
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Redner} from '../../../../model/redner';

@Component({
  templateUrl: './edit.component.html',
})
export class RednerEditDialogComponent {
  
  disabled: boolean = false
  editForm: FormGroup
  
  constructor(formBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA)
              public data: {
                redner: Redner
              }) {
    this.editForm = formBuilder.group({
      title: [this.data.redner.attributes.title, Validators.required],
    });
    this.editForm.statusChanges.subscribe((status: string) => {
      if (status == 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.editForm.get('title').valueChanges.subscribe((value: string) => {
      this.data.redner.attributes.title = value;
    });
  }
              
}