/**
 * Antrag ohne Leitantrag bearbeiten
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, Output, EventEmitter, forwardRef, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';

import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {Gliederung} from '../../../../model/gliederung';
import {GliederungService} from '../../../../services/gliederung.service';

const noop = () => {};

@Component({
  selector: 'antrag-ohne-leitantrag-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['../../edit.component.scss'], 
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AntragOhneLeitantragEditComponent),
    multi: true
  }]
})
export class AntragOhneLeitantragEditComponent implements OnInit {
 
  @Output() validityChange = new EventEmitter
  antrag: Antrag = null
  antragForm: FormGroup
  gliederungen: Array<Gliederung> = []
  hasAntragstellerName: boolean = false
   
  constructor(formBuilder: FormBuilder,
              private glierderungService: GliederungService) {
    this.antragForm = formBuilder.group({
      title: [null, Validators.required],
      gliederung: [null, GliederungSelectedValidator],
      antragsteller: [null],
      inhalt: [null, Validators.required],
      ohne_begruendung: [null],
      begruendung: [null, Validators.required],
    });
    this.antragForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.antragForm.get('title').valueChanges.subscribe(value => {
      this.antrag.attributes.title = value;
    });
    this.antragForm.get('gliederung').valueChanges.subscribe((value: Array<Gliederung>) => {
      if (this.antrag.relationships === undefined) {
        this.antrag.relationships = {};
      }
      this.antrag.relationships.gliederung = {
        data: value,
      };
      this.updateAntragstellerName();
    });
    this.antragForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.inhalt = {
        value: value,
      };
    });
    this.antragForm.get('begruendung').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.begruendung = {
        value: value,
      };
    });
    this.antragForm.get('antragsteller').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.antragsteller = value;
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Antrag {
    return this.antrag;
  };

  set value(v: Antrag) {
    this.antrag = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
    this.updateAntragstellerName();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Antrag) {
    if (value !== null) {
      this.antrag = value;
      this.updateFormFromValue();
      this.updateAntragstellerName();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.antrag);
  }
  
  ngOnInit(): void {
  }
  
  updateFormFromValue(): void {
    this.antragForm.get('title').setValue(this.antrag.attributes.title);
    this.antragForm.get('gliederung').setValue(this.antrag.relationships.gliederung.data);
    this.antragForm.get('inhalt').setValue(this.antrag.attributes.inhalt.value);
    this.antragForm.get('ohne_begruendung').setValue(this.antrag.attributes.ohne_begruendung);
    if (this.antrag.attributes.ohne_begruendung == 1) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
    }
    if (this.antrag.attributes.begruendung !== undefined 
      && this.antrag.attributes.begruendung !== null) {
      this.antragForm.get('begruendung').setValue(this.antrag.attributes.begruendung.value);
    }
    for (let i in this.antrag.relationships.gliederung.data) {
      if (this.antrag.relationships.gliederung.data[i].attributes === undefined) {
        this.glierderungService.getGliederung(this.antrag.relationships.gliederung.data[i].id).subscribe(gliederung => {
          let newGliederungen: Array<Gliederung> = [];
          for (let i in this.antrag.relationships.gliederung.data) {
            if (this.antrag.relationships.gliederung.data[i].id === gliederung.id) {
              newGliederungen.push(gliederung);
            }
            else {
              newGliederungen.push(this.antrag.relationships.gliederung.data[i]);
            }
          }
          this.antrag.relationships.gliederung.data = newGliederungen;
          this.updateAntragstellerName();
        });
      }
    }
    if (this.antrag.attributes.antragsteller !== undefined) {
      this.antragForm.get('antragsteller').setValue(this.antrag.attributes.antragsteller);
    }
  }
  
  ohneBegruendungChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.antrag.attributes.ohne_begruendung = 1;
    }
    else {
      this.antrag.attributes.ohne_begruendung = 0;
    }
    this.onChangeCallback(this.antrag);
    if (this.antrag.attributes.ohne_begruendung) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
      if (this.antrag.attributes.begruendung === null 
        || this.antrag.attributes.begruendung.value === '') {
        this.antragForm.get('begruendung').setErrors({
          required: true,
        });
      }
    }
  }

  updateAntragstellerName(): void {
    let as: boolean = false;
    for (let i in this.antrag.relationships.gliederung.data) {      
      if (this.antrag.relationships.gliederung.data[i].attributes !== undefined 
        && this.antrag.relationships.gliederung.data[i].attributes.freier_name) {
        as = true;
      }
    }
    this.hasAntragstellerName = as;
    if (as) {
      this.antragForm.get('antragsteller').setValidators([Validators.required]);
    }
    else {
      this.antragForm.get('antragsteller').setValidators(null);
    }
  }
   
}

interface ValidatorFn {    
  (c: AbstractControl): ValidationErrors | null 
}
const GliederungSelectedValidator: ValidatorFn = (formControl: FormControl) => {
  let errors = {};
  if (formControl.value === null) {
    errors['required'] = true;
  }
  else if (formControl.value['length'] === 0) {
    errors['required'] = true;
  }
  return errors;
};