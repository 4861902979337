/**
 * Antrag-Drucken-Dialog
 * 
 * Attila Németh, UBG
 * 15.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {AntragService, AntragPrintOptions} from '../../../../services/antrag.service';

@Component({
  templateUrl: './print.component.html',
})
export class AntragPrintDialogComponent implements OnInit {
  
  printOptions: AntragPrintOptions = {
    mitVotum: false,
    mitBegruendungAntragsteller: false,
    mitBegruendungKommission: false,
    nurBegruendungKommission: false,
    mitMarkup: false,
    mitRedner: false,
  }
  printAccess: AntragPrintOptions = {
    mitVotum: false,
    mitBegruendungAntragsteller: false,
    mitBegruendungKommission: false,
    nurBegruendungKommission: false,
    mitMarkup: false,
    mitRedner: false,
  }
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag,
                private antragService: AntragService) {
  }
  
  ngOnInit(): void {
    this.antragService.getVoten(this.data).subscribe(voten => {
      if (voten.length > 0) {
        this.printAccess.mitVotum = true;
      }
      else {
        this.printAccess.mitVotum = false;
      }
      for (let i in voten) {
        if (voten[i].attributes.fassung_text !== undefined) {
          this.printAccess.mitMarkup = true;
        }
        if (voten[i].relationships.redner_der_ak !== undefined 
          && voten[i].relationships.redner_der_ak.data.length > 0) {
          this.printAccess.mitRedner = true;
        }
        else {
          this.printAccess.mitRedner = false;
        }
      }
    });
    this.setAccess();
  }
  
  setOption(name: string, event: MatCheckboxChange): void {
    this.printOptions[name] = event.checked;
    if (name === 'nurBegruendungKommission') {
      if (event.checked) {
        this.printAccess = {
          mitVotum: false,
          mitBegruendungAntragsteller: false,
          mitBegruendungKommission: false,
          nurBegruendungKommission: true,
          mitMarkup: false,
          mitRedner: false
        };
        this.printOptions = {
          mitVotum: false,
          mitBegruendungAntragsteller: false,
          mitBegruendungKommission: false,
          nurBegruendungKommission: true,
          mitMarkup: false,
          mitRedner: false
        };
      }
      else {
        this.setAccess();
      }
    }
  }
  
  setAccess(): void {
    this.antragService.getVotumBegruendung(this.data).subscribe(begruendung => {
      this.printAccess.mitBegruendungKommission = true;
      this.printAccess.nurBegruendungKommission = true;
    }, (error) => {
      this.printAccess.mitBegruendungKommission = false;
      this.printAccess.nurBegruendungKommission = false;
    });
    if (this.data.attributes.begruendung !== undefined && this.data.attributes.begruendung.value !== null)  {
      this.printAccess.mitBegruendungAntragsteller = true;
    }
    else {
      this.printAccess.mitBegruendungAntragsteller = false;
    }
  }
  
}