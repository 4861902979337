<user-content>
  
  <div class="layout-margin">
    <button mat-raised-button color="accent" (click)="addProjekt()" *ngIf="createAccess">
      <mat-icon>add</mat-icon> Neues Projekt hinzufügen
    </button>
    <table mat-table [dataSource]="projekte" class="mat-elevation-z2" *ngIf="projekte.length > 0">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel </th>
        <td mat-cell *matCellDef="let projekt"> {{ projekt.attributes.title }} </td>
      </ng-container>
      <ng-container matColumnDef="aktiv">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let projekt">
          <mat-icon class="aktiv-1" *ngIf="projekt.attributes.aktiv">check_circle</mat-icon>
          <mat-icon class="aktiv-0" *ngIf="!projekt.attributes.aktiv">cancel</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let projekt">
          <button mat-button (click)="editProjekt(projekt)" 
                  [disabled]="!projektEditable(projekt)"
                  color="primary" matTooltip="Bearbeiten">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-button (click)="openProjekt(projekt)" 
                  color="primary" matTooltip="Öffnen" [disabled]="!projekt.attributes.aktiv">
            <mat-icon>open_in_browser</mat-icon>
          </button>
          <button mat-button (click)="cloneProjekt(projekt)" 
                  color="accent" matTooltip="Klonen" *ngIf="projektEditable(projekt)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </td>
      </ng-container>
    </table>

    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isCloning"></mat-progress-bar>

  </div>
  
</user-content>