/**
 * Markup
 * 
 * Attila Németh, UBG
 * 25.06.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './markup.component.html',
  styleUrls: ['./markup.component.scss'],
})
export class AntragMarkupDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: string) {
  }
  
}