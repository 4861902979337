/**
 * Password ändern Komponent
 * 
 * Attila Németh, UBG
 * 04.05.2020
 */
 
import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors} from '@angular/forms'

export interface Passwords {
  current: string
  new: string
  confirm: string
}

@Component({
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class UserPasswordDialogComponent {
  
  disabled: boolean = true
  passwords: Passwords = null
  passwordForm: FormGroup = null
  
  constructor(private formBuilder: FormBuilder) {
    this.passwords = {
      current: null,
      new: null,
      confirm: null,
    };
    this.passwordForm = this.formBuilder.group({
      current: [null, Validators.required],
      new: [null, Validators.required],
      confirm: [null, Validators.required],
    }, {
      validator: PasswordCheckValidator,
    });
    this.passwordForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.passwordForm.get('current').valueChanges.subscribe((value: string) => {
      this.passwords.current = value;
    });
    this.passwordForm.get('new').valueChanges.subscribe((value: string) => {
      this.passwords.new = value;
    });
    this.passwordForm.get('confirm').valueChanges.subscribe((value: string) => {
      this.passwords.confirm = value;
    });
  }

}

interface ValidatorFn {    
  (c: AbstractControl): ValidationErrors | null 
}
const PasswordCheckValidator: ValidatorFn = (formGroup: FormGroup) => {
  let errors = {};
  if (formGroup.get('new').value !== formGroup.get('confirm').value) {
    errors['samePassword'] = true;
  }
  return errors;
};