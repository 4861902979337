/**
 * Seiten-Service
 * 
 * Attila Németh, UBG
 * 23.04.2020
 */
 
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  
  private headerTitleData: BehaviorSubject<string> = new BehaviorSubject('Antragswesen');
  public headerTitle: Observable<string> = this.headerTitleData.asObservable();
  private backRouteData: BehaviorSubject<string> = new BehaviorSubject(null);
  public backRoute: Observable<string> = this.backRouteData.asObservable();
  private homeClickData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}
  
  setTitle(title: string) {
    this.headerTitleData.next(title);
  }
  
  resetTitle(): void {
    this.headerTitleData.next(null);
  }
  
  setBackRoute(route: string): void {
    this.backRouteData.next(route);
  }
  
  setHome(): void {
    this.homeClickData.next(true);
    this.homeClickData.next(false);
  }
  
  homeClicked(): Observable<boolean> {
    return this.homeClickData.asObservable();
  }
  
}