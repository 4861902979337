/**
 * Beschluss
 * 
 * Attila Németh, UBG
 * 30.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {PageService} from '../../../../services/page.service';
import {Abschnitt, AbschnittBeschluss} from '../../../../model/abschnitt';
import {AbschnittService} from '../../../../services/abschnitt.service';

@Component({
  templateUrl: './beschluss.component.html',
})
export class AbschnittBeschlussDialogComponent implements OnInit {
  
  title: string = ''
  beschluss: string = ''
  isLoading = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                  projektId: string
                  abschnitt: Abschnitt
                },
                private dialogRef: MatDialogRef<AbschnittBeschlussDialogComponent>,
                private abschnittService: AbschnittService,
                private snackbar: MatSnackBar,
                private pageService: PageService) {
  }
  
  ngOnInit(): void {
    this.loadBeschluss();
  }
  
  loadBeschluss(): void {
    this.isLoading = true;
    this.abschnittService.getBeschluss(this.data.abschnitt).subscribe(beschluss => {
      this.isLoading = false;
      this.beschluss = beschluss.text;
      this.title = beschluss.title;
    }, error => {
      this.snackbar.open(error, null, {
        duration: 15000,
      })
      this.isLoading = false;
      this.dialogRef.close();
    });
  }
  
  printBeschluss(): void {
    this.abschnittService.printBeschluss(this.data.abschnitt).subscribe((response: Blob) => {
      let fileName = 'beschluss-' + this.data.abschnitt.attributes.buchstabe + '.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }
  
  
//  printBeschluss(): void {
//    this.projektService.printBeschluss(this.projekt).subscribe((response: Blob) => {
//      let fileName = 'beschluss.docx';
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } 
//      else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    });
//  }
//  
//  beschlussProtokoll(name: string): void {
//    this.projektService.printBeschlussProtokoll(this.projekt, name).subscribe((response: Blob) => {
//      let fileName = 'protokoll-' + name + '.docx';
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } 
//      else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    }, (error: string) => {
//      this.snackbar.open(error, null, {
//        duration: 15000,
//      });
//    });
//  }

}
