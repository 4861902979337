/**
 * Projekt bearbeiten
 * 
 * Attila Németh, UBG
 * 23.04.2020
 */
 
import {Component, Output, EventEmitter, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Projekt} from '../../../model/projekt';
import {Mandant} from '../../../model/mandant';
import {MandantService} from '../../../services/mandant.service';

const noop = () => {};

@Component({
  selector: 'projekt-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProjektEditComponent),
    multi: true
  }]
})
export class ProjektEditComponent {
  
  @Output() validityChange = new EventEmitter
  projekt: Projekt = null
  focused: boolean = false
  projektForm: FormGroup
  mandants: Array<Mandant> = []

  constructor(private formBuilder: FormBuilder,
                private mandantService: MandantService) {
    this.projekt = new Projekt;
    this.projekt.attributes = {
      title: null,
      aktiv: false,
    };
    this.projektForm = this.formBuilder.group({
      title: [this.projekt.attributes.title, Validators.required],
      aktiv: [this.projekt.attributes.aktiv],
      mandant: [null],
      antragsfrist_anfang: [
        this.projekt.attributes.antragsfrist === undefined ? null : this.projekt.attributes.antragsfrist.value, 
        Validators.required
      ],
      antragsfrist_ende: [
        this.projekt.attributes.antragsfrist === undefined ? null : this.projekt.attributes.antragsfrist.end_value, 
        Validators.required
      ],
      email: [null, [Validators.required, Validators.email]],
    });
    this.projektForm.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else if (status === 'INVALID') {
        this.validityChange.emit(false);
      }
      else {
        console.error('Invalid Form Status', status);
      }
    });
    this.projektForm.valueChanges.subscribe((values: {
      title: string
      aktiv: boolean
      antragsfrist_anfang: string
      antragsfrist_ende: string
      email: string
    }) => {
      let fristA = new Date(values.antragsfrist_anfang);
      let fristE = new Date(values.antragsfrist_ende);
      fristE.setHours(24, 0);
      this.projekt.attributes = {
        title: values.title,
        aktiv: values.aktiv,
        antragsfrist: {
          value: fristA.toISOString().slice(0, 19),
          end_value: fristE.toISOString().slice(0, 19),
        },
        absender: values.email,
      };
    });
    this.projektForm.get('mandant').valueChanges.subscribe((value: string) => {
      for (let i in this.mandants) {
        if (this.mandants[i].id == value) {
          if (this.projekt.relationships === undefined) {
            this.projekt.relationships = {
              mandant: {
                data: null,
              },
            };
          }
          this.projekt.relationships.mandant.data = this.mandants[i];
        }
      }
    });
  }
  
  isValid(): boolean {
    return this.projektForm.valid;
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  ngOnInit() {
    this.mandantService.getAll().subscribe((response: {
      data: Array<Mandant>
    }) => {
      this.mandants = response.data;
      if (this.mandants.length > 1) {
        this.projektForm.get('mandant').setValidators([Validators.required]);
      }
      else {
        this.projektForm.get('mandant').setValidators(null);
      }
    });
  }

  get value(): Projekt {
    return this.projekt;
  };

  set value(v: Projekt) {
    if (v !== this.projekt) {
      this.projekt = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  setFocus() {
    this.focused = true;
  }

  writeValue(value: Projekt) {
    if (value !== this.projekt) {
      this.projekt = value;
      if (this.projekt !== null) {
        let formValues: any = {
          title: this.projekt.attributes.title,
          aktiv: this.projekt.attributes.aktiv,
          mandant: null,
        };
        if (this.projekt.attributes.antragsfrist !== undefined) {
          formValues['antragsfrist_anfang'] = this.projekt.attributes.antragsfrist.value;
          formValues['antragsfrist_ende'] = this.projekt.attributes.antragsfrist.end_value;
        }
        if (this.projekt.relationships !== undefined 
          && this.projekt.relationships.mandant.data !== null) {
          formValues['mandant'] = this.projekt.relationships.mandant.data.id;
        }
        if (this.projekt.attributes.absender !== undefined) {
          formValues['email'] = this.projekt.attributes.absender;
        }
        this.projektForm.patchValue(formValues);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.projekt);
  }

}

 
