<mat-card>
  <mat-card-header>
    <mat-card-title>
      Anmeldung
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="emitLogin()">
      <mat-form-field>
        <input matInput type="password" placeholder="Passwort" formControlName="password">
      </mat-form-field>
      <button mat-raised-button color="primary">Anmelden</button>
    </form>
  </mat-card-content>
</mat-card>