/**
 * Begründung bearbeiten
 * 
 * Attila Németh, UBG
 * 15.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {VotumBegruendung} from '../../../../model/votum';

@Component({
  templateUrl: './begruendung.component.html',
})
export class VotumBegruendungEditDialogComponent implements OnInit {
  
  disabled: boolean = true
  begruendungForm: FormGroup
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: VotumBegruendung,
              formBuilder: FormBuilder) {
    this.begruendungForm = formBuilder.group({
      inhalt: [this.data.attributes.inhalt.value],
    });
  }
  
  ngOnInit(): void {
    this.begruendungForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.begruendungForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.data.attributes.inhalt = {
        value: value,
      };
    });
  }
  
}