import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {AttusBannerModule} from '@attus/banner';

import {AntragswesenModule} from './antragswesen/antragswesen.module';

import {UserPasswordDialogComponent} from './components/dialog/password/password.component';
import {UserSettingsDialogComponent} from './components/dialog/settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    UserPasswordDialogComponent,
    UserSettingsDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    AttusBannerModule,
    AntragswesenModule,
  ],
  providers: [
    {
      provide: LOCALE_ID, 
      useValue: "de-DE" 
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserPasswordDialogComponent,
    UserSettingsDialogComponent,
  ],
})
export class AppModule { }
