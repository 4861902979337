/**
 * Gliederung-Service
 * 
 * Attila Németh, UBG
 * 14.05.2020
 */
 
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Projekt} from '../model/projekt';
import {GliederungKategorie, Gliederung} from '../model/gliederung';
import {CookieService} from '@ubg/cookie-service';

@Injectable({
  providedIn: 'root'
})
export class GliederungService {
  
  kategorieBasePath: string = 'api/ubg_gliederung_kategorie/ubg_gliederung_kategorie'
  basePath: string = 'api/ubg_gliederung/ubg_gliederung';
  
  constructor(private drupal: DrupalService,
              private cookieService: CookieService) {}
  
  upload(projekt: Projekt, file: File) {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/gliederungen/upload';
    return this.drupal.postFile(path, file, httpOptions);
  }
  
  download(projekt: Projekt): Observable<any> {
    let httpOptions = this.drupal.getHttpOptions(['Blob', 'Authentication']);
    const path: string = 'ubg/projekt/' + projekt.id + '/gliederungen/download';
    return this.drupal.get(path, httpOptions);
  }
  
  getKategorie(id: string): Observable<GliederungKategorie> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = this.kategorieBasePath + '/' + id;
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: GliederungKategorie,
      }) => {
        return response.data;
      })
    );
  }
  
  updateKategorie(kategorie: GliederungKategorie): Observable<GliederungKategorie> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = this.kategorieBasePath + '/' + kategorie.id;
    return this.drupal.patch(path, {
      data: kategorie
    }, httpOptions).pipe(
      map((response: {
        data: GliederungKategorie,
      }) => {
        return response.data;
      })
    );
  }
  
  getGliederung(id: string): Observable<Gliederung> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    const path: string = this.basePath + '/' + id;
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Gliederung,
      }) => {
        return response.data;
      })
    );
  }
  
  getGliederungByName(projektId: string): Observable<Gliederung> {
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    const path: string = 'ubg/projekt/' + projektId + '/leitantrag/gliederung';
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Gliederung,
      }) => {
        return response.data;
      })
    );
  }
  
  createGliederung(gliederung: Gliederung): Observable<Gliederung> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = this.basePath;
    return this.drupal.post(path, {
      data: gliederung
    }, httpOptions).pipe(
      map((response: {
        data: Gliederung,
      }) => {
        return response.data;
      })
    );
  }
  
  updateGliederung(gliederung: Gliederung): Observable<Gliederung> {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = this.basePath + '/' + gliederung.id;
    return this.drupal.patch(path, {
      data: gliederung
    }, httpOptions).pipe(
      map((response: {
        data: Gliederung,
      }) => {
        return response.data;
      })
    );
  }
  
  deleteGliederung(gliederung: Gliederung) {
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    const path: string = this.basePath + '/' + gliederung.id;
    return this.drupal.delete(path, httpOptions);
  }
  
  getKategorieGliederungen(kategorie: GliederungKategorie): Observable<GliederungKategorie> {
    const path: string = this.kategorieBasePath + '/' + kategorie.id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    httpOptions.params = new HttpParams()
      .set('include', 'gliederungen');
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: GliederungKategorie,
        included: Array<Gliederung>
      }) => {
        for (let i in response.data.relationships.gliederungen.data) {
          response.data.relationships.gliederungen.data[i] = response.included.find(gliederung => gliederung.id === response.data.relationships.gliederungen.data[i].id);
        }
        response.data.relationships.gliederungen.data.sort((gliederung1: Gliederung, gliederung2: Gliederung): number => {
          if (gliederung1.attributes.title > gliederung2.attributes.title) {
            return 1;
          }
          else {
            return -1;
          }
        });
        return response.data;
      }),
    );
  }
  
}