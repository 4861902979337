<div [ngClass]="className">
  <div fxLayout="row" class="header">
    <span class="name" fxFlex="2 2 auto">
      {{ kategorieObject.attributes.title }}
      <span *ngIf="kategorieObject.attributes.kurzb !== undefined 
        && kategorieObject.attributes.kurzb !== ''">
       ({{ kategorieObject.attributes.kurzb }})
      </span>
    </span>
    <button mat-mini-fab color="accent" fxFLex="1 1 3rem" (click)="addGliederung()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button color="primary" fxFLex="1 1 3rem" (click)="toggleFilter()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-icon-button color="primary" fxFLex="1 1 3rem"
            [matMenuTriggerFor]="kategorieMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <button mat-icon-button color="primary" fxFLex="1 1 3rem" (click)="closeWindow()">
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>
  
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <div fxLayout="row" *ngIf="!isLoading">
    <mat-list *ngIf="kategorieObject.relationships !== undefined" fxFlex="3 3 auto" >
      <ng-container *ngFor="let gliederung of kategorieObject.relationships.gliederungen.data">
        <mat-list-item *ngIf="matchFilters(gliederung)">
          <gliederung [gliederung]="gliederung" (delete)="gliederungDeleted()">
          </gliederung>
        </mat-list-item>
      </ng-container>
    </mat-list>
    <div *ngIf="showFilter" fxFlex="1 1 12rem">
      <div class="filter-wrapper mat-elevation-z2">
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput type="text" formControlName="name" placeholder="Name">
            <button mat-icon-button matSuffix (click)="resetFilter('name')" matTooltip="Zurücksetzen">
                    <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

<mat-menu #kategorieMenu="matMenu">
  <button mat-menu-item (click)="editKategorie()">
    <mat-icon>edit</mat-icon> Bearbeiten
  </button>
</mat-menu>