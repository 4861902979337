/**
 * Antrag
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Gliederung} from './gliederung';
import {Ziffer} from './ziffer';
import {User} from './user';
import {Thema} from './thema';
import {Abschnitt} from './abschnitt';
 
export class Antrag {
  
  type: string
  id: string
  attributes: {
    title: string
    nummer?: number,
    status: boolean,
    inhalt: {
      value: string
    }
    ohne_begruendung: number
    begruendung: {
      value: string
    }
    changed?: number
    zeile_von?: number
    zeile_bis?: number
    antragsteller?: string      // Initiativanträge
    zuweisungen?: string        // Virtuelle Attribut
    zuweisungsdatum?: string
    eingangsdatum?: string
    votum?: {                   // Virtuelle Attribut
      label: string
      kategorie: string
    }
    bearbeitungsdatum?: string
    label?: string              // Virtuelle Attribut
    gliederungen?: string        // Virtuelle Attribut
  }
  relationships: {
    gliederung?: {
      data: Array<Gliederung>
    }
    ziffer?: {
      data: Ziffer,
    }
    leitantrag?: {
      data: Antrag
    }
    folgeantrag?: {
      data: Antrag
    }
    zuweisung?: {
      data: Array<User>
    }
    bearbeitung?: {
      data: User
    }
    thema?: {
      data: Thema,
    }
  }
  meta: {
    permissions: {
      edit: boolean
      zuweisung: boolean
      votum: boolean
    }
    isOpen: boolean
  }
  
  create(): void {
    this.attributes = {
      title: null,
      status: true,
      inhalt: {
        value: null,
      },
      ohne_begruendung: 0,
      begruendung: null,
    };
    if (this.type !== 'ubg_antrag--initiativantrag') {
      this.relationships = {
        gliederung: {
          data: [],
        },
      };
    }
  }
  
}

export interface AntragData {
  
  antrag: Antrag
  abschnitt: Abschnitt
  
}