/**
 * Beschluss
 * 
 * Attila Németh, UBG
 * 30.06.2020
 */
 
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {UserService} from '../../../services/user.service';
import {PageService} from '../../../services/page.service';

@Component({
  templateUrl: './beschluss.component.html',
//  styleUrls: ['./antraege.component.scss'],
})
export class ProjektBeschlussComponent implements OnInit, OnDestroy {
  
  projektId: string = null
  projekt: Projekt = null
  isLoggedIn: number = 0
  beschluss: string = '';
  homeSubscription: Subscription
  isLoading = false

  constructor(route: ActivatedRoute,
                private router: Router,
                private snackbar: MatSnackBar,
                userService: UserService,
                private projektService: ProjektService,
                private pageService: PageService) {
    route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
      this.loadProjekt();
    });
    userService.userLoggedIn.subscribe((status: number) => {
      this.isLoggedIn = status;
      this.loadProjekt();
    });
  }
  
  ngOnInit(): void {
    this.homeSubscription = this.pageService.homeClicked().subscribe(status => {
      if (status) {
        this.router.navigate(['projekt/' + this.projektId]);
      }
    });
  }
  
  ngOnDestroy(): void {
    this.homeSubscription.unsubscribe();
  }
  
  loadProjekt(): void {
    this.isLoading = true;
    if (this.isLoggedIn != 0 && this.projektId !== null) {
      this.projektService.get(this.projektId).subscribe((response: {
        data: Projekt
      }) => {
        this.projekt = response.data;
        this.pageService.setTitle(this.projekt.attributes.title);
        this.projektService.setActive(this.projekt);
        this.projektService.getBeschluss(this.projekt).subscribe((beschluss: string) => {
          this.beschluss = beschluss;
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      });
    }
  }
  
  printBeschluss(): void {
    this.projektService.printBeschluss(this.projekt).subscribe((response: Blob) => {
      let fileName = 'beschluss.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }
  
  beschlussProtokoll(name: string): void {
    this.projektService.printBeschlussProtokoll(this.projekt, name).subscribe((response: Blob) => {
      let fileName = 'protokoll-' + name + '.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }

  beschlussProtokollXlsx(): void {
    this.projektService.exportXlsxBeschlussprotokoll(this.projekt).subscribe((response: Blob) => {
      let fileName = 'protokoll.xlsx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }, error => {
      console.warn({error});
      this.snackbar.open('Ein Fehler ist aufgetreten', null, {
        duration: 15000,
      });
    });
  }

}
