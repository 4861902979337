/**
 * Projekt-Dashboard
 * 
 * Attila Németh, UBG
 * 23.04.2020
 */
 
import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {PageService} from '../../../services/page.service';
import {UserService} from '../../../services/user.service';
import {CookieService} from '@ubg/cookie-service';
import {ProjektEditDialogComponent} from '../dialog/edit.component';
import {ProjektEmailDialogComponent} from '../dialog/mail/mail.component'
import {ProjektBannerDialogComponent} from '../dialog/banner/banner.component';

interface UploadEvent {
  type: string
  loaded?: number
  total?: number
  body?: any
  status?: number
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class ProjektDashboardComponent implements OnDestroy {
 
  projektId: string = null 
  projekt: Projekt = null
  isLoggedIn: number = 0
  modal: string = null
  modalClass: string = 'modal';
  editAccess: boolean = false
  breakpoint: string = '(min-width: 1280px)';
  //isMobile: boolean = true
  uploadPhase: number = -1
  isLoading: boolean = false
  projektSubscription: Subscription
  
  constructor(route: ActivatedRoute,
                private router: Router,
                breakpointObserver: BreakpointObserver,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                private pageService: PageService,
                private cookieService: CookieService,
                userService: UserService) {
    route.paramMap.subscribe((params) => {
      this.projektId = params.get('id');
      this.loadProjekt();
    });
    userService.userLoggedIn.subscribe((status: number) => {
      this.isLoggedIn = status;
      this.loadProjekt();
    });
    this.projektSubscription = this.projektService.activePage.subscribe((name: string) => {
      if (name === 'home') {
        this.closeModal();
      }
    });
    /*
    breakpointObserver.observe([this.breakpoint]).subscribe(result => {
      this.isMobile = !result.matches;
    });
    */
  }
  
  ngOnDestroy(): void {
    this.pageService.resetTitle();
    this.projektSubscription.unsubscribe();
    this.projektService.closePage();
  }
  
  loadProjekt(): void {
    if (this.isLoggedIn != 0 && this.projektId !== null) {
      this.projektService.get(this.projektId).subscribe((response: {
        data: Projekt
      }) => {
        this.projekt = response.data;
        this.pageService.setTitle(this.projekt.attributes.title);
        this.cookieService.set('current_projekt', this.projekt.id);
        this.projektService.setActive(this.projekt);
        this.projektService.editPermission(this.projekt).subscribe((access: boolean) => {
          this.editAccess = access;
        });
      });
    }
  }
  
  editProjekt(): void {
    this.dialog.open(ProjektEditDialogComponent, {
      width: '480px',
      data: {
        projekt: this.projekt,
      }
    }).afterClosed().subscribe((newProjekt: any) => {
      if (newProjekt !== '0') {
        this.projektService.update(newProjekt).subscribe(() => {});
      }
    });
  }
  
  editMail(): void {
    this.projektService.get(this.projekt.id).subscribe((response: {
      data: Projekt
    }) => {
      this.dialog.open(ProjektEmailDialogComponent, {
        width: '640px',
        data: response.data
      }).afterClosed().subscribe((newProjekt: any) => {
        if (newProjekt !== '0') {
          this.projektService.update(newProjekt).subscribe(() => {
            this.snackbar.open('Die Einstellungen wurden gespeichert', null, {
              duration: 6000,
            });
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        }
      });
    });
  }
  
  openModal(name: string): void {
    this.modal = name;
    this.modalClass = 'modal';
    setTimeout(() => {
      this.modalClass = 'modal active mat-elevation-z4';
    }, 50);
    setTimeout(() => {
      this.modalClass = 'modal active';
    }, 400);
    this.projektService.openPage('modal');
  }
  
  closeModal(): void {
    this.modalClass = 'modal';
    setTimeout(() => {
      this.modal = null;
    }, 200);
    this.loadProjekt();
  }
  
  openAntraege(): void {
    this.router.navigate(['projekt/' + this.projekt.id + '/antraege']);
  }
  
  openMeinAntrag(): void {
    this.router.navigate(['projekt/' + this.projekt.id + '/mein-antrag']);
  }
  
  banner(): void {
    this.dialog.open(ProjektBannerDialogComponent, {
      data: this.projekt
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.uploadPhase = 0;
        this.projektService.uploadBanner(this.projekt, result).subscribe((event: UploadEvent) => {
          switch (event.type) {
            case 'upload':
              this.uploadPhase = event.loaded / event.total * 100;
              if (this.uploadPhase > 90) {
                this.isLoading = true;
              }
              break;
            case 'header':
              if (event.status !== 200) {
                this.snackbar.open('Diese Datei kann nicht hochgeladen werden', null, {
                  duration: 15000,
                });
              }
              break;
            case 'response':
              this.uploadPhase = -1;
              this.isLoading = false;
              this.snackbar.open('Die Datei wurde hochgeladen', null, {
                duration: 15000,
              });
              break;
            case 'sent':
            case 'other':
              break;
            default:
              console.error('Unbekanntes Antwort', event.type);
          }
        });
      }
    });
  }
  
}

 
