<p *ngIf="isLoading">...</p>

<p *ngIf="!isLoading" fxLayout="row">
  <span fxFlex="1 1 auto">{{ gliederungObject.attributes.title }}</span>
  
  <!--
  <button mat-button fxFlex="1 1 10rem" (click)="setFreierName(false)" color="primary"
          *ngIf="gliederungObject.attributes.freier_name">
    <mat-icon>check_box</mat-icon>
    Mitglieder
  </button>

  <button mat-button fxFlex="1 1 10rem" (click)="setFreierName(true)" color="primary"
          *ngIf="!gliederungObject.attributes.freier_name">
    <mat-icon>check_box_outline_blank</mat-icon>
    Mitglieder
  </button>
-->

  <mat-icon color="primary" *ngIf="gliederungObject.attributes.freier_name && !gliederungObject.attributes.ubg_gliederung_deleg"
    matTooltip="Mitglieder">
    group
  </mat-icon>
  <mat-icon color="accent" *ngIf="gliederungObject.attributes.freier_name && gliederungObject.attributes.ubg_gliederung_deleg"
    matTooltip="Mitglieder">
    group
  </mat-icon>
  
  <button mat-icon-button fxFlex="1 1 2.5rem" (click)="editGliederung()" 
              matTooltip="Bearbeiten" color="primary">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button fxFlex="1 1 2.5rem" (click)="deleteGliederung()" 
              matTooltip="Löschen" color="primary">
  <mat-icon>delete</mat-icon>
  </button>
</p> 