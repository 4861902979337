/**
 * Votum bearbeiten
 * 
 * Attila Németh, UBG
 * 10.06.2020
 */
 
import {Component, Output, EventEmitter, forwardRef, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';

import {MatDialog} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Votum, VotumType, VotumTypeInfo} from '../../../model/votum';
import {VotumService} from '../../../services/votum.service';
import {ConfirmComponent} from '@ubg/confirm';
import {Ueberweisung} from '../../../model/ueberweisung';
import {AntragService} from '../../../services/antrag.service';
import {Antrag} from '../../../model/antrag';
import {ZifferService} from '../../../services/ziffer.service';

const noop = () => {};

@Component({
  selector: 'votum-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'], 
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VotumEditComponent),
    multi: true
  }]
})
export class VotumEditComponent implements OnInit {
 
  @Output() validityChange = new EventEmitter
  votum: Votum = null
  votumForm: FormGroup
  votumTypes: Array<VotumType> = []
  votumTypeInfo: VotumTypeInfo = null
  ueberweisungOptions: Array<Ueberweisung> = []
  ueberweisungFilteredOptions: Array<Ueberweisung> = []
  zifferOptions: Array<Antrag> = []
  fAntragOptions: Array<Antrag> = []
  fAntragFilteredOptions: Array<Antrag> = []
  isFAntragLoading: boolean = false
   
  constructor(formBuilder: FormBuilder,
                private dialog: MatDialog,
                private votumService: VotumService,
                private antragService: AntragService,
                private zifferService: ZifferService) {
    this.votumForm = formBuilder.group({
      type: [null, Validators.required],
      fassung_titel: [null],
      fassung_text: [null],
      ueberweisung: [{
        value: null,
        disabled: true,
      }],
      ziffer: [{
        value: null,
        disabled: true,
      }],
      f_antrag: [{
        value: null,
        disabled: true,
      }],
      begruendung: [null],
      generierter_text: [null],
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Votum {
    return this.votum;
  };

  set value(v: Votum) {
    this.votum = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Votum) {
    if (value !== null) {
      this.votum = value;
      if (this.votum.attributes.generierter_text !== undefined && this.votum.type !== 'ubg_votum--sonstiges') {
        delete(this.votum.attributes.generierter_text);
      }
      this.updateFormFromValue();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.votum);
  }
  
  ngOnInit(): void {
    this.votumService.getVotumTypes().subscribe(types => {
      this.votumTypes = types;
      if (this.votum.type !== undefined) {
        for (let i in this.votumTypes) {
          if (this.votum.type.replace('ubg_votum--', '') == this.votumTypes[i].attributes.drupal_internal__id) {
            this.votumForm.get('type').setValue(this.votumTypes[i]);
          }
        }
      }
    });
    this.votumForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.votumForm.get('type').valueChanges.subscribe((value: VotumType) => {
      this.votumService.getVotumTypeInfo(value).subscribe(info => {
        this.votum.type = 'ubg_votum--' + info.id;
        this.votumTypeInfo = info;
        this.updateFormControls();
        const fields: Array<string> = ['fassung_titel', 'fassung_text', 'ueberweisung', 'ziffer'];
        for(let i in fields) {
          if (!this.votumHasField(fields[i])) {
            delete this.votum.attributes[fields[i]];
          }
        }
      });
    });
    this.votumForm.get('fassung_titel').valueChanges.subscribe((value: string) => {
      this.votum.attributes.fassung_titel = value;
    });
    this.votumForm.get('fassung_text').valueChanges.subscribe((value: string) => {
      this.votum.attributes.fassung_text = {
        value: value,
      };
    });
    this.votumForm.get('ueberweisung').valueChanges.subscribe((value: Array<Ueberweisung>) => {
      if (value !== null) {
        this.votum.relationships.ueberweisung = {
          data: value,
        };
      }
    });
    this.votumForm.get('f_antrag').valueChanges.subscribe(value => {
      if (typeof value === 'string') {
        this.fAntragFilteredOptions = this.fAntragOptions.filter(antrag => antrag.attributes.label.toLowerCase().replace(' ', '').indexOf(value.toLowerCase().replace(' ', '')) >= 0);
      }
    });
    this.votumForm.get('begruendung').valueChanges.subscribe((value: string) => {
      this.votum.attributes.begruendung = value;
    });
    this.votumForm.get('ziffer').valueChanges.subscribe((value: Antrag) => {
      if (value !== null) {
        this.votum.relationships.ziffer = value.relationships.ziffer;
      }
    });
    this.votumForm.get('generierter_text').valueChanges.subscribe((value: string) => {
      this.votum.attributes.generierter_text = {
        value: value,
      };
    });
  }
  
  private updateFormControls(): void {
    if (this.votumHasField('fassung_titel') && !this.votumLeitantrag()) {
      this.votumForm.get('fassung_titel').setValidators(Validators.required);
      if (this.votum.attributes.fassung_titel === undefined 
        || this.votum.attributes.fassung_titel === null
        || this.votum.attributes.fassung_titel === '') {
        this.resetFassungApply();
      }
    }
    else {
      this.votumForm.get('fassung_titel').setValidators(null);
    }
    if (this.votumHasField('fassung_text')) {
      this.votumForm.get('fassung_text').setValidators(Validators.required);
      if (this.votum.attributes.fassung_text === undefined 
        || this.votum.attributes.fassung_text === null) {
        this.resetFassungApply();
      }
    }
    else {
      this.votumForm.get('fassung_text').setValidators(null);
      this.votumForm.get('fassung_text').reset(null);
    }
    if (this.votumHasField('ueberweisung')) {
      this.votumForm.get('ueberweisung').setValidators(Validators.required);
      this.loadUeberweisungOptions();
    }
    else {
      this.votumForm.get('ueberweisung').setValidators(null);
      this.votumForm.get('ueberweisung').reset(null);
    }
    if (this.votumHasField('ziffer')) {
      this.votumForm.get('ziffer').setValidators(Validators.required);
      this.loadZifferOptions();
    }
    else {
      this.votumForm.get('ziffer').setValidators(null);
      this.votumForm.get('ziffer').reset(null);
    }
    if (this.votumHasField('f_antrag')) {
      this.votumForm.get('f_antrag').setValidators(Validators.required);
      this.loadFAntragOptions();
    }
    else {
      this.votumForm.get('f_antrag').setValidators(null);
      this.votumForm.get('f_antrag').reset(null);
    }
    if (this.votum.type === 'ubg_votum--sonstiges') {
      this.votumForm.get('generierter_text').setValidators(Validators.required);
    }
    else {
      this.votumForm.get('generierter_text').setValidators(null);
      this.votumForm.get('generierter_text').reset(null);
    }
  }
  
  votumHasField(fieldName: string): boolean
  {
    if (this.votumTypeInfo === null) {
      return false;
    }
    else {
      for (let i in this.votumTypeInfo.fields) {
        if (this.votumTypeInfo.fields[i].name === fieldName) {
          return true;
        }
      }
    }
    return false;
  }
  
  updateFormFromValue(): void {
    if (this.votum.attributes.fassung_titel !== undefined) {
      this.votumForm.get('fassung_titel').setValue(this.votum.attributes.fassung_titel);
    }
    if (this.votum.attributes.fassung_text !== undefined) {
      this.votumForm.get('fassung_text').setValue(this.votum.attributes.fassung_text.value);
    }
    if (this.votum.relationships.f_antrag !== undefined 
      && this.votum.relationships.f_antrag.data !== null) {
      this.votumForm.get('f_antrag').setValue(this.votum.relationships.f_antrag.data.attributes.label.replace('&nbsp;', ' '));
      this.antragService.getAntrag(this.votum.relationships.f_antrag.data.type, this.votum.relationships.f_antrag.data.id).subscribe(rawAntrag => {
        this.votum.relationships.f_antrag = {
          data: rawAntrag,
        };
      });
    }
    if (this.votum.relationships.ueberweisung !== undefined) {
      this.votumForm.get('ueberweisung').setValue(this.votum.relationships.ueberweisung.data);
    }
    if (this.votum.relationships.ziffer !== undefined) {
      const selectedOptions = this.zifferOptions.filter(antrag => antrag.relationships.ziffer.data.id === this.votum.relationships.ziffer.data.id);
      if (selectedOptions.length > 0) {
        this.votumForm.get('ziffer').setValue(selectedOptions[0]);
      }
    }
    if (this.votum.type === 'ubg_votum--sonstiges' && this.votum.attributes.generierter_text !== null && this.votum.attributes.generierter_text !== undefined) {
      this.votumForm.get('generierter_text').setValue(this.votum.attributes.generierter_text.value);
    }
  }
  
  resetFassung(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie die Fassung der AK zum Originaltext des Antrags zurücksetzen?',
      }
    }).afterClosed().subscribe(result => {
      if (result === '1') {
        this.resetFassungApply();
      }
    });
  }
  
  private resetFassungApply(): void {
    for (let i in this.votum.relationships.antrag.data) {
      if (this.votum.relationships.antrag.data[i].type === 'ubg_antrag--ohne_leitantrag') {
        this.votumForm.get('fassung_titel').setValue(this.votum.relationships.antrag.data[i].attributes.title);
        this.votumForm.get('fassung_text').setValue(this.votum.relationships.antrag.data[i].attributes.inhalt.value);
        return;
      }
      else if (this.votum.relationships.antrag.data[i].type === 'ubg_antrag--leitantrag') {
        this.zifferService.getZiffer(this.votum.relationships.antrag.data[i].relationships.ziffer.data.id).subscribe(ziffer => {
//          this.votumForm.get('fassung_titel').setValue('Ziffer ' + ziffer.attributes.nummer);
          this.votumForm.get('fassung_text').setValue(ziffer.attributes.inhalt.value);
        });
        return;
      }
      else {}
    }
  }
  
  loadUeberweisungOptions(): void {
    this.antragService.getUeberweisungen(this.votum.relationships.antrag.data[0]).subscribe(ueberweisungen => {
      this.ueberweisungOptions = ueberweisungen;
      this.ueberweisungFilteredOptions = ueberweisungen;
      if (this.ueberweisungOptions.length > 0) {
        this.votumForm.get('ueberweisung').enable();
      }
      else {
        this.votumForm.get('ueberweisung').disable();
      }
    });
  }
  
  fAntragSelected(event: MatAutocompleteSelectedEvent): void {
    let antrag: Antrag = this.fAntragOptions.find(antrag => antrag.id === event.option.value);
    this.antragService.getAntrag(antrag.type, antrag.id).subscribe(rawAntrag => {
      this.votumForm.get('f_antrag').setValue(antrag.attributes.label.replace('&nbsp;', ' '));
      this.votum.relationships.f_antrag = {
        data: rawAntrag,
      };
    });
  }
  
  loadZifferOptions(): void {
    this.zifferOptions = [];
    this.antragService.getZiffern(this.votum.relationships.antrag.data[0]).subscribe(antraege => {
      this.zifferOptions = antraege;
      if (this.zifferOptions.length > 0) {
        this.votumForm.get('ziffer').enable();
        this.updateFormFromValue();
      }
      else {
        this.votumForm.get('ziffer').disable();
      }
    });
  }
  
  votumZumLeitantrag(): boolean {
    if (this.votum === null) {
      return false;
    }
    for (let i in this.votum.relationships.antrag.data) {
      if (this.votum.relationships.antrag.data[i].type !== 'ubg_antrag--zum_leitantrag') {
        return false;
      }
    }
    return true;
  }
  
  votumLeitantrag(): boolean {
    if (this.votum === null) {
      return false;
    }
    for (let i in this.votum.relationships.antrag.data) {
      if (this.votum.relationships.antrag.data[i].type !== 'ubg_antrag--zum_leitantrag' 
        && this.votum.relationships.antrag.data[i].type !== 'ubg_antrag--leitantrag') {
        return false;
      }
    }
    return true;
  }
  
  loadFAntragOptions(): void {
    this.isFAntragLoading = true;
    this.antragService.getProjektAntraege(this.votum.relationships.antrag.data[0]).subscribe(antraege => {
      this.fAntragOptions = antraege.filter(antrag => antrag.type !== 'ubg_antrag--leitantrag');
      this.fAntragFilteredOptions = this.fAntragOptions;
      this.votumForm.get('f_antrag').enable();
      this.isFAntragLoading = false;
    });
  }

}