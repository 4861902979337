<div fxLayout="row" fxLayoutGap="0.5rem" *ngIf="!isOpen" (click)="openAntrag()" 
     class="header-closed">
  <antrag-checkbox [antrag]="antragObject" fxFlex="1 1 2.5rem" 
    *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum">
  </antrag-checkbox>
  <h3 fxFlex="1 1 5rem" [innerHtml]="headerLabel"></h3>
  <div fxFlex="3 3 auto" fxLayout="row" fxLayoutGap="1rem" *ngIf="antragObject.attributes.status">
    <div class="title" fxFlex="1 1 50%">
      <p class="antrag-gliederung">{{ gliederungLabel }}</p>
      <p class="antrag-title" [matTooltip]="antragObject.attributes.title">
        {{ antragObject.attributes.title }}
      </p>
    </div>
    <div class="title" fxFlex="1 1 50%">
      <p class="antrag-votum">
        <span *ngIf="antragObject.attributes.votum !== undefined">
          {{ antragObject.attributes.votum.label }}
        </span>
      </p>
      <p class="antrag-bearbeitung">
        <span *ngIf="antragObject.attributes.bearbeitungsdatum !== undefined 
          && antragObject.attributes.bearbeitungsdatum !== null">
          {{ antragObject.attributes.bearbeitungsdatum | date }}
        </span>
        <span *ngIf="antragObject.relationships.bearbeitung !== undefined">
          {{ antragObject.relationships.bearbeitung.data.attributes.nachname }}, 
          {{ antragObject.relationships.bearbeitung.data.attributes.vorname }}
        </span>
      </p>
    </div>
  </div>
  <div fxFlex="3 3 auto" fxLayout="row" fxLayoutGap="1rem" class="title-closed-entwurf"
       *ngIf="!antragObject.attributes.status">
    <span *ngIf="antragObject.attributes.status === false" class="entwurf-label mat-h3">
      Entwurf
    </span>
  </div>
  <button mat-icon-button fxFlex="1 1 3rem" disabled *ngIf="showAnchor">
    <mat-icon>link</mat-icon>
  </button>
  <span fxFlex="1 1 3rem" *ngIf="!showAnchor"></span>
  <button mat-icon-button fxFlex="1 1 3rem" color="primary">
    <mat-icon>expand_more</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutGap="0.5rem" *ngIf="isOpen" (click)="closeAntrag()" 
     class="header-open">
  <antrag-checkbox [antrag]="antragObject" fxFlex="1 1 2.5rem"></antrag-checkbox>
  <div class="title" fxFlex="3 3 auto" fxLayout="column" fxLayoutGap="0">
    <span *ngIf="antragObject.attributes.status === false" class="entwurf-label mat-h3">
      Entwurf
    </span>
  </div>
  <button mat-icon-button fxFlex="1 1 3rem" color="primary">
    <mat-icon>expand_less</mat-icon>
  </button>
</div>