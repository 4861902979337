/**
 * Redner der Antragskommission
 * 
 * Attila Németh, UBG
 * 07.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Redner} from '../../../model/redner';
import {RednerService} from '../../../services/redner.service';

import {RednerEditDialogComponent} from '../dialog/edit/edit.component';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'redner',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class RednerComponent {
  
  @Input() set redner(redner: Redner) {
    this.loadRedner(redner);
  }
  @Output() delete = new EventEmitter
  rednerObject: Redner = null
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private rednerService: RednerService) {
  }
  
  loadRedner(redner: Redner): void {
    this.rednerService.getRedner(redner.id).subscribe((response: Redner) => {
      this.rednerObject = response;
    });
  }
  
  editRedner(): void {
    this.dialog.open(RednerEditDialogComponent, {
      data: {
        redner: this.rednerObject,
      }
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.rednerService.getRedner(this.rednerObject.id).subscribe((redner: Redner) => {
          redner.attributes.title = result['attributes']['title'];
          this.rednerService.updateRedner(redner).subscribe((response: Redner) => {
            this.loadRedner(response);
          }, (error) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        });
      }
    });
  }
  
  deleteRedner(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diesen Redner wirklich löschen?',
        hint: 'Diese Aktion ist nicht widerrufbar',
      }
    }).afterClosed().subscribe((result: string) => {
      if (result === '1') {
        this.rednerService.deleteRedner(this.rednerObject).subscribe(() => {
          this.delete.emit(this.rednerObject.id);
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
}