/**
 * Ziffer anzeigen
 * 
 * Attila Németh, UBG
 * 25.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {Ziffer} from '../../../model/ziffer';
import {ZifferService} from '../../../services/ziffer.service';

@Component({
  selector: 'ziffer',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ZifferViewComponent {
  
  @Input() set ziffer(ziffer: Ziffer) {
    this.zifferObject = ziffer;
    this.loadZeilen();
  }
  zifferObject: Ziffer = null
  nummer_joined: string = ''
  title_joined: string = ''
  isLoading: boolean = false
    
  constructor(private zifferService: ZifferService) {}
  
  private loadZeilen(): void {
    this.isLoading = true;
    this.zifferService.getExtended(this.zifferObject.id).subscribe(ziffer => {
      const divider: string = '<span class="paragraph-divider"></span>';
      this.zifferObject = ziffer;
      let nummers: Array<string> = [];
      let titles: Array<string> = [];
      for (let i in this.zifferObject.relationships.zeilen.data) {
        if (this.zifferObject.relationships.zeilen.data[i].attributes.title.match(/\/p\>$/ig)) {
          nummers.push(this.zifferObject.relationships.zeilen.data[i].attributes.nummer + divider);
          titles.push(this.zifferObject.relationships.zeilen.data[i].attributes.title + divider);
        }
        else {
          nummers.push(this.zifferObject.relationships.zeilen.data[i].attributes.nummer);
          titles.push(this.zifferObject.relationships.zeilen.data[i].attributes.title);
        }
        /*        
        if (this.zifferObject.relationships.zeilen.data[i].attributes.title.match(/\/p\>$/ig)) {
          nummers.push('<span class="paragraph-divider">--</span>');
          titles.push('<span class="paragraph-divider">--</span>');
        }
        */
      }
      var replace = new RegExp(divider + '<br />', 'g');
      this.isLoading = false;
      this.nummer_joined = nummers.join('<br />').replace(replace, divider);
      this.title_joined = titles.join('<br />').replace(/\<\/(li|p|ul|ol)\>\<br \/\>/ig, (m: string) => {
        return m.replace('<br />', '');
      }).replace(replace, divider);
    });
  }
         
}