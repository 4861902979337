/**
 * Antrag zum Leitantrag bearbeiten
 * 
 * Attila Németh, UBG
 * 02.06.2020
 */
 
import {Component, Output, EventEmitter, forwardRef, OnInit, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';
import {Subscription} from "rxjs";

import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';
import {ZifferService} from '../../../../services/ziffer.service';
import {Gliederung} from '../../../../model/gliederung';
import { GliederungService } from 'src/app/antragswesen/services/gliederung.service';

const noop = () => {};

@Component({
  selector: 'antrag-zum-leitantrag-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['../../edit.component.scss'], 
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AntragZumLeitantragEditComponent),
    multi: true
  }]
})
export class AntragZumLeitantragEditComponent implements OnInit, OnDestroy {
 
  @Output() validityChange = new EventEmitter
  antrag: Antrag = null
  antragForm: FormGroup
  gliederungen: Array<Gliederung> = []
  zeileFirst: number = null
  zeileLast: number = null
  zeileVonSubscription: Subscription
  zeileBisSubscription: Subscription
  hasAntragstellerName: boolean = false
   
  constructor(formBuilder: FormBuilder,
                private antragService: AntragService,
                private gliederungService: GliederungService,
                private zifferService: ZifferService) {
    this.antragForm = formBuilder.group({
//      title: [null, Validators.required],
      gliederung: [null, GliederungSelectedValidator],
      antragsteller: [null],
      inhalt: [null, Validators.required],
      ohne_begruendung: [null],
      begruendung: [null, Validators.required],
      ziffer: [{
        value: null,
        disabled: true,
        errors: [],
      }, Validators.required],
      zeile_von: [null, Validators.required],
      zeile_bis: [null, Validators.required],
    });
    this.antragForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.validityChange.emit(true);
      }
      else {
        this.validityChange.emit(false);
      }
    });
    this.antragForm.get('gliederung').valueChanges.subscribe((value: Array<Gliederung>) => {
      if (this.antrag.relationships === undefined) {
        this.antrag.relationships = {};
      }
      this.antrag.relationships.gliederung = {
        data: value,
      };
      this.updateAntragstellerName();
    });
    this.antragForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.inhalt = {
        value: value,
      };
    });
    this.antragForm.get('begruendung').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.begruendung = {
        value: value,
      };
    });
    this.antragForm.get('antragsteller').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.antragsteller = value;
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Antrag {
    return this.antrag;
  };

  set value(v: Antrag) {
    this.antrag = v;
    this.onChangeCallback(v);
    this.updateFormFromValue();
    this.updateAntragstellerName();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Antrag) {
    if (value !== null) {
      this.antrag = value;
      this.updateFormFromValue();
      this.updateAntragstellerName();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.antrag);
  }
  
  ngOnInit(): void {
    this.zeileVonSubscription = this.antragForm.get('zeile_von').valueChanges.subscribe(value => {
      this.antrag.attributes.zeile_von = value;
    });
    this.zeileBisSubscription = this.antragForm.get('zeile_bis').valueChanges.subscribe(value => {
      this.antrag.attributes.zeile_bis = value;
    });
  }
  
  ngOnDestroy(): void {
    this.zeileVonSubscription.unsubscribe();
    this.zeileBisSubscription.unsubscribe();
  }

  updateAntragstellerName(): void {
    let as: boolean = false;
    if (this.antrag.type === 'ubg_antrag--initiativantrag') {
      as = true;
    }
    for (let i in this.antrag.relationships.gliederung.data) {
      if (this.antrag.relationships.gliederung.data[i].attributes === null || this.antrag.relationships.gliederung.data[i].attributes === undefined) {
        this.gliederungService.getGliederung(this.antrag.relationships.gliederung.data[i].id).subscribe(gliederung => {
          const delta = this.antrag.relationships.gliederung.data.findIndex(g => g.id === gliederung.id);
          this.antrag.relationships.gliederung.data[delta] = gliederung;
          this.updateAntragstellerName();
        });
      }
      else if (this.antrag.relationships.gliederung.data[i].attributes.freier_name) {
        as = true;
      }
    }
    this.hasAntragstellerName = as;
    if (as) {
      this.antragForm.get('antragsteller').setValidators([Validators.required]);
    }
    else {
      this.antragForm.get('antragsteller').setValidators(null);
    }
  }
  
  updateFormFromValue(): void {
//    this.antragForm.get('title').setValue(this.antrag.attributes.title);
    this.antragForm.get('gliederung').setValue(this.antrag.relationships.gliederung.data);
    if (this.antrag.attributes.antragsteller !== null && this.antrag.attributes.antragsteller !== undefined) {
      this.antragForm.get('antragsteller').setValue(this.antrag.attributes.antragsteller);
    }
    if (this.antrag.attributes.inhalt !== null) {
      this.antragForm.get('inhalt').setValue(this.antrag.attributes.inhalt.value);
    }
    this.antragForm.get('ohne_begruendung').setValue(this.antrag.attributes.ohne_begruendung);
    if (this.antrag.attributes.ohne_begruendung == 1) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
    }
    if (this.antrag.attributes.begruendung !== undefined
      && this.antrag.attributes.begruendung !== null) {
      this.antragForm.get('begruendung').setValue(this.antrag.attributes.begruendung.value);
    }
    this.antragForm.get('ziffer').setValue(this.antrag.relationships.leitantrag.data);
    this.antragForm.get('zeile_von').setValue(this.antrag.attributes.zeile_von);
    this.antragForm.get('zeile_bis').setValue(this.antrag.attributes.zeile_bis);
    this.loadZiffer();
  }
  
  private loadZiffer(): void {
    if (this.antrag.relationships.leitantrag.data !== null 
      && this.antrag.relationships.leitantrag.data.attributes === undefined) {
      this.antragService.getAntrag(this.antrag.relationships.leitantrag.data.type, this.antrag.relationships.leitantrag.data.id).subscribe(leitantrag => {
        if (leitantrag.relationships.ziffer.data === null) {
          this.loadZiffer();
        }
        else {
          this.zifferService.getZiffer(leitantrag.relationships.ziffer.data.id).subscribe(ziffer => {
            leitantrag.relationships.ziffer.data = ziffer;
            this.antrag.relationships.leitantrag.data = leitantrag;
            this.loadZiffer();
          });
        }
      });
    }
    else {
      let zeileFirst: number = 9999999;
      let zeileLast: number = -1;
      if (this.antrag.relationships.leitantrag.data.relationships !== undefined) {
        for (let i in this.antrag.relationships.leitantrag.data.relationships.ziffer.data.relationships.zeilen.data) {
          if (Number(this.antrag.relationships.leitantrag.data.relationships.ziffer.data.relationships.zeilen.data[i].attributes.nummer) < zeileFirst) {
            zeileFirst = Number(this.antrag.relationships.leitantrag.data.relationships.ziffer.data.relationships.zeilen.data[i].attributes.nummer);
          }
          if (Number(this.antrag.relationships.leitantrag.data.relationships.ziffer.data.relationships.zeilen.data[i].attributes.nummer) > zeileLast) {
            zeileLast = Number(this.antrag.relationships.leitantrag.data.relationships.ziffer.data.relationships.zeilen.data[i].attributes.nummer);
          }
        }
        this.zeileFirst = zeileFirst;
        this.zeileLast = zeileLast;
        this.antragForm.get('zeile_von').setValidators([Validators.required, Validators.min(this.zeileFirst), Validators.max(this.zeileLast)]);
        this.antragForm.get('zeile_bis').setValidators([Validators.required, Validators.min(this.zeileFirst), Validators.max(this.zeileLast)]);
      }
    }
  }
  
  ohneBegruendungChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.antrag.attributes.ohne_begruendung = 1;
    }
    else {
      this.antrag.attributes.ohne_begruendung = 0;
    }
    this.onChangeCallback(this.antrag);
    if (this.antrag.attributes.ohne_begruendung) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
      if (this.antrag.attributes.begruendung === null 
        || this.antrag.attributes.begruendung.value === '') {
        this.antragForm.get('begruendung').setErrors({
          required: true,
        });
      }
    }
  }
   
}

interface ValidatorFn {    
  (c: AbstractControl): ValidationErrors | null 
}
const GliederungSelectedValidator: ValidatorFn = (formControl: FormControl) => {
  let errors = {};
  if (formControl.value === null) {
    errors['required'] = true;
  }
  else if (formControl.value['length'] === 0) {
    errors['required'] = true;
  }
  return errors;
};