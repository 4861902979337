<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let kategorie of projektObject.relationships.gliederungen.data" cdkDrag class="thema-drag"
    fxLayout="row">
    <mat-icon fxFlex="1 1 2rem">drag_indicator</mat-icon>
    <gliederung-kategorie-closed [kategorie]="kategorie" fxFlex="2 2 auto" (open)="openKategorie($event)">
    </gliederung-kategorie-closed>
  </div>
</div>

<gliederung-kategorie-open [kategorie]="kategorieOpened" *ngIf="kategorieOpened !== null" (close)="closeKategorie()">
</gliederung-kategorie-open>

<!--
<div fxLayout="row" fxLayoutGap="1rem">
  <div fxFlex="2 2 auto">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let kategorie of projektObject.relationships.gliederungen.data" cdkDrag class="thema-drag"
        fxLayout="row">
        <mat-icon fxFlex="1 1 2rem">drag_indicator</mat-icon>
        <gliederung-kategorie [kategorie]="kategorie" fxFlex="2 2 auto"></gliederung-kategorie>
      </div>
    </div>
  </div>
  <div fxFlex="1 1 auto">
    <gliederung-operations [projekt]="projektObject" (change)="loadGliederungen()"></gliederung-operations>
  </div>
</div>
-->