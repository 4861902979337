<h1 mat-dialog-title>Password ändern</h1>
<div mat-dialog-content>
  <form [formGroup]="passwordForm">
    <mat-form-field>
      <input matInput type="password" formControlName="current" required placeholder="Aktuelles Passwort">
      <mat-error>
        <span *ngIf="!passwordForm.get('current').valid
          && passwordForm.get('current').touched">
          Das aktuelle Passwort muss angegegen werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" formControlName="new" required placeholder="Neues Passwort">
      <mat-error>
        <span *ngIf="!passwordForm.get('new').valid
          && passwordForm.get('new').touched">
          Das neue Passwort muss angegegen werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" formControlName="confirm" required placeholder="Neues Passwort bestätigen">
      <mat-error>
        <span *ngIf="!passwordForm.get('confirm').valid
          && passwordForm.get('confirm').touched">
          Das neue Passwort muss bestätigt werden
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">Verwerfen</button>
  <button mat-button [mat-dialog-close]="passwords" [disabled]="disabled" color="primary">
    Password ändern
  </button>
</div>