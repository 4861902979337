<mat-dialog-content class="mat-body">
  <form [formGroup]="projektForm">
    <mat-form-field>
      <input type="email" matInput placeholder="E-Mail" required formControlName="absender" required>
      <mat-error *ngIf="!projektForm.get('absender').valid">
        <span *ngIf="projektForm.get('absender').touched 
                  && projektForm.get('absender').errors !== null
                  && projektForm.get('absender').errors.email !== undefined
                  && projektForm.get('absender').errors.email">
          Es ist keine gültige E-Mail-Adresse
        </span>
        <span *ngIf="projektForm.get('absender').touched 
                  && projektForm.get('absender').errors !== null
                  && projektForm.get('absender').errors.required !== undefined
                  && projektForm.get('absender').errors.required">
          Die E-Mail muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea formControlName="absenden" required matInput placeholder="Neuer Antrag" 
                rows="12">
      </textarea>
      <mat-hint>Ändern Sie die Platzhalter nur,wenn Sie wiessen, was Sie tun!</mat-hint>
      <mat-error *ngIf="!projektForm.get('absenden').valid">
        <span *ngIf="projektForm.get('absenden').touched 
                  && projektForm.get('absenden').errors !== null
                  && projektForm.get('absenden').errors.required !== undefined
                  && projektForm.get('absenden').errors.required">
          Der Text muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea formControlName="zuweisung" required matInput placeholder="Zuweisung" 
                rows="12">
      </textarea>
      <mat-hint>Ändern Sie die Platzhalter nur,wenn Sie wiessen, was Sie tun!</mat-hint>
      <mat-error *ngIf="!projektForm.get('zuweisung').valid">
        <span *ngIf="projektForm.get('zuweisung').touched 
                  && projektForm.get('zuweisung').errors !== null
                  && projektForm.get('zuweisung').errors.required !== undefined
                  && projektForm.get('zuweisung').errors.required">
          Der Text muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea formControlName="entwurf_benachrichtigung" required matInput 
                placeholder="Entwurf-Benachrichtigung" rows="12">
      </textarea>
      <mat-hint>Ändern Sie die Platzhalter nur,wenn Sie wiessen, was Sie tun!</mat-hint>
      <mat-error *ngIf="!projektForm.get('entwurf_benachrichtigung').valid">
        <span *ngIf="projektForm.get('entwurf_benachrichtigung').touched 
                  && projektForm.get('entwurf_benachrichtigung').errors !== null
                  && projektForm.get('entwurf_benachrichtigung').errors.required !== undefined
                  && projektForm.get('entwurf_benachrichtigung').errors.required">
          Der Text muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="disabled">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
  <button mat-button *ngIf="emptySettings$ | async" [matMenuTriggerFor]="copyMenu" color="primary">
    Von anderem Projekt übernehmen
  </button>
  <mat-menu #copyMenu="matMenu" >
    <button mat-menu-item *ngFor="let projekt of projekte" (click)="copyFromProjekt(projekt)">
      {{ projekt.attributes.title }}
    </button>
  </mat-menu>
</mat-dialog-actions>