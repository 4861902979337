/**
 * Antrag-Operationen auf Projekt-Ebene
 * 
 * Attila Németh, UBG
 * 26.05.2020
 */
 
import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {ProjektBroschuerePrintDialogComponent} from '../dialog/print/print.component';
import { PrintBegruendungOptions, ProjektBegruendungPrintDialogComponent } from '../dialog/print-begruendung/print-begruendung';

@Component({
  selector: 'projekt-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class ProjektOperationsComponent implements OnDestroy {
    
  projekt: Projekt = null
  projektSubscription: Subscription
  modal: string = null
  isPrinting: boolean = false
  
  constructor(private router: Router,
               private dialog: MatDialog,
               private snackbar: MatSnackBar,
               private projektService: ProjektService) {
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      this.projekt = projekt;
    });
  }
  
  ngOnDestroy(): void {
    this.projektSubscription.unsubscribe();
  }
  
  addAntrag(): void {
    this.modal = 'antrag';
  }
  
  closeModal(): void {
    this.modal = null
  }
  
  printBroschuere(): void {
    this.dialog.open(ProjektBroschuerePrintDialogComponent, {
      data: this.projekt
    }).afterClosed().subscribe(result => {
      if (result !== undefined && result !== '0') {
        this.isPrinting = true;
        this.projektService.printBroschuere(this.projekt, result).subscribe((response: Blob) => {
          let fileName = 'broschuere.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
          this.isPrinting = false;
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
          this.isPrinting = false;
        });
      }
    });
  }

  printRedner(): void {
    this.projektService.exportRedner(this.projekt).subscribe((response: Blob) => {
      let fileName = 'redner.docx';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
  beschluss(): void {
    this.router.navigate(['projekt/' + this.projekt.id + '/beschluss']);
  }

  printBegruendungen(): void {
//    this.dialog.open(ProjektBegruendungPrintDialogComponent).afterClosed().subscribe((result: PrintBegruendungOptions|null) => {
//      if (result !== null && result !== undefined) {
        const result: PrintBegruendungOptions = {
          votum: false,
        };
        this.projektService.printBegruendungen(this.projekt, result).subscribe((response: Blob) => {
          let fileName = 'begruendungen.docx';
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(response);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, fileName);
          } 
          else {
            a.href = url;
            a.download = fileName;
            a.click();
          }
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
//      }
//    });
  }
  
}