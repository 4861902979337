<mat-toolbar color="primary">
  <button mat-button [matMenuTriggerFor]="MainMenu" *ngIf="isLoggedIn 
          && user !== null && user.attributes.mail !== undefined">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #MainMenu="matMenu">
    <div class="mat-menu-header" *ngIf="isLoggedIn && user !== null">
      <h3 *ngIf="user.attributes.nachname !== null && user.attributes.nachname !== undefined">
        {{ user.attributes.nachname }}, {{ user.attributes.vorname }}
      </h3>
    </div>
    <button mat-menu-item (click)="settings()" *ngIf="user !== null && user.attributes.mail !== null && user.attributes.mail !== undefined">
      <mat-icon>settings</mat-icon>Einstellungen
    </button>
    <button mat-menu-item (click)="password()" *ngIf="user !== null && user.attributes.mail !== null && user.attributes.mail !== undefined">
      <mat-icon>account_circle</mat-icon>Passwort ändern
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>Abmelden
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="gotoPage('projekte')" *ngIf="user !== null && user.attributes.mail !== null && user.attributes.mail !== undefined">
      <mat-icon>list</mat-icon>Projektliste
    </button>
  </mat-menu>
  <h1>{{ headerTitle | async }}</h1>
  <projekt-header></projekt-header>
</mat-toolbar>

<router-outlet></router-outlet>