<form [formGroup]="antragForm" (ngSubmit)="createAntrag()">
  <gliederung-select formControlName="gliederung" required *ngIf="antrag.type !== 'ubg_antrag--initiativantrag'">
  </gliederung-select>
  <mat-form-field *ngIf="hasAntragstellerName">
    <mat-label>Antragsteller</mat-label>
    <textarea matInput formControlName="antragsteller" rows="2"></textarea>
    <mat-error *ngIf="!antragForm.get('antragsteller').valid">
      <span *ngIf="antragForm.get('antragsteller').errors.required && antragForm.get('antragsteller').touched">
        Der Antragsteller (z.B. die Namen der Mitglieder) muss angegeben werden
      </span>
    </mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutGap="0.5rem">
    <mat-form-field fxFlex>
      <mat-label>Buchstabe</mat-label>
      <mat-select formControlName="abschnitt" required>
        <mat-option *ngFor="let abschnitt of abschnitte" [value]="abschnitt">
          {{ abschnitt.attributes.buchstabe }} - {{ abschnitt.attributes.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!antragForm.get('abschnitt').valid">
        <span *ngIf="antragForm.get('abschnitt').errors.required && antragForm.get('abschnitt').touched">
          Der Abschnitt muss ausgewählt werden
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="abschnitt !== null && abschnitt.type === 'ubg_abschnitt--mit_leitantrag'">
      <mat-label>Ziffer</mat-label>
      <mat-select formControlName="ziffer" required>
        <ng-container *ngFor="let antrag of abschnitt.relationships.antraege.data">
          <mat-option [value]="antrag" *ngIf="antrag.type === 'ubg_antrag--leitantrag'">
            {{ antrag.attributes.title }} ({{ abschnitt.attributes.buchstabe }} {{ antrag.attributes.nummer }})
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="!antragForm.get('ziffer').valid">
        <span *ngIf="antragForm.get('ziffer').errors.required && antragForm.get('ziffer').touched">
          Die Ziffer muss ausgewählt werden
        </span>
      </mat-error>
    </mat-form-field>
  </div>
  <h3 *ngIf="antrag.type !== null && antrag.type !== undefined && antrag.type !== 'ubg_antrag--zum_leitantrag'">
    <mat-form-field>
      <input matInput type="text" maxlength="255" formControlName="title" 
             placeholder="Titel" required>
      <mat-error *ngIf="!antragForm.get('title').valid">
        <span *ngIf="antragForm.get('title').errors.required && antragForm.get('title').touched">
          Der Titel muss angegeben werden
        </span>
      </mat-error>
    </mat-form-field>
  </h3>
  <div fxLayout="row" fxLayoutGap="0.5rem">
    <mat-form-field fxFlex *ngIf="zeileFirst !== null && zeileLast !== null">
      <input matInput type="number" placeholder="Von Zeile" required formControlName="zeile_von">
      <mat-hint>{{ zeileFirst }} - {{ zeileLast }}</mat-hint>
      <mat-error *ngIf="!antragForm.get('zeile_von').valid && antragForm.get('zeile_von').touched">
        <span *ngIf="antragForm.get('zeile_von').errors.required">
          Die Zeilennummern müssen angegeben werden
        </span>
        <span *ngIf="antragForm.get('zeile_von').errors.min || antragForm.get('zeile_von').errors.max">
          Diese Ziffer hat die Zeilen {{ zeileFirst }} - {{ zeileLast }}
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="zeileFirst !== null && zeileLast !== null">
      <input matInput type="number" placeholder="Bis Zeile" required formControlName="zeile_bis">
      <mat-hint>{{ zeileFirst }} - {{ zeileLast }}</mat-hint>
      <mat-error *ngIf="!antragForm.get('zeile_bis').valid && antragForm.get('zeile_bis').touched">
        <span *ngIf="antragForm.get('zeile_bis').errors.required">
          Die Zeilennummern müssen angegeben werden
        </span>
        <span *ngIf="antragForm.get('zeile_bis').errors.min || antragForm.get('zeile_bis').errors.max">
          Diese Ziffer hat die Zeilen {{ zeileFirst }} - {{ zeileLast }}
        </span>
      </mat-error>
    </mat-form-field>
  </div>
  <ng-container *ngIf="validAbschnitt">
    <cketext formControlName="inhalt" placeholder="Antrag"></cketext>
    <p>
      <mat-checkbox (change)="ohneBegruendungChanged($event)">
        Keine Begründung angeben
      </mat-checkbox>
    </p>
    <cketext formControlName="begruendung" placeholder="Begründung" 
             *ngIf="antrag !== null && !antrag.attributes.ohne_begruendung"></cketext>
  </ng-container>
  
  <mat-divider></mat-divider>
  
  <div class="actions">
    <button mat-stroked-button type="button" (click)="cancel()" color="primary">
      Verwerfen
    </button>&nbsp;
    <button mat-raised-button [disabled]="disabled" color="primary">
      Speichern
    </button>
  </div>
  
</form>