<h3 *ngIf="antragObject !== null" class="open-title mat-h2" fxLayout="row" fxLayoutGap="0.5rem">
  <span class="label" fxFlex="1 1 3.25em" [innerHtml]="headerLabel"></span>
  <span class="gliederung" fxFlex="1 1 auto">{{ gliederungLabel }}</span>
  <antrag-menu [antrag]="antragObject" [abschnitt]="abschnittObject" fxFlex="1 1 3rem">
  </antrag-menu>
</h3>
<p *ngIf="antragObject.attributes.zuweisungen !== undefined" class="zuweisungen mat-small">
  {{ antragObject.attributes.zuweisungen }}
</p>
<h4 *ngIf="antragObject !== null" class="mat-h3">
  {{ antragObject.attributes.title }}
</h4>

<ng-content></ng-content>