<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <div fxLayout="row" fxLayoutGap="1rem">
    <form [formGroup]="zuweisungForm" fxFlex="1 1 50%">
      <mat-form-field>
        <input type="text" placeholder="Neue Zuweisung" matInput 
               [matAutocomplete]="auto" formControlName="zuweisung">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="zuweisungSelected($event)">
          <mat-option *ngFor="let option of zuweisungFilteredOptions" [value]="option.id">
            {{ option.attributes.nachname }}, {{ option.attributes.vorname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <mat-list fxFlex="1 1 50%" *ngIf="data.relationships.zuweisung !== undefined">
      <mat-list-item *ngFor="let user of data.relationships.zuweisung.data" 
        fxLayout="row" fxLayoutGap="1rem">
        <span fxFlex="1 1 auto" *ngIf="user.attributes !== undefined">
          {{ user.attributes.nachname }},
          {{ user.attributes.vorname }}
        </span>
        <button mat-icon-button color="primary" fxFlex="1 1 3rem"
                (click)="removeZuweisung(user)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary">Speichern</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>