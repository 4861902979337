<button mat-raised-button color="accent" (click)="addAntragOhneLeitenatrag()"
      [disabled]="abschnittObject === null || gliederungObject === null || expired"
      *ngIf="abschnittObject !== null">
  <mat-icon>add</mat-icon> Neuen Antrag stellen
</button>

<div fxLayout="column" fxLayoutGap="1rem">
  <ng-container *ngFor="let antrag of gliederungAntraege">
    <antrag-ohne-leitantrag [antrag]="antrag" [abschnitt]="abschnittObject" fxFlex>
    </antrag-ohne-leitantrag>
  </ng-container>
</div>