/**
 * Gliederung-Dialog
 * 
 * Attila Németh, UBG
 * 14.05.2020
 */
 
import {Component, Inject, OnInit, OnDestroy} from '@angular/core'; 
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscribable, Subscription} from 'rxjs';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Gliederung} from '../../../../model/gliederung';

@Component({
  templateUrl: './gliederung.component.html',
  styleUrls: ['./gliederung.component.scss'],
})
export class GliederungDialogComponent implements OnInit, OnDestroy {
  
  gliederungForm: FormGroup = null
  freierNameSubscription: Subscription
  delegierteSubscription: Subscription
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: Gliederung, 
              private formBuilder: FormBuilder) {
    this.gliederungForm = this.formBuilder.group({
      title: [this.data.attributes.title, Validators.required],
      email: [null, [
        Validators.required,
        Validators.email,
      ]],
      freier_name: [null],
      ubg_gliederung_deleg: [null],
    });
    if (this.data.attributes.e_mail !== undefined) {
      this.gliederungForm.get('email').setValue(this.data.attributes.e_mail);
    }
    if (this.data.attributes.freier_name) {
      this.gliederungForm.get('freier_name').setValue(this.data.attributes.freier_name);
    }
    if (this.data.attributes.ubg_gliederung_deleg) {
      this.gliederungForm.get('ubg_gliederung_deleg').setValue(this.data.attributes.ubg_gliederung_deleg);
    }
    this.gliederungForm.get('title').valueChanges.subscribe((value) => {
      this.data.attributes.title = value;
    });
    this.gliederungForm.get('email').valueChanges.subscribe((value) => {
      this.data.attributes.e_mail = value;
    });
  }
  
  ngOnInit(): void {
    this.freierNameSubscription = this.gliederungForm.get('freier_name').valueChanges.subscribe(value => {
      this.data.attributes.freier_name = value;
    });
    this.delegierteSubscription = this.gliederungForm.get('ubg_gliederung_deleg').valueChanges.subscribe(value => {
      this.data.attributes.ubg_gliederung_deleg = value;
    });
  }
  
  ngOnDestroy(): void {
    this.freierNameSubscription.unsubscribe();
    this.delegierteSubscription.unsubscribe();
  }
  
}