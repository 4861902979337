<p class="op-title mat-h4">
  <span class="mat-h3">
    {{ abschnittObject.attributes.buchstabe }}
  </span>
  {{ abschnittObject.attributes.title }}
</p>
<mat-action-list>
  <button mat-list-item *ngIf="abschnittObject.type == 'ubg_abschnitt--mit_leitantrag' && abschnittObject.relationships.antraege.data.length == 0"
          (click)="editLeitantrag()">
    <mat-icon>note_add</mat-icon> Leitantrag eintragen
  </button>
  <button mat-list-item *ngIf="leitantragEditable()"
          (click)="editLeitantrag()">
    <mat-icon>description</mat-icon> Leitantrag bearbeiten
  </button>
  <mat-progress-bar mode="indeterminate" *ngIf="isSorting" color="accent"></mat-progress-bar>
  <mat-progress-bar mode="determinate" color="primary" [value]="sortPercent" *ngIf="sortPercent > 0"></mat-progress-bar>
  <button mat-list-item *ngIf="abschnittObject.relationships.antraege.data.length > 0 && sortPermission"
          (click)="sortAntraege()" [disabled]="sortPercent > 0">
    <mat-icon>sort</mat-icon>
    <span *ngIf="sortPercent === 0">Anträge neu sortieren</span>
    <span *ngIf="sortPercent > 0">{{ formatBack(sortBack) }}</span>
  </button>
  <button mat-list-item *ngIf="abschnittObject.type !== 'ubg_abschnitt--mit_leitantrag' 
          && abschnittObject.relationships.antraege.data.length > 0 && sortPermission
          && !isDragged"  (click)="dragAntraege()">
    <mat-icon>low_priority</mat-icon> Reihenfolge ändern
  </button>
  <button mat-list-item *ngIf="abschnittObject.type !== 'ubg_abschnitt--mit_leitantrag' 
          && abschnittObject.relationships.antraege.data.length > 0 && sortPermission
          && isDragged"  (click)="dragNoAntraege()">
    <mat-icon>view_agenda</mat-icon> Normale Ansicht
  </button>
  <button mat-list-item (click)="abschnittPrint()">
    <mat-icon>print</mat-icon> In MS Word exportieren
  </button>
  <button mat-list-item *ngIf="abschnittObject.type === 'ubg_abschnitt--mit_leitantrag' 
          && abschnittObject.relationships.antraege.data.length > 0"
          (click)="abschnittLeitantragPrint()">
    <mat-icon>print</mat-icon> Leitantrag in MS Word
  </button>
  <button mat-list-item (click)="beschluss()">
    <mat-icon>description</mat-icon> Beschluss
  </button>
  <button mat-list-item (click)="printBegruendungen()">
    <mat-icon>print</mat-icon> Begründungen in MS Word
  </button>
  <ng-container *ngIf="selectedAntraege.length > 1">
    <mat-divider></mat-divider>
    <button mat-list-item (click)="setGemeinsam()">
      <mat-icon>group_work</mat-icon> Gemeinsam behandeln
    </button>
  </ng-container>
</mat-action-list>