/**
 * Antrag löschen Dialog
 * 
 * Attila Németh, UBG
 * 15.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Antrag} from '../../../../model/antrag';

@Component({
  templateUrl: './delete.component.html',
})
export class AntragDeleteDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Antrag) {
  }
  
}