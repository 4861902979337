<button mat-button [matMenuTriggerFor]="menu" *ngIf="antragObject !== null"
        (menuOpened)="menuOpened()">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="editPermission && antragObject.attributes.status == false"
          (click)="antragLive()">
    <mat-icon>verified</mat-icon> Antrag stellen
  </button>
  <button mat-menu-item *ngIf="editPermission"
          (click)="antragEdit()">
    <mat-icon>edit</mat-icon> Antrag bearbeiten
  </button>
  <button mat-menu-item *ngIf="deletePermission"
          (click)="antragDelete()">
    <mat-icon>delete</mat-icon> Antrag löschen
  </button>
  <button mat-menu-item *ngIf="editPermission && antragObject.relationships.folgeantrag !== undefined"
          (click)="antragNoAnchor()">
    <mat-icon>link_off</mat-icon> Verankerung aufheben
  </button>
  <button mat-menu-item *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.zuweisung"
          (click)="antragZuweisung()">
    <mat-icon>assignment_ind</mat-icon> Zuweisung
  </button>
  
  <!-- Votum -->
  <ng-container *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum">
    <ng-container *ngIf="nestedMenu">
      <button mat-menu-item [matMenuTriggerFor]="votumMenu">
        <mat-icon>thumbs_up_down</mat-icon> Votum
      </button>
      <mat-menu #votumMenu="matMenu">
        <button mat-menu-item (click)="setVotum()">
          <mat-icon>thumbs_up_down</mat-icon> Votum
        </button>
        <button mat-menu-item *ngIf="votumAk !== null
                && votumAk.relationships.antrag.data.length > 1"
                (click)="abweichendesVotum()">
          <mat-icon>thumbs_up_down</mat-icon> Abweichendes Votum
        </button>
        <button mat-menu-item *ngIf="votumAk !== null"
                (click)="deleteVotum()">
          <mat-icon>close</mat-icon> Votum der Antragkommission löschen
        </button>
        <button mat-menu-item (click)="setParteitag()">
          <mat-icon>how_to_vote</mat-icon> Parteitag
        </button>
        <button mat-menu-item *ngIf="votumPt !== null"
                (click)="deleteParteitag()">
          <mat-icon>close</mat-icon> Votum der Parteitages löschen
        </button>
        <button mat-menu-item (click)="editBegruendung()">
          <mat-icon>edit</mat-icon> Begründung der AK
        </button>
        <button mat-menu-item *ngIf="begruendung !== null
                && begruendung.relationships.antraege.data.length > 1"
                (click)="abweichendeBegruendung()">
          <mat-icon>edit</mat-icon> Abweichende Begründung
        </button>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="!nestedMenu">
      <button mat-menu-item (click)="setVotum()">
          <mat-icon>thumbs_up_down</mat-icon> Votum
        </button>
        <button mat-menu-item *ngIf="votumAk !== null
                && votumAk.relationships.antrag.data.length > 1"
                (click)="abweichendesVotum()">
          <mat-icon>thumbs_up_down</mat-icon> Abweichendes Votum
        </button>
        <button mat-menu-item *ngIf="votumAk !== null"
                (click)="deleteVotum()">
          <mat-icon>close</mat-icon> Votum der Antragkommission löschen
        </button>
        <button mat-menu-item (click)="setParteitag()">
          <mat-icon>how_to_vote</mat-icon> Parteitag
        </button>
        <button mat-menu-item *ngIf="votumPt !== null"
                (click)="deleteParteitag()">
          <mat-icon>close</mat-icon> Votum der Parteitages löschen
        </button>
        <button mat-menu-item (click)="editBegruendung()">
          <mat-icon>edit</mat-icon> Begründung der AK
        </button>
        <button mat-menu-item *ngIf="begruendung !== null
                && begruendung.relationships.antraege.data.length > 1"
                (click)="abweichendeBegruendung()">
          <mat-icon>edit</mat-icon> Abweichende Begründung
        </button>
    </ng-container>
  </ng-container>
  <!-- /Votum -->
  
  <button mat-menu-item *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum && votumAk !== null"
          (click)="setRedner()">
    <mat-icon>record_voice_over</mat-icon> Redner der AK
  </button>
  <button mat-menu-item *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum 
          && (antragObject.type == 'ubg_antrag--ohne_leitantrag' || antragObject.type == 'ubg_antrag--initiativantrag')"
          (click)="setThema()">
    <mat-icon>subject</mat-icon> Thema
  </button>
  <button mat-menu-item *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum && gemeinsam === null"
          (click)="setGemeinsam()">
    <mat-icon>group_work</mat-icon> Gemeinsam behandeln
  </button>
  <button mat-menu-item *ngIf="antragObject.meta !== undefined && antragObject.meta.permissions.votum && gemeinsam !== null"
          (click)="removeGemeinsam()">
    <mat-icon>group_work</mat-icon> Nicht mehr gemeinsam behandeln
  </button>
  <button mat-menu-item (click)="antragMarkup()" *ngIf="votumAk !== null">
    <mat-icon>compare</mat-icon> Markup
  </button>
  <button mat-menu-item (click)="antragPrint()" *ngIf="printPermission">
    <mat-icon>print</mat-icon> In MS Word exportieren
  </button>
</mat-menu>