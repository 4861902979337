/**
 * Leitantrag bei Meinem Antrag
 * 
 * Attila Németh, UBG
 * 02.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Antrag} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';
import {AntragService} from '../../../../services/antrag.service';
import {Gliederung} from '../../../../model/gliederung';
import {ProjektService} from '../../../../services/projekt.service';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'antrag-mein-leitantrag',
  templateUrl: './mein.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragMeinLeitantragComponent implements OnInit, OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.loadAntraege();
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
    }
  }
  abschnittObject: Abschnitt = null
  isOpen: boolean = false
  gliederungAntraege: Array<Antrag> = []
  @Input() set gliederung(gliederung: Gliederung) {
    this.gliederungObject = gliederung;
    this.loadAntraege();
  }
  gliederungObject: Gliederung = null
  projektSubscription: Subscription
  expired: boolean = false
  editAntrag: Antrag = null
  editAntragSaveable: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private antragService: AntragService,
                private projektService: ProjektService) {}
                
  ngOnInit(): void {
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      this.expired = projekt.isAbgelaufen();
    });
  }
  
  ngOnDestroy(): void {
    this.projektSubscription.unsubscribe();
  }
  
  toggle(event: string): void {
    if (event === 'open') {
      this.isOpen = true;
    }
    else {
      this.isOpen = false;
    }
  }
  
  addAntrag(): void {
    let antrag = new Antrag;
    antrag.type = 'ubg_antrag--zum_leitantrag';
    antrag.create();
    antrag.attributes.status = false;
    antrag.relationships.leitantrag = {
      data: this.antragObject,
    };
    this.editAntrag = antrag;
  }
  
  antragValidityChanged(event: boolean): void {
    this.editAntragSaveable = event;
  }
  
  antragSave(): void {
    this.antragService.createAntrag(this.editAntrag).subscribe(antrag => {
      this.antragService.addAntragToAbschnitt(antrag, this.abschnittObject).subscribe(() => {
        this.snackbar.open('Ihr Antrag wurde als Entwurf eingetragen', null, {
          duration: 6000,
        });
        this.editAntrag = null;
        this.loadAntraege();
      }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
  loadAntraege(): void {
    if (this.antragObject !== null && this.gliederungObject !== null) {
      this.antragService.getAntraegeZumLeitantrag(this.antragObject).subscribe(antraege => {
        let antragGliederung: Array<Gliederung>;
        let newAntraege: Array<Antrag> = [];
        for (let i in antraege) {
          antragGliederung = antraege[i].relationships.gliederung.data.filter(gliederung => gliederung.id === this.gliederungObject.id);
          if (antragGliederung.length > 0) {
            newAntraege.push(antraege[i]);
          }
        }
        this.gliederungAntraege = newAntraege;
      });
    }
  }
  
  antragCancel(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie den Antrag verwerfen?',
      }
    }).afterClosed().subscribe(result => {
      if (result == '1') {
        this.editAntrag = null;
      }
    });
  }
  
}