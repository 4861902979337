/**
 * Meine Anträge - Liste
 * 
 * Attila Németh, UBG
 * 28.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {Abschnitt} from '../../../../model/abschnitt';
import {Gliederung} from '../../../../model/gliederung';

@Component({
  selector: 'mein-antrag-list',
  templateUrl: './list.component.html',
})
export class ProjektMeinAntragListComponent {
  
  @Input() set abschnitt(abschnitt: Abschnitt) {
    this.abschnittObject = abschnitt;
  }
  abschnittObject: Abschnitt = null
  @Input() set gliederung(gliederung: Gliederung) {
    this.gliederungObject = gliederung;
  }
  gliederungObject: Gliederung = null
  
  constructor() {}

}