/**
 * Antrag-Checkbox
 * 
 * Attila Németh, UBG
 * 30.06.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';

@Component({
  selector: 'antrag-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class AntragCheckboxComponent {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
    }
  }
  antragObject: Antrag = null
  
  constructor(private antragService: AntragService) {}
  
  checkboxClicked(event: MouseEvent): void {
    event.stopPropagation();
  }
  
  checkboxChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.antragService.selectAntrag(this.antragObject);
    }
    else {
      this.antragService.deselectAntrag(this.antragObject);
    }
  }
  
}