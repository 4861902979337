/**
 * Gemeinsam behandeln
 * 
 * Attila Németh, UBG
 * 30.06.2020
 */
 
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {AbschnittPrintOptions} from '../../../../services/abschnitt.service';
import {Abschnitt} from '../../../../model/abschnitt';
import {Antrag} from '../../../../model/antrag';
import {AntragService} from '../../../../services/antrag.service';
import {Votum, VotumBegruendung, GemeinsamOptions} from '../../../../model/votum';

@Component({
  templateUrl: './gemeinsam.component.html',
})
export class AbschnittGemeinsamDialogComponent implements OnInit {

  response: GemeinsamOptions
  gemeinsamForm: FormGroup
  disabled: boolean = true
  votumOptions: Array<Votum> = []
  begruendungOptions: Array<VotumBegruendung> = []
    
  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<Antrag>,
                formBuilder: FormBuilder,
                private antragService: AntragService) {
    this.gemeinsamForm = formBuilder.group({
      votum: [{
        value: null,
        disabled: true,
      }, Validators.required],
      begruendung: [{
        value: null,
        disabled: true,
      }],
    });
    this.response = {
      antrag: this.data,
      votum: null,
      begruendung: null,
    };
  }
  
  ngOnInit(): void {
    this.loadVotumOptions(this.data);
    this.loadBegruendungOptions(this.data);
    this.gemeinsamForm.get('votum').valueChanges.subscribe((value: Votum) => {
      this.response.votum = value;
    });
    this.gemeinsamForm.get('begruendung').valueChanges.subscribe((value: VotumBegruendung) => {
      this.response.begruendung = value;
    });
    this.gemeinsamForm.statusChanges.subscribe(status => {
      // console.debug(status);
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
  }
  
  loadVotumOptions(antraege: Array<Antrag>): void {
    for (let i in antraege) {
      this.antragService.getVoten(antraege[i]).subscribe(voten => {
        if (voten.length === 0 && this.votumOptions.length === 0) {
          this.gemeinsamForm.get('votum').setValidators(null);
          this.disabled = false;
        }
        for (let j in voten) {
          if (voten[j].attributes.ebene === 'ak') {
            let newOptions: Array<Votum> = [];
            for (let k in this.votumOptions) {
              if (this.votumOptions[k].id !== voten[j].id) {
                newOptions.push(this.votumOptions[k]);
              }
            }
            newOptions.push(voten[j]);
            this.votumOptions = newOptions;
            if (this.votumOptions.length === 1) {
              this.response.votum = this.votumOptions[0];
              this.gemeinsamForm.get('votum').setValidators(null);
              this.disabled = false;
            }
            else if (this.votumOptions.length === 0) {
              this.gemeinsamForm.get('votum').setValidators(null);
              this.disabled = false;
            }
            else {
              this.response.votum = null;
              this.gemeinsamForm.get('votum').setValidators(Validators.required);
              this.gemeinsamForm.get('votum').enable();
            }
          }
        }
      });
    }
  }
  
  loadBegruendungOptions(antraege: Array<Antrag>): void {
    for (let i in antraege) {
      this.antragService.getVotumBegruendung(antraege[i]).subscribe(votumBegruendung => {
        if (votumBegruendung !== null) {
          let newOptions: Array<VotumBegruendung> = [];
          for (let k in this.begruendungOptions) {
            if (this.begruendungOptions[k].id !== votumBegruendung.id) {
              newOptions.push(this.begruendungOptions[k]);
            }
          }
          newOptions.push(votumBegruendung);
          this.begruendungOptions = newOptions;
          if (this.begruendungOptions.length === 1) {
            this.response.begruendung = this.begruendungOptions[0];
          }
          else {
            this.response.begruendung = null;
            this.gemeinsamForm.get('begruendung').enable();        
          }
        }
      });
    }
  }
  
  getVotumLabel(votum: Votum): string {
    let labels: Array<string> = [];
    for (let i in votum.relationships.antrag.data) {
      labels.push(votum.relationships.antrag.data[i].attributes.label);
    }
    return labels.sort((label1: string, label2: string): number => {
      if (label1 < label2) {
        return -1;
      }
      else {
        return +1;
      }
    }).join(', ');
  }
  
  getBegruendungLabel(begruendung: VotumBegruendung): string {
    let labels: Array<string> = [];
    for (let i in begruendung.relationships.antraege.data) {
      labels.push(begruendung.relationships.antraege.data[i].attributes.label);
    }
    return labels.sort((label1: string, label2: string): number => {
      if (label1 < label2) {
        return -1;
      }
      else {
        return +1;
      }
    }).join(', ');
  }
  
}