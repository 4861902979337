/**
 * Antrag-Kopfbereich
 * 
 * Attila Németh, UBG
 * 19.05.2020
 */
 
import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {Subscription} from 'rxjs';

import {Antrag} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';

@Component({
  selector: 'antrag-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragHeaderComponent implements OnInit, OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.updateLabel();
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
      this.updateLabel();
    }
  }
  abschnittObject: Abschnitt = null
  @Output() toggle = new EventEmitter
  isOpen: boolean = false  
  headerLabel: string = null
  gliederungLabel: string = null
  showAnchor: boolean = false
  fragmentSubscription: Subscription;
  
  constructor(private route: ActivatedRoute,
                private viewPortScroller: ViewportScroller,) {}
  
  ngOnInit(): void {
    this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
      if (fragment !== null && fragment !== undefined) {
        if (fragment.replace('antrag-', '') == this.antragObject.id) {
          this.openAntrag();
          setTimeout(() => {
            this.viewPortScroller.scrollToAnchor(fragment);
          }, 250);
        }
      }
    });
  }
  
  ngOnDestroy(): void {
    this.fragmentSubscription.unsubscribe();
  }
  
  updateLabel(): void {
    if (this.antragObject.attributes.label === undefined || this.antragObject.attributes.label === null) {
      let elements: Array<string> = [];
      if (this.abschnittObject !== null) {
        elements.push(this.abschnittObject.attributes.buchstabe);
      }
      if (this.antragObject !== null) {
        elements.push(this.antragObject.attributes.nummer.toString());
      }
      this.headerLabel = elements.join(' ');
    }
    else {
      this.headerLabel = this.antragObject.attributes.label;
    }
    let gliederungLabels: Array<string> = [];
    if (this.antragObject.relationships.gliederung !== undefined) {
      for (let i in this.antragObject.relationships.gliederung.data) {
        gliederungLabels.push(this.antragObject.relationships.gliederung.data[i].attributes.longTitle);
      }
    }
    else if(this.antragObject.attributes.antragsteller !== undefined) {
      gliederungLabels.push(this.antragObject.attributes.antragsteller.substring(0, 63));
    }
    this.gliederungLabel = gliederungLabels.join(', ');
    let newAnchor: boolean = false;
    if (this.antragObject.relationships !== undefined) {
      if (this.antragObject.relationships.folgeantrag !== undefined
            && this.antragObject.relationships.folgeantrag.data !== undefined
            && this.antragObject.relationships.folgeantrag.data !== null) {
        newAnchor = true;
      }
    }
    this.showAnchor = newAnchor;
  }
  
  openAntrag(): void {
    this.isOpen = true;
    this.toggle.emit('open');
  }
  
  closeAntrag(): void {
    this.isOpen = false;
    this.toggle.emit('close');
  }
       
}