<attus-mat-banner *ngIf="loadError !== null">
  <attus-mat-banner-text>
    Die Anträge können nicht geladen werden: {{ loadError }}
  </attus-mat-banner-text>
  <attus-mat-banner-actions>
    <button mat-button (click)="loadAbschnitt()" color="primary">
      Erneut versuchen
    </button>
  </attus-mat-banner-actions>
</attus-mat-banner>

<mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>

<ng-container *ngIf="!isLoading && loadError === null">
  
  <abschnitt-ohne-leitantrag [abschnitt]="abschnitt" [scroll]="scroll"
    *ngIf="abschnitt !== null 
    && (abschnitt.type=='ubg_abschnitt--ohne_leitantrag')">
  </abschnitt-ohne-leitantrag>

  <abschnitt-initiativantraege [abschnitt]="abschnitt" [scroll]="scroll"
    *ngIf="abschnitt !== null 
    && abschnitt.type=='ubg_abschnitt--initiativantraege'">
  </abschnitt-initiativantraege>

  <abschnitt-mit-leitantrag [abschnitt]="abschnitt" [scroll]="scroll"
    *ngIf="abschnitt !== null && abschnitt.type=='ubg_abschnitt--mit_leitantrag'">
  </abschnitt-mit-leitantrag>
  
</ng-container>