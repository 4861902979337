/**
 * Benutzer-Service
 * 
 * Attila Németh, UBG
 * 22.04.2020
 */
 
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  private userLoggedInData: BehaviorSubject<number> = new BehaviorSubject(-1);
  public userLoggedIn: Observable<number> = this.userLoggedInData.asObservable();

  constructor(private drupal: DrupalService) {
    this.drupal.userLoggedIn.subscribe((value: number) => {
      this.userLoggedInData.next(value);
    });
  }
  
  login(username: string, password: string): void {
    this.drupal.login(username, password);
  }
  
  gliederungLogin(password: string, projektId: string): void {
    this.drupal.customLogin({
      grant_type: 'ubg_antragswesen',
      passwort: password,
      projekt: projektId,
    });
  }
  
  logout(): void {
    this.drupal.logout();
  }
  
  getCurrentUser() {
    return this.drupal.getCurrentUser();
  }
  
  /**
   * Password ändern
   * 
   * 04.05.2020
   */
  changePassword(currentPass: string, newPass: string) {
    return this.drupal.changePassword(currentPass, newPass);
  }
  
  getSettings(): Observable<UserSettings> {
    const path: string = 'ubg/antragswesen/settings';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.get(path, httpOptions).pipe(
      map((response: UserSettings) => {
        return response;
      })
    );
  }
  
  setSettings(settings: UserSettings) {
    const path: string = 'ubg/antragswesen/settings';
    let httpOptions = this.drupal.getHttpOptions(['Authentication']);
    return this.drupal.post(path, settings, httpOptions);
  }
  
}

export interface UserSettings {
  
  e_mail_benachrichtigung: boolean
  
}