/**
 * List der Antragsberechtigten Gliederungen
 * 
 * Attila Németh, UBG
 * 12.05.2020
 */
 
import {Component, Input} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {GliederungKategorie} from '../../../model/gliederung';

@Component({
  selector: 'gliederung-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class GliederungListComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== undefined && projekt !== null) {
      this.projektObject = projekt;
      this.loadGliederungen();
    }
  }
  projektObject: Projekt = null
  kategorieOpened: GliederungKategorie = null
  
  constructor(private snackbar: MatSnackBar,
                private projektService: ProjektService) {
    this.projektService.activeProjektGliederungUpdate.subscribe(() => {
      this.loadGliederungen();
    });                
  }
  
  loadGliederungen(): void {
    if (this.projektObject !== null) {
      this.projektService.getProjektGliederungen(this.projektObject).subscribe((response) => {
        this.projektObject.relationships.gliederungen = {
          data: response,
        };
      });
    }
  }
  
  drop(event: CdkDragDrop<string[]>): void {
    let newKategorien: Array<GliederungKategorie> = [];
    for (let i in this.projektObject.relationships.gliederungen.data) {
      newKategorien.push(this.projektObject.relationships.gliederungen.data[i]);
    }
    moveItemInArray(newKategorien, event.previousIndex, event.currentIndex);
    this.projektObject.relationships.gliederungen.data = newKategorien;
    this.projektService.update(this.projektObject).subscribe((response: {
      data: Projekt,
    }) => {
      this.projektObject = response.data;
      this.loadGliederungen();
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      })
    })
  }
  
  openKategorie(event: GliederungKategorie): void {
    this.kategorieOpened = event;
  }
  
  closeKategorie(): void {
    this.kategorieOpened = null;
  }
    
}
