/**
 * Gliederung-Kategorie-Dialog
 * 
 * Attila Németh, UBG
 * 14.05.2020
 */
 
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {GliederungKategorie} from '../../../../model/gliederung';

@Component({
  templateUrl: './kategorie.component.html',
  styleUrls: ['./kategorie.component.scss'],
})
export class GliederungKategorieDialogComponent {
  
  kategorieForm: FormGroup = null
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: GliederungKategorie, 
              private formBuilder: FormBuilder) {
    this.kategorieForm = this.formBuilder.group({
      title: [this.data.attributes.title, Validators.required],
      kurzb: [null],
    });             
    if (this.data.attributes.kurzb !== undefined) {
      this.kategorieForm.get('kurzb').setValue(this.data.attributes.kurzb);
    }
    this.kategorieForm.get('title').valueChanges.subscribe((value: string) => {
      this.data.attributes.title = value;
    });
    this.kategorieForm.get('kurzb').valueChanges.subscribe((value: string) => {
      this.data.attributes.kurzb = value;
    });
  }
  
}