<mat-dialog-content class="mat-body" fxLayout="column">
  <p fxFlex="1 1">Möchten Sie dieses Projekt klonen?</p>
  <mat-form-field fxFlex="1 1">
    <mat-label>Votum</mat-label>
    <mat-select [(ngModel)]="data.votum">
      <mat-option *ngFor="let votum of voten" [value]="votum.id">
        {{ votum.label }}
      </mat-option>
    </mat-select>
    <mat-hint>Wenn Sie ein Votum auswählen, alle Anträge im Projekt bekommen dieses Votum</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="data" color="primary">Ja, klonen</button>
  <button mat-button mat-dialog-close="0">Verwerfen</button>
</mat-dialog-actions>