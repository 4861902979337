/**
 * Überweisung-Element
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Ueberweisung, UeberweisungGruppe} from '../../../model/ueberweisung';
import {UeberweisungService} from '../../../services/ueberweisung.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {UeberweisungEditDialogComponent} from '../dialog/edit/edit.component';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'ueberweisung',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
})
export class UeberweisungComponent {
  
  @Input() set ueberweisung(ueberweisung: Ueberweisung) {
    this.ueberweisungObject = ueberweisung
    if (this.ueberweisungObject.attributes === undefined) {
      this.loadUeberweisung();
    }
  }
  @Output() delete: EventEmitter<Ueberweisung> = new EventEmitter
  ueberweisungObject: Ueberweisung = null
  displayGruppe: boolean = false
  gruppeName: string = null

  constructor(breakpointObserver: BreakpointObserver,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private ueberweisungService: UeberweisungService) {
    breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((status) => {
      this.displayGruppe = status.matches;
    });
  }
  
  loadUeberweisung(): void {
    this.ueberweisungService.getUeberweisung(this.ueberweisungObject.id).subscribe((response: Ueberweisung) => {
      this.ueberweisungObject = response;
//      this.loadGruppe();
    });
  }
  
//  loadGruppe(): void {
//    this.ueberweisungService.getGruppe(this.ueberweisungObject.relationships.gruppe.data.id).subscribe((response: UeberweisungGruppe) => {
//      this.gruppeName = response.attributes.title;
//    });
//  }
  
  editUeberweisung(): void {
    this.dialog.open(UeberweisungEditDialogComponent, {
      width: '720px',
      data: {
        ueberweisung: this.ueberweisungObject,
      }
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.ueberweisungService.updateUeberweisung(result).subscribe((response: Ueberweisung) => {
          this.ueberweisungObject = response;
//          this.loadGruppe();
        });
      }
    });
  }
  
  deleteUeberweisung(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diese Überweisung löschen?',
      }
    }).afterClosed().subscribe(result => {
      if (result === '1') {
        this.ueberweisungService.deleteUeberweisung(this.ueberweisungObject).subscribe(() => {
          this.delete.emit(this.ueberweisungObject);
        });
      }
    });
  }
     
}