/**
 * Antragsformular für alle Typen
 * 
 * Attila Németh, UBG
 * 26.05.2020
 */
 
import {Component, forwardRef, OnInit, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {Antrag} from '../../../model/antrag';
import {AntragService} from '../../../services/antrag.service';
import {Gliederung} from '../../../model/gliederung';
import {Abschnitt} from '../../../model/abschnitt';
import {AbschnittService} from '../../../services/abschnitt.service';
import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {ZifferService} from '../../../services/ziffer.service';

const noop = () => {};

@Component({
  selector: 'antrag-mixed-edit',
  templateUrl: './mixed_edit.component.html',
  styleUrls: ['../edit.component.scss'], 
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AntragMixedEditComponent),
    multi: true
  }]
})
export class AntragMixedEditComponent implements OnInit, OnDestroy {
 
  antrag: Antrag = null
  antragForm: FormGroup
  disabled: boolean = true
  abschnitte: Array<Abschnitt> = []
  abschnitt: Abschnitt = null
  projektubscription: Subscription
  formValiditySubscription: Subscription
  formTitleSubscription: Subscription
  formGliederungSubscription: Subscription
  formAbschnittSubscription: Subscription
  formZifferSubscription: Subscription
  formInhaltSubscription: Subscription
  formBegruendungSubscription: Subscription
  formZeileVonSubscription: Subscription
  formZeileBisSubscription: Subscription
  projekt: Projekt = null
  validAbschnitt: boolean = false
  zeileFirst: number = null
  zeileLast: number = null
  hasAntragstellerName: boolean = false
  formAntragstellerSubscription: Subscription;
   
  constructor(formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                private abschnittService: AbschnittService,
                private antragService: AntragService,
                private zifferService: ZifferService) {
    this.antragForm = formBuilder.group({
      title: [null, Validators.required],
      gliederung: [null, Validators.required],
      antragsteller: [null],
      abschnitt: [null, Validators.required],
      ziffer: [null],
      zeile_von: [null],
      zeile_bis: [null],
      inhalt: [null, Validators.required],
      begruendung: [null, Validators.required],
    });
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop
  
  get value(): Antrag {
    return this.antrag;
  };

  set value(v: Antrag) {
    this.antrag = v;
    this.onChangeCallback(v);
    this.updateAntragstellerName();
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  writeValue(value: Antrag) {
    if (value !== null) {
      this.antrag = value;
      this.updateAntragstellerName();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.antrag);
  }
  
  ngOnInit(): void {
    this.antrag = new Antrag;
    this.antrag.create();
    this.projektubscription = this.projektService.activeProjekt.subscribe(projekt => {
      if (projekt !== null && projekt !== undefined) {
        this.projekt = projekt;
        this.projektService.getAbschnitte(this.projekt).subscribe(abschnitte => {
          this.abschnitte = abschnitte;
        });
      }
    });
    this.formValiditySubscription = this.antragForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.disabled = false;
      }
      else {
        this.disabled = true;
      }
    });
    this.formTitleSubscription = this.antragForm.get('title').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.title = value;
    });
    this.formGliederungSubscription = this.antragForm.get('gliederung').valueChanges.subscribe((value: Array<Gliederung>) => {
      this.antrag.relationships.gliederung.data = value;
      this.updateAntragstellerName();
    });
    this.formAbschnittSubscription = this.antragForm.get('abschnitt').valueChanges.subscribe((value: Abschnitt) => {
      this.antragForm.get('ziffer').reset(null);
      if (value === null) {
        this.abschnitt = null;
        this.antrag.type = null;
        this.antragForm.get('title').setValidators(null);
      }
      else {
        this.abschnittService.getExtended(value.id).subscribe(abschnitt => {
          this.abschnitt = abschnitt;
          if (this.abschnitt.type == 'ubg_abschnitt--mit_leitantrag') {
            this.antrag.type = 'ubg_antrag--zum_leitantrag';
            this.antragForm.get('ziffer').setValidators([Validators.required]);
            this.validAbschnitt = false;
            this.antragForm.get('title').setValidators(null);
            this.antragForm.get('title').reset(null);
            this.antragForm.get('gliederung').setValidators(Validators.required);
            this.antragForm.get('antragsteller').setValidators(null);
          }
          else {
            this.antragForm.get('ziffer').setValidators(null);
            this.validAbschnitt = true;
            if (this.abschnitt.type == 'ubg_abschnitt--ohne_leitantrag') {
              this.antrag.type = 'ubg_antrag--ohne_leitantrag';
              this.antragForm.get('gliederung').setValidators(Validators.required);
            }
            else if (this.abschnitt.type == 'ubg_abschnitt--initiativantraege') {
              this.antrag.type = 'ubg_antrag--initiativantrag';
              this.antragForm.get('gliederung').setValidators(null);
              this.antragForm.get('gliederung').reset(null);
              this.antragForm.get('antragsteller').setValidators(Validators.required);
            }
            this.zeileFirst = null;
            this.zeileLast = null;
            this.antragForm.get('zeile_von').setValidators(null);
            this.antragForm.get('zeile_bis').setValidators(null);
            this.antragForm.get('title').setValidators([Validators.required]);
            this.antragForm.get('ziffer').reset(null);
            this.antragForm.get('zeile_von').reset(null);
            this.antragForm.get('zeile_bis').reset(null);
          }
          this.updateAntragstellerName();
        });
      }
    });
    this.formZifferSubscription = this.antragForm.get('ziffer').valueChanges.subscribe((value: Antrag) => {
      if (value === null) {
        this.antrag.relationships.leitantrag = {
          data: value,
        };
        this.validAbschnitt = true;
      }
      else {
        this.antragService.getAntrag(value.type, value.id).subscribe(rawLeitantrag => {
          this.antrag.relationships.leitantrag = {
            data: rawLeitantrag,
          };
          this.validAbschnitt = true;
          this.loadZiffer(value);
        });
      }
    });
    this.formInhaltSubscription = this.antragForm.get('inhalt').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.inhalt = {
        value: value,
      };
    });
    this.formBegruendungSubscription = this.antragForm.get('begruendung').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.begruendung = {
        value: value,
      };
    });
    this.formZeileVonSubscription = this.antragForm.get('zeile_von').valueChanges.subscribe((value: number) => {
      this.antrag.attributes.zeile_von = value;
    });
    this.formZeileBisSubscription = this.antragForm.get('zeile_bis').valueChanges.subscribe((value: number) => {
      this.antrag.attributes.zeile_bis = value;
    });
    this.formAntragstellerSubscription = this.antragForm.get('antragsteller').valueChanges.subscribe((value: string) => {
      this.antrag.attributes.antragsteller = value;
    });
  }
  
  ngOnDestroy(): void {
    this.projektubscription.unsubscribe();
    this.formValiditySubscription.unsubscribe();
    this.formTitleSubscription.unsubscribe();
    this.formGliederungSubscription.unsubscribe();
    this.formAbschnittSubscription.unsubscribe();
    this.formZifferSubscription.unsubscribe();
    this.formInhaltSubscription.unsubscribe();
    this.formBegruendungSubscription.unsubscribe();
    this.formZeileVonSubscription.unsubscribe();
    this.formZeileBisSubscription.unsubscribe();
  }
  
  loadZiffer(antrag: Antrag): void {
    this.zifferService.getZiffer(antrag.relationships.ziffer.data.id).subscribe(ziffer => {
      let zeileFirst: number = 9999999;
      let zeileLast: number = -1;
      for (let i in ziffer.relationships.zeilen.data) {
        if (Number(ziffer.relationships.zeilen.data[i].attributes.nummer) < zeileFirst) {
          zeileFirst = Number(ziffer.relationships.zeilen.data[i].attributes.nummer);
        }
        if (Number(ziffer.relationships.zeilen.data[i].attributes.nummer) > zeileLast) {
          zeileLast = Number(ziffer.relationships.zeilen.data[i].attributes.nummer);
        }
      }
      this.zeileFirst = zeileFirst;
      this.zeileLast = zeileLast;
      this.antragForm.get('zeile_von').reset(null);
      this.antragForm.get('zeile_von').setValidators([Validators.required, Validators.min(this.zeileFirst), Validators.max(this.zeileLast)]);
      this.antragForm.get('zeile_bis').reset(null);
      this.antragForm.get('zeile_bis').setValidators([Validators.required, Validators.min(this.zeileFirst), Validators.max(this.zeileLast)]);
    });
  }
  
  ohneBegruendungChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.antrag.attributes.ohne_begruendung = 1;
    }
    else {
      this.antrag.attributes.ohne_begruendung = 0;
    }
    this.onChangeCallback(this.antrag);
    if (this.antrag.attributes.ohne_begruendung) {
      this.antragForm.get('begruendung').setValidators(null);
      this.antragForm.get('begruendung').setErrors(null);
    }
    else {
      this.antragForm.get('begruendung').setValidators(Validators.required);
      if (this.antrag.attributes.begruendung === null 
        || this.antrag.attributes.begruendung.value === '') {
        this.antragForm.get('begruendung').setErrors({
          required: true,
        });
      }
    }
  }
  
  cancel(): void {}
  
  createAntrag(): void {
    this.antragService.createAntrag(this.antrag).subscribe(antrag => {
      this.antragService.addAntragToAbschnitt(antrag, this.abschnitt).subscribe(() => {
        this.antragService.setBearbeitung(antrag).subscribe(() => {
          this.abschnittService.setUpdateAbschnitt(this.abschnitt);
          this.snackbar.open('Der Antrag wurde eingetragen', null, {
            duration: 6000,
          });
          this.antragService.setUpdateLastAntrag();
          this.antrag = new Antrag;
          this.antrag.create();
          this.antrag.relationships.gliederung.data = this.antragForm.get('gliederung').value;
          this.antragForm.get('title').reset();
          this.antragForm.get('title').markAsUntouched();
          this.antragForm.get('abschnitt').reset(null);
          this.antragForm.get('abschnitt').markAsUntouched();
          this.antragForm.get('inhalt').reset(null);
          this.antragForm.get('begruendung').reset(null);
          this.validAbschnitt = false;
        });
      });
    }, (error: string) => {
      this.snackbar.open(error, null, {
        duration: 15000,
      });
    });
  }
  
  updateAntragstellerName(): void {
    let as: boolean = false;
    if (this.antrag.type === 'ubg_antrag--initiativantrag') {
      as = true;
    }
    for (let i in this.antrag.relationships.gliederung.data) {
      if (this.antrag.relationships.gliederung.data[i].attributes.freier_name) {
        as = true;
      }
    }
    this.hasAntragstellerName = as;
    if (as) {
      this.antragForm.get('antragsteller').setValidators([Validators.required]);
    }
    else {
      this.antragForm.get('antragsteller').setValidators(null);
    }
  }
   
}

interface ValidatorFn {    
  (c: AbstractControl): ValidationErrors | null 
}
const GliederungSelectedValidator: ValidatorFn = (formControl: FormControl) => {
  let errors = {};
  if (formControl.value === null) {
    errors['required'] = true;
  }
  else if (formControl.value['length'] === 0) {
    errors['required'] = true;
  }
  return errors;
};