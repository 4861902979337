/**
 * Leitantrag drucken
 * 
 * Attila Németh, UBG
 * 26.06.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Abschnitt} from '../../../../model/abschnitt';

@Component({
  templateUrl: './print_leitantrag.component.html',
})
export class AbschnittPrintLeitantragDialogComponent {
  
  view: string = null
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Abschnitt) {
  }
  
}