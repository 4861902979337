/**
 * Antragsinhalt
 * 
 * Attila Németh, UBG
 * 26.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {Antrag} from '../../../../model/antrag';
import {Votum, VotumBegruendung, VotumGemeinsam} from '../../../../model/votum';
import {AntragService} from '../../../../services/antrag.service';
import {VotumService} from '../../../../services/votum.service';

@Component({
  selector: 'antrag-inhalt',
  templateUrl: './inhalt.component.html',
  styleUrls: ['../../view.component.scss'],
})
export class AntragInhaltComponent {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.loadVoten();
    }
  }
  antragObject: Antrag = null
  voten: Array<Votum> = []
  begruendung: VotumBegruendung = null
  gemeinsam: VotumGemeinsam = null
  
  constructor(private antragService: AntragService,
              private votumService: VotumService) {}
  
  loadVoten(): void {
    this.antragService.getVoten(this.antragObject).subscribe(voten => {
      if (voten.length > 0) {
        this.voten = voten;
      }
      else {
        this.votumService.getGemeinsamByAntrag(this.antragObject).subscribe(gemeinsam => {
          if (gemeinsam.type !== undefined) {
            this.gemeinsam = gemeinsam;
          }
        });
      }
    });
    this.antragService.getVotumBegruendung(this.antragObject).subscribe(begruendung => {
      this.begruendung = begruendung;
    }, (error) => {
      this.begruendung = null;
    });
  }
  
  hasRedner(): boolean {
    for (let i in this.voten) {
      if (this.voten[i].relationships.redner_der_ak !== undefined
        && this.voten[i].relationships.redner_der_ak.data.length > 0) {
          return true;
      }
    }
    return false;
  }
  
}