/**
 * Antrag
 * 
 * Attila Németh, UBG
 * 19.05.2020
 */
 
import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {Antrag} from '../../../../model/antrag';
import {Abschnitt} from '../../../../model/abschnitt';
import {AntragService} from '../../../../services/antrag.service';
import {AntragEvent} from '../../../../model/events';

@Component({
  selector: 'antrag-ohne-leitantrag',
  templateUrl: './view.component.html',
//  styleUrls: ['./view.component.scss'],
})
export class AntragOhneLeitantragComponent implements OnDestroy {
    
  @Input() set antrag(antrag: Antrag) {
    if (antrag !== null) {
      this.antragObject = antrag;
      this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
        if (event !== null && event.antrag.id === this.antragObject.id) {
          switch (event.name) {
            default:
              console.warn(event.name);
          }
        }
      });
    }
  }
  antragObject: Antrag = null
  @Input() set abschnitt(abschnitt: Abschnitt) {
    if (abschnitt !== null) {
      this.abschnittObject = abschnitt;
    }
  }
  abschnittObject: Abschnitt = null
  isOpen: boolean = false
  antragEventSubscription: Subscription = null
  
  constructor(private antragService: AntragService) {}
  
  ngOnDestroy(): void {
    if (this.antragEventSubscription !== null) {
      this.antragEventSubscription.unsubscribe();
    }
  }
  
  toggle(event: string): void {
    if (event === 'open') {
      this.isOpen = true;
    }
    else {
      this.isOpen = false;
    }
  }
  
}