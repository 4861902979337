/**
 * Ziffer-Service
 * 
 * Attila Németh, UBG
 * 22.05.2020
 */
 
import {Injectable} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators'; 

import {DrupalService} from '../../../../projects/ubg/drupal/src/lib/drupal.service';
import {Ziffer, ZifferZeile} from '../model/ziffer';
import {CookieService} from '@ubg/cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ZifferService {

  basePath: string = 'api/ubg_ziffer/ubg_ziffer'

  constructor(private cookieService: CookieService, 
                private drupal: DrupalService) {}

  /**
   * Eine Ziffer
   */
  getZiffer(id: string): Observable<Ziffer> {
    const path: string = this.basePath + '/' + id + '?include=zeilen';
    const httpOptions = this.drupal.getHttpOptions(['Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Ziffer
        included: Array<ZifferZeile>
      }) => {
        if (response.data.relationships.zeilen !== undefined) {
          for (let i in response.data.relationships.zeilen.data) {
            for (let j in response.included) {
              if (response.data.relationships.zeilen.data[i].type == response.included[j].type
                && response.data.relationships.zeilen.data[i].id == response.included[j].id) {
                response.data.relationships.zeilen.data[i] = response.included[j];
              }
            }
          }
        }
        return response.data;
      })
    );
  }

  /**
   * Eine Ziffer erstellen
   */
  createZiffer(ziffer: Ziffer): Observable<Ziffer> {
    const path: string = this.basePath;
    const httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    return this.drupal.post(path,{
      data: ziffer
    }, httpOptions).pipe(
      map((response: {
        data: Ziffer
      }) => {
        return response.data;
      })
    );
  }
  
  /**
   * Die Ziffer in erweitertem Format
   */
  getExtended(id: string): Observable<Ziffer> {
    const path: string = 'ubg/ziffer/api/' + id;
    let httpOptions = this.drupal.getHttpOptions(['JsonApi', 'Authentication']);
    if (this.cookieService.get('gliederung') !== undefined) {
      httpOptions.headers = httpOptions.headers.set('X-Antragswesen', this.cookieService.get('gliederung'));
    }
    return this.drupal.get(path, httpOptions).pipe(
      map((response: {
        data: Ziffer
      }) => {
        return response.data;
      })
    );
  }
   
}