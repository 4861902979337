<h1 mat-dialog-title>Einstellungen</h1>
<div mat-dialog-content>
  <form [formGroup]="settingsForm">
    <mat-checkbox formControlName="e_mail_benachrichtigung" ngDefaultControl color="primary">
      E-Mail-Benachrichtigung
    </mat-checkbox>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">Verwerfen</button>
  <button mat-button [mat-dialog-close]="data" color="primary">
    Speichern
  </button>
</div>