<div fxLayout="row" class="header" *ngIf="kategorieObject !== null">
  <span class="name" fxFlex="2 2 auto" *ngIf="kategorieObject.attributes !== undefined">
    {{ kategorieObject.attributes.title }}
  </span>
  <span class="name" fxFlex="2 2 auto" *ngIf="kategorieObject.attributes === undefined">
    ...
  </span>
  <button mat-icon-button color="primary" fxFLex="1 1 3rem" (click)="this.open.emit(kategorieObject)">
    <mat-icon>expand_more</mat-icon>
  </button>
</div>