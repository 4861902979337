<button mat-flat-button color="primary" (click)="uebernehmen()">
  <mat-icon>library_books</mat-icon> Von anderem Projekt übernehmen
</button>

<button mat-raised-button color="accent" (click)="addUeberweisung()" 
        [disabled]="projektObject.relationships.ueberweisung_gruppen.data.length <= 0">
  <mat-icon>add</mat-icon> Neue Überweisung hinzufügen
</button>

<mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>
<div cdkDropList (cdkDropListDropped)="dropUeberweisung($event)">
  <div *ngFor="let ueberweisung of projektObject.relationships.ueberweisungen.data" cdkDrag
    fxLayout="row" class="drag">
    <mat-icon fxFlex="1 1 2rem">drag_indicator</mat-icon>
    <ueberweisung fxFlex="1 1 auto" [ueberweisung]="ueberweisung" (delete)="ueberweisungDeleted($event)">
    </ueberweisung>
  </div>
</div>

<button mat-raised-button color="accent" (click)="addUeberweisungGruppe()">
  <mat-icon>add</mat-icon> Neue Gruppe hinzufügen
</button>

<div cdkDropList (cdkDropListDropped)="dropGruppe($event)">
  <div *ngFor="let ueberweisungGruppe of projektObject.relationships.ueberweisung_gruppen.data"
    cdkDrag fxLayout="row" class="drag">
    <mat-icon fxFlex="1 1 2rem">drag_indicator</mat-icon>
    <ueberweisung-gruppe [ueberweisungGruppe]="ueberweisungGruppe" fxFlex="1 1 auto"
      (delete)="deleteGruppe($event)">
    </ueberweisung-gruppe>
  </div>
</div>