/**
 * Überweisung bearbeiten
 * 
 * Attila Németh, UBG
 * 05.05.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Ueberweisung} from '../../../../model/ueberweisung';
import {Projekt} from '../../../../model/projekt';

@Component({
  templateUrl: './edit.component.html',
})
export class UeberweisungEditDialogComponent {
  
  disabled: boolean = true
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                ueberweisung: Ueberweisung
              }) {}
              
  validityChanged(valid: boolean) {
    this.disabled = !valid;
  }
  
}
