/**
 * Votum
 * 
 * Attila Németh, UBG
 * 10.06.2020
 */
 
import {Antrag} from './antrag';
import {Ueberweisung} from './ueberweisung';
import {Ziffer} from './ziffer';
import {Redner} from './redner';
 
export class Votum {
  
  type: string
  id: string
  attributes: {
    drupal_internal__id?: number
    ebene: string
    fassung_titel?: string
    fassung_text?: {
      value: string
    },
    generierter_text?: {
      value: string
    }
    begruendung?: string            // Fake Attribute
    changed?: string
  }
  relationships: {
    antrag: {
      data: Array<Antrag>
    }
    f_antrag?: {
      data: Antrag
    }
    ueberweisung?: {
      data: Array<Ueberweisung>
    }
    ziffer?: {
      data: Ziffer
    }
   redner_der_ak?: {
     data: Array<Redner>
   }
  }
  
}

export class VotumType {
  
  type: string = 'ubg_votum_type--ubg_votum_type'
  id: string
  attributes: {
    label: string
    kategorie: string
    drupal_internal__id: string
    gewicht_op: number
  }
  
}

export interface VotumTypeInfo {
  
  id: string
  label: string
  kategorie: string
  fields: Array<{
    name: string
    type: string
    label: string
  }>
  
}

export class VotumBegruendung {
  
  id: string
  type: string = 'ubg_votum_begruendung--ubg_votum_begruendung'
  attributes: {
    inhalt: {
      value: string
    }
  }
  relationships: {
    antraege: {
      data: Array<Antrag>
    }
  }
  
}

export class VotumGemeinsam {

  id: string  
  type: string = "ubg_votum_gemeinsam--ubg_votum_gemeinsam"
  relationships: {
    antraege: {
      data: Array<Antrag>
    }
  }
  attributes: {
    text?: string         // Virtuelles Attribut
  }
  
}

export interface GemeinsamOptions {
  
  antrag: Array<Antrag>
  votum: Votum
  begruendung: VotumBegruendung
  
}