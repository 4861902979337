/**
 * Gliederung
 * 
 * Attila Németh, UBG
 * 14.05.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {GliederungService} from '../../../services/gliederung.service';
import {Gliederung} from '../../../model/gliederung';
import {GliederungDialogComponent} from '../dialog/gliederung/gliederung.component';
import {ConfirmComponent} from '@ubg/confirm';

@Component({
  selector: 'gliederung',  
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class GliederungComponent {
   
  @Input() set gliederung(gliederung: Gliederung) {
    this.gliederungObject = gliederung;
    if (this.gliederungObject.attributes === undefined) {
      this.loadGliederung();
    }
  }
  @Output() delete = new EventEmitter
  gliederungObject: Gliederung
  isLoading: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private gliederungService: GliederungService) {
    
  }
  
  loadGliederung(): void {
    this.isLoading = true;
    this.gliederungService.getGliederung(this.gliederungObject.id).subscribe((response) => {
      this.gliederungObject = response;
      this.isLoading = false;
    });
  }
  
  editGliederung(): void {
    this.dialog.open(GliederungDialogComponent, {
      data: this.gliederungObject
    }).afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.gliederungService.getGliederung(this.gliederungObject.id).subscribe((gliederung) => {
          gliederung.attributes.title = result['attributes']['title'];
          gliederung.attributes.e_mail = result['attributes']['e_mail'];
          gliederung.attributes.freier_name = result['attributes']['freier_name'];
          gliederung.attributes.ubg_gliederung_deleg = result['attributes']['ubg_gliederung_deleg'];
          console.debug(gliederung);
          this.gliederungService.updateGliederung(gliederung).subscribe((gliederung) => {
            this.gliederungObject = gliederung;
          }, (error: string) => {
            this.snackbar.open(error, null, {
              duration: 15000,
            });
          });
        });
      }
    });
  }
  
  deleteGliederung(): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Möchten Sie diese Gliederung löschen?',
      }
    }).afterClosed().subscribe((result: any) => {
      if (result == 1) {
        this.gliederungService.deleteGliederung(this.gliederungObject).subscribe(() => {
          this.delete.emit();
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        })
      }
    });
  }
  
  setFreierName(value: boolean): void {
    this.gliederungService.getGliederung(this.gliederungObject.id).subscribe((gliederung) => {
      gliederung.attributes.freier_name = value;
      this.gliederungService.updateGliederung(gliederung).subscribe((gliederung) => {
        this.gliederungObject = gliederung;
      }, (error: string) => {
        this.snackbar.open(error, null, {
          duration: 15000,
        });
      });
    });
  }
  
}