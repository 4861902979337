/**
 * Meine Anträge
 * 
 * Attila Németh, UBG
 * 09.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {Sort} from '@angular/material/sort';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {Antrag} from '../../../model/antrag';
import {AntragService} from '../../../services/antrag.service';
import {CookieService} from '@ubg/cookie-service';

@Component({
  selector: 'antrag-meine',
  templateUrl: './meine.component.html',
  styleUrls: ['./meine.component.scss'],
})
export class AntragMeineComponent implements OnInit, OnDestroy {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
      this.loadAntraege()
    }
  }
  projektObject: Projekt = null
  antraege: Array<Antrag> = []
  antragSubscription: Subscription
  columns: Array<string> = ['label', 'gliederung', 'title', 'goto',
                              'zuweisung', 'zuweisungsdatum', 'votum']
    
  constructor(private router: Router,
                private projektService: ProjektService,
                private antragService: AntragService,
                private cookieService: CookieService) {}
                
  ngOnInit(): void {
    this.antragSubscription = this.antragService.lastAntragUpdate.subscribe(() => {
      this.loadAntraege();
    });
  }
  
  ngOnDestroy(): void {
    this.antragSubscription.unsubscribe();
  }
  
  loadAntraege(): void {
    this.projektService.getMeineAntraege(this.projektObject).subscribe(antraege => {
      this.antraege = antraege;
    });
  }
  
  gotoAntrag(antrag: Antrag): void {
    const cookieId: string = 'mein-read-' + antrag.id;
    this.cookieService.set(cookieId, 1);
    this.router.navigate(['projekt/' + this.projektObject.id + '/antraege'], {
      fragment: 'antrag-' + antrag.id,
    })
  }
  
  getClass(antrag: Antrag): string {
    const cookieId: string = 'mein-read-' + antrag.id;
    const cookie = this.cookieService.get(cookieId);
    if (cookie === null) {
      return 'antrag-unread';
    }
    else {
      return 'antrag-read';
    }
  }

  sort(event: Sort): void {
    let sortedAntraege: Array<Antrag> = this.antraege.sort((antrag1: Antrag, antrag2: Antrag): number => {
      let values: Array<string> = [];
      let response: number = 0;
      switch(event.active) {
        case 'label':
          values.push(antrag1.attributes.label);
          values.push(antrag2.attributes.label);
          break;
        case 'gliederung':
          values.push(antrag1.attributes.gliederungen);
          values.push(antrag2.attributes.gliederungen);
          break;
        case 'zuweisung':
          values.push(antrag1.attributes.zuweisungen);
          values.push(antrag2.attributes.zuweisungen);
          break;
        case 'zuweisungsdatum':
          values.push(antrag1.attributes.zuweisungsdatum);
          values.push(antrag2.attributes.zuweisungsdatum);
          break;
        default:
          console.error('Unbekannte Sortierung', event.active);
          return 0;
      }
      if (values[0] < values[1]) {
        response = 1;
      }
      else {
        response = -1;
      }
      if (event.direction === 'desc') {
        response = response * -1;
      }
      return response;
    });
    this.antraege = [];
    for (let i in sortedAntraege) {
      this.antraege.push(sortedAntraege[i]);
    }
  }
  
}