/**
 * Initiativanträge
 * 
 * Attila Németh, UBG
 * 08.06.2020
 */
 
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {AbschnittService} from '../../../../services/abschnitt.service';
import {Abschnitt} from '../../../../model/abschnitt';
import {Antrag} from '../../../../model/antrag';
import {AntragEditDialogComponent} from '../../../antrag/dialog/antrag/antrag.component';
import {AntragService} from '../../../../services/antrag.service';
import {ConfirmComponent} from '@ubg/confirm';
import {AntragEvent} from '../../../../model/events';
import {FilterService} from '../../../../services/filter.service';

@Component({
  selector: 'abschnitt-initiativantraege',
  templateUrl: './abschnitt.component.html',
  styleUrls: ['../abschnitt.component.scss'],
})
export class AbschnittInititivantraegeComponent implements OnInit, OnDestroy {
    
  @Input() set abschnitt(abschnitt: Abschnitt) {
    this.abschnittObject = abschnitt;
    this.antragEventSubscription = this.antragService.antragEvent.subscribe((event: AntragEvent) => {
      if (event !== null && (event.name == 'loaded')) {
        this.reloadAbschnitt();
      }
    });
    this.setThemen();
  }
  @Input() set scroll(scroll: number) {
    if (scroll > 0) {
      let top: number = 76 - scroll;
      if (top < 8) {
        top = 8;
      }
      this.opsTop = top.toString() + 'px';
    }
  }
  abschnittObject: Abschnitt = null
  antragSubscription: Subscription
  lastAntragUpdate: number = new Date().getTime()
  dragSubscription: Subscription;
  isDragged: boolean = false
  antragEventSubscription: Subscription = null
  filterSubscription: Subscription
  opsTop: string = '76px'
  themen: Array<{
    id: string
    label: string
  }> = []
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private abschnittService: AbschnittService,
                private antragService: AntragService,
                private filterService: FilterService) {}
                
  ngOnInit(): void {
    this.antragSubscription = this.abschnittService.updateAbschnitt.subscribe(abschnitt => {
      if (abschnitt !== null && this.abschnittObject !== null && abschnitt.id === this.abschnittObject.id) {
        this.reloadAbschnitt();
      }
    });
    this.dragSubscription = this.abschnittService.dragAbschnitt.subscribe(abschnitt => {
      if (abschnitt !== null && this.abschnittObject !== null 
        && abschnitt.id === this.abschnittObject.id) {
        this.isDragged = true;
      }
      else {
        this.isDragged = false;
      }
    });
    this.filterSubscription = this.filterService.filterResult.subscribe(result => {
      if (result !== null && result.abschnitt.id === this.abschnittObject.id) {
        if (result.result === null) {
          this.reloadAbschnitt();
        }
        else {
          this.abschnittObject.relationships.antraege.data = result.result;
        }
      }
    });
  }
    
  ngOnDestroy(): void {
    this.antragSubscription.unsubscribe();
    if (this.antragEventSubscription !== null) {
      this.antragEventSubscription.unsubscribe();
    }
    this.filterSubscription.unsubscribe();
  }
  
  addAntrag(): void {
    let antrag = new Antrag;
    antrag.type = 'ubg_antrag--initiativantrag';
    antrag.create();
    antrag.attributes.status = true;
    this.dialog.open(AntragEditDialogComponent, {
      width: '640px',
      data: antrag,
    }).afterClosed().subscribe(result => {
      if (result !== '0') {
        this.antragService.createAntrag(result).subscribe(antrag => {
          this.antragService.addAntragToAbschnitt(antrag, this.abschnittObject).subscribe(() => {
            this.antragService.setBearbeitung(antrag).subscribe(() => {
              this.abschnittService.getExtended(this.abschnittObject.id).subscribe(abschnitt => {
                this.abschnittObject = abschnitt
              });
            }, (error: string) => {
              this.snackbar.open(error, null, {
                duration: 15000,
              });
            });
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      }
    });
  }
  
  reloadAbschnitt(): void {
    this.abschnittService.getExtended(this.abschnittObject.id).subscribe(abschnitt => {
      this.abschnittObject = abschnitt;
      this.setThemen();
      console.info('Der Abschnitt wurde neu geladen. Es gibt neue Anträge');
    });
  }
  
  drop(event: CdkDragDrop<string[]>) {    
    let currentAntragLabel: string;
    let destinationAntragLabel: string;
    let currentAntrag: Antrag;
    let destinationAntrag: Antrag;
    for (let i in this.abschnittObject.relationships.antraege.data) {
      if (i == event.previousIndex.toString()) {
        currentAntragLabel = this.abschnittObject.attributes.buchstabe + ' ' + 
          this.abschnittObject.relationships.antraege.data[i].attributes.nummer;
        currentAntrag = this.abschnittObject.relationships.antraege.data[i];
      }
      if (i == event.currentIndex.toString()) {
        destinationAntragLabel = this.abschnittObject.attributes.buchstabe + ' ' + 
          this.abschnittObject.relationships.antraege.data[i].attributes.nummer;
        destinationAntrag = this.abschnittObject.relationships.antraege.data[i];
      }
    }
    this.dialog.open(ConfirmComponent, {
      data: {
        question: 'Soll dar Antrag ' + currentAntragLabel + ' ständig vor dem Antrag ' + 
          destinationAntragLabel + ' gelistet werden?',
        hint: 'Diese Einstellung kann immer aufgehoben werden',
      }
    }).afterClosed().subscribe(result => {
      this.antragService.getAntrag(currentAntrag.type, currentAntrag.id).subscribe(antrag => {
        antrag.relationships.folgeantrag.data = destinationAntrag;
        this.antragService.updateAntrag(antrag).subscribe(() => {
          this.abschnittService.sortAntraege(this.abschnittObject).subscribe(() => {
            const newAntragLabel: string = this.abschnittObject.attributes.buchstabe + ' ' + 
              antrag.attributes.nummer;
            this.snackbar.open(currentAntragLabel + ' ist ab jetzt ' + 
              newAntragLabel, null, {
              duration: 6000,
            });
            this.abschnittService.setDragAbschnitt(null);
            this.abschnittService.setUpdateAbschnitt(this.abschnittObject);
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
        });
      });
    });
  }

  private setThemen(): void {
    let themaName: string;
    let previousThemaName: string = '';
    this.themen = [];
    for (let i in this.abschnittObject.relationships.antraege.data) {
      if (this.abschnittObject.relationships.antraege.data[i].relationships.thema !== undefined 
        && this.abschnittObject.relationships.antraege.data[i].relationships.thema.data !== null
        && this.abschnittObject.relationships.antraege.data[i].relationships.thema.data !== undefined) {
        themaName = this.abschnittObject.relationships.antraege.data[i].relationships.thema.data.attributes.title;
      }
      else {
        themaName = 'Kein Thema';
      }
      if (themaName !== previousThemaName) {
        previousThemaName = themaName;
        this.themen.push({
          id: this.abschnittObject.relationships.antraege.data[i].id,
          label: themaName,
        })
      }
    }
  }

  antragHasThema(antrag: Antrag): any {
    if (this.abschnittObject.attributes.sortierung === 'c') {
      return false;
    }
    for (let i in this.themen) {
      if (this.themen[i].id === antrag.id) {
        return this.themen[i].label;
      }
    }
    return false;
  }
  
}
