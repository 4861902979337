<p fxLayout="row">
  <span *ngIf="rednerObject !== null" fxFlex="2 2 auto">
    {{ rednerObject.attributes.title }}
  </span>
  <button mat-icon-button matTooltip="Bearbeiten" color="primary" (click)="editRedner()" fxFlex="1 1 2.5rem">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Löschen" color="primary" (click)="deleteRedner()" fxFlex="1 1 2.5rem">
    <mat-icon>delete</mat-icon>
  </button>
</p>