<h1 mat-dialog-title>Leitantrag in MS Word exportieren</h1>
<mat-dialog-content class="mat-body" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <mat-form-field>
    <mat-label>Darstellung</mat-label>
    <mat-select [(ngModel)]="view">
      <mat-option value="lines">Mit Zeilen (Broschüre-Ansicht)</mat-option>
      <mat-option value="text">Text</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="0" color="primary">
    <mat-icon>close</mat-icon> Verwerfen
  </button>
  <button mat-button [mat-dialog-close]="view" color="primary" [disabled]="view === null">
    <mat-icon>print</mat-icon> Drucken
  </button>
</mat-dialog-actions>