/**
 * Einstellungen
 * 
 * Attila Németh, UBG
 * 10.06.2020
 */
 
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors} from '@angular/forms'

import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {UserSettings} from '../../../antragswesen/services/user.service';

export interface Passwords {
  current: string
  new: string
  confirm: string
}

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class UserSettingsDialogComponent {
  
  disabled: boolean = true
  settingsForm: FormGroup
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: UserSettings,
              private formBuilder: FormBuilder) {
    this.settingsForm = this.formBuilder.group({
      e_mail_benachrichtigung: [data.e_mail_benachrichtigung],
    });
    this.settingsForm.get('e_mail_benachrichtigung').valueChanges.subscribe((value: boolean) => {
      this.data.e_mail_benachrichtigung = value;
    });
  }

}