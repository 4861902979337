<p fxLayout="row">
  <ng-container>
    <span *ngIf="themaObject.attributes !== undefined && themaObject.attributes.title !== null"
      fxFlex="1 1 auto">
    {{ themaObject.attributes.title }}
    </span>
    <span *ngIf="themaObject.attributes === undefined 
      || (themaObject.attributes !== undefined && themaObject.attributes.title === null)"
      fxFlex="1 1 auto">
      ...
    </span>
  </ng-container>
  <button mat-icon-button (click)="edit()" matTooltip="Bearbeiten" fxFlex="1 1 2.5rem"
          color="primary">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="deleteThema()" matTooltip="Löschen" fxFlex="1 1 2.5rem"
          color="primary">
    <mat-icon>delete</mat-icon>
  </button>
</p>