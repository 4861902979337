<form [formGroup]="votumForm">
  <mat-form-field>
    <mat-select formControlName="type" required placeholder="Votum">
      <mat-option *ngFor="let type of votumTypes" [value]="type">
        {{ type.attributes.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <!-- Fassung -->
  <ng-container *ngIf="votumZumLeitantrag() && votumHasField('fassung_text')">
    <p>
      Die Fassung der Antragskommission kann beim Leitantrag bearbeitet werden.
    </p>
  </ng-container>
  <ng-container *ngIf="!votumZumLeitantrag()">
    <mat-form-field *ngIf="votumHasField('fassung_titel') && !votumLeitantrag()">
      <input type="text" maxlength="255" placeholder="Titel der Fassung der AK" required
             formControlName="fassung_titel" matInput>
      <mat-error *ngIf="!votumForm.get('fassung_titel').valid">
        Der Titel muss angegeben werden
      </mat-error>
    </mat-form-field>
    <button mat-button color="accent" (click)="resetFassung()" *ngIf="votumHasField('fassung_text')">
      <mat-icon>refresh</mat-icon> Fassung zurücksetzen
    </button>
    <cketext formControlName="fassung_text" placeholder="Text der Fassung der AK" required
             *ngIf="votumHasField('fassung_text')">
    </cketext>
  </ng-container>
  <!-- /Fassung -->
  
  <mat-form-field *ngIf="votumHasField('f_antrag')">
    <mat-progress-bar mode="query" *ngIf="isFAntragLoading">
    </mat-progress-bar>
    <input matInput type="text" placeholder="Antrag"
           formControlName="f_antrag" [matAutocomplete]="autoFAntrag" required>
    <mat-autocomplete #autoFAntrag="matAutocomplete"
      (optionSelected)="fAntragSelected($event)">
      <mat-option *ngFor="let antrag of fAntragFilteredOptions" [value]="antrag.id"
        [innerHtml]="antrag.attributes.label">
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="!votumForm.get('f_antrag').valid && votumForm.get('f_antrag').touched">
      <span>Ein Antrag muss ausgewählt werden</span>
    </mat-error>
  </mat-form-field>
  
  <ueberweisung-select *ngIf="votumHasField('ueberweisung')" [options]="ueberweisungOptions" formControlName="ueberweisung">
  </ueberweisung-select>
  <mat-error *ngIf="!votumForm.get('ueberweisung').valid 
    && votumForm.get('ueberweisung').touched">
    Die Überweisung muss ausgewählt werden
  </mat-error>
  <!--
  <mat-form-field *ngIf="votumHasField('ueberweisung')">
    <input matInput type="text" placeholder="Überweisung"
             formControlName="ueberweisung" [matAutocomplete]="autoUeberweisung" required>
      <mat-autocomplete #autoUeberweisung="matAutocomplete" 
        (optionSelected)="ueberweisungSelected($event)">
        <mat-option *ngFor="let option of ueberweisungFilteredOptions" [value]="option.id">
          {{ option.attributes.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="!votumForm.get('ueberweisung').valid 
        && votumForm.get('ueberweisung').touched">
        Die Überweisung muss ausgewählt werden
      </mat-error>
  </mat-form-field>
-->
  <mat-form-field *ngIf="votumHasField('ziffer')">
    <mat-select placeholder="Ziffer" formControlName="ziffer" required>
      <mat-option *ngFor="let antrag of zifferOptions" [value]="antrag">
        <span [innerHtml]="antrag.attributes.label"></span> ({{ antrag.attributes.title }})
      </mat-option>
    </mat-select>
    <mat-error *ngIf="!votumForm.get('ziffer').valid 
      && votumForm.get('ziffer').touched">
      Die Ziffer muss ausgewählt werden
    </mat-error>
  </mat-form-field>
  <cketext formControlName="generierter_text" placeholder="Votum" required
           *ngIf="votum !== null && votum.type == 'ubg_votum--sonstiges'">
  </cketext>
  <mat-divider></mat-divider>
  <cketext formControlName="begruendung" placeholder="Begründung der Antragskommission"
           *ngIf="votum !== null && votum.id === undefined">
  </cketext>
</form>