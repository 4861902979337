<mat-card>
  <mat-card-content *ngIf="mode === 'view' && zifferInhalt !== null">
    <div fxLayout="row" fxLayoutGap="0.5rem">
      <div fxFlex="3 3 auto">
        <p class="number">Ziffer {{ zifferInhalt.nummer }}</p>
        <div [innerHtml]="zifferInhalt.inhalt"></div>
      </div>
      <mat-action-list fxFlex="1 1 12rem">
        <button mat-list-item (click)="edit()">
          <mat-icon>edit</mat-icon> Bearbeiten
        </button>
        <button mat-list-item (click)="deleteZiffer()">
          <mat-icon>delete</mat-icon> Löschen
        </button>
      </mat-action-list>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="mode === 'edit'">
    <form [formGroup]="zifferForm" (ngSubmit)="updateZiffer()">
      <cketext formControlName="inhalt" placeholder="Inhalt">
      </cketext>
      <mat-error *ngIf="!zifferForm.get('inhalt').valid && zifferForm.get('inhalt').touched">
        <span *ngIf="zifferForm.get('inhalt').errors.toolong">
          Ein oder mehr Wörter sind zu lang. Nur Würter mit max. 240 Zeichen sind erlaubt.
        </span>
      </mat-error>
      <button mat-stroked-button [disabled]="disabled" color="primary">
        Ziffer eintragen
      </button>
    </form>
  </mat-card-content>
</mat-card>