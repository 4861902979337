/**
 * Gliederungen
 * 
 * Attila Németh, UBG
 * 12.05.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {ProjektSelectDialogComponent} from '../dialog/select/select.component';
import {Abschnitt} from '../../../model/abschnitt';
import {AbschnittService} from '../../../services/abschnitt.service';

@Component({
  selector: 'projekt-gliederungen',
  templateUrl: './gliederungen.component.html',
})
export class ProjektGliederungenComponent {
  
  @Input() set projekt(projekt: Projekt) {
    if (projekt !== null) {
      this.projektObject = projekt;
      this.hasAntraege = false;
      for (let i in this.projektObject.relationships.abschnitte.data) {
        this.abschnittService.get(this.projektObject.relationships.abschnitte.data[i].type, this.projektObject.relationships.abschnitte.data[i].id).subscribe((response: {
          data: Abschnitt
        }) => {
          if (response.data.relationships.antraege.data.length > 0) {
            this.hasAntraege = true;
          }
        });
      }
    }
  }
  projektObject: Projekt = null
  isLoading: boolean = false
  hasAntraege: boolean = false
  
  constructor(private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private projektService: ProjektService,
                private abschnittService: AbschnittService) {}
  
  uebernehmen(): void {
    this.dialog.open(ProjektSelectDialogComponent, {
      width: '480px',
      data: {
        exclude: [this.projektObject],
      }
    }).afterClosed().subscribe(result => {
      if (result !== undefined && result !== '0') {
        this.projektObject.relationships.gliederungen.data = [];
        this.isLoading = true;
        this.projektService.uebernehmen(result, this.projektObject, 'gliederungen').subscribe(() => {
          this.projektService.get(this.projektObject.id).subscribe((response: {
            data: Projekt
          }) => {
            this.projektObject = response.data;
            this.isLoading = false;
          });
        }, (error: string) => {
          this.snackbar.open(error, null, {
            duration: 15000,
          });
          this.isLoading = false;
        });
      }
    });
  }
  
}