/**
 * Abschnitt
 * 
 * Attila Németh, UBG
 * 24.04.2020
 */
 
import {Antrag} from './antrag';
 
export class Abschnitt {
  
  id: string
  type: string
  attributes: {
    title: string
    buchstabe: string
    sortierung?: string
    gliederung?: string     // Virtueller Attribut
  }
  relationships: {
    antraege: {
      data: Array<Antrag>
    }
  }
  
  create(): void {
    this.type = null;
    this.attributes = {
      title: null,
      buchstabe: null,
      sortierung: null
    };
    this.relationships = {
      antraege: {
        data: null,
      }
    };
  }
  
}

export interface AbschnittBeschluss {
  
  title: string
  text: string
  
}