<div class="actions" fxLayout="row" fxLayoutGap="1rem">
  <button mat-flat-button color="accent" matTooltip="Neu laden" *ngIf="hasNew"
          (click)="loadAntraege()" fxFlex="1 1 64px">
    <mat-icon>notifications</mat-icon>
  </button>
  <button mat-flat-button color="primary" matTooltip="Drucken"
          (click)="printAntraege()" fxFlex="1 1 64px">
    <mat-icon>print</mat-icon>
  </button>  
  <mat-checkbox (change)="changeZuweisung($event)" color="primary" fxFlex="1 1 auto">
    Keine Zuweisung
  </mat-checkbox>
  <mat-checkbox (change)="changeVotum($event)" color="primary"  fxFlex="1 1 auto">
    Kein Votum
  </mat-checkbox>
</div>

<mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>

<attus-mat-banner *ngIf="loadError !== null">
  <attus-mat-banner-text>
    Die Anträge können nicht geladen werden: {{ loadError }}
  </attus-mat-banner-text>
  <attus-mat-banner-actions>
    <button mat-button (click)="loadAntraege()" color="primary">
      Erneut versuchen
    </button>
  </attus-mat-banner-actions>
</attus-mat-banner>

<div class="table-wrapper" *ngIf="loadError === null">
  <table mat-table [dataSource]="antraege" *ngIf="!isLoading"  matSort (matSortChange)="sort($event)">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Antrag</th>
      <td mat-cell *matCellDef="let antrag" [innerHtml]="antrag.attributes.label"></td>
    </ng-container>
    <ng-container matColumnDef="open">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let antrag">
        <button mat-icon-button color="primary" (click)="openAntrag(antrag)">
          <mat-icon>launch</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="gliederung">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Antragsteller</th>
      <td mat-cell *matCellDef="let antrag">{{ antrag.attributes.gliederungen }}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Titel</th>
      <td mat-cell *matCellDef="let antrag"> {{ antrag.attributes.title }} </td>
    </ng-container>
    <ng-container matColumnDef="goto">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let antrag">
        <button mat-icon-button color="primary" matTooltip="Zum Antrag" (click)="gotoAntrag(antrag)">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="eingangsdatum">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Eingangsdatum</th>
      <td mat-cell *matCellDef="let antrag"> {{ antrag.attributes.eingangsdatum | date }} </td>
    </ng-container>
    <ng-container matColumnDef="zuweisung">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="!offeneParams.zuweisung">Zuweisung</span>
      </th>
      <td mat-cell *matCellDef="let antrag">
        <span *ngIf="antrag.attributes.zuweisungen !== undefined">
          {{ antrag.attributes.zuweisungen }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="zuweisung_button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let antrag">
        <button mat-icon-button color="primary" (click)="zuweisungAntrag(antrag)"
                matTooltip="Zuweisen">
          <mat-icon>assignment_ind</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="votum">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="!offeneParams.votum">Votum</span>
      </th>
      <td mat-cell *matCellDef="let antrag">
        <span *ngIf="antrag.attributes.votum !== undefined">
          {{ antrag.attributes.votum.label }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" [ngClass]="getAntragClass(row)"></tr>
  </table>
</div>