/**
 * Abschnitt-Selektor
 * 
 * Attila Németh, UBG
 * 18.05.2020
 */
 
import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

import {Projekt} from '../../../model/projekt';
import {ProjektService} from '../../../services/projekt.service';
import {Abschnitt} from '../../../model/abschnitt';
import {AbschnittService} from '../../../services/abschnitt.service';

@Component({
  selector: 'abschnitt-selektor',
  templateUrl: './selektor.component.html',
  styleUrls: ['./selektor.component.scss'],
})
export class AbschnittSelektorComponent implements OnDestroy {
  
  projekt: Projekt
  abschnitte: Array<Abschnitt> = []
  activeAbschnitt: Abschnitt = null
  isLarge: boolean = false
  breakpoint: string = '(min-width: 960px)';
  match: MediaQueryList
  breakpointSubscription: Subscription
  projektSubscription: Subscription = null
  
  constructor(breakpointObserver: BreakpointObserver,
                private router: Router,
                private location: Location,
                private projektService: ProjektService,
                private abschnittService: AbschnittService) {
    this.breakpointSubscription = breakpointObserver.observe(this.breakpoint).subscribe(result => {
      this.isLarge = result.matches;
    });
    this.projektSubscription = this.projektService.activeProjekt.subscribe(projekt => {
      if (projekt !== null) {
        this.projekt = projekt;
        this.projektService.getAbschnitte(this.projekt).subscribe(abschnitte => {
          this.abschnitte = abschnitte;
          this.abschnittService.activeAbschnitt.subscribe(abschnitt => {
            this.activeAbschnitt = abschnitt;
          });
          let current: Abschnitt = this.abschnittService.getActiveAbscnhitt();
          if (current === null) {
            this.abschnittService.setActiveAbschnitt(this.abschnitte[0]);
          }
          else {
            this.activeAbschnitt = current;
          }
        });
      }
    })
  }
  
  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
    if (this.projektSubscription !== null) {
      this.projektSubscription.unsubscribe();
    }
  }
  
  setActiveAbschnitt(abschnitt: Abschnitt): void {
    const url: string = this.router.url.replace(/\#(.*?)$/, '');
    this.router.navigate([url]);
    setTimeout(() => {      
      this.abschnittService.setActiveAbschnitt(abschnitt);
    }, 150);
  }

}

 
