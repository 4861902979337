<ng-container *ngIf="projekte.length > 1 && projekt !== null">
  <button mat-button [matMenuTriggerFor]="changeProjektMenu" matTooltip="Anderes Projekt wählen">
    <mat-icon>swap_horiz</mat-icon>
  </button>
  <mat-menu #changeProjektMenu="matMenu">
    <ng-container *ngFor="let pr of projekte">
      <button mat-menu-item *ngIf="pr.attributes.aktiv"
            (click)="gotoProjekt(pr)" [disabled]="pr.id === projekt.id">
        {{ pr.attributes.title }}
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
<!--
<button mat-icon-button matTooltip="Zurück" *ngIf="backRoute !== null" (click)="goBack()">
  <mat-icon>navigate_before</mat-icon>
</button>
-->
<button mat-button matTooltip="Übersicht" *ngIf="projekt !== null && isMeinAntrag && projektActivePage === 'meine-abschnitte'" 
        (click)="gotoMeinHome()">
  <mat-icon>navigate_before</mat-icon>
</button>
<button mat-icon-button matTooltip="Übersicht" 
        *ngIf="projekt !== null && projektActivePage !== 'modal' && viewAccess && !isGliederung" 
        (click)="gotoProjekt(projekt)">
  <mat-icon>home</mat-icon>
</button>
<button mat-button matTooltip="Übersicht" *ngIf="projekt !== null && projektActivePage === 'modal'" (click)="gotoProjektHome()">
  <mat-icon>navigate_before</mat-icon>
</button>