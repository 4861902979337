/**
 * Anmeldung für Gliederungen
 * 
 * Attila Németh, UBG
 * 28.05.2020
 */
 
import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mein-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class ProjektMeinAntragLoginComponent implements OnInit, OnDestroy {
  
  @Output() login = new EventEmitter
  isLoggedIn: boolean = false
  loginForm: FormGroup
  password: string = null
  passwordSubscription: Subscription
  
  constructor(formBuilder: FormBuilder) {
    this.loginForm = formBuilder.group({
      password: [null],
    });
  }
  
  ngOnInit(): void {
    this.passwordSubscription = this.loginForm.get('password').valueChanges.subscribe(value => {
      this.password = value;
    });
  }
  
  ngOnDestroy(): void {
    this.passwordSubscription.unsubscribe();
  }
  
  emitLogin(): void {
    this.login.emit(this.password);
  }

                
}