<table mat-table [dataSource]="antraege" *ngIf="antraege.length > 0" matSort (matSortChange)="sort($event)">
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Antrag</th>
    <td mat-cell *matCellDef="let antrag" [innerHtml]="antrag.attributes.label"></td>
  </ng-container>
  <ng-container matColumnDef="gliederung">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Antragsteller</th>
    <td mat-cell *matCellDef="let antrag">{{ antrag.attributes.gliederungen }}</td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Titel</th>
    <td mat-cell *matCellDef="let antrag"> {{ antrag.attributes.title }} </td>
  </ng-container>
  <ng-container matColumnDef="goto">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let antrag">
      <button mat-icon-button color="primary" matTooltip="Zum Antrag" (click)="gotoAntrag(antrag)">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="zuweisung">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zuweisung</th>
    <td mat-cell *matCellDef="let antrag">
      <span *ngIf="antrag.attributes.zuweisungen !== undefined">
        {{ antrag.attributes.zuweisungen }}
      </span>
    </td>
  </ng-container>       
  <ng-container matColumnDef="zuweisungsdatum">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zuweisungsdatum</th>
    <td mat-cell *matCellDef="let antrag">
      <span *ngIf="antrag.attributes.zuweisungsdatum !== undefined">
        {{ antrag.attributes.zuweisungsdatum | date }}
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="votum">
    <th mat-header-cell *matHeaderCellDef>Abgeschlossen</th>
    <td mat-cell *matCellDef="let antrag">
      <mat-icon *ngIf="antrag.attributes.votum !== undefined">
        checked
      </mat-icon>
    </td>
  </ng-container>       
  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let antrag; columns: columns;" [ngClass]="getClass(antrag)"></tr>
</table>